import { FC, useEffect } from "react";
import Modal from "../wrapper/modal";
import InputField from "../components/shared/InputField";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { useHandelSubCategory } from "../hooks/category/useHandelSubCategory";
import { DropdownOptionModel } from "../model/DropdownOption";
import Dropdown from "../components/shared/Dropdown";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { handelInputMap } from "../util/handelInputMap";

type Props = {
  open: boolean;
  defaultValue?: any;
  categories: DropdownOptionModel[];
  onClose: (state: boolean) => void;
  intl: IntlShape;
};

const SubCategoryModal: FC<Props> = ({
  open,
  onClose,
  categories,
  defaultValue,
  intl,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    setValue,
    reset,
    selectedCategory,
    setSelectedCategory,
  } = useHandelSubCategory(onClose);

  useEffect(() => {
    if (defaultValue && categories) {
      setValue("title_fa", defaultValue.title_fa);
      setValue("title_ps", defaultValue.title_ps);
      setValue("title_en", defaultValue.title_en);
      setSelectedCategory(
        categories.find((item) => item.id === defaultValue?.category?.id)
      );
    } else {
      setSelectedCategory(categories[0]);
      reset();
    }
  }, [defaultValue, categories]);

  const onSubmitSubCategory = (data: any) => {
    if (defaultValue) {
      onUpdate(data, defaultValue.id);
    } else {
      onSubmit(data);
      reset();
    }
  };
  return (
    <Modal className="max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full py-10">
        <div className="items-center">
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.ADD_SUB_CATEGORY.id}
              defaultMessage={TRANSLATION.ADD_SUB_CATEGORY.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.ENTER_SUBCATEGORY_INFORMATION.id}
              defaultMessage={
                TRANSLATION.ENTER_SUBCATEGORY_INFORMATION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmitSubCategory)}
        >
          <div className="w-full mt-5">
            <Dropdown
              label={handelInputMap(intl, "main_category")}
              placeholder={handelInputMap(intl, "main_category_placeholder")}
              options={categories}
              onChange={setSelectedCategory}
              defaultValue={selectedCategory}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="title_fa"
              label={handelInputMap(intl, "title_fa")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.title_fa}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="title_ps"
              label={handelInputMap(intl, "title_ps")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.title_ps}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="title_en"
              label={handelInputMap(intl, "title_en")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              register={register}
              errors={errors?.title_en}
              required={true}
            />
          </div>
          <div className="flex flex-row gap-x-2 pt-6">
            <Button
              onClick={() => onClose(false)}
              size="medium"
              theme="error"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button
              size="medium"
              type="submit"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.ADD.id}
                  defaultMessage={TRANSLATION.ADD.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SubCategoryModal;
