/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from "clsx";
import React, {
  ChangeEvent,
  ReactElement,
  useState,
  KeyboardEvent,
  useRef,
  useEffect,
} from "react";
import EyeIcon from "../icons/EyeIcon";
// import InfoIcon from '../icons/InfoIcon';
// import HelpIcon from '../icons/HelpIcon';
// import EyeIcon from '../icons/EyeIcon';
// import EyeOffIcon from '../icons/EyeOffIcon';
import { Typo } from "./typo";
import EyeOffIcon from "../icons/EyeOffIcon";

type Props = {
  defaultValue?: string;
  errors?: any;
  placeholder?: string;
  register: (name: any, registerOptions: any) => any;
  onkeydown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChangeOption?: (value: string) => void;
  name: string;
  registerOptions?: any;
  label?: string;
  suffix?: string | ReactElement;
  prefix?: string | ReactElement;
  prefixHasBorder?: boolean;
  helpIcon?: boolean;
  hintText?: string;
  options?: Array<string>;
  defaultOption?: string;
  type?: string;
  disabled?: boolean;
  className?: string;
  verificationtext?: string;
  labelStyle?: string;
  required?: boolean;
  value?: string | number;
  inputStyle?: string;
  autoComplete?: boolean;
  [x: string]: any;
};

const InputField: React.FC<Props> = (props) => {
  const {
    defaultValue = "",
    errors = {},
    placeholder = "",
    register,
    onkeydown,
    name,
    registerOptions = {},
    label = "",
    suffix = undefined,
    prefix = undefined,
    prefixHasBorder = false,
    helpIcon = false,
    hintText = "",
    onChangeOption,
    options = [],
    defaultOption,
    type = "text",
    disabled = false,
    className = "",
    labelStyle = "",
    required = false,
    inputStyle = "",
    autoComplete = false,
    value,
    ...rest
  } = props;

  const [togglePassword, setTogglePassword] = useState(type);
  const onChangeDropdown = (event: ChangeEvent<HTMLSelectElement>) => {
    onChangeOption && onChangeOption(event.target.value);
  };

  // Create a ref for the input element and another ref for the prefix element
  const prefixRef = useRef<HTMLDivElement | null>(null);

  // Use state to track the left padding value
  const [leftPadding, setLeftPadding] = useState<number | undefined>(14);

  // Calculate the left padding based on the width of the prefix element
  useEffect(() => {
    if (prefix && prefixRef.current) {
      const prefixWidth = prefixRef.current.clientWidth;
      // Set the left padding to the width of the prefix element + some extra spacing
      setLeftPadding(prefixHasBorder ? prefixWidth + 16 : prefixWidth); // Adjust the spacing as needed
    }
  }, [prefix]);

  return (
    <div className="w-full relative space-y-1.5">
      {label && (
        <label htmlFor={name || ""} className="text-start">
          <Typo.heading4
            fontWeight="font-medium"
            className={` text-gray-800 ${labelStyle}`}
          >
            {label}
            {required ? " * " : ""}
          </Typo.heading4>
        </label>
      )}
      <div className=" relative">
        <div className="block w-full">
          <div className="relative ">
            {prefix && (
              <div
                ref={prefixRef}
                className={`pointer-events-none absolute ps-[14px] pe-2 inset-y-0 left-0 flex items-center ${
                  prefixHasBorder ? "border-r border-r-gray-300 " : ""
                }`}
              >
                <Typo.bodyD fontWeight="font-normal" className="text-gray-400 ">
                  {prefix}
                </Typo.bodyD>
              </div>
            )}
            <input
              style={{ paddingLeft: `${leftPadding}px` }}
              {...register(name, registerOptions)}
              placeholder={placeholder}
              name={name}
              type={togglePassword}
              defaultValue={defaultValue}
              autoComplete={`${autoComplete}`}
              disabled={disabled}
              value={value}
              onKeyDown={onkeydown}
              className={clsx(
                `pe-[14px] w-full py-[14px] bg-white placeholder:text-gray-400 font-iransans text-sm 
                ${inputStyle ? inputStyle : "rounded-lg border shadow-xs"}`,
                errors?.message == null
                  ? `border-gray-300 text-gray-900 focus:ring-4 focus:ring-gray-100 focus:border-none ${
                      inputStyle
                        ? ""
                        : " focus:border-gray-300 focus:shadow-base"
                    }  disabled:bg-gray-50 disabled:text-gray-400 `
                  : "  border-error-300 text-gray-400 focus:border-error-300 focus:ring-error-100 ",
                options?.length !== 0 ? " pe-[74px] " : "",
                options?.length !== 0 && errors?.message ? " pe-[98px] " : "",
                options?.length !== 0 && helpIcon ? " pe-[98px] " : "",
                (helpIcon || errors?.message) && options?.length == 0
                  ? " pe-[42px] "
                  : "",
                className
              )}
              {...rest}
            />

            <div className="absolute inset-y-0 -end-2 flex items-center">
              <div className="flex gap-x-[6px] pe-[14px]">
                <div className="flex items-center">
                  {suffix ? (
                    <div className="pointer-events-none">
                      <Typo.heading4
                        fontWeight="font-normal"
                        className="text-gray-900"
                      >
                        {suffix}
                      </Typo.heading4>
                    </div>
                  ) : (
                    ""
                  )}
                  {helpIcon && !errors?.message ? (
                    <div></div>
                  ) : (
                    // <HelpIcon className="pointer-events-none text-gray-400"></HelpIcon>
                    ""
                  )}
                  {/* {errors?.message ? <InfoIcon className="text-error-500"></InfoIcon> : ''} */}
                  {type === "password" && !errors?.message && !disabled ? (
                    <div
                      onClick={
                        togglePassword === "password"
                          ? () => setTogglePassword("text")
                          : () => setTogglePassword("password")
                      }
                      className=""
                    >
                      {togglePassword === "password" ? (
                        <EyeIcon className="h-6 w-6 text-gray-700"></EyeIcon>
                      ) : (
                        <EyeOffIcon className="h-5 text-gray-700"></EyeOffIcon>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {options?.length !== 0 && (
                  <select
                    id="currency"
                    name="currency"
                    value={defaultOption}
                    onChange={onChangeDropdown}
                    className="rounded-lg focus:ring-0 h-10 shadow-none border-none outline-none"
                  >
                    {options?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          <Typo.heading4
                            fontWeight="font-normal"
                            className="text-gray-500 p-2"
                          >
                            {item}
                          </Typo.heading4>
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {errors?.message || hintText ? (
        <Typo.heading4
          fontWeight="font-normal"
          className={`w-full text-start absolute pb-4 ${
            errors?.message ? "text-error-500" : "text-gray-500"
          }`}
        >
          {errors?.message ? errors?.message : hintText}
        </Typo.heading4>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputField;
