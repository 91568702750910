import { useState } from "react";
import AgencyInfo from "./AgencyInfo";
import AgencyFilter from "./AgencyFilter";
import { useParams } from "react-router-dom";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import { useHandelAgency } from "../../../hooks/user/useHandelAgency";
import { ListingObject, ListingProp } from "../../../model/Listing";
import Pagination from "../../../components/shared/Pagination";
import ListingCard from "../../../components/shared/Card/ListingCard";
import { useIntl } from "react-intl";
import { useAuth } from "../../../context/auth";
import { useLang } from "../../../context/language";
const AgencyDetail = () => {
  const LIMIT = 2;
  const intl = useIntl();
  const auth = useAuth();
  const lang = useLang();
  const { id } = useParams();
  const [filter, setFilter] = useState<ListingProp>({
    page: 1,
    limit: 5,
    purpose: "",
    address: "",
    currency: "",
    unit: "",
    title: "",
  });
  const { ToGetListings, onLike, onDisLike } = useHandelListing({ intl });
  const { ToGetAgency } = useHandelAgency();

  const listings = ToGetListings(
    { page: filter.page, limit: LIMIT, agencyId: Number(id) },
    true
  );
  const agency = ToGetAgency(Number(id));

  const onChange = (name: string, value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  return (
    <div className="w-full">
      <AgencyInfo
        agency={agency?.data}
        direction={lang.direction}
        locale={lang.language}
      />
      <div className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-7 mt-20">
          <div className=" hidden lg:block lg:col-span-2">
            <AgencyFilter intl={intl} />
          </div>
          <div className="col-span-4 lg:col-span-5">
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {listings?.data?.rows?.map((listing: ListingObject) => {
                return (
                  <ListingCard
                    key={listing?.id}
                    listing={listing}
                    background="white"
                    user={auth?.user}
                    direction={lang.direction}
                    intl={intl}
                    onLike={onLike}
                    onDisLike={onDisLike}
                    locale={lang.language}
                  />
                );
              })}
            </div>
            <div className="mt-28">
              {listings?.data?.count && (
                <Pagination
                  onPageChange={(page: number) => onChange("page", page)}
                  total={listings.data.count}
                  perPage={LIMIT}
                  currentPage={filter?.page ? filter.page : 1}
                  type="simple-card-footer"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetail;
