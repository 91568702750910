import React, { useState } from "react";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import ProvinceModal from "../../../modal/ProvinceModal";
import Pagination from "../../../components/shared/Pagination";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import TrashIcon from "../../../components/icons/TrashIcon";
import { Province } from "../../../model/Province";
import ConfirmModal from "../../../modal/ConfirmModal";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "../../../context/language";
import { getLocaleProvinces } from "../../../util/appUtil";
const Provinces = () => {
  // Define the columns and data for the table
  const LIMIT = 10;
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [page, setPage] = useState(1);
  const [province, setProvince] = useState<Province>();
  const { ToGetProvinces, onDelete } = useHandelProvince();
  const provinces: any = ToGetProvinces(page, LIMIT);
  const intl = useIntl();
  const lang = useLang();

  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "number"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "country_name"),
        accessor: "country.country_fa",
      },
      {
        Header: handelInputMap(intl, "country_code"),
        accessor: "country.countryCode",
      },
      {
        Header: handelInputMap(intl, "state_fa"),
        accessor: "state_fa",
      },
      {
        Header: handelInputMap(intl, "state_ps"),
        accessor: "state_ps",
      },
      {
        Header: handelInputMap(intl, "state_en"),
        accessor: "state_en",
      },

      {
        Header: handelInputMap(intl, "actions"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                icon={<Edit2Icon className="h-4 w-4" />}
                onClick={() => handleEdit(cell?.row?.original)}
              ></Button>
              <Button
                theme="error"
                size="normal"
                icon={<TrashIcon className="h-4 w-4" />}
                onClick={() => handleDelete(cell?.row?.original)}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEdit = (province: any) => {
    setProvince(province);
    setShowModal(true);
  };

  const handleDelete = (province: any) => {
    setConfirm(true);
    setProvince(province);
  };

  const onConfirm = (state: boolean) => {
    if (state && province) {
      onDelete(Number(province.id));
    }
    setConfirm(false);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.PROVINCE_TITLE.id}
              defaultMessage={TRANSLATION.PROVINCE_TITLE.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading3 className="text-gray-600 mt-2">
            <FormattedMessage
              id={TRANSLATION.PROVINCE_DESCRIPTION.id}
              defaultMessage={TRANSLATION.PROVINCE_DESCRIPTION.defaultMessage}
            />
          </Typo.heading3>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Button
            onClick={() => {
              setProvince(undefined);
              setShowModal(true);
            }}
            size="small"
            theme="primary"
          >
            <Typo.heading5 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.ADD.id}
                defaultMessage={TRANSLATION.ADD.defaultMessage}
              />
            </Typo.heading5>
          </Button>
        </div>
      </div>
      <div className="py-10">
        <Table columns={columns} data={provinces?.data?.rows || []} />
      </div>
      {provinces?.data?.count && (
        <Pagination
          onPageChange={setPage}
          total={provinces?.data?.count}
          currentPage={page}
          perPage={10}
          type="centered-page-number"
          direction={lang.direction}
        />
      )}
      <ProvinceModal
        defaultValue={province}
        open={showModal}
        onClose={setShowModal}
        intl={intl}
        provinces={getLocaleProvinces(provinces?.data)}
        locale={lang.language}
      />
      <ConfirmModal
        icon={<TrashIcon className="h-10" />}
        title={TRANSLATION.DELETE_PROVINCE}
        description={TRANSLATION.DELETE_PROVINCE_MESSAGE}
        open={confirm}
        onClose={onConfirm}
      />
    </div>
  );
};

export default Provinces;
