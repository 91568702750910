import React from "react";
import { Typo } from "./typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import Spinner from "./Spinners";
type Props = {
  className?: string;
  showText?: boolean;
};

const Loader: React.FC<Props> = (props) => {
  const { className, showText = false } = props;

  return (
    <div
      className={`bg-white flex flex-col gap-y-5 items-center justify-center ${className}`}
    >
      <Spinner className="h-10 w-10"></Spinner>
      {showText && (
        <div className=" text-center">
          <Typo.heading2 fontWeight="font-medium" className="text-gray-700">
            <FormattedMessage
              id={TRANSLATION.LOADING.id}
              defaultMessage={TRANSLATION.LOADING.defaultMessage}
            />
          </Typo.heading2>
        </div>
      )}
    </div>
  );
};

export default Loader;
