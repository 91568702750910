type Props = {
  className?: string;
};
const ShareIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 11.875C13.8682 11.875 12.884 12.4858 12.3357 13.39L7.89311 11.1692C8.03977 10.8075 8.12484 10.4142 8.12484 10C8.12484 9.58583 8.03977 9.19249 7.89311 8.83082L12.3357 6.61001C12.884 7.51334 13.8682 8.125 14.9998 8.125C16.7223 8.125 18.1248 6.72333 18.1248 5C18.1248 3.27667 16.7223 1.875 14.9998 1.875C13.2773 1.875 11.8748 3.27667 11.8748 5C11.8748 5.14417 11.8982 5.28251 11.9174 5.42168L7.19649 7.78168C6.63149 7.22168 5.85569 6.875 4.99902 6.875C3.27652 6.875 1.87402 8.27667 1.87402 10C1.87402 11.7233 3.27652 13.125 4.99902 13.125C5.85486 13.125 6.63149 12.7775 7.19649 12.2183L11.9174 14.5783C11.8982 14.7175 11.8748 14.8558 11.8748 15C11.8748 16.7233 13.2773 18.125 14.9998 18.125C16.7223 18.125 18.1248 16.7233 18.1248 15C18.1248 13.2767 16.7223 11.875 14.9998 11.875ZM14.9998 3.125C16.0332 3.125 16.8748 3.96583 16.8748 5C16.8748 6.03417 16.0332 6.875 14.9998 6.875C13.9665 6.875 13.1248 6.03417 13.1248 5C13.1248 3.96583 13.9665 3.125 14.9998 3.125ZM4.99984 11.875C3.9665 11.875 3.12484 11.0342 3.12484 10C3.12484 8.96583 3.9665 8.125 4.99984 8.125C6.03317 8.125 6.87484 8.96583 6.87484 10C6.87484 11.0342 6.03317 11.875 4.99984 11.875ZM14.9998 16.875C13.9665 16.875 13.1248 16.0342 13.1248 15C13.1248 13.9658 13.9665 13.125 14.9998 13.125C16.0332 13.125 16.8748 13.9658 16.8748 15C16.8748 16.0342 16.0332 16.875 14.9998 16.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShareIcon;
