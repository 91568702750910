import { TRANSLATION } from "../static/translation";

export const handelListingState = (intl: any) => {
  const states: { [key: string]: string } = {
    pending: intl.formatMessage({
      id: TRANSLATION.PENDING.id,
      defaultMessage: TRANSLATION.PENDING.defaultMessage,
    }),
    active: intl.formatMessage({
      id: TRANSLATION.ACTIVE.id,
      defaultMessage: TRANSLATION.ACTIVE.defaultMessage,
    }),
    expired: intl.formatMessage({
      id: TRANSLATION.EXPIRED.id,
      defaultMessage: TRANSLATION.EXPIRED.defaultMessage,
    }),
    inactive: intl.formatMessage({
      id: TRANSLATION.INACTIVE.id,
      defaultMessage: TRANSLATION.INACTIVE.defaultMessage,
    }),
    rejected: intl.formatMessage({
      id: TRANSLATION.REJECTED.id,
      defaultMessage: TRANSLATION.REJECTED.defaultMessage,
    }),
    deleted: intl.formatMessage({
      id: TRANSLATION.DELETED.id,
      defaultMessage: TRANSLATION.DELETED.defaultMessage,
    }),
    edited: intl.formatMessage({
      id: TRANSLATION.EDITED.id,
      defaultMessage: TRANSLATION.EDITED.defaultMessage,
    }),
  };
  return states;
};

export const handelGolasMap = (intl: any, key: string) => {
  const goals: { [key: string]: string } = {
    sell: intl.formatMessage({
      id: TRANSLATION.SELL.id,
      defaultMessage: TRANSLATION.SELL.defaultMessage,
    }),
    rent: intl.formatMessage({
      id: TRANSLATION.RENT.id,
      defaultMessage: TRANSLATION.RENT.defaultMessage,
    }),
    mortgage: intl.formatMessage({
      id: TRANSLATION.MORTGAGE.id,
      defaultMessage: TRANSLATION.MORTGAGE.defaultMessage,
    }),
  };
  return goals[key];
};
