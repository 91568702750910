import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { Country } from "../../model/Country";
type props = (state: boolean) => void;
export const useHandelCountry = (onClose?: props) => {
  const queryClient = useQueryClient();
  const schema = object().shape({
    countryCode: string().required(),
    country_fa: string().required(),
    country_ps: string().required(),
    country_en: string().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doCategory: any) => {
      const response = await Fetch({
        method: "POST",
        data: doCategory,
        endpoint: "/country",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["country"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (country: any) => {
      const response = await Fetch({
        method: "PUT",
        data: country,
        endpoint: `/country/${country.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["country"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (country: any) => {
      const response = await Fetch({
        method: "DELETE",
        data: country,
        endpoint: `/country/${country.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["country"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const onSubmit = async (country: Country) => {
    const data = {
      country_fa: country.country_fa,
      country_ps: country.country_ps,
      country_en: country.country_en,
      countryCode: country.countryCode,
    };
    mutation.mutate(data);
  };

  const onUpdate = async (country: Country, id: number) => {
    const data = {
      id: id,
      country_fa: country.country_fa,
      country_ps: country.country_ps,
      country_en: country.country_en,
      countryCode: country.countryCode,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    const data = {
      id: id,
    };
    deleteMutation.mutate(data);
  };

  function ToGetCounntries() {
    const result = useQuery({
      queryKey: ["country"],
      queryFn: async () => await getCountries(),
    });
    return result;
  }

  const getCountries = async (): Promise<Country[]> => {
    const response = await Fetch({
      endpoint: `/country`,
    });
    return response?.data;
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    onDelete,
    reset,
    setValue,
    ToGetCounntries,
  };
};
