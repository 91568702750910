type Props = {
    className?: string;
    type: 'primary' | 'secondary'
};
const AHLogo = ({ className, type }: Props) => {
    return (
        <svg className={className} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 509.45 509.45">
            <circle className={`${type == "primary" ? ' fill-secondary-600' : ' fill-white'}`} cx="462.48" cy="462.46" r="46.97" />
            <path fill="currentColor" className=" c" d="M311.46,0h-113.36c-5.3,0-10.39,2.1-14.14,5.85L5.86,183.8c-3.75,3.75-5.86,8.84-5.86,14.15v113.4c0,5.3,2.1,10.39,5.85,14.14l177.95,178.1c3.75,3.75,8.84,5.86,14.15,5.86h168.29v-143.12H143.12V143.08h223.12v223.25h143.21V198.14c0-5.3-2.1-10.39-5.85-14.14L325.61,5.86c-3.75-3.75-8.84-5.86-14.15-5.86Z" />
            <polygon fill="currentColor" points="266.76 366.33 266.76 266.76 266.76 242.69 266.76 143.08 242.69 143.08 242.69 242.69 242.69 266.76 242.69 366.33 266.76 366.33" />
            <polygon fill="currentColor" points="143.12 266.76 242.69 266.76 266.76 266.76 366.24 266.76 366.24 242.69 266.76 242.69 242.69 242.69 143.12 242.69 143.12 266.76" />
        </svg>
    );
};

export default AHLogo;
