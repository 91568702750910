import { useState } from "react";
import CategoryModal from "../../../modal/CategoryModal";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import ArticleCard from "../../../components/shared/Card/ArticleCard";
import Pagination from "../../../components/shared/Pagination";
import ConfirmModal from "../../../modal/ConfirmModal";
import { TRANSLATION } from "../../../static/translation";
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "../../../context/language";
const Articles = () => {
  const LIMIT = 9;
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [articleId, setArticleId] = useState<number>();
  const { ToGetArticles, onDelete } = useHandelArticle();
  const articles = ToGetArticles({ page: page, limit: LIMIT });
  const intl = useIntl();
  const lang = useLang();

  const onDeleteArticle = (articleId: number) => {
    setArticleId(articleId);
    setConfirm(true);
  };

  const onConfirm = (state: boolean) => {
    if (state && articleId) {
      onDelete(Number(articleId));
    }
    setConfirm(false);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto mt-4">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.ARTICLE_TITLE.id}
              defaultMessage={TRANSLATION.ARTICLE_TITLE.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading3 className="text-gray-600 mt-2">
            <FormattedMessage
              id={TRANSLATION.ARTICLE_DESCRIPTION.id}
              defaultMessage={
                TRANSLATION.ARTICLE_DESCRIPTION.defaultMessage
              }
            />
          </Typo.heading3>
        </div>
      </div>
      <div className="py-10 grid grid-cols-3 p-4 gap-4">
        {articles?.data?.rows?.map((article) => {
          return (
            <ArticleCard
              key={article.title}
              article={article}
              page="dashboard"
              onDelete={onDeleteArticle}
            />
          );
        })}
      </div>
      <div className="w-full mb-24 mt-8 md:mt-16 lg:mt-24 ">
        <Pagination
          onPageChange={setPage}
          total={articles?.data?.count || 0}
          perPage={LIMIT}
          currentPage={page}
          type="centered-page-number"
          direction={lang.direction}
        />
      </div>
      <CategoryModal open={showModal} onClose={setShowModal} intl={intl} />
      <ConfirmModal
        open={confirm}
        onClose={onConfirm}
        title={TRANSLATION.DELETE_ARTICLE}
        description={TRANSLATION.DELETE_ARTICLE_MESSAGE}
        icon={undefined}
      />
    </div>
  );
};

export default Articles;
