// import { CheckIcon } from '@heroicons/react/20/solid'
import { DropdownOptionModel } from "../../../model/DropdownOption";
import clsx from "clsx";
import { Typo } from "../typo";
import TickIcon from "../../icons/TickIcon";
type ProgressStepProps = {
  steps?: DropdownOptionModel[];
  currentStep: number;
  className?: string;
  disabled?: boolean;
};

const ProgressSteps: React.FC<ProgressStepProps> = (props) => {
  const { steps = [], currentStep = 1 } = props;
  return (
    <div className="flex justify-between h-20">
      <nav aria-label="Progress" className=" relative">
        <ol role="list" className="flex ">
          {steps.map((step, stepIdx) => (
            <li
              key={step.title}
              className={clsx(
                stepIdx !== steps.length - 1 ? "" : "",
                "relative pl-1 pt-1 flex flex-row"
              )}
            >
              <>
                {stepIdx !== 0 ? (
                  <div
                    className={clsx(
                      "left-3 mt-[18px] ml-1 w-16 h-0.5 rounded-sm  ",
                      stepIdx < currentStep ? "bg-primary-500 " : "bg-gray-300"
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  aria-hidden="true"
                  className="group relative flex items-center"
                >
                  <span className="flex h-9 items-center">
                    <span
                      className={clsx(
                        "relative flex h-8 w-8 items-center justify-center rounded-full",
                        {
                          "bg-primary-500 ": currentStep > stepIdx + 1,
                          " border-primary-500 text-white":
                            currentStep === stepIdx + 1 &&
                            currentStep !== steps.length,
                          " bg-primary-500": currentStep === steps.length,
                          " bg-white ": currentStep < stepIdx + 1,
                        }
                      )}
                    >
                      {currentStep > stepIdx + 1 ||
                      currentStep === steps.length ? (
                        <TickIcon className="text-white h-3" />
                      ) : currentStep === stepIdx + 1 ? (
                        <span
                          className={clsx(
                            " rounded-full bg-primary-500 flex justify-center items-center h-full w-full"
                          )}
                        >
                          <Typo.heading1 fontWeight="font-bold">
                            {stepIdx + 1}
                          </Typo.heading1>
                        </span>
                      ) : (
                        <span
                          className={clsx(
                            " rounded-full bg-gray-100 text-gray-400 flex items-center justify-center h-full w-full"
                          )}
                        >
                          <Typo.heading1 fontWeight="font-bold">
                            {stepIdx + 1}
                          </Typo.heading1>
                        </span>
                      )}
                    </span>
                  </span>
                  <span className="absolute top-10 -start-[20%] w-full">
                    <Typo.heading4
                      fontWeight="font-medium"
                      className={clsx(
                        "text-gray-900 whitespace-nowrap w-full text-center",
                        stepIdx < currentStep
                          ? "text-primary-600"
                          : "text-gray-400"
                      )}
                    >
                      {step.title}
                    </Typo.heading4>
                  </span>
                </div>
              </>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default ProgressSteps;
