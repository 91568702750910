import BulkStarIcon from "../../../../components/icons/BulkStarIcon";
import GardenIcon from "../../../../components/icons/GardenIcon";
import HuobiTokenIcon from "../../../../components/icons/HuobiTokenIcon";
import SwimmingIcon from "../../../../components/icons/SwimmingIcon";
import WeightIcon from "../../../../components/icons/WeightIcon";
import Wind2Icon from "../../../../components/icons/Wind2Icon";
import Toggle from "../../../../components/shared/Toggle";
import { TRANSLATION } from "../../../../static/translation";
import PropertyLabel from "../propertyLabel";

type Props = {
  featureValues: any;
  valueChange: (option: boolean, name: string) => void;
};

const RecreationTab: React.FC<Props> = (props) => {
  const { featureValues, valueChange } = props;
  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-6">
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.SWIMMING_POOL}
                  icon={<SwimmingIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.swimmingPool}
              onChange={(state) => valueChange(state, "swimmingPool")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.JACUZZI}
                  icon={<Wind2Icon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.jacuzzi}
              onChange={(state) => valueChange(state, "jacuzzi")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.SAUNA}
                  icon={<HuobiTokenIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.sauna}
              onChange={(state) => valueChange(state, "sauna")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.GARDEN}
                  icon={<GardenIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.garden}
              onChange={(state) => valueChange(state, "garden")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.GYM}
                  icon={<WeightIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.gym}
              onChange={(state) => valueChange(state, "gym")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecreationTab;
