import React, { useCallback, useState } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Button from "./button";
import { Typo } from "./typo";
import CloudIcon from "../icons/CloudIcon";
import { useHandelFile } from "../../hooks/file/useHandelFile";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
type ImageProps = {
  image?: string;
  title: string;
  description: string;
  onChange?: (image: string) => void;
  className?: string;
};

const SingleImageUploader: React.FC<ImageProps> = (props) => {
  const { image = "", onChange, className, title, description } = props;
  const [loading, setLoading] = useState(false);
  const { onCompresed, onUpload } = useHandelFile();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      if (acceptedFiles?.length > 0) {
        const compressedFile = await onCompresed(acceptedFiles[0]);
        const uploadResult = await onUpload(compressedFile);
        onChange && onChange(uploadResult);
      }
      setLoading(false);
    },
    [image, onChange]
  );

  const onDeleteImage = () => {
    onChange && onChange("");
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".svg"],
    },
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  };

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneOptions);
  return (
    <div className={`w-full ${className}`}>
      {image || loading ? (
        <ImagePreview
          onClick={() => onDeleteImage()}
          uploading={loading}
          className="w-full h-40"
          src={image?.toString()}
        />
      ) : (
        <div
          {...getRootProps({
            className:
              "dropzone border border-dashed border-gray-300 rounded-lg py-4 px-6",
          })}
        >
          <input {...getInputProps()} />
          <div
            onClick={open}
            className="flex flex-col w-full items-center gap-y-3 mt-3 cursor-pointer"
          >
            <CloudIcon className="text-gray-300" />
            <Button theme="blue-link">
              <Typo.heading5
                fontWeight="font-medium"
                className="text-secondary-500 py-2 underline"
              >
                {title ? (
                  title
                ) : (
                  <FormattedMessage
                    id={TRANSLATION.ADD_IMAGE.id}
                    defaultMessage={TRANSLATION.ADD_IMAGE.defaultMessage}
                  />
                )}
              </Typo.heading5>
            </Button>
          </div>
          <Typo.heading5 className="text-gray-400 text-center mt-1">
            {description ? (
              description
            ) : (
              <FormattedMessage
                id={TRANSLATION.ADD_IMAGE_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.ADD_IMAGE_DESCRIPTION.defaultMessage
                }
              />
            )}
          </Typo.heading5>
        </div>
      )}
    </div>
  );
};

export default SingleImageUploader;
