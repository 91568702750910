import PropertyLabel from "../propertyLabel";
import Toggle from "../../../../components/shared/Toggle";
import BulkStarIcon from "../../../../components/icons/BulkStarIcon";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { TRANSLATION } from "../../../../static/translation";
import AdProductIcon from "../../../../components/icons/AdProductIcon";
import SofaIcon from "../../../../components/icons/SofaIcon";
import LiftIcon from "../../../../components/icons/LiftIcon";
import SecurityCameraIcon from "../../../../components/icons/SecurityCameraIcon";
import InternetIcon from "../../../../components/icons/InternetIcon";
import FormatSquareIcon from "../../../../components/icons/FormatSquareIcon";
import ElementEqualIcon from "../../../../components/icons/ElementEqualIcon";
import SecurityUserIcon from "../../../../components/icons/SecurityUser";
import MaintananceUserIcon from "../../../../components/icons/MaintananceUserIcon";
import WindIcon from "../../../../components/icons/WindIcon";

type Props = {
  featureValues: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string,
    name: string
  ) => void;
};

const PointTab: React.FC<Props> = (props) => {
  const { featureValues, valueChange } = props;
  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-6">
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.cabinet}
              onChange={(state) => valueChange(state, "cabinet")}
              label={
                <PropertyLabel
                  title={TRANSLATION.CABINET}
                  icon={<AdProductIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.furnished}
              onChange={(state) => valueChange(state, "furnished")}
              label={
                <PropertyLabel
                  title={TRANSLATION.FURNISHED}
                  icon={<SofaIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.lift}
              onChange={(state) => valueChange(state, "lift")}
              label={
                <PropertyLabel
                  title={TRANSLATION.LIFT}
                  icon={<LiftIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.securityComera}
              onChange={(state) => valueChange(state, "securityComera")}
              label={
                <PropertyLabel
                  title={TRANSLATION.SECURITY_COMERA}
                  icon={
                    <SecurityCameraIcon className="h-5 w-5 text-gray-300" />
                  }
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.internetCabel}
              onChange={(state) => valueChange(state, "internetCabel")}
              label={
                <PropertyLabel
                  title={TRANSLATION.INTERNET_CABLE}
                  icon={<InternetIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.boundryWall}
              onChange={(state) => valueChange(state, "boundryWall")}
              label={
                <PropertyLabel
                  title={TRANSLATION.BOUNDY_WALL}
                  icon={<FormatSquareIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.corner}
              onChange={(state) => valueChange(state, "corner")}
              label={
                <PropertyLabel
                  title={TRANSLATION.CORNER}
                  icon={<ElementEqualIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.securityStaff}
              onChange={(state) => valueChange(state, "securityStaff")}
              label={
                <PropertyLabel
                  title={TRANSLATION.SECURITY_STAFF}
                  icon={<SecurityUserIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.maintenanceStaff}
              onChange={(state) => valueChange(state, "maintenanceStaff")}
              label={
                <PropertyLabel
                  title={TRANSLATION.MAINTANANCE_STAFF}
                  icon={<MaintananceUserIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.AIR_CONDITIONING}
                  icon={<WindIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.airConditioning}
              onChange={(state) => valueChange(state, "airConditioning")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointTab;
