import { TRANSLATION } from "../static/translation";

export const handelUnitsMap = (intl: any, key: string) => {
  const areas: { [key: string]: string } = {
    squareMters: intl.formatMessage({
      id: TRANSLATION.SQUARE_METERS.id,
      defaultMessage: TRANSLATION.SQUARE_METERS.defaultMessage,
    }),
    bisou: intl.formatMessage({
      id: TRANSLATION.BISOU.id,
      defaultMessage: TRANSLATION.BISOU.defaultMessage,
    }),
    acre: intl.formatMessage({
      id: TRANSLATION.ACRE.id,
      defaultMessage: TRANSLATION.ACRE.defaultMessage,
    }),
  };
  return areas[key];
};

export const handelCurrencyMap = (intl: any, key: string) => {
  const currencies: { [key: string]: string } = {
    af: intl.formatMessage({
      id: TRANSLATION.AF_CURRENCY.id,
      defaultMessage: TRANSLATION.AF_CURRENCY.defaultMessage,
    }),
    us: intl.formatMessage({
      id: TRANSLATION.US_CURRENCY.id,
      defaultMessage: TRANSLATION.US_CURRENCY.defaultMessage,
    }),
  };
  return currencies[key];
};

export const handelDocumentsMap = (intl: any, key: string) => {
  const documents: { [key: string]: string } = {
    customary: intl.formatMessage({
      id: TRANSLATION.DOCUMENT_CUSTOMARY.id,
      defaultMessage: TRANSLATION.DOCUMENT_CUSTOMARY.defaultMessage,
    }),
    sharia: intl.formatMessage({
      id: TRANSLATION.DOCUMENT_SHARIA.id,
      defaultMessage: TRANSLATION.DOCUMENT_SHARIA.defaultMessage,
    }),
    property: intl.formatMessage({
      id: TRANSLATION.DOCUMENT_PROPERTY.id,
      defaultMessage: TRANSLATION.DOCUMENT_PROPERTY.defaultMessage,
    }),
    "": intl.formatMessage({
      id: TRANSLATION.DOCUMENT_NO_MATTER.id,
      defaultMessage: TRANSLATION.DOCUMENT_NO_MATTER.defaultMessage,
    }),
  };
  return documents[key];
};

export const handelNomaMap = (intl: any, key: string) => {
  const nomas: { [key: string]: string } = {
    ceramic: intl.formatMessage({
      id: TRANSLATION.NOMA_CERAMIC.id,
      defaultMessage: TRANSLATION.NOMA_CERAMIC.defaultMessage,
    }),
    travertine: intl.formatMessage({
      id: TRANSLATION.NOMA_TRAVERTINE.id,
      defaultMessage: TRANSLATION.NOMA_TRAVERTINE.defaultMessage,
    }),
    cement: intl.formatMessage({
      id: TRANSLATION.NOMA_CEMENT.id,
      defaultMessage: TRANSLATION.NOMA_CEMENT.defaultMessage,
    }),
    glass: intl.formatMessage({
      id: TRANSLATION.NOMA_GLASS.id,
      defaultMessage: TRANSLATION.NOMA_GLASS.defaultMessage,
    }),
    alkoban: intl.formatMessage({
      id: TRANSLATION.NOMA_ALKOBAN.id,
      defaultMessage: TRANSLATION.NOMA_ALKOBAN.defaultMessage,
    }),
    "": intl.formatMessage({
      id: TRANSLATION.NOMA_OTHER.id,
      defaultMessage: TRANSLATION.NOMA_OTHER.defaultMessage,
    }),
  };
  return nomas[key];
};

export const handelFloorsMap = (intl: any, key: string) => {
  const floors: { [key: string]: string } = {
    ceramic: intl.formatMessage({
      id: TRANSLATION.FLOOR_CERAMIC.id,
      defaultMessage: TRANSLATION.FLOOR_CERAMIC.defaultMessage,
    }),
    mosaic: intl.formatMessage({
      id: TRANSLATION.FLOOR_MOSAIC.id,
      defaultMessage: TRANSLATION.FLOOR_MOSAIC.defaultMessage,
    }),
    cement: intl.formatMessage({
      id: TRANSLATION.FLOOR_CEMENT.id,
      defaultMessage: TRANSLATION.FLOOR_CEMENT.defaultMessage,
    }),
    wood: intl.formatMessage({
      id: TRANSLATION.FLOOR_WOOD.id,
      defaultMessage: TRANSLATION.FLOOR_WOOD.defaultMessage,
    }),
    "": intl.formatMessage({
      id: TRANSLATION.FLOOR_OTHER.id,
      defaultMessage: TRANSLATION.FLOOR_OTHER.defaultMessage,
    }),
  };
  return floors[key];
};

export const handelElectricitesMap = (intl: any, key: string) => {
  const electricities: { [key: string]: string } = {
    nothing: intl.formatMessage({
      id: TRANSLATION.ELECTRICITY_NOTHING.id,
      defaultMessage: TRANSLATION.ELECTRICITY_NOTHING.defaultMessage,
    }),
    solar: intl.formatMessage({
      id: TRANSLATION.ELECTRICITY_SOLAR.id,
      defaultMessage: TRANSLATION.ELECTRICITY_SOLAR.defaultMessage,
    }),
    generator: intl.formatMessage({
      id: TRANSLATION.ELECTRICITY_GENERATOR.id,
      defaultMessage: TRANSLATION.ELECTRICITY_GENERATOR.defaultMessage,
    }),
    ups: intl.formatMessage({
      id: TRANSLATION.ELECTRICITY_UPS.id,
      defaultMessage: TRANSLATION.ELECTRICITY_UPS.defaultMessage,
    }),
    "": intl.formatMessage({
      id: TRANSLATION.ELECTRICITY_OTHER.id,
      defaultMessage: TRANSLATION.ELECTRICITY_OTHER.defaultMessage,
    }),
  };
  return electricities[key];
};
