import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";

type Props = {
  className?: string;
  disabled?: boolean;
  currentStep: number;
  loading: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  btnType?: "button" | "submit";
};

const NextPrevButtons: React.FC<Props> = (props) => {
  const {
    className,
    btnType = "button",
    currentStep,
    loading,
    onPrev,
    onNext,
  } = props;
  return (
    <div className={`w-full flex gap-x-4 ${className}`}>
      {currentStep > 1 && currentStep < 4 ? (
        <Button
          onClick={onPrev}
          size="small"
          theme="primary"
          rounded="rounded-lg"
          className="min-w-[100px]"
        >
          <Typo.heading4 fontWeight="font-medium">
            <FormattedMessage
              id={TRANSLATION.PREV.id}
              defaultMessage={TRANSLATION.PREV.defaultMessage}
            />
          </Typo.heading4>
        </Button>
      ) : (
        ""
      )}
      {currentStep < 4 ? (
        <Button
          type={btnType}
          onClick={onNext}
          loading={loading}
          size="small"
          theme="primary"
          rounded="rounded-lg"
          className="min-w-[100px]"
        >
          <Typo.heading4 fontWeight="font-medium">
            <FormattedMessage
              id={TRANSLATION.NEXT.id}
              defaultMessage={TRANSLATION.NEXT.defaultMessage}
            />
          </Typo.heading4>
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default NextPrevButtons;
