import { useParams } from "react-router-dom";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import { Typo } from "../../../components/shared/typo";
import ListingDetailsLabel from "../../../components/shared/ListingDetailsLabel";
import MaximizeIcon from "../../../components/icons/MaximizeIcon";
import LayerIcon from "../../../components/icons/LayerIcon";
import FormatSquareIcon from "../../../components/icons/FormatSquareIcon";
import CalenderIcon from "../../../components/icons/CalenderIcon";
import Building4Icon from "../../../components/icons/Building4Icon";
import FreezingLineIcon from "../../../components/icons/FreezingLineIcon";
import AdProductIcon from "../../../components/icons/AdProductIcon";
import FlashIcon from "../../../components/icons/FlashIcon";
import SofaIcon from "../../../components/icons/SofaIcon";
import WindIcon from "../../../components/icons/WindIcon";
import OceanProtocolIcon from "../../../components/icons/OceanProtocolIcon";
import ElementEqualIcon from "../../../components/icons/ElementEqualIcon";
import DropIcon from "../../../components/icons/DropIcon";
import StartIcon from "../../../components/icons/StarIcon";
import Wind2Icon from "../../../components/icons/Wind2Icon";
import HuobiTokenIcon from "../../../components/icons/HuobiTokenIcon";
import WeightIcon from "../../../components/icons/WeightIcon";
import SecurityUserIcon from "../../../components/icons/SecurityUser";
import Loader from "../../../components/shared/Loader";
import Map from "../../../components/shared/Map";
import ListingImageSlider from "../../../components/shared/ListingImageSlider";
import KitchenIcon from "../../../components/icons/KitchenIcon";
import MosqueIcon from "../../../components/icons/MosqueIcon";
import DisabilityIcon from "../../../components/icons/DisabilityIcon";
import GardenIcon from "../../../components/icons/GardenIcon";
import InternetIcon from "../../../components/icons/InternetIcon";
import SewerageIcon from "../../../components/icons/SewerageIcon";
import Note2Icon from "../../../components/icons/Note2Icon";
import WarehouseIcon from "../../../components/icons/WarehouseIcon";
import BathIcon from "../../../components/icons/BathIcon";
import SwimmingIcon from "../../../components/icons/SwimmingIcon";
import ParkingIcon from "../../../components/icons/ParkingIcon";
import { FormattedMessage, useIntl } from "react-intl";
import UserInfo from "../../../components/shared/Card/UserInfo";
import ListingInfo from "../../../components/shared/Card/ListingInfo";
import clsx from "clsx";
import { TRANSLATION } from "../../../static/translation";
import ListingDetailsContainer from "../../../components/shared/Card/ListingDetailsContainer";
import NearbyLocationModal from "../../../modal/NearbyLocationModal";
import { useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import RelatedListings from "../home/RelatedListings";
import NearbySchoolIcon from "../../../components/icons/NearbySchoolIcon";
import NearbyHospitalIcon from "../../../components/icons/NearbyHospitalIcon";
import NearbyBusIcon from "../../../components/icons/NeabyBusIcon";
import NearbyRestuarantIcon from "../../../components/icons/NearbyRestuarantIcon";
import NearbyStoreIcon from "../../../components/icons/NearbyStoreIcon";
import BedIcon from "../../../components/icons/BedIcon";
import MaintananceUserIcon from "../../../components/icons/MaintananceUserIcon";
import LiftIcon from "../../../components/icons/LiftIcon";
import SecurityCameraIcon from "../../../components/icons/SecurityCameraIcon";
import { handelElectricitesMap } from "../../../util/handelListingFeature";
import { handelUnitsMap } from "../../../util/handelListingFeature";
import { handelDocumentsMap } from "../../../util/handelListingFeature";
import { handelNomaMap } from "../../../util/handelListingFeature";
import { handelFloorsMap } from "../../../util/handelListingFeature";
import SEO from "../../../components/website/Seo";
import { useLang } from "../../../context/language";
import { useAuth } from "../../../context/auth";
import { getLocalTitle } from "../../../util/appUtil";
const ListingDetails = (props: any) => {
  const { slug } = useParams();
  const intl = useIntl();
  const lang = useLang();
  const auth = useAuth();
  const { ToGetListingDetails } = useHandelListing({ intl });
  const [nearbyTab, setNearbyTab] = useState<DropdownOptionModel>();
  const listing = ToGetListingDetails(`${slug}`);
  const files = listing?.data?.listingFiles || [];

  return (
    <>
      <SEO
        title={"title"}
        description={"description"}
        name={"name of listing"}
        type={"type of listing "}
        // url={`${process.env.REACT_APP_FRONTEND_URL}${ROUTES.LISTINGS_ROUTE}/${listing?.data?.slug}`}
        image={listing.data?.thumbnail}
      ></SEO>
      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto bg-white rounded-lg pt-16 pb-16">
        {!listing?.isLoading ? (
          <div className="space-y-5">
            <div className="flex flex-col-reverse lg:grid lg:grid-cols-6 gap-x-6">
              <div
                className={clsx(
                  "flex flex-col gap-y-4",
                  files?.length > 0 ? "col-span-2" : "col-span-6"
                )}
              >
                <div
                  className={clsx("grid gap-4 grid-cols-1 sm:grid-cols-3", {
                    "lg:grid-cols-1 ": files?.length > 0,
                  })}
                >
                  <ListingInfo
                    className={
                      files?.length > 0
                        ? "col-span-1 sm:col-span-2 lg:col-span-1"
                        : "col-span-2 "
                    }
                    listing={listing?.data}
                    intl={intl}
                    local={lang.language}
                  />
                  <UserInfo
                    className="py-5 shadow-shadow2 rounded-xl w-full"
                    user={listing?.data?.user}
                    direction={lang.direction}
                  />
                </div>
              </div>
              {files.length > 0 && (
                <div className="col-span-4 select-none">
                  <ListingImageSlider
                    direction={lang.direction}
                    files={files}
                  />
                </div>
              )}
            </div>

            <div className="w-full">
              <div className="col-span-5 space-y-4 ">
                <ListingDetailsContainer
                  title={TRANSLATION.LISTING_DESCRIPTION_TITLE}
                >
                  {listing?.data?.description && (
                    <Typo.body3 className="text-gray-600">
                      {listing?.data.description}
                    </Typo.body3>
                  )}
                </ListingDetailsContainer>

                <ListingDetailsContainer title={TRANSLATION.LISTING_PROPERTY}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4">
                    <ListingDetailsLabel
                      title={TRANSLATION.AREA}
                      icon={<MaximizeIcon className="h-5 w-5" />}
                    >
                      <span>
                        {`${listing?.data?.area} ` +
                          handelUnitsMap(intl, `${listing?.data?.unit}`)}
                      </span>
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.FLOORS}
                      icon={<LayerIcon className="h-5 w-5" />}
                    >
                      {listing?.data?.floors}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.LISTING_TYPE}
                      icon={<FormatSquareIcon className="h-5 w-5" />}
                    >
                      {getLocalTitle(
                        lang.language,
                        listing?.data?.subCategory.title_fa,
                        listing?.data?.subCategory.title_ps,
                        listing?.data?.subCategory.title_en
                      )}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.BEDROOM_AMOUNT}
                      icon={<BedIcon className="h-5 w-5" />}
                    >
                      {listing?.data?.bedrooms}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.BATHROOM_AMOUNT}
                      icon={<BathIcon className="h-5 w-5" />}
                    >
                      {listing?.data?.bathrooms}
                    </ListingDetailsLabel>

                    <ListingDetailsLabel
                      title={TRANSLATION.DOCUMENT_TYPE}
                      icon={<Note2Icon className="h-5 w-5" />}
                    >
                      <span>
                        {handelDocumentsMap(
                          intl,
                          `${listing?.data?.documentType}`
                        )}
                      </span>
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.BUILT_YEAR}
                      icon={<CalenderIcon className="h-5 w-5" />}
                    >
                      {listing?.data?.builtYear}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.NOMA}
                      icon={<Building4Icon className="h-5 w-5" />}
                    >
                      {handelNomaMap(intl, `${listing?.data?.noma}`)}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.FLOOR}
                      icon={<FreezingLineIcon className="h-5 w-5" />}
                    >
                      {handelFloorsMap(intl, `${listing?.data?.floor}`)}
                    </ListingDetailsLabel>

                    <ListingDetailsLabel
                      title={TRANSLATION.PARKING_SPACE}
                      icon={<ParkingIcon className="h-5 w-5" />}
                    >
                      <FormattedMessage
                        id={TRANSLATION.METER.id}
                        defaultMessage={TRANSLATION.METER.defaultMessage}
                        values={{ value: listing?.data?.parkingSpace }}
                      />
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.KITCHENS}
                      icon={<KitchenIcon className="h-5 w-5" />}
                    >
                      {listing?.data?.kitchens}
                    </ListingDetailsLabel>
                    <ListingDetailsLabel
                      title={TRANSLATION.ELECTRICITY_BACKUP}
                      icon={<FlashIcon className="h-5 w-5" />}
                    >
                      {handelElectricitesMap(
                        intl,
                        `${listing?.data?.electricityBackup}`
                      )}
                    </ListingDetailsLabel>
                  </div>
                </ListingDetailsContainer>

                <ListingDetailsContainer title={TRANSLATION.POSIBLITY_FEATURES}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4">
                    {listing?.data?.lift && (
                      <ListingDetailsLabel
                        title={TRANSLATION.LIFT}
                        icon={<LiftIcon className="h-5 w-5" />}
                      />
                    )}

                    {listing?.data?.securityComera && (
                      <ListingDetailsLabel
                        title={TRANSLATION.SECURITY_COMERA}
                        icon={<SecurityCameraIcon className="h-5 w-5" />}
                      />
                    )}

                    {listing?.data?.furnished && (
                      <ListingDetailsLabel
                        title={TRANSLATION.FURNISHED}
                        icon={<SofaIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.airConditioning && (
                      <ListingDetailsLabel
                        title={TRANSLATION.AIR_CONDITIONING}
                        icon={<WindIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.centralHeating && (
                      <ListingDetailsLabel
                        title={TRANSLATION.CENTRAL_HEATING}
                        icon={<OceanProtocolIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.mosque && (
                      <ListingDetailsLabel
                        title={TRANSLATION.MOSQUE}
                        icon={<MosqueIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing.data?.corner && (
                      <ListingDetailsLabel
                        title={TRANSLATION.CORNER}
                        icon={<ElementEqualIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.electricity && (
                      <ListingDetailsLabel
                        title={TRANSLATION.ELECTRICITY}
                        icon={<FlashIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.water && (
                      <ListingDetailsLabel
                        title={TRANSLATION.WATER}
                        icon={<DropIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.sewerage && (
                      <ListingDetailsLabel
                        title={TRANSLATION.SEWERAGE}
                        icon={<SewerageIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.boundryWall && (
                      <ListingDetailsLabel
                        title={TRANSLATION.BOUNDY_WALL}
                        icon={<FormatSquareIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.otherFeature && (
                      <ListingDetailsLabel
                        title={TRANSLATION.OTHER_FEATURE}
                        icon={<StartIcon className="h-5 w-5" />}
                      >
                        <FormattedMessage
                          id={TRANSLATION.OTHER_FEATURE.id}
                          defaultMessage={
                            TRANSLATION.OTHER_FEATURE.defaultMessage
                          }
                        />
                      </ListingDetailsLabel>
                    )}
                    {listing?.data?.securityStaff && (
                      <ListingDetailsLabel
                        title={TRANSLATION.SECURITY_STAFF}
                        icon={<SecurityUserIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.maintenanceStaff && (
                      <ListingDetailsLabel
                        title={TRANSLATION.MAINTANANCE_STAFF}
                        icon={<MaintananceUserIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.facilitiesForDisabled && (
                      <ListingDetailsLabel
                        title={TRANSLATION.FACILITIES_FOR_DISABLED}
                        icon={<DisabilityIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.garden && (
                      <ListingDetailsLabel
                        title={TRANSLATION.GARDEN}
                        icon={<GardenIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.otherFacilities && (
                      <ListingDetailsLabel
                        title={TRANSLATION.OTHER_FACILITIES}
                        icon={<StartIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.cabinet && (
                      <ListingDetailsLabel
                        title={TRANSLATION.CABINET}
                        icon={<AdProductIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.warehouse && (
                      <ListingDetailsLabel
                        title={TRANSLATION.WAREHOUSE}
                        icon={<WarehouseIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.internetCabel && (
                      <ListingDetailsLabel
                        title={TRANSLATION.INTERNET_CABLE}
                        icon={<InternetIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.swimmingPool && (
                      <ListingDetailsLabel
                        title={TRANSLATION.SWIMMING_POOL}
                        icon={<SwimmingIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.jacuzzi && (
                      <ListingDetailsLabel
                        title={TRANSLATION.JACUZZI}
                        icon={<Wind2Icon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.sauna && (
                      <ListingDetailsLabel
                        title={TRANSLATION.SAUNA}
                        icon={<HuobiTokenIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.gym && (
                      <ListingDetailsLabel
                        title={TRANSLATION.GYM}
                        icon={<WeightIcon className="h-5 w-5" />}
                      />
                    )}
                  </div>
                </ListingDetailsContainer>

                <ListingDetailsContainer title={TRANSLATION.NEARBY_LOCATION}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4">
                    {listing?.data?.nearbySchools && (
                      <ListingDetailsLabel
                        title={TRANSLATION.NEARBY_SCHOOL}
                        icon={<NearbySchoolIcon className="h-5 w-5" />}
                      />
                    )}

                    {listing?.data?.nearbyHospitals && (
                      <ListingDetailsLabel
                        title={TRANSLATION.NEARBY_HOSPITAL}
                        icon={<NearbyHospitalIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.nearbyPublicTransports && (
                      <ListingDetailsLabel
                        title={TRANSLATION.NEARBY_PUBLIC_TRANSPORTS}
                        icon={<NearbyBusIcon className="h-5 w-5" />}
                      />
                    )}

                    {listing?.data?.nearbyMalls && (
                      <ListingDetailsLabel
                        title={TRANSLATION.NEARBY_MALLS}
                        icon={<NearbyStoreIcon className="h-5 w-5" />}
                      />
                    )}
                    {listing?.data?.nearbyRestaurants && (
                      <ListingDetailsLabel
                        title={TRANSLATION.NEARBY_RESTAURANT}
                        icon={<NearbyRestuarantIcon className="h-5 w-5" />}
                      />
                    )}
                  </div>
                </ListingDetailsContainer>

                <ListingDetailsContainer
                  title={TRANSLATION.LOCATION_ON_MAP}
                  description={listing?.data?.location?.address}
                >
                  <Map
                    coords={[
                      Number(listing?.data?.location?.latitude || 0),
                      Number(listing?.data?.location?.longitude || 0),
                    ]}
                  />
                </ListingDetailsContainer>

                {/* <ListingDetailsContainer

                title={TRANSLATION.NEARBY_LOCATION}
                description={intl.formatMessage({
                  id: TRANSLATION.NEARBY_LOCATION_DESCRIPTION.id,
                  defaultMessage:
                    TRANSLATION.NEARBY_LOCATION_DESCRIPTION.defaultMessage,
                })}
              >
                <NeayByLocation intl={intl} onChange={setNearbyTab} />
              </ListingDetailsContainer> */}

                {listing?.data?.listings &&
                  listing?.data?.listings?.length > 0 && (
                    <RelatedListings
                      listings={listing?.data?.listings}
                      direction={lang.direction}
                      user={auth?.user}
                      intl={intl}
                      locale={lang.language}
                    />
                  )}
              </div>
            </div>
          </div>
        ) : (
          <Loader className="h-screen" />
        )}

        <NearbyLocationModal
          open={nearbyTab ? true : false}
          onClose={() => setNearbyTab(undefined)}
          intl={intl}
          currentNearby={nearbyTab}
          setCurrentNearby={setNearbyTab}
          location={listing?.data?.location}
        />
      </div>
    </>
  );
};

export default ListingDetails;
