import React from "react";
import { Typo } from "./typo";
type Props = {
  placeId: string;
  description: string;
  className?: string;
  isSelected?: boolean;
};

const LocationDropdownItem: React.FC<Props> = (props) => {
  const { description, className, isSelected = false } = props;

  return (
    <div
      className={`relative flex item-center w-full py-2.5 overflow-hidden pl-3.5 pr-6 cursor-pointer h-11 disabled:cursor-not-allowed bg-white  hover:bg-gray-100 ${className}`}
    >
      <div className="flex items-center gap-x-2 overflow-hidden ">
        <Typo.heading5
          fontWeight="font-medium"
          className="text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden "
        >
          {description}
        </Typo.heading5>
      </div>
      {isSelected && (
        <span className="absolute inset-y-0 right-0 flex bg-gray-100 items-center pl-1 pr-3 text-blue-gray-600">
        </span>
      )}
    </div>
  );
};

export default LocationDropdownItem;
