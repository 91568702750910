import { FormattedMessage, IntlShape } from "react-intl";
import Dropdown from "../../../components/shared/Dropdown";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";

type Props = {
  intl: IntlShape;
};

const AgencyFilter: React.FC<Props> = (props) => {
  const { intl } = props;
  return (
    <div className="flex">
      <div className=" flex flex-col gap-y-6 w-full items-start bg-white  mx-auto rounded-xl p-8 shadow-shadow2 ">
        <Typo.headingC fontWeight="font-medium" className="text-gray-900 ">
          <FormattedMessage
            id={TRANSLATION.FILTER.id}
            defaultMessage={TRANSLATION.FILTER.defaultMessage}
          />
        </Typo.headingC>
        <Dropdown
          label={handelInputMap(intl, "province")}
          placeholder={handelInputMap(intl, "province_placeholder")}
          options={[]}
        />
        <Dropdown
          label={handelInputMap(intl, "address")}
          placeholder={handelInputMap(intl, "address_placeholder")}
          options={[]}
        />
        <Dropdown
          label={handelInputMap(intl, "agencyName")}
          placeholder={handelInputMap(intl, "agencyName_placeholder")}
          options={[]}
        />
        <div className="w-full">
          <Button className="w-full" size="medium" theme="primary">
            <Typo.heading4 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.SEARCH.id}
                defaultMessage={TRANSLATION.SEARCH.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgencyFilter;
