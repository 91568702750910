type Props = {
  className?: string;
};
const InternetIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.125 6C15.662 6 18.962 7.353 21.418 9.809L22.832 8.395C19.999 5.561 16.196 4 12.125 4C8.05397 4.001 4.25097 5.561 1.41797 8.395L2.83197 9.809C5.28797 7.353 8.58797 6 12.125 6ZM17.796 14.307C14.722 11.233 9.52797 11.233 6.45397 14.307L7.86797 15.721C10.175 13.414 14.075 13.414 16.382 15.721L17.796 14.307Z"
        fill="currentColor"
      />
      <path
        d="M20.562 11.2938C15.99 6.71978 8.26096 6.71978 3.68896 11.2938L5.10297 12.7078C8.90997 8.90078 15.341 8.90078 19.148 12.7078L20.562 11.2938Z"
        fill="currentColor"
      />
      <path
        d="M12.125 20C13.2296 20 14.125 19.1046 14.125 18C14.125 16.8954 13.2296 16 12.125 16C11.0204 16 10.125 16.8954 10.125 18C10.125 19.1046 11.0204 20 12.125 20Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InternetIcon;
