import React, { useCallback } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import Button from "./button";
import { Typo } from "./typo";
import ImagePreview from "./ImagePreview";
import { ImageFile } from "../../model/Image";
import GalleryAddIcon from "../icons/GalleryAddIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import TickCircleIcon from "../icons/TickCircleIcon";
import Divider from "./Divider";
import PlusIcon from "../icons/PlusIcon";
import { useHandelFile } from "../../hooks/file/useHandelFile";
type ImageProps = {
  images: ImageFile[];
  setImages: (images: ImageFile[]) => void;
  thumbnail: string;
  setThumbnail: (thumbnail: string) => void;
  deletedImages: string[];
  setDeletedImages: (files: string[]) => void;
  className?: string;
  maxImages?: number;
  title?: string;
  description?: string;
};

const ImageUploader: React.FC<ImageProps> = (props) => {
  const {
    images = [],
    setImages,
    className,
    maxImages = 20,
    deletedImages,
    setDeletedImages,
    thumbnail,
    setThumbnail,
  } = props;
  const { onCompresed, onUpload } = useHandelFile();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const remainingSpace = maxImages - images.length;
      if (acceptedFiles?.length <= remainingSpace) {
        const newFiles: { id: number; file: File }[] = acceptedFiles.map(
          (file, index) => ({
            id: images.length + index,
            file: file,
          })
        );
        const updatedFiles = newFiles.slice(0, remainingSpace);
        setImages([...images, ...updatedFiles]);
        let newImages = [...images, ...updatedFiles];
        try {
          const uploadPromises = newImages
            .filter((image) => image.file instanceof File)
            .map(async (file) => {
              if (file.file instanceof File) {
                const compressedFile = await onCompresed(file.file);
                const uploadResult = await onUpload(compressedFile);
                if (uploadResult) {
                  newImages = newImages.map((obj) =>
                    obj.id === file.id ? { ...obj, file: uploadResult } : obj
                  );
                  setImages(newImages);
                }
                return uploadResult;
              }
            });
          await Promise.all(uploadPromises);
        } catch (error) {
          console.error("Upload Error: ", error);
        }
      } else {
        alert(`You can only upload up to ${maxImages} images.`);
      }
    },
    [images, setImages]
  );

  const onDeleteImage = (imageId: number) => {
    const findedImage = images?.find((file) => file.id === imageId);
    if (findedImage && !(findedImage.file instanceof File)) {
      if (findedImage.file === thumbnail) {
        setThumbnail("");
      }
      setDeletedImages([...deletedImages, findedImage.file]);
    }
    const updatedImages = images.filter((item) => item.id !== imageId);
    setImages(updatedImages);
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
    maxFiles: maxImages - images?.length,
    multiple: true,
    noClick: true,
    noKeyboard: true,
  };

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneOptions);

  return (
    <div
      className={`w-full p-3 sm:p-6 md:p-8 border border-dashed border-primary-600 rounded-lg ${className}`}
    >
      <div className="flex gap-x-12 items-center">
        <div className="w-full flex flex-col sm:flex-row gap-y-8 gap-x-12 ">
          <div
            {...getRootProps({
              className: "dropzone",
            })}
          >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center">
              <GalleryAddIcon className="w-20 h-20 sm:h-12 sm:w-12" />
              <Button
                rounded="rounded-lg"
                theme="primary"
                icon={<PlusIcon className="h-4" />}
                size="sxtra-small"
                className="mt-3 w-full sm:w-auto"
                onClick={open}
                disabled={images?.length >= maxImages}
              >
                <Typo.heading4 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.ADD_IMAGE.id}
                    defaultMessage={TRANSLATION.ADD_IMAGE.defaultMessage}
                  />
                </Typo.heading4>
              </Button>
            </div>
          </div>
          <div className="pt-1">
            <div className="space-y-3">
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.IMAGE_NOTE1.id}
                    defaultMessage={TRANSLATION.IMAGE_NOTE1.defaultMessage}
                  />
                </Typo.body5>
              </div>
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.IMAGE_NOTE2.id}
                    defaultMessage={TRANSLATION.IMAGE_NOTE2.defaultMessage}
                  />
                </Typo.body5>
              </div>
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.IMAGE_NOTE3.id}
                    defaultMessage={TRANSLATION.IMAGE_NOTE3.defaultMessage}
                  />
                </Typo.body5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {images?.length > 0 && <Divider type="horizontal" className="my-6" />}
      {images?.length > 0 && (
        <Typo.body5 className="text-gray-500 pb-4">
          <FormattedMessage
            id={TRANSLATION.DOUBLE_CLICK.id}
            defaultMessage={TRANSLATION.DOUBLE_CLICK.defaultMessage}
          />
        </Typo.body5>
      )}
      <div className="w-full h-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
        {images.map((file) => (
          <div key={file.id} className="relative">
            <ImagePreview
              onClick={() => onDeleteImage(file?.id || 0)}
              onSelected={() => setThumbnail(`${file.file}`)}
              onMaximize={() => ({})}
              className="rounded-xl md:flex-1 h-full w-full"
              uploading={file.file instanceof File}
              thumbnail={file?.file === thumbnail}
              src={
                file.file instanceof File
                  ? URL.createObjectURL(file.file)
                  : file.file
              }
            />
            <div
              id={`progress-circle-${file.id}`}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
