import axios, { AxiosError } from "axios";
export default class HttpError {
  public status: number | undefined;
  public data: any;
  public originalError: AxiosError | undefined;

  constructor(error: any) {
    if (axios.isAxiosError(error)) {
      this.originalError = error;
      this.status = error.response?.status;
      this.data = error.response?.data;
    } else {
      this.originalError = undefined;
      this.status = undefined;
      this.data = undefined;
    }
  }
}