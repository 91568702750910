type Props = {
  className?: string;
};
const AdProductIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35222)">
        <path
          d="M19.3223 0.0300293H10.5938V4.33336H19.9176V0.863363C19.9176 0.642349 19.8548 0.430387 19.7432 0.274107C19.6316 0.117827 19.4802 0.0300293 19.3223 0.0300293Z"
          fill="currentColor"
        />
        <path
          d="M0.0800781 0.863363V4.33336H9.40389V0.0300293H0.675316C0.517449 0.0300293 0.366048 0.117827 0.254419 0.274107C0.142791 0.430387 0.0800781 0.642349 0.0800781 0.863363Z"
          fill="currentColor"
        />
        <path
          d="M0.0800781 19.1367C0.0800781 19.3577 0.142791 19.5696 0.254419 19.7259C0.366048 19.8822 0.517449 19.97 0.675316 19.97H9.40389V6H0.0800781V19.1367ZM3.80865 9.16667H7.14198C7.29985 9.16667 7.45125 9.25446 7.56288 9.41074C7.67451 9.56702 7.73722 9.77899 7.73722 10C7.73722 10.221 7.67451 10.433 7.56288 10.5893C7.45125 10.7455 7.29985 10.8333 7.14198 10.8333H3.80865C3.65078 10.8333 3.49938 10.7455 3.38775 10.5893C3.27612 10.433 3.21341 10.221 3.21341 10C3.21341 9.77899 3.27612 9.56702 3.38775 9.41074C3.49938 9.25446 3.65078 9.16667 3.80865 9.16667Z"
          fill="currentColor"
        />
        <path
          d="M10.5938 19.97H19.3223C19.4802 19.97 19.6316 19.8822 19.7432 19.7259C19.8548 19.5696 19.9176 19.3577 19.9176 19.1367V6H10.5938V19.97ZM12.8557 9.16667H16.189C16.3469 9.16667 16.4983 9.25446 16.6099 9.41074C16.7215 9.56702 16.7842 9.77899 16.7842 10C16.7842 10.221 16.7215 10.433 16.6099 10.5893C16.4983 10.7455 16.3469 10.8333 16.189 10.8333H12.8557C12.6978 10.8333 12.5464 10.7455 12.4348 10.5893C12.3231 10.433 12.2604 10.221 12.2604 10C12.2604 9.77899 12.3231 9.56702 12.4348 9.41074C12.5464 9.25446 12.6978 9.16667 12.8557 9.16667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35222">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdProductIcon;
