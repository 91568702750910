import React from "react";
import Button from "../button";
import MessageIcon from "../../icons/MessageIcon";
type Props = {
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  phone?: string;
};

const MessageButton: React.FC<Props> = (props) => {
  const { children, disabled, loading, className, phone } = props;

  const sendMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Use navigator.sendSMS to send a message
    // You need to pass the phone number and the message
    // navigator.sendSMS("1234567890", "Hello");
  };

  return (
    <>
      <div>
        <Button
          size="sxtra-small"
          rounded="rounded-lg"
          disabled={!phone}
          theme="stroke-primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            sendMessage(event)
          }
          icon={<MessageIcon className="h-5 w-5" />}
        />
      </div>
    </>
  );
};

export default MessageButton;
