import PropertyLabel from "../propertyLabel";
import InputField from "../../../../components/shared/InputField";
import Mondy3Icon from "../../../../components/icons/Money3Icon";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { TRANSLATION } from "../../../../static/translation";
import { handelInputMap } from "../../../../util/handelInputMap";
import Toggle from "../../../../components/shared/Toggle";
import BulkStarIcon from "../../../../components/icons/BulkStarIcon";
import NearbyStoreIcon from "../../../../components/icons/NearbyStoreIcon";
import NearbyRestuarantIcon from "../../../../components/icons/NearbyRestuarantIcon";
import NearbyHospitalIcon from "../../../../components/icons/NearbyHospitalIcon";
import NearbySchoolIcon from "../../../../components/icons/NearbySchoolIcon";
import NearbyBusIcon from "../../../../components/icons/NeabyBusIcon";

type Props = {
  featureValues: any;
  intl: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string,
    name: string
  ) => void;
};

const NearbyTab: React.FC<Props> = (props) => {
  const { featureValues, valueChange, intl } = props;
  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-6">
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.nearbyMalls}
              onChange={(state) => valueChange(state, "nearbyMalls")}
              label={
                <PropertyLabel
                  title={TRANSLATION.NEARBY_MALLS}
                  icon={<NearbyStoreIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.nearbyRestaurants}
              onChange={(state) => valueChange(state, "nearbyRestaurants")}
              label={
                <PropertyLabel
                  title={TRANSLATION.NEARBY_RESTAURANT}
                  icon={<NearbyRestuarantIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.nearbyHospitals}
              onChange={(state) => valueChange(state, "nearbyHospitals")}
              label={
                <PropertyLabel
                  title={TRANSLATION.NEARBY_HOSPITAL}
                  icon={<NearbyHospitalIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.nearbySchools}
              onChange={(state) => valueChange(state, "nearbySchools")}
              label={
                <PropertyLabel
                  title={TRANSLATION.NEARBY_SCHOOLS}
                  icon={<NearbySchoolIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              defaultValue={featureValues.nearbyPublicTransports}
              onChange={(state) => valueChange(state, "nearbyPublicTransports")}
              label={
                <PropertyLabel
                  title={TRANSLATION.NEARBY_PUBLIC_TRANSPORTS}
                  icon={<NearbyBusIcon className="h-5 w-5 text-gray-300" />}
                />
              }
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default NearbyTab;
