import React from "react";
type props = {
  className?: string;
};
const TickIcon = ({ className }: props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.99984L7.66 13.6598L19 2.33984"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
