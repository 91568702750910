import PrivacyPolicyContent from "../shared/PrivacyPolicyContent";

const PrivacyPolicyMobile = () => {
  return (
    <div className="w-full">
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPolicyMobile;
