import React from "react";
import Image from "../../../components/shared/Image";
import { Typo } from "../../../components/shared/typo";
import Button from "../../../components/shared/button";
import downloadAfghouseApp from "../../../assets/image/download-afghouse-app.svg";
import mobileAppBackground from "../../../assets/image/mobile-app-background.svg";
import AppStoreIcon from "../../../components/icons/AppStoreIcon";
import GooglePlayIcon from "../../../components/icons/GooglePalyIcon";
import qrCode from "../../../assets/image/qrcode.svg";
import ArrowVictorIcon from "../../../components/icons/ArrowVictorIcon";
import mobileApp from "../../../assets/image/mobile-app.png";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  className?: string;
  direction: string;
};

const DownloadApp: React.FC<Props> = (props) => {
  const { className, direction } = props;

  return (
    <div className="bg-gray-50 py-14 relative select-none ">
      <div className="w-full absolute bottom-0">
        <Image src={mobileAppBackground} className="w-full h-full" />
      </div>
      <div className="max-w-7xl mx-auto relative px-4 sm:px-6 lg:px-8 ">
        <div className="grid md:grid-cols-2">
          <div className="flex flex-col gap-y-10">
            <Image className="w-full" src={downloadAfghouseApp} />

            <Typo.heading1 className="text-gray-800 ">
              <FormattedMessage
                id={TRANSLATION.DOWNLOAD_APP_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.DOWNLOAD_APP_DESCRIPTION.defaultMessage
                }
              />
            </Typo.heading1>

            <div className="flex flex-col sm:flex-row gap-6">
              <Button
                icon={<AppStoreIcon className="h-6 text-white" />}
                theme="secandory"
                to={`https://apps.apple.com/us/app/afghan-house/id1543871518`}
              >
                <Typo.heading3>
                  <FormattedMessage
                    id={TRANSLATION.DOWNLOAD_FROM_APP_STORE.id}
                    defaultMessage={
                      TRANSLATION.DOWNLOAD_FROM_APP_STORE.defaultMessage
                    }
                  />
                </Typo.heading3>
              </Button>
              <Button
                icon={<GooglePlayIcon className="h-6" />}
                theme="secandory"
                to={`https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PACKAGE_NAME}`}
              >
                <Typo.heading3>
                  <FormattedMessage
                    id={TRANSLATION.DOWNLOAD_FROM_PLAY_STORE.id}
                    defaultMessage={
                      TRANSLATION.DOWNLOAD_FROM_PLAY_STORE.defaultMessage
                    }
                  />
                </Typo.heading3>
              </Button>
            </div>
            <div className="flex items-center gap-x-10">
              <div>
                <Image src={qrCode} />
              </div>
              <div>
                <Typo.heading1 className="max-w-[162px] text-center">
                  <FormattedMessage
                    id={TRANSLATION.SCAN_QR_CODE.id}
                    defaultMessage={TRANSLATION.SCAN_QR_CODE.defaultMessage}
                  />
                </Typo.heading1>
              </div>
              <div>
                <ArrowVictorIcon
                  className={clsx("h-10 text-gray-700", {
                    "transform -scale-x-100 ": direction === DIRECTIONS.LTR,
                  })}
                />
              </div>
            </div>
          </div>
          <div className=" hidden md:block">
            <img className="w-full h-full" src={mobileApp} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
