import { useParams } from "react-router-dom";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import ArticelDetails from "../../shared/ArticleDetails";
const MobileArticleDetails = () => {
    const { id } = useParams();
    const { ToGetArticle } = useHandelArticle();
    const article = ToGetArticle(Number(id));
    return (
        <div className="w-full overflow-x-hidden">
            <div className="w-full max-w-7xl mx-auto relative">
                <div className="px-4 sm:px-8 md:px-12 lg:px-16">
                    <ArticelDetails article={article} />
                </div>
            </div>
        </div>
    );
};

export default MobileArticleDetails;
