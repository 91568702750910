import axios, { AxiosRequestConfig } from "axios";
import HttpError from "./httpError";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

// const getCsrfToken = async () => {
//   const res = await fetch("http://localhost:1337/csrf-token").then((response) =>
//     response.json()
//   );
//   return res.csrfToken;
// };

const http = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   "X-CSRF-Token": await getCsrfToken(),
  // },
});

interface fetchProp {
  method?: "POST" | "PUT" | "GET" | "DELETE" | 'PATCH';
  endpoint: string;
  data?: object;
  headers?: object;
  hasFile?: boolean;
  requiredAuth?: boolean;
}
export async function Fetch({
  method = "GET",
  endpoint,
  data,
  headers,
  requiredAuth = false,
  hasFile = false,
}: fetchProp) {
  let headerObj = headers || {};

  if (requiredAuth) {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON?.parse(tokenData) : "";
    headerObj = {
      ...headerObj,
      Authorization: `Bearer ${token}`,
    };
  }
  if (hasFile) {
    headerObj = {
      ...headerObj,
      "Content-Type": `multipart/form-data`,
    };
  }

  const axiosConfig: AxiosRequestConfig = {
    method: method,
    url: endpoint,
    data: data,
    headers: headerObj,
  };

  try {
    const response = await http(axiosConfig);
    return response.data;
  } catch (error) {
    throw new HttpError(error);
  }
}
