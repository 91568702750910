import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { PHONE_NUMBER_REGULAR_EXPRESSION } from "../../static/constants";
export const useHandelRegister = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "POST",
        endpoint: "/register",
        data: doUser,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      setLoading(false);
      setShowModal(false);
    },
    onError: (error) => {
      console.error("eeeeeee", error);
      setLoading(false);
    },
  });

  type FormValues = {
    name: string;
    email: string;
    password: string;
    phone: string;
  };

  const schema = object().shape({
    name: string().required(),
    email: string().required().email(),
    password: string().required(""),
    phone: string()
      .required()
      .matches(
        PHONE_NUMBER_REGULAR_EXPRESSION,
        "Phone number must be in valid"
      ),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async (data: any, provinceId: number, roleId: number) => {
    setLoading(true);
    const newData = {
      emailVerified: true,
      provinceId: provinceId,
      roleId: roleId,
      username: data.email,
      ...data,
    };

    mutation.mutate(newData);
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    setLoading,
    loading,
    setShowModal,
    showModal,
  };
};
