import React, { ChangeEvent, useState } from "react";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import Pagination from "../../../components/shared/Pagination";
import { useHandelUser } from "../../../hooks/user/useHandelUser";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import { User } from "../../../model/User";
import EyeIcon from "../../../components/icons/EyeIcon";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import Dropdown from "../../../components/shared/Dropdown";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { getRoles, getUserStates } from "../../../static/constants";
import InputField from "../../../components/shared/InputField";
import ProfileIcon from "../../../components/icons/ProfileIcon";
import { ROUTES } from "../../../constants/routes";
import Loader from "../../../components/shared/Loader";
import NoListingFound from "../listings/noListingFound";
import debounce from "lodash.debounce";
import { DEBOUNCE } from "../../../static/constants";
import UserModal from "../../../modal/UserModal";
import { useHandelRegister } from "../../../hooks/user/useHandelRegister";
import { FormattedMessage, useIntl } from "react-intl";
import { handelInputMap } from "../../../util/handelInputMap";
import { TRANSLATION } from "../../../static/translation";
import { useLang } from "../../../context/language";
import { getLocaleProvinces } from "../../../util/appUtil";
const Users = () => {
  // Define the columns and data for the table
  const Limit = 10;
  const { ToGetUsers } = useHandelUser();
  const { ToGetProvinces } = useHandelProvince();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [user, setUser] = useState<User>();
  const provinceResponse = ToGetProvinces(1, -1);
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const { showModal, setShowModal } = useHandelRegister();
  const [selectedRole, setSelectedRole] = useState<DropdownOptionModel>();
  const [state, setState] = useState<DropdownOptionModel>();
  const intl = useIntl();
  const lang = useLang();
  const roles = getRoles(intl);
  const userSates = getUserStates(intl);

  const users: any = ToGetUsers({
    page: page,
    limit: Limit,
    roleId: selectedRole?.id || undefined,
    provinceId: Number(selectedProvince?.id) || undefined,
    title: title,
    emailVerified: state?.id === 1 ? true : state?.id === 2 ? false : undefined,
  });

  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "number"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "name"),
        accessor: "name",
      },
      {
        Header: handelInputMap(intl, "email"),
        accessor: "email",
      },
      {
        Header: handelInputMap(intl, "agency"),
        accessor: "agencyName",
        Cell: ({ cell }: any) => (
          <div>
            {cell.row.values.agencyName
              ? cell.row.values.agencyName
              : intl.formatMessage({
                  id: TRANSLATION.DOES_NOT_HAVE.id,
                  defaultMessage: TRANSLATION.DOES_NOT_HAVE.defaultMessage,
                })}
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "province"),
        accessor: "province.state",
      },
      {
        Header: handelInputMap(intl, "confirmed"),
        accessor: "emailVerified",
        Cell: ({ cell }: any) => (
          <div>
            <FormattedMessage
              id={
                cell.row.values.emailVerified
                  ? TRANSLATION.YES.id
                  : TRANSLATION.NO.id
              }
              defaultMessage={
                cell.row.values.emailVerified
                  ? TRANSLATION.YES.defaultMessage
                  : TRANSLATION.NO.defaultMessage
              }
            />
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "actions"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                icon={<Edit2Icon className="h-4 w-4" />}
                to={`${ROUTES.DASHBOARD_USERS_ROUTE}/${cell.row.values.id}`}
                // onClick={() => handleEdit(cell.row.values)}
              ></Button>
              <Button
                theme="secandory"
                size="normal"
                to={`${ROUTES.DASHBOARD_USERS_ROUTE}/${cell.row.values.id}`}
                icon={<EyeIcon className="h-4 w-4" />}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEdit = (user: any) => {
    setUser(user);
    // setShowModal(true);
  };

  const handleDelete = (user: any) => {
    setConfirm(true);
    setUser(user);
  };

  const onConfirm = (state: boolean) => {
    if (state && user) {
      // onDelete(Number(category.id));
    }
    setConfirm(false);
  };

  const debouncedSetTitle = debounce(setTitle, DEBOUNCE);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="bg-white rounded-lg px-4">
        <div className=" whitespace-nowrap flex items-center gap-x-3 pt-5">
          <ProfileIcon className="h-5" />
          <Typo.body4 fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.USER_TITLE_WITH_VALUE.id}
              defaultMessage={TRANSLATION.USER_TITLE_WITH_VALUE.defaultMessage}
              values={{ value: users?.data?.count }}
            />
          </Typo.body4>
        </div>
        <div className="w-full py-5 flex gap-x-4 items-center">
          <Dropdown
            options={provinces || []}
            defaultValue={selectedProvince}
            onChange={setSelectedProvince}
            placeholder={handelInputMap(intl, "province_placeholder")}
          />
          <Dropdown
            options={roles}
            defaultValue={selectedRole}
            onChange={setSelectedRole}
            placeholder={handelInputMap(intl, "role_placeholder")}
          />
          <Dropdown
            options={userSates}
            defaultValue={state}
            onChange={setState}
            placeholder={handelInputMap(intl, "user_account_state")}
          />
          <InputField
            placeholder={handelInputMap(intl, "user_search_placeholder")}
            register={() => ({})}
            name="title"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              debouncedSetTitle(event.target.value)
            }
          />
          <Button
            onClick={() => setShowModal(true)}
            theme="primary"
            className=""
          >
            <Typo.heading4 className="whitespace-nowrap">
              <FormattedMessage
                id={TRANSLATION.CREATE_ACCOUNT.id}
                defaultMessage={TRANSLATION.CREATE_ACCOUNT.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
        {users?.data?.isLoading && <Loader className="h-screen" />}
        {!users?.data?.isLoading && users?.data?.count === 0 && (
          <NoListingFound />
        )}

        {!users?.data?.isLoading && users?.data?.count > 0 && (
          <Table columns={columns} data={users?.data?.rows || []} />
        )}
        <div className="pt-20 pb-10">
          {users?.data?.count > 0 ? (
            <Pagination
              onPageChange={setPage}
              total={users?.data?.count}
              currentPage={page}
              perPage={Limit}
              type="centered-page-number"
              direction={lang.direction}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <UserModal
        open={showModal}
        onClose={setShowModal}
        intl={intl}
        provinces={provinces}
      />
    </div>
  );
};

export default Users;
