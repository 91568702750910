type Props = {
  className?: string;
};
const GpsIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M9.99967 16.6667C13.6816 16.6667 16.6663 13.6819 16.6663 10C16.6663 6.31814 13.6816 3.33337 9.99967 3.33337C6.31778 3.33337 3.33301 6.31814 3.33301 10C3.33301 13.6819 6.31778 16.6667 9.99967 16.6667Z"
        fill="#E0E0E0"
      />
      <path
        d="M10.0004 12.5999C11.4363 12.5999 12.6004 11.4358 12.6004 9.9999C12.6004 8.56396 11.4363 7.3999 10.0004 7.3999C8.56445 7.3999 7.40039 8.56396 7.40039 9.9999C7.40039 11.4358 8.56445 12.5999 10.0004 12.5999Z"
        fill="#E0E0E0"
      />
      <path
        d="M10.625 1.66663V3.36663H10.6167C10.4167 3.34163 10.2083 3.33329 10 3.33329C9.79167 3.33329 9.58333 3.34163 9.38333 3.36663H9.375V1.66663C9.375 1.32496 9.65833 1.04163 10 1.04163C10.3417 1.04163 10.625 1.32496 10.625 1.66663Z"
        fill="#BDBDBD"
      />
      <path
        d="M3.33366 10C3.33366 10.2083 3.34199 10.425 3.36699 10.625H1.66699C1.32533 10.625 1.04199 10.3417 1.04199 10C1.04199 9.65833 1.32533 9.375 1.66699 9.375H3.36699C3.34199 9.575 3.33366 9.79167 3.33366 10Z"
        fill="#BDBDBD"
      />
      <path
        d="M10.625 16.6333V18.3333C10.625 18.675 10.3417 18.9583 10 18.9583C9.65833 18.9583 9.375 18.675 9.375 18.3333V16.6333H9.38333C9.58333 16.6583 9.79167 16.6666 10 16.6666C10.2083 16.6666 10.4167 16.6583 10.6167 16.6333H10.625Z"
        fill="#BDBDBD"
      />
      <path
        d="M18.9588 10C18.9588 10.3417 18.6755 10.625 18.3338 10.625H16.6338C16.6588 10.425 16.6671 10.2083 16.6671 10C16.6671 9.79167 16.6588 9.575 16.6338 9.375H18.3338C18.6755 9.375 18.9588 9.65833 18.9588 10Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default GpsIcon;
