import React, { useState, Fragment, ReactElement, ChangeEvent } from "react";
import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { DropdownOptionModel } from "../model/DropdownOption";
import { Typo } from "./shared/typo";
import InputDropdownOption, {
  InputDropdownLabel,
} from "./shared/DropdownOption";
import { MenuOptions } from "../model/MenuOptionsInterface";
import ChevronIcon from "./icons/ChevronIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../static/translation";
type Props = {
  iconType?: "none" | "image" | "icon" | "onlineIndicatro";
  position?: "relative" | "absolute";
  prefix?: string | ReactElement;
  suffex?: string | ReactElement;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  hasSearch?: boolean;
  options?: Array<MenuOptions>;
  defaultValue?: DropdownOptionModel;
  onChange?: (option: DropdownOptionModel) => void;
  searchStyle?: string;
  [x: string]: any;
};

const CategoryDropdown: React.FC<Props> = (props) => {
  const {
    label = "",
    hasSearch = false,
    placeholder = "",
    position = "absolute",
    prefix,
    suffex,
    options = [],
    defaultValue,
    onChange,
    iconType = "none",
    disabled = false,
    searchStyle,
  } = props;
  const [query, setQuery] = useState("");

  return (
    <div className="flex flex-col gap-y-1.5 w-full">
      {label ? (
        <Typo.heading4
          fontWeight="font-normal"
          className="text-gray-700 text-start"
        >
          {label}
        </Typo.heading4>
      ) : (
        ""
      )}
      <Combobox value={defaultValue} disabled={disabled} onChange={onChange}>
        {({ open }) => (
          <div className="relative">
            <div
              className={clsx(
                "relative w-full cursor-default overflow-hidden bg-white rounded-lg text-left shadow-xs border border-gray-300 focus:ring-4 focus:ring-gray-100",
                { " ring-4 ring-gray-100": open }
              )}
            >
              {hasSearch ? (
                <div
                  className={clsx(
                    " flex items-center px-3.5 ",
                    searchStyle ? searchStyle : "h-11"
                  )}
                >
                  {/* <MagnifyingGlassIcon className="h-5 w-5 text-gray-500"></MagnifyingGlassIcon> */}
                  <Combobox.Input
                    placeholder={placeholder}
                    className="w-full border-none py-[12px] h-5 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={(option: DropdownOptionModel) =>
                      option?.title
                    }
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setQuery(event.target.value)
                    }
                  />
                </div>
              ) : (
                <Combobox.Button className="flex w-full gap-x-2 justify-between whitespace-nowrap items-center py-[12px] px-3.5 group">
                  <InputDropdownLabel
                    prefix={prefix}
                    placeholder={placeholder}
                    value={defaultValue}
                    iconType={iconType}
                  ></InputDropdownLabel>

                  {suffex ? (
                    suffex
                  ) : (
                    <ChevronIcon
                      className={clsx("h-4 w-4 text-gray-500", {
                        " rotate-180 ": open,
                      })}
                    ></ChevronIcon>
                  )}
                </Combobox.Button>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                className={`${position} z-30 min-w-max mt-2 w-full py-6 px-5 overflow-y-auto overflow-x-hidden max-h-60 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {options?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    <Typo.heading5
                      fontWeight="font-normal"
                      className="text-gray-500"
                    >
                      <FormattedMessage
                        id={TRANSLATION.NOTHING_FOUND.id}
                        defaultMessage={
                          TRANSLATION.NOTHING_FOUND.defaultMessage
                        }
                      />
                    </Typo.heading5>
                  </div>
                ) : (
                  <div className="w-full">
                    <div className="grid grid-cols-3 gap-x-4">
                      {options?.map((option) => (
                        <div key={option.label} className="col-span-1">
                          <Combobox.Label className="w-full border-b">
                            <Typo.heading4
                              className="text-gray-900 text-start px-3.5 pb-1.5 w-full border-b border-b-gray-100 mb-4"
                              fontWeight="font-bold"
                            >
                              {option?.label}
                            </Typo.heading4>
                          </Combobox.Label>
                          {option?.options?.map((optionItem) => (
                            <Combobox.Option
                              key={`${option.label}-${optionItem?.id}`}
                              className={({ active }) =>
                                `relative cursor-pointer h-11 disabled:cursor-not-allowed select-none ${
                                  active ? " text-gray-900" : "text-gray-700"
                                }`
                              }
                              value={optionItem}
                            >
                              {({ selected }) => (
                                <InputDropdownOption
                                  iconType={iconType}
                                  key={optionItem.id}
                                  value={optionItem}
                                  selected={selected}
                                ></InputDropdownOption>
                              )}
                            </Combobox.Option>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default CategoryDropdown;
