export const buildEndpoint = (endpoint: string, params: Record<string, any>) => {
  let first = true;
  for (const key in params) {
    if (params[key] !== undefined) {
      endpoint += first ? "?" : "&";
      endpoint += `${key}=${params[key]}`;
      first = false;
    }
  }
  return endpoint;
};
