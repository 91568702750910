import React from "react";
import clsx from "clsx";
import { ListingObject } from "../../../model/Listing";
import { Typo } from "../typo";
import Price from "../Price";
import LocationPinIcon from "../../icons/LocationPinIcon";
import ClockIcon from "../../icons/ClockIcon";
import { getLocalTitle, timeAgo } from "../../../util/appUtil";
import Button from "../button";
import HeartIcon from "../../icons/HeartIcon";
import { IntlShape } from "react-intl";
import { handelGolasMap } from "../../../util/handelListingState";
import ShareButton from "../ShareButton";
type Prop = {
  listing?: ListingObject;
  className?: string;
  intl: IntlShape;
  local: string;
};

const ListingInfo: React.FC<Prop> = (props) => {
  const { listing, className, intl, local } = props;

  return (
    <div className={clsx("w-full", className)}>
      <div className=" bg-gray-50 rounded-xl px-6 pt-6 pb-14">
        <div className="flex items-center justify-between">
          <Typo.heading3 fontWeight="font-medium" className=" text-info-200">
            {`${getLocalTitle(
              local,
              listing?.subCategory.title_fa,
              listing?.subCategory.title_ps,
              listing?.subCategory.title_en
            )} ${handelGolasMap(intl, `${listing?.purpose}`)}`}
          </Typo.heading3>
          <Price
            price={listing?.price || ""}
            currency={listing?.currency || ""}
            intl={intl}
          />
        </div>
        <Typo.headingD fontWeight="font-bold" className=" text-gray-900 mt-2">
          {listing?.title || ""}
        </Typo.headingD>
        <div className="flex justify-between items-end">
          <div>
            <div className="mt-4 text-gray-600 flex items-center gap-x-1">
              <LocationPinIcon className="h-[18px]" />
              <Typo.body2>{listing?.location?.address}</Typo.body2>
            </div>
            <div className="mt-3 text-gray-400 flex items-center gap-x-1">
              <ClockIcon className="h-[18px]" />
              <Typo.body2>
                {timeAgo(listing?.createdAt?.toString() || "", intl)}
              </Typo.body2>
            </div>
          </div>
          <div className="flex gap-x-1">
            <ShareButton listing={listing} />

            <Button
              size="small"
              theme="label"
              icon={<HeartIcon className="h-6 w-6 text-gray-800" />}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingInfo;
