import React from "react";

import { Typo } from "../../../components/shared/typo";
import CategoryCard from "../../../components/shared/Card/CategoryCard";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
type Props = {
  categories?: any;
  direction:string
};

const HomeCategory: React.FC<Props> = (props) => {
  const { categories = [], direction } = props;
  return (
    <div className="max-w-7xl mx-auto pb-40 px-0 md:px-6 lg:px-8">
      <div className=" mt-96 sm:mt-80 lg:mt-40 space-y-4">
        <Typo.headingB
          fontWeight="font-black"
          className=" text-center text-primary-500"
        >
          <FormattedMessage
            id={TRANSLATION.LISTING_CATEGORY.id}
            defaultMessage={TRANSLATION.LISTING_CATEGORY.defaultMessage}
          />
        </Typo.headingB>
        <Typo.body4 className=" max-w-2xl mx-auto text-gray-500 text-center">
          <FormattedMessage
            id={TRANSLATION.LISTING_SCRIPTION.id}
            defaultMessage={
              TRANSLATION.LISTING_SCRIPTION.defaultMessage
            }
          />
        </Typo.body4>
      </div>
      <div className="grid grid-cols-1 gap-y-6 md:grid-cols-3 gap-x-6 mt-16">
        {categories?.map((category: any) => (
          <CategoryCard
            id={category.id}
            key={category.label}
            label={category.label}
            items={category.options}
            direction={direction}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeCategory;
