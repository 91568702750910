import React, { useState } from "react";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import TrashIcon from "../../../components/icons/TrashIcon";
import ConfirmModal from "../../../modal/ConfirmModal";
import { useHandelCountry } from "../../../hooks/location/useHandelCountry";
import CountryModal from "../../../modal/CountryModal";
import { Country } from "../../../model/Country";
import { TRANSLATION } from "../../../static/translation";
import { FormattedMessage, useIntl } from "react-intl";
import { handelInputMap } from "../../../util/handelInputMap";
const Countries = () => {
  // Define the columns and data for the table
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [country, setCountry] = useState<Country>();
  const { ToGetCounntries, onDelete } = useHandelCountry();
  const countries: any = ToGetCounntries();
  const intl = useIntl();

  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "number"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "country_name_fa"),
        accessor: "country_fa",
      },
      {
        Header: handelInputMap(intl, "country_name_ps"),
        accessor: "country_ps",
      },
      {
        Header: handelInputMap(intl, "country_name_en"),
        accessor: "country_en",
      },
      {
        Header: handelInputMap(intl, "country_code"),
        accessor: "countryCode",
      },
      {
        Header: handelInputMap(intl, "actions"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                icon={<Edit2Icon className="h-4 w-4" />}
                onClick={() => handleEdit(cell.row.values)}
              ></Button>
              <Button
                theme="error"
                size="normal"
                icon={<TrashIcon className="h-4 w-4" />}
                onClick={() => handleDelete(cell?.row?.values)}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEdit = (country: any) => {
    setCountry(country);
    setShowModal(true);
  };

  const handleDelete = (country: any) => {
    setConfirm(true);
    setCountry(country);
  };

  const onConfirm = (state: boolean) => {
    if (state && country) {
      onDelete(Number(country.id));
    }
    setConfirm(false);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.COUNTRY_TITLE.id}
              defaultMessage={TRANSLATION.COUNTRY_TITLE.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading3 className="text-gray-600 mt-2">
            <FormattedMessage
              id={TRANSLATION.COUNTRY_DESCRIPTION.id}
              defaultMessage={TRANSLATION.COUNTRY_DESCRIPTION.defaultMessage}
            />
          </Typo.heading3>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Button
            onClick={() => {
              setCountry(undefined);
              setShowModal(true);
            }}
            size="small"
            theme="primary"
          >
            <Typo.heading5 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.ADD.id}
                defaultMessage={TRANSLATION.ADD.defaultMessage}
              />
            </Typo.heading5>
          </Button>
        </div>
      </div>
      <div className="py-10">
        <Table columns={columns} data={countries?.data || []} />
      </div>
      <CountryModal
        defaultValue={country}
        open={showModal}
        onClose={setShowModal}
        intl={intl}
      />
      <ConfirmModal
        icon={<TrashIcon className="h-10" />}
        title={TRANSLATION.DELETE_COUNTRY}
        description={TRANSLATION.DELETE_COUNTRY_MESSAGE}
        open={confirm}
        onClose={onConfirm}
      />
    </div>
  );
};

export default Countries;
