import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { useIntl } from "react-intl";
import { handelValidationMap } from "../../util/handelValidationMap";
import { AlertModel } from "../../model/Alert";
import { AppError } from "../../static/AppError";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
export const useHandelForgotPasswordVerification = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const validations = handelValidationMap(intl);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<AlertModel>();

    const serverMessages = handelServerMessageMap(intl);
    const schema = object({
        token: string()
            .required('OTP is required')
            .matches(/^\d{6}$/, 'Enter a valid 6-digit OTP'),
    });
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const mutation = useMutation({
        mutationFn: async (doUser: any) => {
            return await Fetch({
                method: "POST",
                endpoint: "/password-reset/verify",
                data: doUser,
            });
        },
        onSuccess: (data) => {
            setLoading(false);
            navigate(ROUTES.RESET_PASSWORD_ROUTE)
            toast.success("حساب شما موفقانه تایید شد");
            localStorage.removeItem('email')

        },
        onError: (error: AppError) => {
            localStorage.removeItem('password_token')
            console.error(error);
            setLoading(false);
        },
    });

    const onSubmit = async (data: any) => {
        setLoading(true);
        const email = localStorage.getItem('email');
        localStorage.setItem('password_token', data.token)
        const newData = {
            email: email,
            token: data.token,
        };
        mutation.mutate(newData);
    };

    return {
        register,
        errors,
        handleSubmit,
        onSubmit,
        reset,
        loading,
        alert,
        setAlert,
    };
};
