import React, { useState } from "react";
import { ArticleCategoryModel } from "../../../model/Article";
import { Typo } from "../../../components/shared/typo";
import CheckboxField from "../../../components/shared/CheckboxField";
import ArticleSubscribe from "./ArticleSubscribe";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
type Props = {
  categories?: ArticleCategoryModel[];
  selectedCategories: number[];
  handleCheckboxChange: (categoryId: number) => void;
  intl: IntlShape;
};

const ArticleFilter: React.FC<Props> = (props) => {
  const { categories, selectedCategories, handleCheckboxChange, intl } = props;

  return (
    <div className="space-y-10">
      <div className="border space-y-10 border-gray-300 rounded-xl p-8">
        <div>
          <Typo.headingD fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.CATEGORIES.id}
              defaultMessage={TRANSLATION.CATEGORIES.defaultMessage}
            />
          </Typo.headingD>
        </div>
        <div className="space-y-4">
          {categories?.map((category) => {
            return (
              <CheckboxField
                key={category.name}
                isSelected={selectedCategories.includes(Number(category?.id))}
                onChange={() => handleCheckboxChange(Number(category?.id))}
                register={() => ({})}
                label={category.name}
              />
            );
          })}
        </div>
      </div>
      <ArticleSubscribe intl={intl} />
    </div>
  );
};

export default ArticleFilter;
