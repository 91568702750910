import InputField from "../../../../components/shared/InputField";
import Dropdown from "../../../../components/shared/Dropdown";
import {
  getBuildYears,
  getElectricityBackups,
  getFloors,
} from "../../../../static/constants";
import PropertyLabel from "../propertyLabel";
import Mondy3Icon from "../../../../components/icons/Money3Icon";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { TRANSLATION } from "../../../../static/translation";
import { handelInputMap } from "../../../../util/handelInputMap";
import Building4Icon from "../../../../components/icons/Building4Icon";
import FreezingLineIcon from "../../../../components/icons/FreezingLineIcon";
import CalenderIcon from "../../../../components/icons/CalenderIcon";
import LayerIcon from "../../../../components/icons/LayerIcon";
import ParkingIcon from "../../../../components/icons/ParkingIcon";
import KitchenIcon from "../../../../components/icons/KitchenIcon";
import FlashIcon from "../../../../components/icons/FlashIcon";
import { getNomas } from "../../../../static/constants";

type Props = {
  featureValues: any;
  intl: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string,
    name: string
  ) => void;
};

const FeatureTab: React.FC<Props> = (props) => {
  const { featureValues, valueChange, intl } = props;
  const nomas = getNomas(intl);
  const floors = getFloors(intl);
  const electricityBackups = getElectricityBackups(intl);
  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-6">
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.NOMA}
            icon={<Building4Icon className="h-5 w-5 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <Dropdown
              placeholder={handelInputMap(intl, "noma_placeholder")}
              options={nomas}
              defaultValue={featureValues.noma}
              onChange={(option) => valueChange(option, "noma")}
              name="noma"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.FLOOR}
            icon={<FreezingLineIcon className="h-5 w-5 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <Dropdown
              placeholder={handelInputMap(intl, "floors_placeholder")}
              options={floors}
              defaultValue={featureValues.floor}
              onChange={(option) => valueChange(option, "floor")}
              name="floor"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.BUILT_YEAR}
            icon={<CalenderIcon className="h-5 w-5 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <Dropdown
              placeholder={handelInputMap(intl, "builtYear_placeholder")}
              options={getBuildYears()}
              defaultValue={featureValues.builtYear}
              onChange={(option) => valueChange(option, "builtYear")}
              name="builtYear"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.FLOORS}
            icon={<LayerIcon className="h-5 w-5 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "floors_placeholder")}
              register={() => ({})}
              value={featureValues.floors}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="floors"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.PARKING_SPACE}
            icon={<ParkingIcon className="h-6 w-6 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "parkingSpace_placeholder")}
              register={() => ({})}
              value={featureValues.parkingSpace}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="parkingSpace"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.ELECTRICITY_BACKUP}
            icon={<FlashIcon className="h-5 w-5 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <Dropdown
              placeholder={handelInputMap(
                intl,
                "electricityBackup_placeholder"
              )}
              options={electricityBackups}
              defaultValue={featureValues.electricityBackup}
              onChange={(option) => valueChange(option, "electricityBackup")}
              name="electricityBackup"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.KITCHENS}
            icon={<KitchenIcon className="h-6 w-6 text-gray-300" />}
          />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "kitchens_placeholder")}
              register={() => ({})}
              defaultValue={featureValues.kitchens}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="kitchens"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.OTHER_FEATURE}
            icon={<Mondy3Icon />}
          />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "otherFeature_placeholder")}
              register={() => ({})}
              value={featureValues.otherFeature}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="otherFeature"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureTab;
