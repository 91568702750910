import React from "react";
import { ListingObject } from "../../../model/Listing";
import { User } from "../../../model/User";
import { Typo } from "../typo";
import LocationPinIcon from "../../icons/LocationPinIcon";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { mapPorpuse } from "../../../util/mapTranslation";
import { useIntl } from "react-intl";
import UserInfo from "./UserInfo";
import ListingAttributes from "../ListingAction/ListingAttributes";
import ImageThumbnail from "../ListingAction/ImageThumbnail";
import Price from "../Price";
import { getLocalTitle } from "../../../util/appUtil";
type ListingProp = {
  listing?: ListingObject;
  user: User | null;
  showUserInfo?: boolean;
  direction: string;
  onLike: (listingId: number) => void;
  onDisLike: (listingId: number) => void;
  locale: string;
};

const ListingCardRow: React.FC<ListingProp> = (props) => {
  const {
    listing,
    showUserInfo = true,
    user,
    direction,
    onLike,
    onDisLike,
    locale,
  } = props;
  const intl = useIntl();

  return (
    <div>
      <Link to={`${ROUTES.DASHBOARD_LISTINGS_ROUTE}/${listing?.slug}`}>
        <div className="py-6 px-4 sm:grid sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-3 xl:grid-cols-5 gap-x-6">
          <div className="relative sm:col-span-2 lg:col-span-1">
            <ImageThumbnail
              user={user}
              listing={listing}
              showInteration={true}
              intl={intl}
              type="feature"
              onLike={onLike}
              onDisLike={onDisLike}
            />
          </div>
          <div className="sm:col-span-3 md:col-span-5 lg:col-span-2 xl:col-span-4">
            <div className="flex h-full">
              <div className="grow">
                <div className="flex items-center justify-between pe-5">
                  <Typo.heading4
                    fontWeight="font-medium"
                    className="text-info-200 mt-2"
                  >
                    {mapPorpuse(
                      intl,
                      listing?.purpose || "",
                      getLocalTitle(
                        locale,
                        listing?.subCategory.title_fa,
                        listing?.subCategory.title_ps,
                        listing?.subCategory.title_en
                      ) || ""
                    )}
                  </Typo.heading4>
                  <Price
                    intl={intl}
                    price={listing?.price}
                    currency={listing?.currency}
                  />
                </div>
                <Typo.heading3
                  fontWeight="font-bold"
                  className="text-gray-900 mt-2"
                >
                  {listing?.title}
                </Typo.heading3>
                <div className="flex gap-x-1 items-center text-gray-600 mt-3">
                  <LocationPinIcon className="h-[18px]" />
                  <Typo.body4>{listing?.location?.address || ""}</Typo.body4>
                </div>
                <div className="flex gap-x-1 items-center text-gray-600 mt-3">
                  <ListingAttributes
                    area={listing?.area}
                    unit={listing?.unit}
                    bathrooms={listing?.bathrooms}
                    bedrooms={listing?.bedrooms}
                    intl={intl}
                  />
                </div>
              </div>
              {showUserInfo && (
                <div className="hidden xl:flex justify-end ps-2 border-s h-full items-center">
                  <UserInfo
                    user={listing?.user}
                    phone={listing?.phone}
                    agency={listing?.agency}
                    direction={direction}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListingCardRow;
