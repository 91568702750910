type Props = {
  className?: string;
};
const AddHomeIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className}`}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.51666 2.86667L3.02499 6.36667C2.27499 6.95 1.66666 8.19167 1.66666 9.13334V15.3083C1.66666 17.2417 3.24166 18.825 5.17499 18.825H14.825C16.7583 18.825 18.3333 17.2417 18.3333 15.3167V9.25C18.3333 8.24167 17.6583 6.95 16.8333 6.375L11.6833 2.76667C10.5167 1.95 8.64166 1.99167 7.51666 2.86667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path
          d="M6 11H14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15V7"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      {/* <path
        d="M10 15.4917V12.9917"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      /> */}
    </svg>
  );
};

export default AddHomeIcon;
