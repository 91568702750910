type Props = {
  className?: string;
};
const NearbyRestuarantIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5446 21.5V22.8333C26.5446 23.3952 26.5434 23.8687 26.4884 24.2892C26.4343 24.703 26.3345 25.0045 26.1772 25.2325C25.8933 25.6442 25.2224 26.0833 23.2946 26.0833H4.62793C2.70013 26.0833 2.02922 25.6442 1.74534 25.2325C1.58807 25.0045 1.48819 24.703 1.4341 24.2892C1.37913 23.8687 1.37793 23.3952 1.37793 22.8333V21.5C1.37793 21.1809 1.64214 20.9167 1.96126 20.9167H25.9613C26.2804 20.9167 26.5446 21.1809 26.5446 21.5Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M16.4851 4.22478L16.2972 4.97619L17.0543 5.13977C21.5305 6.10692 24.876 10.0747 24.876 14.8334V16.8334C24.876 17.1525 24.6118 17.4167 24.2927 17.4167H3.69271C3.37359 17.4167 3.10938 17.1525 3.10938 16.8334V14.8334C3.10938 10.0746 6.4681 6.09456 10.9425 5.14018L11.7183 4.9747L11.5096 4.20934C11.4473 3.98088 11.416 3.75394 11.416 3.50002C11.416 1.84862 12.9843 0.544127 14.7346 1.0234L14.7387 1.02448C15.871 1.32777 16.6114 2.35759 16.5832 3.57924C16.572 3.80226 16.5402 4.00429 16.4851 4.22478ZM9.99937 13.9167H17.9994C18.9603 13.9167 19.7494 13.1276 19.7494 12.1667C19.7494 11.2058 18.9603 10.4167 17.9994 10.4167H9.99937C9.03849 10.4167 8.24937 11.2058 8.24937 12.1667C8.24937 13.1276 9.03849 13.9167 9.99937 13.9167Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default NearbyRestuarantIcon;
