import React from "react";
import { ListingObject } from "../../../model/Listing";
import Button from "../button";
import Edit2Icon from "../../icons/Edit2Icon";
import { ROUTES } from "../../../constants/routes";
import { Typo } from "../typo";
import TrashIcon from "../../icons/TrashIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import TickIcon from "../../icons/TickIcon";
import { ListingState } from "../../../static/enum";
import { XIcon } from "react-share";
import CloseIcon from "../../icons/CloseIcon";
type Props = {
  listing?: ListingObject;
  className?: string;
  onDelete?: (listingId: number) => void;
  onDisable?: (listingId: number) => void;
  onActive?: (listingId: number) => void
};

const ListingCardAction: React.FC<Props> = (props) => {
  const { listing, onDelete, onDisable, onActive } = props;

  return (
    <div className="flex w-full justify-start gap-x-3 items-center">
      <Button
        theme="gray"
        icon={<Edit2Icon className="h-4" />}
        size="sxtra-small"
        rounded="rounded-lg"
        to={`${ROUTES.EDIT_LISTING_ROUTE}/${listing?.slug}`}
      >
        <Typo.heading5 fontWeight="font-medium">
          <FormattedMessage
            id={TRANSLATION.EDIT.id}
            defaultMessage={TRANSLATION.EDIT.defaultMessage}
          />
        </Typo.heading5>
      </Button>
      {listing?.listingState === ListingState.ACTIVE && (
        <Button
          theme="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onDisable && onDisable(Number(listing?.id));
          }}
          icon={<CloseIcon className="w-4" />}
          size="sxtra-small"
          rounded="rounded-lg"
        >
          <Typo.heading5 fontWeight="font-medium">
            <FormattedMessage
              id={TRANSLATION.INACTIVE.id}
              defaultMessage={TRANSLATION.INACTIVE.defaultMessage}
            />
          </Typo.heading5>
        </Button>
      )}
      {listing?.listingState === ListingState.INACTIVE && (
        <Button
          theme="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onActive && onActive(Number(listing?.id));
          }}
          icon={<TickIcon className="w-4" />}
          size="sxtra-small"
          rounded="rounded-lg"
        >
          <Typo.heading5 fontWeight="font-medium">
            <FormattedMessage
              id={TRANSLATION.ACTIVE.id}
              defaultMessage={TRANSLATION.ACTIVE.defaultMessage}
            />
          </Typo.heading5>
        </Button>
      )}
      <Button
        theme="error"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onDelete && onDelete(Number(listing?.id));
        }}
        icon={<TrashIcon className="h-5" />}
        size="sxtra-small"
        rounded="rounded-lg"
      ></Button>
    </div>
  );
};

export default ListingCardAction;
