import CallCallingIcon from "../../../components/icons/CallCallingIcon";
import DollarSquareIcon from "../../../components/icons/DollarSquare";
import GalleryAddIcon from "../../../components/icons/GalleryAddIcon";
import Home3Icon from "../../../components/icons/Home3Icon";
import HomeHashtagIcon from "../../../components/icons/HomeHashtagIcon";
import Mondy3Icon from "../../../components/icons/Money3Icon";
import VideoAddIcon from "../../../components/icons/VideoAddIcon";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import {
  getCurrencies,
  getDocuments,
  getGolas,
  getUnits,
} from "../../../static/constants";
import PropertyLabel from "./propertyLabel";
import SectionContainer from "./sectionContainer";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import Tab from "../../../components/shared/Tab";
import Dropdown from "../../../components/shared/Dropdown";
import GpsIcon from "../../../components/icons/GpsIcon";
import BulkHomeIcon from "../../../components/icons/BulkHomeIcon";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useEffect, useState } from "react";
import ProvinceDropdown from "../../../components/shared/ProvinceDropdown";
import Map from "../../../components/shared/Map";
import InputField from "../../../components/shared/InputField";
import Maximize3Icon from "../../../components/icons/Maximize3";
import ImageUploader from "../../../components/shared/ImageUploader";
import VideoUploader from "../../../components/shared/VideoUploader";
import DocumentTextIcon from "../../../components/icons/DocumentTextIcons";
import RankingIcon from "../../../components/icons/RankingIcon";
import BedIcon from "../../../components/icons/BedIcon";
import BathIcon from "../../../components/icons/BathIcon";
import ReceiptTextIcon from "../../../components/icons/ReceiptTextIcon";
import BulkTextIcon from "../../../components/icons/BulkTextIcon";
import MessageTextIcon from "../../../components/icons/MessageTextIcon";
import TextField from "../../../components/shared/TextField";
import AddCallIcon from "../../../components/icons/AddCallIcon";
import AddEmailIcon from "../../../components/icons/AddEmailIcon";
import PlusIcon from "../../../components/icons/PlusIcon";
import FeatureModal from "../../../modal/FeatureModal";
import { useHandelCategory } from "../../../hooks/category/useHandelCategory";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import { ImageFile } from "../../../model/Image";
import { Location } from "../../../model/Location";
import FeatureBadges from "./FeatureBadges";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { useIntl } from "react-intl";
import { useAuth } from "../../../context/auth";
import { Category } from "../../../model/Category";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import Loader from "../../../components/shared/Loader";
import {
  getLocaleCategories,
  getLocaleProvinces,
  getLocalTitle,
} from "../../../util/appUtil";
import { useLang } from "../../../context/language";
const NewListing = () => {
  // Define the columns and data for the table
  const intl = useIntl();
  const lang = useLang();
  const auth = useAuth();
  const goals = getGolas(intl);
  const documents = getDocuments(intl);
  const currencies = getCurrencies(intl);
  const units = getUnits(intl);
  const navigate = useNavigate();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetProvinces } = useHandelProvince();
  const [location, setLocation] = useState<Location>();
  const [requiredLocation, setRequiredLocation] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<DropdownOptionModel>();
  const [purpose, setPurpose] = useState<DropdownOptionModel>(goals[0]);
  const [document, setDocument] = useState<DropdownOptionModel>(documents[0]);
  const [selectedUnit, setSelectedUnit] = useState<DropdownOptionModel>(
    units[0]
  );
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownOptionModel>(
    currencies[0]
  );
  const [images, setImages] = useState<ImageFile[]>([]);
  const [thumbnail, setThumbnail] = useState("");
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [showFeature, setShowFeature] = useState(false);
  const [videos, setVideos] = useState<string[]>([]);
  const provinceResponse = ToGetProvinces(1, -1);
  const categoryResponse = ToGetCategories();
  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const {
    register,
    errors,
    handleSubmit,
    onSubmit,
    valueChange,
    setValue,
    featureValues,
    defaultFeatures,
    setFeatureValues,
    loading,
    onCancel,
  } = useHandelListing({ intl: intl });

  const onSubmitListing = async (data: any) => {
    if (location && selectedSubCategory && selectedProvince) {
      await onSubmit(
        data,
        images,
        thumbnail,
        deletedImages,
        selectedSubCategory,
        selectedCurrency,
        selectedUnit,
        document,
        purpose,
        selectedProvince,
        location
      );
    } else {
      setRequiredLocation(true);
    }
  };

  const onCloseFeature = (state: boolean) => {
    if (!state) {
      setFeatureValues(defaultFeatures);
    }
    setShowFeature(false);
  };

  const onChangeCategory = (category: DropdownOptionModel) => {
    setSelectedCategory(category);
    categoryResponse?.data?.map((cat: any) => {
      if (cat.id === category.id) {
        const subCategory: any = selectedSubCategory;
        if (subCategory?.categoryId !== category?.id) {
          setSelectedSubCategory(cat?.subCategories[0]);
        }
      }
    });
  };

  useEffect(() => {
    if (categoryResponse?.data) {
      const firstTab: any =
        categoryResponse?.data?.length > 0 ? categoryResponse?.data[0] : null;
      setSelectedCategory(firstTab);
      if (firstTab && firstTab?.subCategories?.length > 0) {
        setSelectedSubCategory(firstTab?.subCategories[0]);
      }
    }
  }, [categoryResponse?.data]);

  useEffect(() => {
    if (provinceResponse?.data) {
      const firstProvince: any =
        provinceResponse?.data?.count > 0
          ? provinceResponse?.data?.rows[0]
          : null;
      setSelectedProvince(firstProvince);
    }
  }, [provinceResponse?.data]);

  useEffect(() => {
    if (auth?.user) {
      if (!auth?.user?.completed && auth?.user?.roleId < 3) {
        navigate(ROUTES.COMPELETE_PROFILE_ROUTE);
      }
      setValue("email", auth.user.email);
      setValue("phone", auth?.user?.phone);
    }
  }, [auth?.user]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5 space-y-5">
      {auth?.user ? (
        <div className="w-full bg-white pb-24 rounded-lg">
          <form
            id="listing"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmitListing)}
          >
            <div className="w-full p-8 shadow-shadow2">
              <Typo.body2
                fontWeight="font-bold"
                className="text-gray-900 text-center"
              >
                <FormattedMessage
                  id={TRANSLATION.REGISTER_NEW_LISTING.id}
                  defaultMessage={
                    TRANSLATION.REGISTER_NEW_LISTING.defaultMessage
                  }
                />
              </Typo.body2>
              <Typo.body4
                fontWeight="font-light"
                className="text-gray-400 mt-3 text-center"
              >
                <FormattedMessage
                  id={TRANSLATION.REGISTER_NEW_LISTING_DESCRIPTION.id}
                  defaultMessage={
                    TRANSLATION.REGISTER_NEW_LISTING_DESCRIPTION.defaultMessage
                  }
                />
              </Typo.body4>
            </div>
            <div className=" max-w-3xl mx-auto w-full ">
              <SectionContainer
                className="mt-14"
                title={TRANSLATION.DEAL_AND_LOCATION_TITLE}
                icon={<Home3Icon className="h-6" />}
              >
                <div className="space-y-8">
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.GOAL}
                      icon={<Mondy3Icon />}
                    />
                    <div className="flex gap-x-4 ps-7 items-center">
                      {goals?.map((goal) => {
                        return (
                          <Button
                            key={goal.id}
                            size="small"
                            onClick={() => setPurpose(goal)}
                            theme={
                              goal.id === purpose.id
                                ? "stroke-primary"
                                : "stroke"
                            }
                          >
                            <Typo.heading5 fontWeight="font-medium">
                              {goal.title}
                            </Typo.heading5>
                          </Button>
                        );
                      })}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.LISTING_TYPE}
                      icon={<BulkHomeIcon />}
                    />
                    <Tab
                      type="underline"
                      tabs={getLocaleCategories(
                        lang.language,
                        categoryResponse?.data
                      )}
                      currentTab={selectedCategory}
                      onTabChange={onChangeCategory}
                      showDropdown={false}
                    />
                    {categoryResponse?.data?.map((category: Category) => {
                      return (
                        <div
                          key={category?.id}
                          className={` gap-4 ps-7 items-center ${
                            selectedCategory?.id === category?.id
                              ? "flex flex-wrap"
                              : "hidden"
                          } `}
                        >
                          {category?.subCategories?.map((subCategory: any) => {
                            return (
                              <Button
                                key={subCategory?.id}
                                size="small"
                                onClick={() =>
                                  setSelectedSubCategory(subCategory)
                                }
                                theme={
                                  selectedSubCategory &&
                                  subCategory?.id === selectedSubCategory?.id
                                    ? "stroke-primary"
                                    : "stroke"
                                }
                              >
                                <Typo.heading5 fontWeight="font-medium">
                                  {getLocalTitle(
                                    lang.language,
                                    subCategory?.title_fa,
                                    subCategory?.title_ps,
                                    subCategory?.title_en
                                  )}
                                </Typo.heading5>
                              </Button>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.LISTING_LOCATION}
                      icon={<GpsIcon />}
                    />
                    <div className="w-full mt-5 ps-7 flex flex-col sm:grid sm:grid-cols-2 gap-6">
                      <Dropdown
                        options={provinces || []}
                        defaultValue={selectedProvince}
                        onChange={setSelectedProvince}
                        placeholder={handelInputMap(
                          intl,
                          "province_placeholder"
                        )}
                      />
                      <ProvinceDropdown
                        defaultValue={location}
                        onItemSelect={(location: any) => setLocation(location)}
                        placeholder={handelInputMap(
                          intl,
                          "address_placeholder"
                        )}
                        showError={!location && requiredLocation}
                      />
                      <div className=" hidden col-span-2 sm:flex flex-col gap-y-8">
                        <Map
                          coords={[
                            location?.latitude || 34.5553,
                            location?.longitude || 69.2075,
                          ]}
                        />
                        {/* <div className="flex justify-between items-center">
                        <div className="flex items-center gap-x-2">
                          <DangerIcon />
                          <Typo.body4 className="text-gray-900">
                            موقعیت مکانی شما دقیق نشان داده نشود
                          </Typo.body4>
                        </div>
                        <div>
                          <Toggle defaultValue={true} onChange={() => ({})} />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.PRICE_AND_AREA}
                icon={<DollarSquareIcon className="h-6" />}
              >
                <div className="space-y-8">
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.PRICE}
                      icon={<Mondy3Icon />}
                    />
                    <div className="w-full mt-5 ps-7 grid grid-cols-7 sm:grid-cols-6 gap-x-6 gap-y-8">
                      <div className="col-span-4 md:col-span-5">
                        <InputField
                          placeholder={handelInputMap(
                            intl,
                            "price_placeholder"
                          )}
                          register={register}
                          name="price"
                          errors={errors.price}
                        />
                      </div>
                      <div className="col-span-3 sm:col-span-2 md:col-span-1">
                        <Dropdown
                          options={currencies || []}
                          defaultValue={selectedCurrency}
                          onChange={setSelectedCurrency}
                          placeholder={handelInputMap(intl, "currency")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.AREA}
                      icon={<Maximize3Icon />}
                    />
                    <div className="w-full mt-5 ps-7 grid grid-cols-7 sm:grid-cols-6 gap-x-6 gap-y-8">
                      <div className="col-span-4 md:col-span-5">
                        <InputField
                          placeholder={handelInputMap(intl, "area_placeholder")}
                          register={register}
                          name="area"
                          errors={errors.area}
                        />
                      </div>
                      <div className="col-span-3 sm:col-span-2 md:col-span-1">
                        <Dropdown
                          options={units || []}
                          defaultValue={selectedUnit}
                          onChange={setSelectedUnit}
                          placeholder={handelInputMap(intl, "unit_placeholder")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.FACILITIES_AND_CONVENIENVE}
                icon={<HomeHashtagIcon className="h-6" />}
              >
                <div className="space-y-8">
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.BEDROOM}
                      icon={<BedIcon className="h-5 w-5 text-gray-300" />}
                    />
                    <div className="w-full mt-5 ps-7">
                      <InputField
                        placeholder={handelInputMap(
                          intl,
                          "bedroom_placeholder"
                        )}
                        register={register}
                        name="bath"
                        errors={errors.bath}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.BATHROOM}
                      icon={<BathIcon className="h-5 w-5 text-gray-300" />}
                    />
                    <div className="w-full mt-5 ps-7">
                      <InputField
                        placeholder={handelInputMap(
                          intl,
                          "bathroom_placeholder"
                        )}
                        register={register}
                        name="room"
                        errors={errors.room}
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.DOCUMENT_TYPE}
                      icon={<DocumentTextIcon />}
                    />
                    <div className="w-full mt-5 ps-7 flex flex-wrap gap-4">
                      {documents?.map((item) => {
                        return (
                          <Button
                            key={item.id}
                            onClick={() => setDocument(item)}
                            theme={
                              item.id === document.id
                                ? "stroke-primary"
                                : "stroke"
                            }
                          >
                            <Typo.heading5 fontWeight="font-medium">
                              {item.title}
                            </Typo.heading5>
                          </Button>
                        );
                      })}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.FACILITIES_AND_CONVENIENVE}
                      icon={<RankingIcon />}
                    />
                    <div className="w-full mt-5 ps-7 flex flex-col gap-y-6">
                      <Typo.body5 className="text-gray-600">
                        <FormattedMessage
                          id={TRANSLATION.MORE_FEATURE_DESCRIPTION.id}
                          defaultMessage={
                            TRANSLATION.MORE_FEATURE_DESCRIPTION.defaultMessage
                          }
                        />
                      </Typo.body5>
                      <div>
                        <Button
                          onClick={() => setShowFeature(true)}
                          theme="primary"
                          size="small"
                        >
                          <Typo.heading5 fontWeight="font-medium">
                            <FormattedMessage
                              id={TRANSLATION.ADD_MORE_FEATURE.id}
                              defaultMessage={
                                TRANSLATION.ADD_MORE_FEATURE.defaultMessage
                              }
                            />
                          </Typo.heading5>
                        </Button>
                      </div>
                    </div>
                    <div className="w-full">
                      <FeatureBadges
                        valueChange={valueChange}
                        featureValues={featureValues}
                      />
                    </div>
                  </div>
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.ADDITIONAL_INFORMATION}
                icon={<ReceiptTextIcon className="h-6" />}
              >
                <div className="space-y-8">
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.TITLE}
                      icon={<BulkTextIcon className="h-5 w-5" />}
                    />
                    <div className="w-full mt-5 ps-7">
                      <InputField
                        placeholder={handelInputMap(intl, "listing_title")}
                        register={register}
                        name="title"
                        errors={errors.title}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.DESCRIPTION}
                      icon={<MessageTextIcon className="h-5 w-5" />}
                    />
                    <div className="w-full mt-5 ps-7">
                      <TextField
                        placeholder={handelInputMap(
                          intl,
                          "listing_description"
                        )}
                        register={register}
                        name="description"
                        errors={errors?.description}
                      />
                    </div>
                  </div>
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.ADD_IMAGE}
                icon={<GalleryAddIcon className="h-6 w-6" />}
              >
                <div className=" space-y-8">
                  <ImageUploader
                    images={images}
                    maxImages={20}
                    setImages={setImages}
                    deletedImages={deletedImages}
                    setDeletedImages={setDeletedImages}
                    thumbnail={thumbnail}
                    setThumbnail={setThumbnail}
                  />
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.ADD_VIDEO}
                icon={<VideoAddIcon className="h-6" />}
              >
                <div className=" space-y-8">
                  <VideoUploader
                    register={register}
                    setVideos={setVideos}
                    setVideoValue={(values: string[]) =>
                      setValue("videos", values)
                    }
                    errors={errors}
                    videos={videos}
                    maxVideos={2}
                  />
                </div>
              </SectionContainer>

              <SectionContainer
                className="mt-14"
                title={TRANSLATION.CONTACT_INFROMATION}
                icon={<CallCallingIcon className="h-6" />}
              >
                <div className="space-y-8">
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.EMAIL}
                      icon={<AddEmailIcon className="h-5 w-5" />}
                    />
                    <div className="w-full mt-5 ps-7">
                      <InputField
                        placeholder={handelInputMap(intl, "email_placeholder")}
                        register={register}
                        name="email"
                        dir="ltr"
                        errors={errors?.email}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <PropertyLabel
                      title={TRANSLATION.PHONE}
                      icon={<AddCallIcon className="h-5 w-5" />}
                    />
                    <div className="w-full mt-5 ps-7 gap-x-6 gap-y-8">
                      <InputField
                        defaultValue={auth?.user?.phone}
                        placeholder={handelInputMap(intl, "phone_placeholder")}
                        register={register}
                        name="phone"
                        errors={errors.phone}
                      />
                    </div>
                  </div>
                </div>
              </SectionContainer>

              <div className="mt-10 flex px-4 md:px-0 gap-x-4">
                <Button
                  theme="primary"
                  type="submit"
                  loading={loading}
                  icon={<PlusIcon />}
                  className="w-full sm:w-auto"
                >
                  <Typo.heading3 fontWeight="font-medium">
                    <FormattedMessage
                      id={TRANSLATION.REGISTER_NEW_LISTING.id}
                      defaultMessage={
                        TRANSLATION.REGISTER_NEW_LISTING.defaultMessage
                      }
                    />
                  </Typo.heading3>
                </Button>

                <Button
                  onClick={onCancel}
                  theme="secandory"
                  className="w-full sm:w-auto"
                >
                  <Typo.heading3 fontWeight="font-medium">
                    <FormattedMessage
                      id={TRANSLATION.CANCEL.id}
                      defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                    />
                  </Typo.heading3>
                </Button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Loader className="h-screen" />
      )}
      <FeatureModal
        featureValues={featureValues}
        valueChange={valueChange}
        open={showFeature}
        onClose={onCloseFeature}
      />
    </div>
  );
};

export default NewListing;
