import React from "react";
import TickCircleIcon from "../../../components/icons/TickCircleIcon";
import { Typo } from "../../../components/shared/typo";
type Props = {
  title?: string;
};

const RecordContent: React.FC<Props> = (props) => {
  const { title } = props;

  return (
    <div className="flex gap-x-2 items-center">
      <TickCircleIcon className="text-primary-600 h-6 w-6"></TickCircleIcon>
      <Typo.body3 className="text-gray-800">{title}</Typo.body3>
    </div>
  );
};

export default RecordContent;
