import { ChangeEvent, FC, useState } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import Image from "../components/shared/Image";
import { Typo } from "../components/shared/typo";
import afLogo from "../../src/assets/image/logo.svg";
import Dropdown from "../components/shared/Dropdown";
import { DropdownOptionModel } from "../model/DropdownOption";
import Toggle from "../components/shared/Toggle";
import { Listing } from "../model/Listing";
import { getListingStates } from "../static/constants";
import { FormattedMessage, IntlShape } from "react-intl";
import PropertyLabel from "../pages/dashboard/listings/propertyLabel";
import { TRANSLATION } from "../static/translation";
import StartIcon from "../components/icons/StarIcon";
import { useHandelListing } from "../hooks/listing/useHandelListing";
import TextField from "../components/shared/TextField";
import { handelInputMap } from "../util/handelInputMap";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  listing: Listing;
  intl: IntlShape;
  // onUpdateState: asy (listingId: number, stateId: number, feature: boolean) => void;
};

const UpdateListingModal: FC<Props> = ({ open, onClose, listing, intl }) => {
  const listingStates = getListingStates(intl);
  const defaultState = listingStates.find(
    (item) => item.id === listing?.listingState
  );
  const [selectedState, setSelectedState] = useState<
    DropdownOptionModel | undefined
  >(defaultState);
  const [feature, setFeature] = useState(listing.featured || false);
  const [content, setContent] = useState("");
  const { onUpdateState, loading } = useHandelListing({ intl });

  const onUpdateListing = async () => {
    await onUpdateState({
      listingId: Number(listing?.id),
      listingState: String(selectedState?.id),
      title: "",
      content: content,
    });
    onClose && onClose(true);
  };

  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center mb-5">
          <Image className="h-14 w-14 mx-auto" src={afLogo} alt="logo" />
          <Typo.heading2
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.EDIT_LISTING.id}
              defaultMessage={TRANSLATION.EDIT_LISTING.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-gray-500 text-center mt-6"
          >
            {listing.title}
          </Typo.heading1>
        </div>

        <div className="space-y-5">
          <Dropdown
            options={listingStates || []}
            defaultValue={selectedState}
            onChange={setSelectedState}
            label={intl.formatMessage({
              id: TRANSLATION.LISTING_STATE.id,
              defaultMessage: TRANSLATION.LISTING_STATE.defaultMessage,
            })}
          />

          <TextField
            placeholder={handelInputMap(intl, "listing_description")}
            register={() => ({})}
            rows={3}
            defaultValue={content}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              setContent(event.target.value)
            }
            name="description"
          />
          <Toggle
            size="sm"
            defaultValue={feature}
            onChange={setFeature}
            label={
              <PropertyLabel
                title={TRANSLATION.ADD_TO__FREATURE_LISTING}
                icon={<StartIcon className="h-5 w-5 text-gray-300" />}
              />
            }
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-4 mt-10">
          <Button
            loading={loading}
            onClick={onUpdateListing}
            size="medium"
            type="submit"
            theme="primary"
            className="w-full"
          >
            <Typo.heading4 fontWeight="font-bold" className="text-white">
              <Typo.heading3 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.SAVE_CHANGES.id}
                  defaultMessage={TRANSLATION.SAVE_CHANGES.defaultMessage}
                />
              </Typo.heading3>
            </Typo.heading4>
          </Button>
          <Button onClick={onClose} className="w-full" theme="secandory">
            <Typo.heading4 fontWeight="font-medium">
              <Typo.heading3 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading3>
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateListingModal;
