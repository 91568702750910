/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from "clsx";
import React from "react";
import { Typo } from "./typo";
import YoutubeIcon from "../icons/YoutubeIcon";
import Divider from "./Divider";

type Props = {
  defaultValue?: string;
  errors?: any;
  placeholder?: string;
  register: (name: any, registerOptions: any) => any;
  name: string;
  registerOptions?: any;
  label?: string;
  hintText?: string;
  type?: string;
  disabled?: boolean;
  className?: string;
  verificationtext?: string;
  labelStyle?: string;
  required?: boolean;
  value?: string | number;
  inputStyle?: string;
  autoComplete?: boolean;
  [x: string]: any;
};

const VideoInput: React.FC<Props> = (props) => {
  const {
    defaultValue = "",
    errors = {},
    placeholder = "https://www.youtube/",
    register,
    name,
    registerOptions = {},
    label = "",
    hintText = "",
    disabled = false,
    className = "",
    labelStyle = "",
    required = false,
    inputStyle = "",
    value,
    ...rest
  } = props;

  return (
    <div className="w-full relative space-y-1.5" dir="ltr">
      {label && (
        <label htmlFor={name || ""} className="text-start">
          <Typo.heading4
            fontWeight="font-medium"
            className={` text-gray-800 ${labelStyle}`}
          >
            {label}
            {required ? " * " : ""}
          </Typo.heading4>
        </label>
      )}
      <div className="relative">
        <div className="block w-full">
          <div className="relative ">
            <input
              {...register(name, registerOptions)}
              placeholder={placeholder}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
              value={value}
              className={clsx(
                `pe-[14px] ps-12 w-full py-2 border-none focus:ring-2 focus:border focus:border-gray-300 focus:ring-gray-100 bg-white placeholder:text-gray-400 font-iransans text-sm
                ${inputStyle ? inputStyle : " rounded-[4px] shadow-xs"}`,
                {
                  "border-error-300 text-gray-400 focus:border-error-300 focus:ring-error-100 ":
                    errors?.message,
                },
                className
              )}
              {...rest}
            />

            <div className="absolute z-10 inset-y-0 start-0 flex items-center">
              <div className="flex gap-x-2 px-2 items-center">
                <YoutubeIcon className="h-6 text-error-300" />
                <div className="h-6 w-[1px] bg-gray-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Typo.heading4
        fontWeight="font-normal"
        className={`w-full text-left ${
          errors?.message ? "text-error-500" : "text-gray-500"
        }`}
      >
        {errors?.message ? errors?.message : hintText}
      </Typo.heading4>
    </div>
  );
};

export default VideoInput;
