import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import Dropdown from "../../../components/shared/Dropdown";
import TextField from "../../../components/shared/TextField";
import { useHandelArticleCategory } from "../../../hooks/article/useHandelArticleCategory";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import DraftEditor from "../../../components/shared/Editor";
import { useAuth } from "../../../context/auth";
import SingleImageUploader from "../../../components/shared/SingleImageUploader";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
const NewArticle = () => {
  // Define the columns and data for the table
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const [content, setContent] = useState<any>();
  const [image, setImage] = useState("");
  const auth = useAuth();
  const intl = useIntl();
  const { register, errors, handleSubmit, onSubmit } = useHandelArticle();

  const { ToGetArticleCategory } = useHandelArticleCategory();
  const categories = ToGetArticleCategory();

  const onCreateArticle = async (data: any) => {
    if (selectedCategory) {
      await onSubmit({
        userId: auth?.user?.id,
        articleCategoryId: Number(selectedCategory?.id),
        image: image,
        content: content,
        ...data,
      });
    } else {
      toast.error(
        intl.formatMessage({
          id: TRANSLATION.ARTICLE_TITLE_REQUIRED.id,
          defaultMessage: TRANSLATION.ARTICLE_TITLE_REQUIRED.defaultMessage,
        })
      );
    }
  };

  useEffect(() => {
    if (categories?.data && categories?.data?.length > 0) {
      setSelectedCategory({
        id: categories?.data[0].id,
        title: categories?.data[0].name,
      });
    }
  }, [categories?.data]);

  return (
    <div className="px-4 sm:px-6 py-5 lg:px-8 space-y-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onCreateArticle)}
        >
          <div className=" space-y-8 bg-white rounded-lg">
            <div className="w-full p-8 shadow-shadow2 rounded-t-lg">
              <Typo.body2
                fontWeight="font-bold"
                className="text-gray-900 text-center"
              >
                <FormattedMessage
                  id={TRANSLATION.ADD_NEW_ARTICLE.id}
                  defaultMessage={TRANSLATION.ADD_NEW_ARTICLE.defaultMessage}
                />
              </Typo.body2>
              <Typo.body4
                fontWeight="font-light"
                className="text-gray-400 mt-3 text-center"
              >
                <FormattedMessage
                  id={TRANSLATION.ADD_NEW_ARTICLE_DESCRIPTION.id}
                  defaultMessage={
                    TRANSLATION.ADD_NEW_ARTICLE_DESCRIPTION.defaultMessage
                  }
                />
              </Typo.body4>
            </div>
            <div className="w-full rounded-lg pb-10 px-14 space-y-8">
              <div className="w-full flex gap-x-5">
                <InputField
                  name="title"
                  label={handelInputMap(intl, "title")}
                  placeholder={handelInputMap(
                    intl,
                    "article_title_placeholder"
                  )}
                  register={register}
                  errors={errors?.title}
                  required={true}
                />
              </div>
              <SingleImageUploader
                title={handelInputMap(intl, "article_image")}
                description={handelInputMap(intl, "article_image_placeholder")}
                image={image}
                onChange={setImage}
              />
              <div className="w-full flex gap-x-5">
                <TextField
                  name="description"
                  label={handelInputMap(intl, "description")}
                  placeholder={handelInputMap(
                    intl,
                    "article_description_placeholder"
                  )}
                  register={register}
                  rows={5}
                  errors={errors?.description}
                  required={true}
                />
              </div>
              <div className="w-full flex gap-x-5">
                <Dropdown
                  label={handelInputMap(intl, "article_category")}
                  placeholder={handelInputMap(
                    intl,
                    "article_category_placeholder"
                  )}
                  required={true}
                  defaultValue={selectedCategory}
                  onChange={setSelectedCategory}
                  options={
                    categories?.data?.map((category) => ({
                      id: category.id,
                      title: category.name,
                    })) || []
                  }
                />
              </div>
              <div className="w-full rounded-lg">
                <DraftEditor value={content} setValue={setContent} />
              </div>

              <div className="pt-6">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    <FormattedMessage
                      id={TRANSLATION.SAVE_CHANGES.id}
                      defaultMessage={TRANSLATION.SAVE_CHANGES.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewArticle;
