import React from "react";
import { AgencyObject } from "../../../model/Agency";
import { Typo } from "../typo";
import Image from "../Image";
import Button from "../button";
import { ROUTES } from "../../../constants/routes";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { getLocalTitle } from "../../../util/appUtil";
type AgencyProp = {
  agency?: AgencyObject;
  locale: string;
};

const AgencyCard: React.FC<AgencyProp> = (props) => {
  const { agency ,locale} = props;
  return (
    <div className=" rounded-xl border border-gray-200 focus:border-secondary-200 bg-white p-6 hover:border-white cursor-pointer hover:shadow-shadow2">
      <div className="space-y-3 flex flex-col items-center">
        <Image
          className="h-28 rounded-lg mx-auto"
          src={
            agency?.users?.length && agency?.users?.length > 0
              ? agency?.users[0]?.image
              : undefined
          }
        />
        <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
          {agency?.name}
        </Typo.heading2>
        <Typo.heading3 className="text-gray-700">
          {getLocalTitle(
            locale,
            agency?.province?.state_fa,
            agency?.province?.state_ps,
            agency?.province?.state_en
          )}
        </Typo.heading3>
        <div dir="ltr">
          <Typo.heading3 className="text-gray-500 flex divide-x-2">
            {agency?.sells && agency.sells > 0 ? (
              <span className="px-1">
                <FormattedMessage
                  id={TRANSLATION.FOR_SELL.id}
                  defaultMessage={TRANSLATION.FOR_SELL.defaultMessage}
                  values={{ name: agency?.sells }}
                />
              </span>
            ) : (
              ""
            )}
            {agency?.rents && agency.rents > 0 ? (
              <span className="px-1">
                <FormattedMessage
                  id={TRANSLATION.FOR_RENT.id}
                  defaultMessage={TRANSLATION.FOR_RENT.defaultMessage}
                  values={{ name: agency?.rents }}
                />
              </span>
            ) : (
              ""
            )}
            {agency?.mortgages && agency.mortgages > 0 ? (
              <span className="px-1">
                <FormattedMessage
                  id={TRANSLATION.FOR_MORTGAGE.id}
                  defaultMessage={TRANSLATION.FOR_MORTGAGE.defaultMessage}
                  values={{ name: agency?.mortgages }}
                />
              </span>
            ) : (
              ""
            )}
          </Typo.heading3>
        </div>
        <Button
          to={`${ROUTES.AGENCIES_ROUTE}/${agency?.id}`}
          theme="gray"
          size="small"
          className="mt-3"
        >
          <Typo.heading4 fontWeight="font-medium" className="text-primary-500">
            <FormattedMessage
              id={TRANSLATION.MORE_DETAILS.id}
              defaultMessage={TRANSLATION.MORE_DETAILS.defaultMessage}
            />
          </Typo.heading4>
        </Button>
      </div>
    </div>
  );
};

export default AgencyCard;
