type Props = {
  className?: string;
};
const GlobeIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="currentColor"
      />
      <path
        d="M9.00023 21.75H8.00023C7.59023 21.75 7.25023 21.41 7.25023 21C7.25023 20.59 7.57023 20.26 7.98023 20.25C6.41023 14.89 6.41023 9.11 7.98023 3.75C7.57023 3.74 7.25023 3.41 7.25023 3C7.25023 2.59 7.59023 2.25 8.00023 2.25H9.00023C9.24023 2.25 9.47023 2.37 9.61023 2.56C9.75023 2.76 9.79023 3.01 9.71023 3.24C7.83023 8.89 7.83023 15.11 9.71023 20.77C9.79023 21 9.75023 21.25 9.61023 21.45C9.47023 21.63 9.24023 21.75 9.00023 21.75Z"
        fill="currentColor"
      />
      <path
        d="M15.0004 21.7502C14.9204 21.7502 14.8404 21.7402 14.7604 21.7102C14.3704 21.5802 14.1504 21.1502 14.2904 20.7602C16.1704 15.1102 16.1704 8.89018 14.2904 3.23018C14.1604 2.84018 14.3704 2.41018 14.7604 2.28018C15.1604 2.15018 15.5804 2.36018 15.7104 2.75018C17.7004 8.71018 17.7004 15.2702 15.7104 21.2202C15.6104 21.5502 15.3104 21.7502 15.0004 21.7502Z"
        fill="currentColor"
      />
      <path
        d="M12 17.2C9.21 17.2 6.43 16.81 3.75 16.02C3.74 16.42 3.41 16.75 3 16.75C2.59 16.75 2.25 16.41 2.25 16V15C2.25 14.76 2.37 14.53 2.56 14.39C2.76 14.25 3.01 14.21 3.24 14.29C8.89 16.17 15.12 16.17 20.77 14.29C21 14.21 21.25 14.25 21.45 14.39C21.65 14.53 21.76 14.76 21.76 15V16C21.76 16.41 21.42 16.75 21.01 16.75C20.6 16.75 20.27 16.43 20.26 16.02C17.57 16.81 14.79 17.2 12 17.2Z"
        fill="currentColor"
      />
      <path
        d="M20.9998 9.75011C20.9198 9.75011 20.8398 9.74011 20.7598 9.71011C15.1098 7.83011 8.87978 7.83011 3.22978 9.71011C2.82978 9.84011 2.40978 9.63011 2.27978 9.24011C2.15978 8.84011 2.36978 8.42011 2.75978 8.29011C8.71978 6.30011 15.2798 6.30011 21.2298 8.29011C21.6198 8.42011 21.8398 8.85011 21.6998 9.24011C21.6098 9.55011 21.3098 9.75011 20.9998 9.75011Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GlobeIcon;
