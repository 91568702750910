import React, { useState } from "react";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelArticleCategory } from "../../../hooks/article/useHandelArticleCategory";
import ArticleCategoryModal from "../../../modal/ArticleCategoryModal";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import { ROUTES } from "../../../constants/routes";
import TrashIcon from "../../../components/icons/TrashIcon";
import { ArticleCategoryModel } from "../../../model/Article";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useIntl } from "react-intl";
const ArticleCategory = () => {
  // Define the columns and data for the table
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const { ToGetArticleCategory } = useHandelArticleCategory();
  const [categoryArticle, setCategoryArticle] =
    useState<ArticleCategoryModel>();
  const articleCategories: any = ToGetArticleCategory();
  const columns = React.useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: TRANSLATION.NUMBER.id,
          defaultMessage: TRANSLATION.NUMBER.defaultMessage,
        }),
        accessor: "id",
      },
      {
        Header: intl.formatMessage({
          id: TRANSLATION.TITLE.id,
          defaultMessage: TRANSLATION.TITLE.defaultMessage,
        }),
        accessor: "name",
      },
      {
        Header: intl.formatMessage({
          id: TRANSLATION.ACTIONS.id,
          defaultMessage: TRANSLATION.ACTIONS.defaultMessage,
        }),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                icon={<Edit2Icon className="h-4 w-4" />}
                onClick={() => handleEdit(cell.row.original)}
              ></Button>
              <Button
                theme="error"
                size="normal"
                to={`${ROUTES.DASHBOARD_ARTICLES_ROUTE}/${cell.row.values.id}`}
                icon={<TrashIcon className="h-4 w-4" />}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEdit = (category: any) => {
    setCategoryArticle(category);
    setShowModal(true);
  };

  const onCreate = () => {
    setCategoryArticle(undefined);
    setShowModal(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.ARTICLE_CATEGORY.id}
              defaultMessage={TRANSLATION.ARTICLE_CATEGORY.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading3 className="text-gray-600 mt-2">
            <FormattedMessage
              id={TRANSLATION.ARTICLE_CATEGORY_DESCRIPTION.id}
              defaultMessage={
                TRANSLATION.ARTICLE_CATEGORY_DESCRIPTION.defaultMessage
              }
            />
          </Typo.heading3>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Button onClick={onCreate} size="small" theme="primary">
            <Typo.heading5 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.ADD_NEW_CATEGORY.id}
                defaultMessage={TRANSLATION.ADD_NEW_CATEGORY.defaultMessage}
              />
            </Typo.heading5>
          </Button>
        </div>
      </div>
      <div className="py-10">
        <Table columns={columns} data={articleCategories?.data || []} />
      </div>
      <ArticleCategoryModal
        defaultValue={categoryArticle}
        open={showModal}
        onClose={setShowModal}
        intl={intl}
      />
    </div>
  );
};

export default ArticleCategory;
