import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import {
  NotificationProp,
  NotificationResponse,
} from "../../model/Notification";
import { buildEndpoint } from "../../util/buildEndpoint";
import { NOTIFICATION_STATES } from "../../static/enum";

export const useHandelNotification = () => {
  const queryClient = useQueryClient();
  function ToGetNotifications(
    notificationProp: NotificationProp,
    userId?: number
  ) {
    const result = useQuery({
      queryKey: ["notifications", notificationProp.page, notificationProp.showUnRead, notificationProp.refresh],
      queryFn: async () => await getNotifications(notificationProp),
      enabled: !!userId,
    });
    return result;
  }

  const getNotifications = async (
    notificationProp: NotificationProp
  ): Promise<NotificationResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("/notification", notificationProp),
      requiredAuth: true,
    });
    return response?.data;
  };

  const readMutation = useMutation({
    mutationFn: async (notificaion: any) => {
      const response = await Fetch({
        method: "PUT",
        data: notificaion,
        endpoint: '/notification',
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const daleteMutation = useMutation({
    mutationFn: async () => {
      const response = await Fetch({
        method: "DELETE",
        data: {},
        endpoint: '/notification',
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const onReadNotifications = async () => {
    const data = {
      state: NOTIFICATION_STATES.READ,
    };
    readMutation.mutate(data);
  };

  const onDeleteNotifications = async () => {
    daleteMutation.mutate();
  };

  return {
    ToGetNotifications,
    onReadNotifications,
    onDeleteNotifications
  };
};
