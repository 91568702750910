import Calender2Icon from "../../components/icons/Calender2Icon";
import { Typo } from "../../components/shared/typo";
import Image from "../../components/shared/Image";
import Divider from "../../components/shared/Divider";
import FolderIcon from "../../components/icons/FolderIcon";
type Props = {
    article: any;
};
const ArticelDetails: React.FC<Props> = (props) => {
    const { article } = props;
    return (
        <div className="col-span-3 md:col-span-2">
            <div className="bg-gray-50 rounded-xl p-4 sm:p-6 md:p-8 lg:p-10">
                <Typo.heading1 fontWeight="font-bold">
                    {article.data?.title}
                </Typo.heading1>
                <div className="text-gray-400 flex flex-row gap-x-3 mt-4">
                    <div className="flex flex-row gap-x-2 items-center ">
                        <Calender2Icon className="h-5 w-5 " />
                        <Typo.heading2>
                            {article?.data?.createdAt &&
                                Intl.DateTimeFormat("fa-IR").format(
                                    new Date(`${article.data?.createdAt}`)
                                )}
                        </Typo.heading2>
                    </div>
                    <div className="flex items-center">
                        <Divider className="text-gray-100 h-full" type={"vertical"} />
                    </div>
                    <div className="flex flex-row gap-x-2 items-center">
                        <FolderIcon className="h-5 w-5 " />
                        <Typo.heading2>
                            {article.data?.articleCategory?.name}
                        </Typo.heading2>
                    </div>
                </div>
                <Image
                    src={article.data?.image}
                    className="h-48 sm:h-80 md:h-96 lg:h-[452px] w-full select-none rounded-lg mt-6"
                ></Image>
            </div>
            <div className="mt-8 sm:mt-12 md:mt-16 lg:mt-20">
                <article className="prose text-justify w-full">
                    {article.data?.content && (
                        <div
                            dangerouslySetInnerHTML={{ __html: article.data?.content }}
                        />
                    )}
                </article>
            </div>
        </div>
    );
};

export default ArticelDetails;
