type Props = {
  className?: string;
};
const EmptyImage = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M22.0187 16.82L18.8887 9.50002C18.3187 8.16002 17.4687 7.40002 16.4987 7.35002C15.5387 7.30002 14.6087 7.97002 13.8987 9.25002L11.9987 12.66C11.5987 13.38 11.0287 13.81 10.4087 13.86C9.77867 13.92 9.14867 13.59 8.63867 12.94L8.41867 12.66C7.70867 11.77 6.82867 11.34 5.92867 11.43C5.02867 11.52 4.25867 12.14 3.74867 13.15L2.01867 16.6C1.39867 17.85 1.45867 19.3 2.18867 20.48C2.91867 21.66 4.18867 22.37 5.57867 22.37H18.3387C19.6787 22.37 20.9287 21.7 21.6687 20.58C22.4287 19.46 22.5487 18.05 22.0187 16.82Z"
        fill="#BDBDBD"
      />
      <path
        d="M6.96984 8.38C8.83657 8.38 10.3498 6.86672 10.3498 5C10.3498 3.13327 8.83657 1.62 6.96984 1.62C5.10312 1.62 3.58984 3.13327 3.58984 5C3.58984 6.86672 5.10312 8.38 6.96984 8.38Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export default EmptyImage;
