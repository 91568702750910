type Props = {
  className?: string;
};
const SofaIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35378)">
        <path
          d="M2 19.4286C2 19.7143 2.22222 19.9592 2.48148 19.9592H2.66667C2.92593 19.9592 3.14815 19.7143 3.14815 19.4286V19.1021H2V19.4286Z"
          fill="currentColor"
        />
        <path
          d="M14.8154 11.1021H5.29688C4.74459 11.1021 4.29688 11.5498 4.29688 12.1021V12.6327C4.29688 13.1849 4.74459 13.6327 5.29687 13.6327H14.8154C15.3677 13.6327 15.8154 13.1849 15.8154 12.6327V12.1021C15.8154 11.5498 15.3677 11.1021 14.8154 11.1021Z"
          fill="currentColor"
        />
        <path
          d="M3.70356 7.99995C3.88874 8.24485 4.03689 8.57138 4.148 8.8571C4.25911 9.18363 4.29615 9.51016 4.29615 9.83669C4.29615 10.0621 4.47889 10.2449 4.70431 10.2449H15.4065C15.6319 10.2449 15.8147 10.0621 15.8147 9.83669C15.8147 9.03069 16.133 8.26366 16.6433 7.71155C17.3115 6.98855 18.111 6.17264 18.111 5.18815V3.02036C18.111 1.38771 16.8887 0.0407715 15.4073 0.0407715H4.62949C3.148 0.0407715 1.92578 1.38771 1.92578 3.02036V6.80829C1.92578 6.85779 1.96406 6.88871 2.00677 6.91372C2.02533 6.92459 2.04867 6.94009 2.06996 6.94306C2.72314 7.03393 3.3029 7.43799 3.70356 7.99995Z"
          fill="currentColor"
        />
        <path
          d="M16.9258 19.4286C16.9258 19.7143 17.148 19.9592 17.4073 19.9592H17.5924C17.8517 19.9592 18.0739 19.7143 18.0739 19.4286V19.1021H16.9258V19.4286Z"
          fill="currentColor"
        />
        <path
          d="M18.4816 7.71436C17.5556 7.71436 16.7778 8.44905 16.5927 9.42864C16.5556 9.55109 16.5556 9.71436 16.5556 9.8368V14.0409C16.5556 14.2858 16.3704 14.449 16.1853 14.449H3.88896C3.66674 14.449 3.51859 14.245 3.51859 14.0409V9.8368C3.51859 8.65313 2.66674 7.71436 1.59266 7.71436H1.51859C0.700391 7.71436 0.0371094 8.37764 0.0371094 9.19584V16.7673C0.0371094 17.6059 0.716974 18.2858 1.55563 18.2858H3.48155H16.5186H18.4445C19.3036 18.2858 20.0001 17.5893 20.0001 16.7302V9.23287C20.0001 8.39422 19.3202 7.71436 18.4816 7.71436Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35378">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SofaIcon;
