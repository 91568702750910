type Props = {
  className?: string;
};
const ChevronDownIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-6 w-6 ${className}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.74998C12.192 9.74998 12.384 9.82295 12.53 9.96995L16.53 13.9699C16.823 14.2629 16.823 14.738 16.53 15.031C16.237 15.324 15.762 15.324 15.469 15.031L11.999 11.561L8.52903 15.031C8.23603 15.324 7.76099 15.324 7.46799 15.031C7.17499 14.738 7.17499 14.2629 7.46799 13.9699L11.468 9.96995C11.616 9.82295 11.808 9.74998 12 9.74998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDownIcon;
