type Props = {
  className?: string;
};
const AppStoreIcon = ({ className }: Props) => {
  return (
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 651.4 800"
      className={className}
    >
      <path fill="currentColor" d="M544.03,425.01c1.12,121.07,106.21,161.35,107.37,161.87-.89,2.84-16.79,57.41-55.36,113.78-33.35,48.74-67.95,97.29-122.47,98.3-53.57,.99-70.79-31.76-132.03-31.76s-80.36,30.76-131.07,32.75c-52.62,1.99-92.69-52.7-126.31-101.26C15.46,599.37-37.04,418.03,33.45,295.62c35.02-60.79,97.61-99.28,165.54-100.27,51.67-.99,100.44,34.76,132.03,34.76s90.84-42.99,153.14-36.68c26.08,1.09,99.3,10.54,146.32,79.36-3.79,2.35-87.36,51-86.46,152.22M443.36,127.73c27.94-33.82,46.74-80.89,41.61-127.73-40.27,1.62-88.96,26.83-117.85,60.63-25.89,29.93-48.56,77.83-42.44,123.75,44.88,3.47,90.74-22.81,118.68-56.65" />
    </svg>
  );
};

export default AppStoreIcon;
