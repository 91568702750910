type Props = {
  className?: string;
};
const MessageIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1665 1.66797H5.83317C3.53317 1.66797 1.6665 3.5263 1.6665 5.81797V10.8013V11.6346C1.6665 13.9263 3.53317 15.7846 5.83317 15.7846H7.08317C7.30817 15.7846 7.60817 15.9346 7.74984 16.118L8.99984 17.7763C9.54984 18.5096 10.4498 18.5096 10.9998 17.7763L12.2498 16.118C12.4082 15.9096 12.6582 15.7846 12.9165 15.7846H14.1665C16.4665 15.7846 18.3332 13.9263 18.3332 11.6346V5.81797C18.3332 3.5263 16.4665 1.66797 14.1665 1.66797ZM6.6665 10.0013C6.19984 10.0013 5.83317 9.6263 5.83317 9.16797C5.83317 8.70964 6.20817 8.33464 6.6665 8.33464C7.12484 8.33464 7.49984 8.70964 7.49984 9.16797C7.49984 9.6263 7.13317 10.0013 6.6665 10.0013ZM9.99984 10.0013C9.53317 10.0013 9.1665 9.6263 9.1665 9.16797C9.1665 8.70964 9.5415 8.33464 9.99984 8.33464C10.4582 8.33464 10.8332 8.70964 10.8332 9.16797C10.8332 9.6263 10.4665 10.0013 9.99984 10.0013ZM13.3332 10.0013C12.8665 10.0013 12.4998 9.6263 12.4998 9.16797C12.4998 8.70964 12.8748 8.33464 13.3332 8.33464C13.7915 8.33464 14.1665 8.70964 14.1665 9.16797C14.1665 9.6263 13.7998 10.0013 13.3332 10.0013Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageIcon;
