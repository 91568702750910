import React from "react";
import { DropdownOptionModel } from "../../model/DropdownOption";
// import InputDropdown from './location Dropdown/InputDropdown';
import Dropdown from "./Dropdown";
import Button from "./button";
import clsx from "clsx";
import { Typo } from "./typo";
type TabProps = {
  currentTab?: DropdownOptionModel;
  tabs?: DropdownOptionModel[];
  label?: string;
  type?: "button" | "underline";
  showDropdown?: boolean;
  onTabChange?: (tab: DropdownOptionModel) => void;
};
const Tab: React.FC<TabProps> = (props) => {
  const {
    currentTab,
    type = "button",
    tabs = [],
    onTabChange,
    showDropdown = true,
  } = props;

  return (
    <div>
      <div
        className={`relative justify-center mx-auto ${
          showDropdown ? "sm:hidden flex " : "hidden"
        }`}
      >
        <Dropdown
          onChange={onTabChange}
          placeholder="Select a tab"
          options={tabs}
          className="w-full"
          defaultValue={currentTab}
        ></Dropdown>
      </div>
      <div className={` ${showDropdown ? "hidden sm:block" : "block"}`}>
        <div
          className={`${
            type === "underline"
              ? "border-b border-gray-200"
              : " bg-gray-50 border border-gray-100 p-[6px] rounded-t-lg"
          }`}
        >
          <nav className="-mb-px flex gap-x-3" aria-label="Tabs">
            {tabs?.map((tab) => (
              <Button
                onClick={() => onTabChange && onTabChange(tab)}
                key={tab.title}
              >
                <div
                  className={clsx(
                    "flex items-center gap-x-2",
                    type === "underline"
                      ? clsx(
                          "flex whitespace-nowrap group pb-3 rounded-none text-gray-500 px-5 text-center py-4 disabled:bg-opacity-50",
                          currentTab && tab.id === currentTab?.id
                            ? " border-b-2 border-b-primary-600 text-primary-600 hover:border-b-primary-700 hover:text-primary-600 focus:border-b-primary-600 focus:text-primary-600"
                            : " border-b-2 border-b-transparent hover:border-b-primary-600 hover:text-primary-600 focus:text-gray-500"
                        )
                      : clsx(
                          " text-gray-400 hover:text-gray-500 px-[14px] py-[10px] focus:text-gray-500 ",
                          currentTab && tab.id === currentTab?.id
                            ? " shadow-sm bg-white rounded-md text-gray-400 focus:text-gray-500 "
                            : "shadow-none"
                        )
                  )}
                >
                  <Typo.heading4
                    fontWeight={
                      type === "button" ? "font-medium" : "font-semibold"
                    }
                  >
                    {tab.title}
                  </Typo.heading4>
                  {tab?.supportingTitle && (
                    <div
                      className={clsx(
                        "py-0.5 px-3.5 rounded-2xl ",
                        tab.id === currentTab?.id
                          ? " bg-primary-50 text-primary-600"
                          : "bg-gray-100 text-gray-400 group-hover:bg-primary-50 group-hover:text-primary-600 group-focus:bg-gray-100 group-focus:text-gray-400 "
                      )}
                    >
                      <Typo.body4 fontWeight="font-normal">
                        {tab.supportingTitle}
                      </Typo.body4>
                    </div>
                  )}
                </div>
              </Button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tab;
