import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { Category } from "../../model/Category";
import { handelValidationMap } from "../../util/handelValidationMap";
import { IntlShape } from "react-intl";
// Define the type of the onClose parameter
// type props = (state: boolean) => void;
type props = {
  intl: IntlShape;
  onClose?: (state: boolean) => void;
};
export const useHandelCategory = (props: props) => {
  const { onClose, intl } = props;
  const queryClient = useQueryClient();
  const validations = handelValidationMap(intl);
  const schema = object().shape({
    title_fa: string().required(validations["title_required"]),
    title_ps: string().required(validations["title_required"]),
    title_en: string().required(validations["title_required"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doCategory: any) => {
      const response = await Fetch({
        method: "POST",
        data: doCategory,
        endpoint: "/category",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["category"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (category: any) => {
      const response = await Fetch({
        method: "DELETE",
        data: category,
        endpoint: `/category/${category.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["category"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (category: any) => {
      const response = await Fetch({
        method: "PUT",
        data: category,
        endpoint: `/category/${category.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["category"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const onUpdate = async (category: Category, id: number) => {
    const data = {
      id: id,
      title_fa: category.title_fa,
      title_ps: category.title_ps,
      title_en: category.title_en,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    const data = {
      id: id,
    };
    deleteMutation.mutate(data);
  };

  const onSubmit = async (data: any) => {
    mutation.mutate(data);
  };

  function ToGetCategories() {
    const result = useQuery({
      queryKey: ["category", ""],
      queryFn: async () => await getCategories(),
    });
    return result;
  }

  const getCategories = async (): Promise<Category[]> => {
    const response = await Fetch({
      endpoint: "/category",
    });
    return response?.data;
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    setValue,
    ToGetCategories,
    onDelete,
    onUpdate,
  };
};
