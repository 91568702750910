type Props = {
  className?: string;
};
const BedIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.33333H12C13.4733 1.33333 14.6667 2.52667 14.6667 4V9.33333C14.6667 9.7 14.3667 10 14 10C13.6333 10 13.3333 9.7 13.3333 9.33333V8H1.33333V9.33333C1.33333 9.7 1.03333 10 0.666667 10C0.3 10 0 9.7 0 9.33333V0.666667C0 0.3 0.3 0 0.666667 0C1.03333 0 1.33333 0.3 1.33333 0.666667V6H6.66667V2.66667C6.66667 1.93333 7.26667 1.33333 8 1.33333ZM6 3.33301C6 4.43967 5.10667 5.33301 4 5.33301C2.89333 5.33301 2 4.43967 2 3.33301C2 2.22634 2.89333 1.33301 4 1.33301C5.10667 1.33301 6 2.22634 6 3.33301Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BedIcon;
