import React, { useCallback, useRef, useState } from "react";
import { Typo } from "../../../components/shared/typo";
import Button from "../../../components/shared/button";
import ChevronIcon from "../../../components/icons/ChevronIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ListingCard from "../../../components/shared/Card/ListingCard";
import { ROUTES } from "../../../constants/routes";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
import { User } from "../../../model/User";
type Props = {
  className?: any;
  listings: any;
  direction: string;
  user: User | null;
  intl: IntlShape;
  onLike: (listingId: number) => void;
  onDisLike: (listingId: number) => void;
  locale: string;
};

const FeaturedListings: React.FC<Props> = (props) => {
  const { listings, direction, user, intl, onLike, onDisLike, locale } = props;
  const popularListing = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const goNext = () => {
    if (popularListing?.current) {
      popularListing?.current?.swiper?.slideNext();
    }
  };

  const goPrev = () => {
    if (popularListing?.current) {
      popularListing?.current?.swiper?.slidePrev();
    }
  };

  const handleSlideChange = useCallback((swiper: any) => {
    setActiveIndex(swiper?.activeIndex);
  }, []);

  return (
    <div className="bg-gray-50 pt-14 pb-4 sm:pb-8 md:pb-12 lg:pb-20">
      <div className="max-w-7xl mx-auto px-0 md:px-6 lg:px-8 ">
        <Typo.headingB
          fontWeight="font-black"
          className="text-center text-primary-600 select-none "
        >
          <FormattedMessage
            id={TRANSLATION.FEATURED_LISTINGS.id}
            defaultMessage={TRANSLATION.FEATURED_LISTINGS.defaultMessage}
          />
        </Typo.headingB>
        <Typo.body4 className="text-gray-500 max-w-2xl select-none text-center mx-5 md:mx-auto mt-4">
          <FormattedMessage
            id={TRANSLATION.FEATURED_LISTINGS_DESCRIPTION.id}
            defaultMessage={
              TRANSLATION.FEATURED_LISTINGS_DESCRIPTION.defaultMessage
            }
          />
        </Typo.body4>
        <div className="mt-4 space-y-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-4">
              <Button
                theme={activeIndex === 0 ? "gray" : "white"}
                size="medium"
                disabled={activeIndex === 0}
                onClick={goPrev}
                className="shadow-shadow2"
                icon={
                  <ChevronIcon
                    className={clsx(
                      "-rotate-90 text-secondary-500",
                      direction === DIRECTIONS.LTR ? "rotate-90" : "-rotate-90"
                    )}
                  />
                }
              ></Button>
              <Button
                disabled={activeIndex === listings?.length - 1}
                theme={activeIndex === listings?.length - 1 ? "gray" : "white"}
                size="medium"
                onClick={goNext}
                icon={
                  <ChevronIcon
                    className={clsx(
                      "text-secondary-500",
                      direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                    )}
                  />
                }
              ></Button>
            </div>
            <div>
              <Button
                size="medium"
                iconPos="right"
                theme="gray"
                className="shadow-shadow2"
                to={ROUTES.LISTINGS_ROUTE}
                icon={
                  <ChevronIcon
                    className={clsx(
                      direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                    )}
                  />
                }
              >
                <Typo.heading4 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.VIEW_ALL.id}
                    defaultMessage={TRANSLATION.VIEW_ALL.defaultMessage}
                  />
                </Typo.heading4>
              </Button>
            </div>
          </div>
          <div>
            <Swiper
              ref={popularListing}
              spaceBetween={24}
              slidesPerView={1}
              onSlideChange={handleSlideChange} // Add the event handler here
              breakpoints={{
                // specify different values for different screen widths
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
              }}
              scrollbar={{ draggable: true }}
            >
              {listings?.map((item: any) => (
                <SwiperSlide key={item.title} className="w-full">
                  <ListingCard
                    listing={item}
                    background="gray"
                    user={user}
                    direction={direction}
                    intl={intl}
                    onLike={onLike}
                    onDisLike={onDisLike}
                    locale={locale}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListings;
