type Props = {
  className?: string;
};
const ActiveMenuIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-[60px] w-5 ${className}`}
      viewBox="0 0 21 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_841_16295)">
        <path
          d="M21 16L21 44"
          stroke="#1ED760"
          strokeWidth="12"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_841_16295"
          x="0"
          y="0"
          width="32"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.843137 0 0 0 0 0.376471 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_841_16295"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_841_16295"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ActiveMenuIcon;
