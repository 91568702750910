import { FormattedMessage, useIntl } from "react-intl";
import { Typo } from "../../../components/shared/typo";
import { TRANSLATION } from "../../../static/translation";
import Alert from "../../../components/shared/Alert";
import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { useHandelResetPassword } from "../../../hooks/forgotPassword/useHandelResetPassword";
import { handelInputMap } from "../../../util/handelInputMap";
const ResetPassword = () => {
    const intl = useIntl();
    const {
        register,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        alert,
        setAlert,
    } = useHandelResetPassword();


    return <div className="w-full">
        <div className="w-full max-w-xs mx-auto py-5">
            <div className=" items-center">
                {/* <Image className="h-14 w-14 mx-auto" src={logo} alt="logo" /> */}
                <Typo.heading1
                    fontWeight="font-bold"
                    className=" text-secondary-900 text-center mt-6"
                >
                    <FormattedMessage id={TRANSLATION.PASSWORD_RESET_TITLE.id} defaultMessage={TRANSLATION.PASSWORD_RESET_TITLE.defaultMessage} />
                </Typo.heading1>
                <Typo.heading5 className="text-gray-400 mt-2 text-center">
                    <FormattedMessage id={TRANSLATION.PASSWORD_RESET_DESCRIPTION.id} defaultMessage={TRANSLATION.PASSWORD_RESET_DESCRIPTION.defaultMessage} />
                </Typo.heading5>
                <Alert
                    type={alert?.type}
                    title={alert?.title}
                    description={alert?.description}
                    open={alert?.open || false}
                    setOpen={() =>
                        setAlert((prevAlert: any) => ({
                            ...prevAlert,
                            open: false,
                        }))
                    }
                />
            </div>
            <form
                id="passwordReset"
                action="#"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="w-full my-7">
                    <InputField
                        name="newPassword"
                        type="password"
                        label={handelInputMap(intl, 'new_password')}
                        placeholder={handelInputMap(intl, 'new_password_placeholder')}
                        register={register}
                        errors={errors?.newPassword}
                        required={true}
                    />
                </div>
                <div className="w-full my-7">
                    <InputField
                        name="confirmPassword"
                        type="password"
                        label={handelInputMap(intl, 'confirm_password')}
                        placeholder={handelInputMap(intl, 'confirm_password_placeholder')}
                        register={register}
                        errors={errors?.confirmPassword}
                        required={true}
                    />
                </div>
                <div className=" flex flex-col space-y-2 ">
                    <Button
                        loading={loading}
                        size="medium"
                        type="submit"
                        theme="primary"
                    >
                        <Typo.heading4 fontWeight="font-bold" className="text-white">
                            <FormattedMessage
                                id={TRANSLATION.CONFIRM.id}
                                defaultMessage={TRANSLATION.CONFIRM.defaultMessage}
                            />
                        </Typo.heading4>
                    </Button>
                </div>
            </form>
        </div>
    </div>;
};

export default ResetPassword;
