import React, { useCallback, useRef, useState } from "react";
import { Typo } from "../typo";
import Button from "../button";
import LeftArrowIcon from "../../icons/LeftArrowIcon";
import RightArrowIcon from "../../icons/RightArrowIcon";
import CategoryCardItem from "../CategoryCardItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Grid } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";
import CommercialIcon from "../../icons/CommercialIcon";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import MapIcon from "../../icons/MapIcon";
import HomeIcon from "../../icons/HomeIcon";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  id: number | string;
  label?: any;
  items?: DropdownOptionModel[];
  className?: string;
  direction?: string;
};

const CategoryCard: React.FC<Props> = (props) => {
  const { id, label, items, direction } = props;
  const swiperRef = useRef<any>(null);
  const [index, setIndex] = useState<{
    currentIndex: number;
    lastIndex: number;
  }>();
  const goNext = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.swiper?.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.swiper?.slidePrev();
    }
  };

  const iconMap: { [key: string]: any } = {
    1: <HomeIcon className="h-10" />,
    2: <MapIcon className="h-10" />,
    3: <CommercialIcon className="h-10" />,
  };

  const handleSlideChange = useCallback((swiper: any) => {
    setIndex({
      currentIndex: swiper?.activeIndex,
      lastIndex: swiper?.snapGrid.length - 1,
    });
  }, []);

  return (
    <div className="relative rounded-xl h-full border flex flex-col space-y-6 border-gray-200 pt-4 px-4">
      <div className="bg-white flex justify-between border-b border-b-gray-100 pb-4">
        <div className="flex gap-x-3 items-center">
          {iconMap[Number(id)]}
          <Typo.heading1 fontWeight="font-medium">{label}</Typo.heading1>
        </div>
        <div className="flex items-center">
          <Button
            size="sxtra-small"
            theme="label"
            onClick={goPrev}
            disabled={index?.currentIndex === 0}
            icon={
              <RightArrowIcon
                className={clsx(
                  "h-4 ",
                  index?.currentIndex === 0 ? "text-gray-400" : "text-gray-800",
                  {
                    "transform -scale-x-100": direction === DIRECTIONS.LTR,
                  }
                )}
              />
            }
          ></Button>
          <Button
            size="sxtra-small"
            theme="label"
            onClick={goNext}
            disabled={
              index?.currentIndex === index?.lastIndex || index?.lastIndex === 0
            }
            icon={
              <LeftArrowIcon
                className={clsx(
                  "h-4",
                  index?.currentIndex === index?.lastIndex ||
                    index?.lastIndex === 0
                    ? "text-gray-400"
                    : "text-gray-800",
                  { " transform -scale-x-100": direction === DIRECTIONS.LTR }
                )}
              />
            }
          ></Button>
        </div>
      </div>
      <div className="w-full relative ">
        <Swiper
          ref={swiperRef}
          onSlideChange={handleSlideChange} // Add the event handler here
          spaceBetween={8}
          slidesPerView={3}
          modules={[Grid, Pagination]}
          pagination={{
            clickable: true,
          }}
          grid={{
            rows: 2,
            fill: "row",
          }}
          scrollbar={{ draggable: true }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
              grid: {
                rows: 1,
                fill: "row",
              },
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 20,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 10,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 10,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
          }}
        >
          {items?.map((item) => (
            <SwiperSlide key={item.title} className="w-full">
              <CategoryCardItem
                id={Number(item?.id)}
                name={item.title}
                amount={Number(item?.supportingTitle) || 0}
              ></CategoryCardItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CategoryCard;
function setCurrentIndex(activeIndex: any) {
  throw new Error("Function not implemented.");
}
