import React from "react";
import { Typo } from "../typo";
import VideoIcon from "../../icons/videoIcon";
import CameraIcon from "../../icons/CameraIcon";
type Props = {
  className?: string;
  videoCount?: number;
  imageCount?: number;
};

const ListingFileNumber: React.FC<Props> = (props) => {
  const { className, videoCount, imageCount } = props;

  return (
    <div className="flex gap-2">
      {videoCount !== 0 && (
        <div className="flex flex-row gap-x-1 items-center bg-white/[0.62] rounded text-gray-900 px-2.5 py-[1px]">
          <Typo.heading5 fontWeight="font-medium">{videoCount}</Typo.heading5>
          <VideoIcon />
        </div>
      )}
      {imageCount !== 0 && (
        <div className="flex flex-row items-center gap-x-1 bg-white/[0.62] rounded text-gray-900 px-2.5 py-[1px] ">
          <Typo.heading5 fontWeight="font-medium">{imageCount}</Typo.heading5>
          <CameraIcon />
        </div>
      )}
    </div>
  );
};

export default ListingFileNumber;
