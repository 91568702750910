import React from "react";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import CloseIcon from "../../../components/icons/CloseIcon";
import { TRANSLATION } from "../../../static/translation";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { defaultFeatures } from "../../../model/Listing";
type TabProps = {
  className?: string;
  featureValues: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string | undefined | null,
    name: string
  ) => void;
};
const FeatureBadges: React.FC<TabProps> = (props) => {
  const { className, featureValues, valueChange } = props;

  return (
    <div>
      {featureValues?.noma?.title && (
        <Badge
          value={featureValues.noma.title}
          title={TRANSLATION.NOMA}
          onDelete={() => valueChange(defaultFeatures.noma, "noma")}
        />
      )}
      {featureValues?.floor.title && (
        <Badge
          value={featureValues.floor.title}
          title={TRANSLATION.FLOOR}
          onDelete={() => valueChange(defaultFeatures.floor, "floor")}
        />
      )}

      {featureValues?.builtYear?.title && (
        <Badge
          value={featureValues.builtYear.title}
          title={TRANSLATION.BUILT_YEAR}
          onDelete={() => valueChange(defaultFeatures.builtYear, "builtYear")}
        />
      )}

      {featureValues?.floors && featureValues?.floors !== 0 && (
        <Badge
          value={featureValues.floors}
          title={TRANSLATION.FLOORS}
          onDelete={() => valueChange(defaultFeatures.floors, "floors")}
        />
      )}

      {featureValues?.parkingSpace && featureValues?.parkingSpace !== 0 && (
        <Badge
          value={featureValues.parkingSpace}
          title={TRANSLATION.PARKING_SPACE}
          onDelete={() =>
            valueChange(defaultFeatures.parkingSpace, "parkingSpace")
          }
        />
      )}

      {featureValues?.electricityBackup?.title && (
        <Badge
          value={featureValues.electricityBackup.title}
          title={TRANSLATION.ELECTRICITY_BACKUP}
          onDelete={() => valueChange("value", "electricityBackup")}
        />
      )}

      {featureValues?.otherFeature && (
        <Badge
          value={featureValues.otherFeature}
          title={TRANSLATION.OTHER_FEATURE}
          onDelete={() =>
            valueChange(defaultFeatures.otherFeature, "otherFeature")
          }
        />
      )}

      {featureValues?.kitchens && featureValues?.kitchens !== 0 && (
        <Badge
          value={featureValues.kitchens}
          title={TRANSLATION.KITCHENS}
          onDelete={() => valueChange(defaultFeatures.kitchens, "kitchens")}
        />
      )}

      {featureValues?.warehouse && (
        <Badge
          value={featureValues.warehouse}
          title={TRANSLATION.WAREHOUSE}
          onDelete={() => valueChange(defaultFeatures.warehouse, "warehouse")}
        />
      )}

      {featureValues?.cabinet && (
        <Badge
          value={featureValues.cabinet}
          title={TRANSLATION.CABINET}
          onDelete={() => valueChange(defaultFeatures.cabinet, "cabinet")}
        />
      )}

      {featureValues?.water && (
        <Badge
          value={featureValues.water}
          title={TRANSLATION.WATER}
          onDelete={() => valueChange(defaultFeatures.water, "water")}
        />
      )}
      {featureValues?.electricity && (
        <Badge
          value={featureValues.electricity}
          title={TRANSLATION.ELECTRICITY}
          onDelete={() =>
            valueChange(defaultFeatures.electricity, "electricity")
          }
        />
      )}

      {featureValues?.centralHeating && (
        <Badge
          value={featureValues.centralHeating}
          title={TRANSLATION.CENTRAL_HEATING}
          onDelete={() =>
            valueChange(defaultFeatures.centralHeating, "centralHeating")
          }
        />
      )}

      {featureValues?.sewerage && (
        <Badge
          value={featureValues.sewerage}
          title={TRANSLATION.SEWERAGE}
          onDelete={() => valueChange(defaultFeatures.sewerage, "sewerage")}
        />
      )}

      {featureValues?.mosque && (
        <Badge
          value={featureValues.mosque}
          title={TRANSLATION.MOSQUE}
          onDelete={() => valueChange(defaultFeatures.mosque, "mosque")}
        />
      )}

      {featureValues?.facilitiesForDisabled && (
        <Badge
          value={featureValues.facilitiesForDisabled}
          title={TRANSLATION.FACILITIES_FOR_DISABLED}
          onDelete={() =>
            valueChange(
              defaultFeatures.facilitiesForDisabled,
              "facilitiesForDisabled"
            )
          }
        />
      )}

      {featureValues?.nearbySchools && (
        <Badge
          value={featureValues.nearbySchools}
          title={TRANSLATION.NEARBY_SCHOOLS}
          onDelete={() =>
            valueChange(defaultFeatures.nearbySchools, "nearbySchools")
          }
        />
      )}

      {featureValues?.nearbyMalls && (
        <Badge
          value={featureValues.nearbyMalls}
          title={TRANSLATION.NEARBY_MALLS}
          onDelete={() =>
            valueChange(defaultFeatures.nearbyMalls, "nearbyMalls")
          }
        />
      )}

      {featureValues?.nearbyHospitals && (
        <Badge
          value={featureValues.nearbyHospitals}
          title={TRANSLATION.NEARBY_HOSPITALS}
          onDelete={() =>
            valueChange(defaultFeatures.nearbyHospitals, "nearbyHospitals")
          }
        />
      )}

      {featureValues?.nearbyRestaurants && (
        <Badge
          value={featureValues.nearbyRestaurants}
          title={TRANSLATION.NEARBY_RESTAURANT}
          onDelete={() =>
            valueChange(defaultFeatures.nearbyRestaurants, "nearbyRestaurants")
          }
        />
      )}

      {featureValues?.nearbyPublicTransports && (
        <Badge
          value={featureValues.nearbyPublicTransports}
          title={TRANSLATION.NEARBY_PUBLIC_TRANSPORTS}
          onDelete={() =>
            valueChange(
              defaultFeatures.nearbyPublicTransports,
              "nearbyPublicTransports"
            )
          }
        />
      )}

      {featureValues?.otherFacilities && (
        <Badge
          value={featureValues.otherFacilities}
          title={TRANSLATION.OTHER_FACILITIES}
          onDelete={() =>
            valueChange(defaultFeatures.otherFacilities, "otherFacilities")
          }
        />
      )}

      {featureValues?.furnished && (
        <Badge
          value={featureValues.furnished}
          title={TRANSLATION.FURNISHED}
          onDelete={() => valueChange(defaultFeatures.furnished, "furnished")}
        />
      )}

      {featureValues?.lift && (
        <Badge
          value={featureValues.lift}
          title={TRANSLATION.LIFT}
          onDelete={() => valueChange(defaultFeatures.lift, "lift")}
        />
      )}

      {featureValues?.securityComera && (
        <Badge
          value={featureValues.securityComera}
          title={TRANSLATION.SECURITY_COMERA}
          onDelete={() =>
            valueChange(defaultFeatures.securityComera, "securityComera")
          }
        />
      )}

      {featureValues?.internetCabel && (
        <Badge
          value={featureValues.internetCabel}
          title={TRANSLATION.INTERNET_CABLE}
          onDelete={() =>
            valueChange(defaultFeatures.internetCabel, "internetCabel")
          }
        />
      )}

      {featureValues?.boundryWall && (
        <Badge
          value={featureValues.boundryWall}
          title={TRANSLATION.BOUNDY_WALL}
          onDelete={() =>
            valueChange(defaultFeatures.boundryWall, "boundryWall")
          }
        />
      )}

      {featureValues?.corner && (
        <Badge
          value={featureValues.corner}
          title={TRANSLATION.CORNER}
          onDelete={() => valueChange(defaultFeatures.corner, "corner")}
        />
      )}

      {featureValues?.securityStaff && (
        <Badge
          value={featureValues.securityStaff}
          title={TRANSLATION.SECURITY_STAFF}
          onDelete={() =>
            valueChange(defaultFeatures.securityStaff, "securityStaff")
          }
        />
      )}

      {featureValues?.maintenanceStaff && (
        <Badge
          value={featureValues.maintenanceStaff}
          title={TRANSLATION.MAINTANANCE_STAFF}
          onDelete={() =>
            valueChange(defaultFeatures.maintenanceStaff, "maintenanceStaff")
          }
        />
      )}

      {featureValues?.airConditioning && (
        <Badge
          value={featureValues.airConditioning}
          title={TRANSLATION.AIR_CONDITIONING}
          onDelete={() =>
            valueChange(defaultFeatures.airConditioning, "airConditioning")
          }
        />
      )}

      {featureValues?.swimmingPool && (
        <Badge
          value={featureValues.swimmingPool}
          title={TRANSLATION.SWIMMING_POOL}
          onDelete={() =>
            valueChange(defaultFeatures.swimmingPool, "swimmingPool")
          }
        />
      )}

      {featureValues?.jacuzzi && (
        <Badge
          value={featureValues.jacuzzi}
          title={TRANSLATION.JACUZZI}
          onDelete={() => valueChange(defaultFeatures.jacuzzi, "jacuzzi")}
        />
      )}

      {featureValues?.sauna && (
        <Badge
          value={featureValues.sauna}
          title={TRANSLATION.SAUNA}
          onDelete={() => valueChange(defaultFeatures.sauna, "sauna")}
        />
      )}

      {featureValues?.gym && (
        <Badge
          value={featureValues.gym}
          title={TRANSLATION.GYM}
          onDelete={() => valueChange(defaultFeatures.gym, "gym")}
        />
      )}

      {featureValues?.garden && (
        <Badge
          value={featureValues.garden}
          title={TRANSLATION.GARDEN}
          onDelete={() => valueChange(defaultFeatures.garden, "garden")}
        />
      )}

      {featureValues?.otherRecreation && (
        <Badge
          value={featureValues.otherRecreation}
          title={TRANSLATION.OTHER_RECREATION}
          onDelete={() =>
            valueChange(defaultFeatures.otherRecreation, "otherRecreation")
          }
        />
      )}
    </div>
  );
};

export default FeatureBadges;

type BageProps = {
  value: string | boolean;
  title: MessageDescriptor;
  onDelete: () => void;
};

export const Badge = (badgeProp: BageProps) => {
  const { value, title, onDelete } = badgeProp;
  return (
    <Button
      rounded="rounded-full"
      onClick={onDelete}
      className="bg-gray-200 rounded-full py-1 px-2 m-1"
      icon={<CloseIcon className="h-4 hover:text-gray-800" />}
    >
      <Typo.body3 className="text-gray-600 flex gap-x-1 items-center">
        <FormattedMessage id={title.id} defaultMessage={title.defaultMessage} />
        {value && value !== true && (
          <span className="font-medium text-gray-800">{`: ${value}`}</span>
        )}
      </Typo.body3>
    </Button>
  );
};
