import React from "react";
import { Typo } from "./typo";
import { handelCurrencyMap } from "../../util/handelListingFeature";
import { IntlShape } from "react-intl";

type Props = {
  price?: string | number;
  currency?: string;
  intl: IntlShape;
};
const Price: React.FC<Props> = (props) => {
  const { price = "", currency = "", intl } = props;
  return (
    <div className="flex items-center gap-x-1">
      <Typo.heading3
        fontWeight="font-bold"
        className="text-primary-600 leading-[31.3px]"
      >
        {parseFloat(price + "").toLocaleString()}
      </Typo.heading3>
      <Typo.heading3 fontWeight="font-medium" className="leading-[28.17px]">
        {handelCurrencyMap(intl, currency)}
      </Typo.heading3>
    </div>
  );
};

export default Price;
