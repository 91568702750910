import React, { useRef } from "react";
import { Typo } from "../../../components/shared/typo";
import Button from "../../../components/shared/button";
import ChevronIcon from "../../../components/icons/ChevronIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ArticleModel } from "../../../model/Article";
import ArticleCard from "../../../components/shared/Card/ArticleCard";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  className?: any;
  articles: ArticleModel[];
  direction: string;
};

const RelatedArticles: React.FC<Props> = (props) => {
  const { articles, direction } = props;
  const popularArticle = useRef<any>(null);
  const goNext = () => {
    if (popularArticle?.current) {
      popularArticle?.current?.swiper?.slideNext();
    }
  };

  const goPrev = () => {
    if (popularArticle?.current) {
      popularArticle?.current?.swiper?.slidePrev();
    }
  };

  return (
    <div className="pt-14">
      <div className=" w-full relative mx-auto">
        <div className="w-full absolute bg-gray-100 h-[300px] pt-16">
          <Typo.headingC
            fontWeight="font-bold"
            className="text-center w-full text-primary-600"
          >
            <FormattedMessage
              id={TRANSLATION.RELATED_ARTICLE.id}
              defaultMessage={TRANSLATION.RELATED_ARTICLE.defaultMessage}
            />
          </Typo.headingC>
          <Typo.heading4 className="text-center w-full text-gray-600">
            <FormattedMessage
              id={TRANSLATION.RELATED_ARTICLE_DETAILS.id}
              defaultMessage={
                TRANSLATION.RELATED_ARTICLE_DETAILS.defaultMessage
              }
            />
          </Typo.heading4>
        </div>
        <div className=" max-w-6xl mx-auto px-8 md:px-16 lg:px-4 xl:px-0 mt-8 sm:mt-12 md:mt-16 lg:mt-20 pt-40">
          <div className="mt-4 space-y-4 relative">
            <div className="absolute -start-6 top-[35%] z-10">
              <Button
                size="medium"
                theme="label"
                rounded="rounded-xl"
                className=" bg-white shadow-shadow2"
                onClick={goPrev}
                icon={
                  <ChevronIcon
                    className={clsx(
                      " text-gray-800",
                      direction === DIRECTIONS.LTR ? "rotate-90" : "-rotate-90"
                    )}
                  />
                }
              ></Button>
            </div>
            <div className="absolute -end-6 top-[35%] z-10">
              <Button
                size="medium"
                theme="label"
                rounded="rounded-xl"
                className="bg-white shadow-shadow2"
                onClick={goNext}
                icon={
                  <ChevronIcon
                    className={clsx(
                      "text-gray-800",
                      direction === DIRECTIONS.LTR
                        ? "-rotate-90 "
                        : "rotate-90 "
                    )}
                  />
                }
              ></Button>
            </div>
            <div>
              <Swiper
                ref={popularArticle}
                spaceBetween={24}
                slidesPerView={1}
                breakpoints={{
                  // specify different values for different screen widths
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  500: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {articles?.map((item: ArticleModel) => (
                  <SwiperSlide key={item.title} className="w-full">
                    <ArticleCard article={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedArticles;
