import { useParams } from "react-router-dom";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import { Typo } from "../../../components/shared/typo";
import Image from "../../../components/shared/Image";
import articlePattern from "../../../assets/image/article-pattern.svg";
import RecordContent from "./RecordContent";
import RelatedArticles from "./RelatedArticles";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useLang } from "../../../context/language";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
import ArticelDetails from "../../shared/ArticleDetails";
const ArticleDetails = () => {
  const { id } = useParams();
  const lang = useLang();
  const { ToGetArticle } = useHandelArticle();
  const article = ToGetArticle(Number(id));
  const records = [
    "شرایط پرداخت پول در معاملات ملکی",
    "تعریف قدرالسهم آپارتمان",
    "اصطلاحات حقوقی معاملات املاک",
    "آشنایی با قرارداد خرید و فروش ملک",
    "شرایط پرداخت پول در معاملات ملکی",
    "تعریف قدرالسهم آپارتمان",
    "اصطلاحات حقوقی معاملات املاک",
    "آشنایی با قرارداد خرید و فروش ملک",
    "شرایط پرداخت پول در معاملات ملکی",
    "تعریف قدرالسهم آپارتمان",
    "اصطلاحات حقوقی معاملات املاک",
    "آشنایی با قرارداد خرید و فروش ملک",
  ];
  return (
    <div className="w-full overflow-x-hidden">
      <div className="w-full max-w-7xl mx-auto relative">
        <div className="grid grid-cols-3 gap-x-6 px-4 sm:px-8 md:px-12 lg:px-16">
          <ArticelDetails article={article} />
          <div className="relative hidden md:block">
            <div className="w-full z-20 h-60 pt-40 ">
              <div className="w-full shadow-shadow2 px-8 py-10 bg-white rounded-xl min-h-[400px]">
                <Typo.heading1
                  fontWeight="font-bold"
                  className=" text-gray-900"
                >
                  <FormattedMessage
                    id={TRANSLATION.WE_CONSIDER.id}
                    defaultMessage={TRANSLATION.WE_CONSIDER.defaultMessage}
                  />
                </Typo.heading1>
                <div className="space-y-4 mt-8">
                  {records?.map((item, index) => {
                    return <RecordContent key={index} title={item} />;
                  })}
                </div>
              </div>
            </div>
            <div
              className={clsx(
                "absolute -z-10 select-none ps-10  top-0 ",
                lang.direction === DIRECTIONS.LTR
                  ? " transform -scale-x-100 start-10 -end-[50%] "
                  : " start-0 -end-[100%]"
              )}
            >
              <Image className="" src={articlePattern} />
            </div>
          </div>
        </div>
        <div className="relative">
          <RelatedArticles
            articles={article.data?.articles || []}
            direction={lang.direction}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleDetails;
