type Props = {
  className?: string;
};
const SecurityCameraIcon = ({ className }: Props) => {
  return (
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.87 119.76"
      className={className}
    >
      <path fill="currentColor" d="M83.88,64.13l-16.25,3.82v51.81h16.25v-55.63h0Zm-49.23-2.76L15.89,33.39,0,37.89l18.94,27.93,15.71-4.45h0Zm88.02-23.08L112.9,3.77c-.78-2.74-3.66-4.35-6.4-3.57L19.1,24.97l30.23,39.5,69.77-19.78c2.74-.78,4.35-3.66,3.57-6.4Zm-72.47,3.77l-2.61-9.21,8.14-2.31,2.61,9.21-8.14,2.31Z" />
    </svg>
  );
};

export default SecurityCameraIcon;
