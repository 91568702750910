import { Typo } from "./typo";
import Button from "./button";
import Image from "./Image";
import Loader from "./Loader";
import React from "react";
import GalleryAddIcon from "../icons/GalleryAddIcon";
import CloseIcon from "../icons/CloseIcon";
import Maximize4Icon from "../icons/Maximize4Icon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
type ImagePorps = {
  isEmpty?: boolean;
  alt?: string;
  src?: string | null | undefined;
  className?: string;
  onClick?: () => void;
  onMaximize?: () => void;
  onSelected?: () => void;
  thumbnail?: boolean;
  uploading?: boolean;
};
const ImagePreview: React.FC<ImagePorps> = (props) => {
  const {
    src,
    alt,
    className,
    onClick,
    onMaximize,
    onSelected,
    thumbnail = false,
    isEmpty = false,
    uploading = false,
  } = props;
  return (
    <div className={className}>
      {isEmpty ? (
        <div className="w-full h-full bg-gray-50 rounded flex items-center justify-center">
          <GalleryAddIcon className="h-8 w-8 text-gray-500" />
        </div>
      ) : (
        <div
          onDoubleClick={onSelected}
          className={`relative w-full h-full group overflow-hidden cursor-pointer select-none`}
        >
          {uploading ? (
            <Loader className=" absolute w-full h-full rounded-sm bg-opacity-50" />
          ) : (
            <div className="absolute flex  h-full w-full items-center justify-center rounded">
              <div className="w-full h-full flex flex-col justify-between">
                <div className="p-1">
                  <Button
                    onClick={onClick}
                    rounded="rounded-full"
                    size="small"
                    className="bg-white h-6 w-6 cursor-pointer"
                    icon={<CloseIcon className="h-5 text-gray-500" />}
                    theme={"label"}
                  ></Button>
                </div>
                <div className="flex items-center">
                  {thumbnail && (
                    <Typo.body5 className="bg-white w-auto mx-auto text-gray-600 rounded-3xl px-1.5 py-0.5">
                      <FormattedMessage
                        id={TRANSLATION.MAIN_IMAGE.id}
                        defaultMessage={TRANSLATION.MAIN_IMAGE.defaultMessage}
                      />
                    </Typo.body5>
                  )}
                </div>
                <div className="p-1 flex justify-end w-full ">
                  <Button
                    onClick={onMaximize}
                    rounded="rounded"
                    size="small"
                    className="h-4 w-4 cursor-pointer backdrop-blur-md bg-white/30"
                    icon={<Maximize4Icon className="h-4 text-white" />}
                    theme={"label"}
                  ></Button>
                </div>
              </div>
            </div>
          )}
          <Image
            className="rounded h-[100%] w-full object-cover"
            src={src}
            alt={alt}
          ></Image>
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
