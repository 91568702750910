type Props = {
  className?: string;
};
const AreaIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03333 2.96667L1.06667 3.93333C0.86 4.14 0.5 3.99333 0.5 3.69333V0.833333C0.5 0.646667 0.646667 0.5 0.833333 0.5H3.69333C3.99333 0.5 4.14 0.86 3.93333 1.06667L2.96667 2.03333L4.42 3.47333C4.68 3.73333 4.68 4.16 4.42 4.42C4.16 4.68 3.73333 4.68 3.47333 4.42L2.03333 2.96667ZM10.0333 2.03333L9.06667 1.06667C8.86 0.86 9.00667 0.5 9.30667 0.5H12.1667C12.3533 0.5 12.5 0.646667 12.5 0.833333V3.69333C12.5 3.99333 12.14 4.14 11.9333 3.93333L10.9667 2.96667L9.52667 4.42C9.26667 4.68 8.84 4.68 8.58 4.42C8.32 4.16 8.32 3.73333 8.58 3.47333L10.0333 2.03333ZM3.93333 11.9333L2.96667 10.9667L4.42 9.52667C4.68 9.26667 4.68 8.84 4.42 8.58C4.16 8.32 3.73333 8.32 3.47333 8.58L2.03333 10.0333L1.06667 9.06667C0.86 8.86 0.5 9.00667 0.5 9.30667V12.1667C0.5 12.3533 0.646667 12.5 0.833333 12.5H3.69333C3.99333 12.5 4.14 12.14 3.93333 11.9333ZM10.9667 10.0333L11.9333 9.06667C12.14 8.86 12.5 9.00667 12.5 9.30667V12.1667C12.5 12.3533 12.3533 12.5 12.1667 12.5H9.30667C9.00667 12.5 8.86 12.14 9.06667 11.9333L10.0333 10.9667L8.58 9.52667C8.32 9.26667 8.32 8.84 8.58 8.58C8.84 8.32 9.26667 8.32 9.52667 8.58L10.9667 10.0333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AreaIcon;
