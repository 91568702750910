import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { useAuth } from "../context/auth";
import { useEffect, useState } from "react";
import BarIcon from "../components/icons/BarIcon";
import Divider from "../components/shared/Divider";
import Sidebar from "../components/dashboard/sidebar/Sidebar";
import Alert from "../components/shared/Alert";
import { useAppSelector } from "../lib/hooks";
import { Typo } from "../components/shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { getNavigations } from "../static/constants";
import { useIntl } from "react-intl";
import Button from "../components/shared/button";
import PlusIcon from "../components/icons/PlusIcon";
import BillIcon from "../components/icons/BillIcon";
import Header from "../components/dashboard/Header";
import { ROUTES } from "../constants/routes";
import ScrollToTop from "../util/scrollToTop";
import { useLang } from "../context/language";
import ProfileDropdown from "../components/shared/ProfileDropdown";
import NotificationDropdown from "../components/shared/Notification/NotificationDropdown";
import { useNotification } from "../context/notification";
import { useDispatch } from "react-redux";
import { showModal } from "../lib/reducers/modal.slice";
export const DashboardLayout = () => {
  const intl = useIntl();
  const auth = useAuth();
  const lang = useLang();
  const location = useLocation();
  const dispatch = useDispatch();
  const alertData = useAppSelector((state) => state.alert.alertData);
  const navigations = getNavigations(intl);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const outlet = useOutlet();
  const notifications = useNotification();

  useEffect(() => {
    if (location.pathname) {
      console.log("pathname", location.pathname);
      if (location?.pathname?.includes("dashboard")) {
        localStorage.setItem("redirect-path", location.pathname);
      }
    }
  }, [location.pathname]);

  if (!auth?.token) {
    dispatch(showModal("login"));
    return <Navigate to="/" />;
  }

  return (
    <ScrollToTop>
      <div>
        <div>
          {/* mobile navigation */}
          <Header
            auth={auth}
            navigations={navigations}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            location={location}
            direction={lang.direction}
          />

          {/* Static sidebar for desktop */}
          <Sidebar direction={lang.direction} />

          <div className="lg:ps-60">
            <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 shadow-shadow4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <BarIcon />
              </button>

              {/* Separator */}
              <Divider
                type="vertical"
                className="block lg:hidden h-6 w-px bg-gray-200"
              />
              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <div className="relative flex flex-1 gap-x-3 items-center justify-center">
                  <div className="hidden sm:block">
                    <Button
                      theme="gray"
                      size="small"
                      rounded="rounded-lg"
                      to={ROUTES.MY_LISTINGS_ROUTE}
                      icon={<BillIcon className="h-6" />}
                    >
                      <Typo.body2 fontWeight="font-medium">
                        <FormattedMessage
                          id={TRANSLATION.LISTINGS.id}
                          defaultMessage={TRANSLATION.LISTINGS.defaultMessage}
                        />
                      </Typo.body2>
                    </Button>
                  </div>
                  <div className="hidden sm:block">
                    <Button
                      theme="primary"
                      size="small"
                      rounded="rounded-lg"
                      to={ROUTES.NEW_LISTING_ROUTE}
                      icon={<PlusIcon className="h-6" />}
                    >
                      <Typo.body3 fontWeight="font-medium">
                        <FormattedMessage
                          id={TRANSLATION.ADD_NEW_LISTING.id}
                          defaultMessage={
                            TRANSLATION.ADD_NEW_LISTING.defaultMessage
                          }
                        />
                      </Typo.body3>
                    </Button>
                  </div>
                </div>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <NotificationDropdown
                    notifications={notifications?.notifications}
                    count={notifications?.count || 0}
                    unread={notifications?.unread || 0}
                    intl={intl}
                  />
                  {/* Separator */}
                  <Divider
                    type="vertical"
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  />
                  {/* Profile dropdown */}
                  <ProfileDropdown
                    onLogout={() => auth.logout()}
                    user={auth?.user}
                  />
                </div>
              </div>
            </div>

            <main className=" bg-gray-100 min-h-screen">
              <div className="min-h-full">{outlet}</div>
            </main>
          </div>
        </div>
        <Alert
          title={alertData.title}
          description={alertData.description}
          open={alertData.type !== ""}
          setOpen={() => ({})}
        />
      </div>
    </ScrollToTop>
  );
};
