type Props = {
  className?: string;
};
const Maximize3Icon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M13.492 1.66675H6.50866C3.47533 1.66675 1.66699 3.47508 1.66699 6.50841V13.4834C1.66699 16.5251 3.47533 18.3334 6.50866 18.3334H13.4837C16.517 18.3334 18.3253 16.5251 18.3253 13.4917V6.50841C18.3337 3.47508 16.5253 1.66675 13.492 1.66675Z"
        fill="#E0E0E0"
      />
      <path
        d="M15.575 4.75833C15.5083 4.60833 15.3917 4.48333 15.2333 4.41667C15.1583 4.39167 15.0833 4.375 15 4.375H11.6667C11.325 4.375 11.0417 4.65833 11.0417 5C11.0417 5.34167 11.325 5.625 11.6667 5.625H13.4917L5.625 13.4917V11.6667C5.625 11.325 5.34167 11.0417 5 11.0417C4.65833 11.0417 4.375 11.325 4.375 11.6667V15C4.375 15.0833 4.39167 15.1583 4.425 15.2417C4.49167 15.3917 4.60833 15.5167 4.76667 15.5833C4.83333 15.6083 4.91667 15.625 5 15.625H8.33333C8.675 15.625 8.95833 15.3417 8.95833 15C8.95833 14.6583 8.675 14.375 8.33333 14.375H6.50833L14.375 6.50833V8.33333C14.375 8.675 14.6583 8.95833 15 8.95833C15.3417 8.95833 15.625 8.675 15.625 8.33333V5C15.625 4.91667 15.6083 4.84167 15.575 4.75833Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default Maximize3Icon;
