type Props = {
  className?: string;
};
const MessageTextIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M13.3337 1.66669H6.66699C3.33366 1.66669 1.66699 3.33335 1.66699 6.66669V17.5C1.66699 17.9584 2.04199 18.3334 2.50033 18.3334H13.3337C16.667 18.3334 18.3337 16.6667 18.3337 13.3334V6.66669C18.3337 3.33335 16.667 1.66669 13.3337 1.66669Z"
        fill="#E0E0E0"
      />
      <path
        d="M14.1663 7.29169H5.83301C5.49134 7.29169 5.20801 7.57502 5.20801 7.91669C5.20801 8.25835 5.49134 8.54169 5.83301 8.54169H14.1663C14.508 8.54169 14.7913 8.25835 14.7913 7.91669C14.7913 7.57502 14.508 7.29169 14.1663 7.29169Z"
        fill="#BDBDBD"
      />
      <path
        d="M11.6663 11.4584H5.83301C5.49134 11.4584 5.20801 11.7417 5.20801 12.0834C5.20801 12.425 5.49134 12.7084 5.83301 12.7084H11.6663C12.008 12.7084 12.2913 12.425 12.2913 12.0834C12.2913 11.7417 12.008 11.4584 11.6663 11.4584Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default MessageTextIcon;
