import React from "react";
import { Typo } from "./typo";
import Button from "./button";
import clsx from "clsx";
type Props = {
  title?: string;
  link?: string;
  currentPath: string;
  onClick?: () => void;
  className?: string;
};
const NavLink: React.FC<Props> = (props) => {
  const { title, link, currentPath, onClick, className } = props;

  return (
    <>
      <Button to={link} onClick={() => !link && onClick && onClick()}>
        <Typo.heading2
          fontWeight={currentPath === link ? "font-medium" : "font-normal"}
          className={clsx(
            currentPath === link
              ? "hover:text-primary-600 "
              : "hover:text-primary-600",
            className
          )}
        >
          {title}
        </Typo.heading2>
      </Button>
    </>
  );
};

export default NavLink;
