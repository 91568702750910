import React, { useState } from "react";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelCategory } from "../../../hooks/category/useHandelCategory";
import SubCategoryModal from "../../../modal/SubCategoryModal";
import { useHandelSubCategory } from "../../../hooks/category/useHandelSubCategory";
import Pagination from "../../../components/shared/Pagination";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import TrashIcon from "../../../components/icons/TrashIcon";
import { SubCategory } from "../../../model/SubCategory";
import ConfirmModal from "../../../modal/ConfirmModal";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "../../../context/language";
import { getLocaleCategories } from "../../../util/appUtil";
const SubCategories = () => {
  // Define the columns and data for the table
  const LIMIT = 10;
  const intl = useIntl();
  const lang = useLang();
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [subCategory, setSubCategory] = useState<SubCategory>();
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetSubCategories, onDelete } = useHandelSubCategory();
  const [page, setPage] = useState(1);
  const response: any = ToGetCategories();
  const subCategoryResponse: any = ToGetSubCategories(page, LIMIT);

  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "number"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "title_fa"),
        accessor: "title_fa",
      },
      {
        Header: handelInputMap(intl, "title_ps"),
        accessor: "title_ps",
      },
      {
        Header: handelInputMap(intl, "title_en"),
        accessor: "title_en",
      },
      {
        Header: handelInputMap(intl, "main_category"),
        accessor: "category.title_fa",
      },
      {
        Header: handelInputMap(intl, "actions"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                icon={<Edit2Icon className="h-4 w-4" />}
                onClick={() => handleEdit(cell.row.original)}
              ></Button>
              <Button
                theme="error"
                size="normal"
                icon={<TrashIcon className="h-4 w-4" />}
                onClick={() => handleDelete(cell?.row?.original)}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleEdit = (subCategory: SubCategory) => {
    setSubCategory(subCategory);
    setShowModal(true);
  };

  const handleDelete = (subCategory: SubCategory) => {
    setConfirm(true);
    setSubCategory(subCategory);
  };

  const onConfirm = (state: boolean) => {
    if (state && subCategory) {
      onDelete(Number(subCategory.id));
    }
    setConfirm(false);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.SUB_CATEGORY_TITLE.id}
              defaultMessage={TRANSLATION.SUB_CATEGORY_TITLE.defaultMessage}
            />
          </Typo.heading2>
          <Typo.heading3 className="text-gray-600 mt-2">
            <FormattedMessage
              id={TRANSLATION.SUB_CATEGORY.id}
              defaultMessage={
                TRANSLATION.SUB_CATEGORY_DESCRIPTION.defaultMessage
              }
            />
          </Typo.heading3>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Button
            onClick={() => {
              setSubCategory(undefined);
              setShowModal(true);
            }}
            size="small"
            theme="primary"
          >
            <Typo.heading5 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.ADD.id}
                defaultMessage={TRANSLATION.ADD.defaultMessage}
              />
            </Typo.heading5>
          </Button>
        </div>
      </div>
      <Table columns={columns} data={subCategoryResponse?.data?.rows || []} />
      {subCategoryResponse?.data?.count && (
        <Pagination
          onPageChange={setPage}
          total={subCategoryResponse?.data?.count}
          perPage={LIMIT}
          currentPage={page}
          type="centered-page-number"
          direction={lang.direction}
        />
      )}

      <SubCategoryModal
        categories={getLocaleCategories(lang.language, response?.data) || []}
        open={showModal}
        defaultValue={subCategory}
        onClose={setShowModal}
        intl={intl}
      />
      <ConfirmModal
        icon={<TrashIcon className="h-10" />}
        title={TRANSLATION.DELETE_SUB_CATEGORY}
        description={TRANSLATION.DELETE_SUB_CATEGORY_MESSAGE}
        open={confirm}
        onClose={onConfirm}
      />
    </div>
  );
};

export default SubCategories;
