import React, { ReactElement } from "react";
import { IntlShape } from "react-intl";
import Home2Icon from "../../icons/Home2Icon";
import { Typo } from "../typo";
import LeftArrowIcon from "../../icons/LeftArrowIcon";
import MyDoughnut from "./MyDoughnut";
import { TRANSLATION } from "../../../static/translation";

type ChartProp = {
  intl: IntlShape;
  type: 'sell' | 'rent' | 'mortgage';
  totalCount: number;
  count: number
};

const ChartCard: React.FC<ChartProp> = (props) => {
  const { intl, type, totalCount, count } = props;

  const iconMap: { [key: string]: ReactElement } = {
    sell: <div className="bg-primary-50 p-2 rounded-[4px]">
      <Home2Icon className="h-5 w-5 text-primary-200" />
    </div>,
    rent: <div className="bg-secondary-50 p-2 rounded-[4px]">
      <Home2Icon className="h-5 w-5 text-secondary-200" />
    </div>,
    mortgage: <div className="bg-info-50 p-2 rounded-[4px]">
      <Home2Icon className="h-5 w-5 text-info-200" />
    </div>,
  };


  const colorMap: { [key: string]: any } = {
    sell: "#1BC457",
    rent: "#1570EF",
    mortgage: "#202020",
  };


  const titleMap: { [key: string]: any } = {
    sell: intl.formatMessage({
      id: TRANSLATION.LISTING_FOR_SALE.id,
      defaultMessage: TRANSLATION.LISTING_FOR_SALE.defaultMessage
    }),
    rent: intl.formatMessage({
      id: TRANSLATION.LISTING_FOR_RENT.id,
      defaultMessage: TRANSLATION.LISTING_FOR_RENT.defaultMessage
    }),
    mortgage: intl.formatMessage({
      id: TRANSLATION.LISTING_FOR_MORTGAGE.id,
      defaultMessage: TRANSLATION.LISTING_FOR_MORTGAGE.defaultMessage
    }),
  };


  return (
    <>
      <div className="relative rounded-2xl bg-white shadow-shadow2 h-full p-4">
        <div className="flex justify-between">
          <div className="flex gap-x-3 mb-2 items-center w-full">
            {iconMap[type]}
            <Typo.heading2 className="font-bold">
              {titleMap[type]}
            </Typo.heading2>
          </div>
          <LeftArrowIcon className="h-6 w-6 text-gray-800 rotate-45" />
        </div>
        <div className="relative">
          <MyDoughnut color={colorMap[type]} totalCount={totalCount} count={count} />
        </div>
      </div>
    </>
  );
};

export default ChartCard;
