import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { Category } from "../../model/Category";
import { useState } from "react";
import { DropdownOptionModel } from "../../model/DropdownOption";
import { SubCategory } from "../../model/SubCategory";
// Define the type of the onClose parameter
type props = (state: boolean) => void;
export const useHandelSubCategory = (onClose?: props) => {
  const [selectedCategory, setSelectedCategory] = useState<
    DropdownOptionModel | undefined
  >();
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const schema = object().shape({
    title_fa: string().required(),
    title_ps: string().required(),
    title_en: string().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: (doSubCategory: any) =>
      Fetch({
        method: "POST",
        endpoint: "/subCategory",
        data: doSubCategory,
        requiredAuth: true,
      }),
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["subCategory"] });
    },
    onError: (error) => {
      console.error("sc", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (subCategory: any) => {
      const response = await Fetch({
        method: "DELETE",
        data: subCategory,
        endpoint: `/subCategory/${subCategory.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["subCategory"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (subCategory: any) => {
      const response = await Fetch({
        method: "PUT",
        data: subCategory,
        endpoint: `/subCategory/${subCategory.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["subCategory"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const onUpdate = async (subCategory: SubCategory, id: number) => {
    const data = {
      id: id,
      title_fa: subCategory.title_fa,
      title_ps: subCategory.title_ps,
      title_en: subCategory.title_en,
      categoryId: selectedCategory?.id,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    const data = {
      id: id,
    };
    deleteMutation.mutate(data);
  };

  const onSubmit = async (data: any) => {
    const subCategory = {
      title_fa: data?.title_fa,
      title_ps: data?.title_ps,
      title_en: data?.title_en,
      categoryId: selectedCategory?.id,
    };
    mutation.mutate(subCategory);
  };

  function ToGetSubCategories(page: number, limit: number) {
    const result = useQuery({
      queryKey: ["subCategory", page],
      queryFn: async () => await getSubCategories(page, limit),
    });
    return result;
  }

  const getSubCategories = async (
    page: number,
    limit: number
  ): Promise<Category[]> => {
    const response = await Fetch({
      endpoint: `/subCategory?page=${page}&limit=${limit}`,
    });
    return response?.data;
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    setValue,
    onUpdate,
    onDelete,
    selectedCategory,
    setSelectedCategory,
    ToGetSubCategories,
  };
};
