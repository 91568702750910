import { object, string, array, number } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { ImageFile } from "../../model/Image";
import { Location } from "../../model/Location";
import { DropdownOptionModel } from "../../model/DropdownOption";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import { buildEndpoint } from "../../util/buildEndpoint";
import {
  ListingCountResponse,
  ListingObject,
  ListingProp,
  ListingResponse,
  ListingUpdateProp,
  defaultFeatures,
} from "../../model/Listing";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { handelValidationMap } from "../../util/handelValidationMap";
import { IntlShape } from "react-intl";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { arabicToLatin } from "../../util/appUtil";
import { PHONE_NUMBER_REGULAR_EXPRESSION } from "../../static/constants";
// Define the type of the onClose parameter
// type props = (state: boolean) => void;
type ListingProps = {
  onClose?: (state: boolean) => void;
  defaultFacilities?: any;
  intl: IntlShape;
};

export const useHandelListing = (props: ListingProps) => {
  const { onClose, defaultFacilities, intl } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const queryClient = useQueryClient();
  const validations = handelValidationMap(intl);
  const messages = handelServerMessageMap(intl);

  // define a custom validator function
  const schema = object().shape({
    price: number()
      .transform((value, originalValue) => {
        if (String(originalValue)?.trim() === "") {
          return undefined;
        } else {
          const num = arabicToLatin(String(originalValue)?.trim());
          return isNaN(num) ? undefined : num;
        }
      })
      .typeError(validations["invalid_price"])
      .required(validations["required_price"]),
    area: number()
      .transform((value, originalValue) => {
        if (String(originalValue)?.trim() === "") {
          return undefined;
        } else {
          const num = arabicToLatin(String(originalValue)?.trim());
          return isNaN(num) ? undefined : num;
        }
      })
      .typeError(validations["invalid_area"])
      .required(validations["required_area"]),
    title: string().required(validations["title_required"]),
    room: number()
      .optional()
      .transform((value, originalValue) => {
        if (String(originalValue)?.trim() === "") {
          return undefined;
        } else {
          const num = arabicToLatin(String(originalValue)?.trim());
          return isNaN(num) ? undefined : num;
        }
      })
      .typeError(validations["invalid_bedroom"]),
    bath: number()
      .optional()
      .transform((value, originalValue) => {
        if (String(originalValue)?.trim() === "") {
          return undefined;
        } else {
          const num = arabicToLatin(String(originalValue)?.trim());
          return isNaN(num) ? undefined : num;
        }
      })
      .typeError(validations["invalid_bathroom"]),
    description: string().required(validations["description_required"]),
    email: string().email(validations["description_required"]),
    phone: string()
      .required()
      .matches(PHONE_NUMBER_REGULAR_EXPRESSION, validations["invalid_phone"]),
    videos: array().of(
      string()
        .url(validations["invalid_link"])
        .required(validations["link_required"])
    ),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doListing: any) => {
      const response = await Fetch({
        method: "POST",
        data: doListing,
        endpoint: "/listing",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["listing"] });
      setLoading(false);
      toast.success(messages["listingCreated"]);
      navigate(ROUTES.MY_LISTINGS_ROUTE);
    },
    onError: (error) => {
      setLoading(false);
      toast.success(messages["failedListingCreation"]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (doListing: any) => {
      const response = await Fetch({
        method: "PUT",
        data: doListing.data,
        endpoint: `/listing/${doListing.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      navigate(-1);
      toast.success(messages["listingEdited"]);
      queryClient.invalidateQueries({ queryKey: ["listingDetails"] });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(messages["failedListingEdition"]);
    },
  });

  const updateStateMutation = useMutation({
    mutationFn: async (doListing: any) => {
      const response = await Fetch({
        method: "PATCH",
        data: doListing.data,
        endpoint: `/listing/${doListing.id}`,
        requiredAuth: true,
      });
      // if (response) {
      //   if (socket && auth.user) {
      //     socket.emit("update-listing", {
      //       userId: auth?.user.id,
      //       name: auth?.user.name,
      //     });
      //   }
      // }
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      // toast.success(messages["listingEdited"]);
      // listingDetails
      queryClient.invalidateQueries({
        queryKey: ["listings"],
      });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(messages["failedListingEdition"]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (listingId: number) => {
      const response = await Fetch({
        method: "DELETE",
        endpoint: `/listing/delete/${listingId}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      toast.success(messages["deletedFromFavorites"]);
      queryClient.invalidateQueries({ queryKey: ["myListings"] });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(messages["errorDeletionFromFavorites"]);
    },
  });

  const likeMutation = useMutation({
    mutationFn: async (doSaveListing: any) => {
      const response = await Fetch({
        method: "POST",
        data: doSaveListing,
        endpoint: "/listing/like",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      toast.success(messages["addedToFavorites"]);
      queryClient.invalidateQueries({ queryKey: ["listings"] });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(messages["errorAdditionToFavorites"]);
    },
  });

  const disLikeMutation = useMutation({
    mutationFn: async (doSaveListing: any) => {
      const response = await Fetch({
        method: "DELETE",
        data: doSaveListing,
        endpoint: "/listing/dislike",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      toast.success(messages["deletedFromFavorites"]);
      queryClient.invalidateQueries({ queryKey: ["listings"] });
      queryClient.invalidateQueries({ queryKey: ["favorites"] });
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(messages["errorDeletionFromFavorites"]);
    },
  });

  const onSubmit = async (
    data: any,
    images: ImageFile[] | null,
    thumbnail: string,
    deletedImages: string[],
    selectedSubCategory: DropdownOptionModel,
    selectedCurrency: DropdownOptionModel,
    selectedUnit: DropdownOptionModel,
    document: DropdownOptionModel,
    purpose: DropdownOptionModel,
    selectedProvince: DropdownOptionModel,
    location?: Location
  ) => {
    setLoading(true);

    let thumbnailUrl = thumbnail; // Assuming thumbnail is a string
    let imageFiles =
      images
        ?.filter(
          (file) => !(file.file instanceof File) && file.file !== thumbnail
        )
        .map((file) => file.file) || [];

    if (!thumbnail && imageFiles?.length > 0) {
      thumbnailUrl = imageFiles[0].toString(); // Assign the first image to thumbnail
      imageFiles = imageFiles.slice(1); // Remove the first image from allImages
    }

    const listingData = {
      userId: auth.user?.id,
      agencyId: auth.user?.agencyId !== null ? auth.user?.agencyId : undefined,
      price: data.price,
      currency: selectedCurrency.id,
      area: data.area,
      unit: selectedUnit.id,
      title: data.title,
      thumbnail: thumbnailUrl,
      description: data.description,
      email: data.email,
      phone: data.phone,
      videos: JSON.stringify(Object.values(data?.videos || [])),
      images: JSON.stringify(imageFiles),
      deletedImages: JSON.stringify(Object.values(deletedImages || [])),
      address: location?.address,
      latitude: location?.latitude,
      longitude: location?.longitude,
      purpose: purpose.id,
      documentType: document?.id,
      subCategoryId: selectedSubCategory?.id,
      provinceId: selectedProvince?.id,
      bedrooms: data.room,
      bathrooms: data.bath,
      noma: featureValues?.noma.id,
      floor: featureValues?.floor.id,
      builtYear: featureValues.builtYear.id,
      floors: featureValues?.floors || 0,
      parkingSpace: featureValues?.parkingSpace || 0,
      electricityBackup: featureValues.electricityBackup.id,
      otherFeature: featureValues.otherFeature,
      kitchens: featureValues?.kitchens || 0,
      warehouse: featureValues?.warehouse || 0,
      cabinet: featureValues.cabinet,
      water: featureValues.water,
      electricity: featureValues.electricity,
      centralHeating: featureValues.centralHeating,
      sewerage: featureValues.sewerage,
      mosque: featureValues.mosque,
      facilitiesForDisabled: featureValues.facilitiesForDisabled,
      nearbySchools: featureValues.nearbySchools,
      nearbyMalls: featureValues.nearbyMalls,
      nearbyHospitals: featureValues.nearbyHospitals,
      nearbyRestaurants: featureValues.nearbyRestaurants,
      nearbyPublicTransports: featureValues.nearbyPublicTransports,
      otherNearby: featureValues.otherNearby,
      otherFacilities: featureValues.otherFacilities,
      furnished: featureValues.furnished,
      lift: featureValues.lift,
      securityComera: featureValues.securityComera,
      internetCabel: featureValues.internetCabel,
      boundryWall: featureValues.boundryWall,
      corner: featureValues.corner,
      securityStaff: featureValues.securityStaff,
      maintenanceStaff: featureValues.maintenanceStaff,
      airConditioning: featureValues.airConditioning,
      swimmingPool: featureValues.swimmingPool,
      jacuzzi: featureValues.jacuzzi,
      sauna: featureValues.sauna,
      gym: featureValues.gym,
      garden: featureValues.garden,
      otherRecreation: featureValues.otherRecreation,
    };

    mutation.mutate(listingData);
  };
  const onUpdate = async (
    data: any,
    images: ImageFile[] | null,
    thumbnail: string,
    deletedImages: string[],
    listing: ListingObject,
    selectedSubCategory: DropdownOptionModel,
    selectedCurrency: DropdownOptionModel,
    selectedUnit: DropdownOptionModel,
    document: DropdownOptionModel,
    purpose: DropdownOptionModel,
    selectedProvince: DropdownOptionModel,
    location?: Location
  ) => {
    setLoading(true);
    const videos = data.videos;

    const defaultImages: string[] =
      listing?.listingFiles
        ?.filter((item: any) => item?.isImage)
        ?.map((item: any) => item?.url) || [];

    const addedImages =
      images
        ?.filter((image) => !defaultImages.includes(`${image.file}`))
        .filter((item) => item.file !== thumbnail)
        .map((file) => `${file.file}`) || [];

    const defaultVideos = listing?.listingFiles
      ?.filter((item: any) => !item?.isImage)
      .map((item: any) => item.url);

    const addedVideos: string[] =
      videos?.filter((item: string) => !defaultVideos?.includes(item)) || [];
    const deletedVideos =
      defaultVideos?.filter((item: string) => !videos?.includes(item)) || [];

    const updateListing = {
      videos: JSON.stringify(Object.values(addedVideos)),
      deletedVideos: JSON.stringify(Object.values(deletedVideos)),
      images: JSON.stringify(Object.values(addedImages)),
      deletedImages: JSON.stringify(Object.values(deletedImages)),
      price: data.price,
      currency: selectedCurrency.id,
      area: data.area,
      unit: selectedUnit.id,
      title: data.title,
      thumbnail: thumbnail,
      description: data.description,
      email: data.email,
      phone: data.phone,
      locationId: listing?.locationId,
      address: location?.address || "",
      latitude: location?.latitude,
      longitude: location?.longitude,
      purpose: purpose.id,
      documentType: document?.id,
      subCategoryId: selectedSubCategory?.id,
      provinceId: selectedProvince?.id,
      bedrooms: data.room,
      bathrooms: data.bath,
      noma: featureValues?.noma.id,
      floor: featureValues?.floor.id,
      builtYear: featureValues.builtYear.id,
      floors: featureValues?.floors || 0,
      parkingSpace: featureValues?.parkingSpace || 0,
      electricityBackup: featureValues.electricityBackup.id,
      otherFeature: featureValues.otherFeature,
      kitchens: featureValues?.kitchens || 0,
      warehouse: featureValues?.warehouse || 0,
      cabinet: featureValues.cabinet,
      water: featureValues.water,
      electricity: featureValues.electricity,
      centralHeating: featureValues.centralHeating,
      sewerage: featureValues.sewerage,
      mosque: featureValues.mosque,
      facilitiesForDisabled: featureValues.facilitiesForDisabled,
      nearbySchools: featureValues.nearbySchools,
      nearbyMalls: featureValues.nearbyMalls,
      nearbyHospitals: featureValues.nearbyHospitals,
      nearbyRestaurants: featureValues.nearbyRestaurants,
      nearbyPublicTransports: featureValues.nearbyPublicTransports,
      otherNearby: featureValues.otherNearby,
      otherFacilities: featureValues.otherFacilities,
      furnished: featureValues.furnished,
      lift: featureValues.lift,
      securityComera: featureValues.securityComera,
      internetCabel: featureValues.internetCabel,
      boundryWall: featureValues.boundryWall,
      corner: featureValues.corner,
      securityStaff: featureValues.securityStaff,
      maintenanceStaff: featureValues.maintenanceStaff,
      airConditioning: featureValues.airConditioning,
      swimmingPool: featureValues.swimmingPool,
      jacuzzi: featureValues.jacuzzi,
      sauna: featureValues.sauna,
      gym: featureValues.gym,
      garden: featureValues.garden,
      otherRecreation: featureValues.otherRecreation,
    };

    const listingData = {
      id: listing.id,
      data: updateListing,
    };
    updateMutation.mutate(listingData);
  };

  const onUpdateState = async (prop: ListingUpdateProp) => {
    setLoading(true);
    const listingData = {
      id: prop.listingId,
      data: {
        listingState: prop.listingState,
        feature: prop.feature,
        title: prop.title || "",
        content: prop.content || "",
      },
    };
    updateStateMutation.mutate(listingData);
  };

  const onLike = (listingId: number) => {
    setLoading(true);

    const saveData = { listingId: listingId };
    likeMutation.mutate(saveData);
  };

  const onDisLike = (listingId: number) => {
    setLoading(true);
    const saveData = { listingId: listingId };
    disLikeMutation.mutate(saveData);
  };

  const onDeleteListing = (listingId: number) => {
    setLoading(true);
    deleteMutation.mutate(listingId);
  };

  const onCancel = () => {
    navigate(-1);
  };

  function ToGetMyListingCount() {
    const result = useQuery({
      queryKey: ["myListingCount"],
      queryFn: async () => await getMyListingCount(),
    });
    return result;
  }

  function ToGetMyListings(props: ListingProp) {
    const result = useQuery({
      queryKey: [
        "myListings",
        props.page,
        props.listingState,
        props.subCategoryId,
        props.provinceId,
        props.purpose,
        props.address,
        props.title,
      ],
      queryFn: async () => await getMyListings(props),
      enabled: !!props.listingState && !!props.userId,
    });
    return result;
  }

  function ToGetFavoriteListings(props: ListingProp) {
    const result = useQuery({
      queryKey: ["favorites", props.userId],
      enabled: !!props.userId,
      queryFn: async () => await getListings(props),
    });
    return result;
  }

  function ToGetListingDetails(slug: string) {
    const result = useQuery({
      queryKey: ["listingDetails", slug],
      queryFn: async () => await getListingDetails(slug),
      enabled: !!slug,
    });
    return result;
  }

  function ToGetDashboardListings(props: ListingProp) {
    const result = useQuery({
      queryKey: [
        "listings",
        props.page,
        props.listingState,
        props.subCategoryId,
        props.provinceId,
        props.address,
        props.title,
        props.code,
        props.purpose,
      ],
      queryFn: async () => await getDashboardListings(props),
    });
    return result;
  }

  function ToGetListings(props: ListingProp, state: boolean) {
    const result = useQuery({
      queryKey: ["listings", props.page, state],
      queryFn: async () => await getListings(props),
    });
    return result;
  }

  const getMyListings = async (
    listingProp: ListingProp
  ): Promise<ListingResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("/listing/my-listings", listingProp),
      requiredAuth: true,
    });
    return response?.data;
  };

  const getMyListingCount = async (): Promise<ListingCountResponse> => {
    const response = await Fetch({
      endpoint: "/listing/my-listing-count",
      requiredAuth: true,
    });
    return response?.data;
  };

  const getListings = async (
    listingProp: ListingProp
  ): Promise<ListingResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("/listing", listingProp),
      requiredAuth: true,
    });
    return response?.data;
  };

  const getDashboardListings = async (
    listingProp: ListingProp
  ): Promise<ListingResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("/listing/dashboard", listingProp),
    });
    return response?.data;
  };

  const getListingDetails = async (slug: string): Promise<ListingObject> => {
    const response = await Fetch({
      endpoint: `/listing/details/${slug}`,
      requiredAuth: auth?.token ? true : false,
    });
    return response?.data;
  };

  const [featureValues, setFeatureValues] = useState<any>(defaultFeatures);

  const valueChange = (
    value: DropdownOptionModel | boolean | string | undefined | null,
    name: string
  ) => {
    setFeatureValues({
      ...featureValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (defaultFacilities) {
      setFeatureValues(defaultFacilities);
    }
  }, [defaultFacilities]);

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    onUpdateState,
    onCancel,
    onDeleteListing,
    onLike,
    onDisLike,
    reset,
    setValue,
    ToGetListings,
    ToGetDashboardListings,
    ToGetListingDetails,
    ToGetMyListings,
    ToGetFavoriteListings,
    ToGetMyListingCount,
    featureValues,
    defaultFeatures,
    setFeatureValues,
    valueChange,
    loading,
  };
};
