/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, ReactElement, useEffect } from "react";
import DangerIcon from "../icons/DangerIcon";
import InfoCircleIcon from "../icons/InfoCircleIcon";
import TickCircleIcon from "../icons/TickCircleIcon";
import clsx from "clsx";
import { Transition } from "@headlessui/react";
import { Typo } from "./typo";
import CloseIcon from "../icons/CloseIcon";
import Button from "./button";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  description?: string;
  type?: "default" | "error" | "warning" | "success";
};

const colorMap: { [key: string]: any } = {
  error: {
    titleColor: "text-error-200",
    descriptionColor: "text-error-100",
    firstButtonTextColor: "text-error-600 hover:text-error-700",
    secondButtonTextColor: "text-error-700 hover:text-error-800",
    iconColor: "text-error-200",
    themeColor: "border-error-70 bg-error-50",
  },
  warning: {
    titleColor: "text-warning-300",
    descriptionColor: "text-warning-300",
    firstButtonTextColor: "text-warning-600 hover:text-warning-700",
    secondButtonTextColor: "text-warning-700 hover:text-warning-800",
    iconColor: "text-warning-300",
    themeColor: "border-warning-70 bg-warning-50",
  },
  success: {
    titleColor: "text-primary-700",
    descriptionColor: "text-success-200",
    firstButtonTextColor: "text-success-600 hover:text-success-700",
    secondButtonTextColor: "text-success-700 hover:text-success-800",
    iconColor: "text-primary-700",
    themeColor: "border-primary-200 bg-success-50",
  },
};

const handleColors = (type: string) => {
  const defaultColor = {
    titleColor: "text-gray-900",
    descriptionColor: "text-gray-600",
    firstButtonTextColor: "text-gray-600 hover:text-gray-700",
    secondButtonTextColor: "text-yellow-700 hover:text-yellow-800",
    iconColor: "text-gray-500",
  };

  return colorMap[type] || defaultColor;
};

const Alert: FC<Props> = ({
  title,
  description,
  type = "default",
  open,
  setOpen,
}) => {
  const colors = handleColors(type);
  const iconMap: { [key: string]: ReactElement } = {
    error: <DangerIcon className="w-5 h-5 text-error-200" />,
    warning: <InfoCircleIcon className="w-5 h-5 text-warning-200" />,
    success: <TickCircleIcon className="w-5 h-5 text-primary-700" />,
  };

  const icon = iconMap[type];

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen && setOpen(false);
      }, 14000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none inset-0 flex my-4 items-start top-12"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={open}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-[640px] overflow-hidden">
            <div className={clsx("p-4 rounded-xl border", colors.themeColor)}>
              <div className="flex relative">
                <div className="flex flex-row">
                  <div className="">{icon}</div>
                  <div className="md:ms-3">
                    {title && (
                      <Typo.heading3 className={colors.titleColor}>
                        {title}
                      </Typo.heading3>
                    )}
                    {description && (
                      <Typo.heading4
                        fontWeight="font-normal"
                        className={colors.descriptionColor}
                      >
                        {description}
                      </Typo.heading4>
                    )}
                  </div>
                </div>
                <div className="absolute end-9 md:relative md:end-0 md:ms-auto ">
                  <Button
                    onClick={() => setOpen(false)}
                    className="-mt-2 -mr-2"
                    icon={
                      <CloseIcon
                        className={`h-5 w-5 ${colors.iconColor}`}
                      ></CloseIcon>
                    }
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Alert;
