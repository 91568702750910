import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Spinner from "./Spinners";
type Props = {
  size?: "large" | "normal" | "medium" | "small" | "sxtra-small";
  icon?: any;
  iconPos?: "top" | "bottom" | "left" | "right" | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  to?: string;
  rounded?:
    | "rounded-full"
    | "rounded-xl"
    | "rounded-lg"
    | "rounded"
    | "rounded-none";
  theme?:
    | "primary"
    | "secandory"
    | "label"
    | "stroke"
    | "stroke-primary"
    | "error"
    | "gray"
    | "primary-link"
    | "blue-link"
    | "gray-link"
    | "base-transparent"
    | "error-outline"
    | "white"
    | "";
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  target?: "" | "_blank";
  [x: string]: any;
};

const Button: React.FC<Props> = (props) => {
  const {
    size = "normal",
    icon = null,
    iconPos = "left",
    type = "button",
    children = "",
    theme = "",
    to,
    disabled = false,
    loading = false,
    rounded = "rounded-xl",
    className,
    target = "",
    ...rest
  } = props;

  const paddingMap = {
    large: ` ${children ? "py-5 px-8" : "p-5"}`,
    normal: ` ${children ? "py-4 px-7" : "p-4"} `,
    medium: ` ${children ? "py-3.5 px-6" : "p-3.5"} `,
    small: ` ${children ? "py-3 px-5" : "p-3"} `,
    "sxtra-small": `${children ? "py-2 px-4 " : "p-2"}`,
  };
  const designMap: { [key: string]: string } = {
    primary: `bg-primary-500 border border-primary-500 text-white hover:bg-primary-600 hover:border-primary-600 focus:bg-primary-600 focus:border-primary-600 disabled:bg-primary-100 disabled:border-gray-100 disabled:text-gray-400 ${paddingMap[size]}`,
    secandory: ` bg-secondary-500 border border-secondary-500 text-white hover:bg-secondary-600 hover:border-secondary-600 focus:bg-secondary-600 focus:border-secondary-600 disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-400 ${paddingMap[size]}`,
    label: `text-gray-800 focus:text-gray-900 hover:text-gray-900 disabled:text-gray-400 ${paddingMap[size]}`,
    gray: ` bg-gray-50 text-secondary-500 border border-gray-50 hover:text-gray-600 focus:text-gray-600 disabled:text-secondary-200 ${paddingMap[size]}`,
    stroke: `border border-secondary-50 text-secondary-500 bg-white hover:border-secondary-100 hover:text-secondary-600 focus:border-secondary-100 focus-within:text-secondary-600 disabled:border-gray-200 disabled:bg-gray-400 ${paddingMap[size]}`,
    "stroke-primary": `border border-primary-600 text-primary-600 bg-white hover:border-primary-700 hover:text-primary-700 focus:border-primary-700 focus-within:text-primary-700 disabled:border-primary-200 disabled:bg-primary-100 ${paddingMap[size]}`,
    "stroke-blue": `border border-primary-600 text-primary-600 bg-white hover:border-primary-700 hover:text-primary-700 focus:border-primary-700 focus-within:text-primary-700 disabled:border-gray-200 disabled:bg-gray-400 ${paddingMap[size]}`,
    warning: `bg-yellow-50 shadow-xs border border-yellow-50 text-yellow-700 hover:bg-yellow-100 hover:border-yellow-100 hover:text-yellow-800 focus:border-yellow-300 focus:bg-yellow-50 focus:text-yellow-700 focus:shadow-primary-yellow disabled:opacity-50 ${paddingMap[size]}`,
    "primary-blue": `bg-blue-700 text-white border border-blue-700 shadow-xs hover:bg-blue-800 hover:border-blue-800 focus:bg-blue-50 focus:border-blue-50 focus:text-blue-700 disabled:bg-blue-700 focus:shadow-primary-blue disabled:border-blue-700 disabled:opacity-50 ${paddingMap[size]}`,
    info: `border border-blue-50 bg-blue-50 text-blue-700 shadow-xs hover:border-blue-100 hover:bg-blue-100  focus:bg-blue-50 focus:border-blue-50 focus:text-blue-700 focus:shadow-info disabled:bg-blue-50 disabled:opacity-50 ${paddingMap[size]}`,
    green: `border border-green-50 bg-green-50 text-white-700 shadow-xs hover:border-green-100 hover:bg-green-100  focus:bg-green-50 focus:border-green-50 focus:text-green-700 focus:shadow-info disabled:bg-green-50 disabled:opacity-50 ${paddingMap[size]}`,
    error: `border border-error-600 bg-error-600 text-white hover:border-error-700 hover:bg-error-700 shadow-xs focus:bg-error-600 focus:border-error-600 focus:shadow-error disabled:bg-error-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50 ${paddingMap[size]}`,
    "primary-link":
      "text-primary-700 hover:text-primary-800 focus:text-primary-500 disabled:text-gray-300",
    "blue-link":
      "text-blue-gray-600 hover:text-blue-gray-500 focus:text-yellow-500 disabled:text-gray-300",
    "gray-link":
      "text-gray-600 hover:text-gray-500 focus:text-yellow-500 disabled:text-gray-300 flex items-center",
    black: "text-white border border-gray-300 py-4 px-6 bg-gray-800",
    "": ``,
    white: `bg-white border border-secondary-50 text-gray-900 hover:bg-gray-50  focus:bg-gray-50 focus:border-gray-50  ${paddingMap[size]}`,
  };

  return (
    <>
      {to ? (
        <Link
          to={to || "#"}
          target={target}
          rel="noopener noreferrer"
          className="p-0"
        >
          <button
            type={type}
            disabled={disabled}
            className={clsx(
              ` disabled:cursor-not-allowed focus:outline-none ${rounded}`,
              designMap[theme],
              className
            )}
            {...rest}
          >
            <div
              className={clsx(
                "flex items-center h-full w-full justify-center gap-x-2",
                {
                  "flex-row-reverse": iconPos === "right",
                }
              )}
            >
              {loading && <Spinner className=" w-5 h-5 leading " />}
              {icon && <div>{icon}</div>}
              {!loading && children}
            </div>
          </button>
        </Link>
      ) : (
        <button
          type={type}
          disabled={disabled}
          className={clsx(
            ` disabled:cursor-not-allowed focus:outline-none ${rounded}`,
            designMap[theme],
            className
          )}
          {...rest}
        >
          <div
            className={clsx(
              "flex items-center h-full w-full justify-center gap-x-2 ",
              {
                "flex-row-reverse": iconPos === "right",
              }
            )}
          >
            {loading && <Spinner className="w-5 h-5 leading " />}
            {icon && <div>{icon}</div>}
            {!loading && children}
          </div>
        </button>
      )}
    </>
  );
};

export default Button;
