type Props = {
  className?: string;
};
const Building4Icon = ({ className }: Props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 666.67 666.67"
      className={className}
    >
      <path fill="currentColor" d="M266.67,166.67H33.33c-18.41,0-33.33,14.92-33.33,33.33v466.67H300V200c0-18.41-14.92-33.33-33.33-33.33Zm-133.33,433.33H66.67v-66.67h66.67v66.67Zm0-100H66.67v-66.67h66.67v66.67Zm0-100H66.67v-66.67h66.67v66.67Zm0-100H66.67v-66.67h66.67v66.67Zm100,300h-66.67v-66.67h66.67v66.67Zm0-100h-66.67v-66.67h66.67v66.67Zm0-100h-66.67v-66.67h66.67v66.67Zm0-100h-66.67v-66.67h66.67v66.67ZM646.87,124.53L366.67,0V666.67h300V155c0-13.18-7.76-25.12-19.8-30.47Zm-46.87,408.8h-166.67v-66.67h166.67v66.67Zm0-133.33h-166.67v-66.67h166.67v66.67Zm0-133.33h-166.67v-66.67h166.67v66.67Z" />
    </svg>
  );
};

export default Building4Icon;
