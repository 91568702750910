import React from "react";
import { DropdownOptionModel } from "../../model/DropdownOption";
import { Typo } from "./typo";
type Props = {
  selected?: boolean;
  value: DropdownOptionModel | undefined;
  iconType?: "none" | "image" | "icon" | "onlineIndicatro";
  [x: string]: any;
};

type LabelProps = {
  placeholder?: string;
  prefix?: any;
  value: DropdownOptionModel | undefined;
  iconType?: "none" | "image" | "icon" | "onlineIndicatro";
  className?: string;
};

const InputDropdownOption: React.FC<Props> = (props) => {
  const { value, iconType, selected } = props;

  return (
    <div
      className={`flex justify-between h-full py-2.5 px-3.5 hover:bg-gray-100 ${
        selected ? " bg-gray-100" : " bg-white"
      } `}
    >
      <div className=" flex gap-x-2 text-md font-medium items-center justify-center">
        {iconType === "icon" && value?.icon && <span>{value.icon}</span>}
        <Typo.heading5
          fontWeight="font-medium"
          className="text-gray-900 whitespace-nowrap"
        >
          {value?.title}
        </Typo.heading5>
      </div>
    </div>
  );
};

export default InputDropdownOption;

export const InputDropdownLabel: React.FC<LabelProps> = (props) => {
  const { value, iconType, placeholder, prefix, className } = props;

  return (
    <div className=" flex justify-between">
      <div className=" flex gap-x-2 text-md items-center justify-center">
        {((prefix && iconType === "none") ||
          (prefix && iconType !== "none" && !value)) &&
          prefix}
        {iconType === "icon" && value?.icon && <span>{value.icon}</span>}
        {value ? (
          <Typo.heading4
            fontWeight="font-medium"
            className={`flex gap-x-2 text-gray-500 hover:text-gray-700 ${className}`}
          >
            {value?.title}
          </Typo.heading4>
        ) : (
          <Typo.heading5 fontWeight="font-normal" className="text-gray-500">
            {placeholder}
          </Typo.heading5>
        )}
      </div>
    </div>
  );
};
