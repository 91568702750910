import { FC } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { TRANSLATION } from "../static/translation";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  icon: any;
  title: MessageDescriptor;
  description: MessageDescriptor;
};

const ConfirmModal: FC<Props> = ({
  open,
  onClose,
  icon,
  title,
  description,
}) => {
  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center">
          <div className="text-primary-600 flex gap-x-2 justify-center">
            <span>{icon && icon} </span>
            <Typo.heading1
              fontWeight="font-bold"
              className=" text-secondary-900 text-center "
            >
              <FormattedMessage
                id={title.id}
                defaultMessage={title.defaultMessage}
              />
            </Typo.heading1>
          </div>

          <Typo.heading5 className="text-gray-500 mt-2 text-center">
            <FormattedMessage
              id={description.id}
              defaultMessage={description.defaultMessage}
            />
          </Typo.heading5>
        </div>
        <div className=" flex flex-col sm:flex-row gap-4 mt-10 pb-4 ">
          <div className="flex w-full">
            <Button
              onClick={() => onClose(true)}
              size="medium"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.CONFIRM.id}
                  defaultMessage={TRANSLATION.CONFIRM.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
          <div className="flex w-full">
            <Button
              size="medium"
              onClick={() => onClose(false)}
              theme="gray"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
