type Props = {
  className?: string;
};
const LiftIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
    >
      <path
        fill="currentColor"
        className="cls-1"
        d="M56.95,71.74c-2.03,1.17-4.66,.47-5.83-1.56l-3.9-6.74c0,10.93,.14,20.96,.14,31.9,0,2.69-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9v-22.09h-2.75v22.09c0,2.69-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9c0-10.89,.13-20.86,.13-31.74l-3.8,6.58c-1.17,2.03-3.8,2.74-5.83,1.56-2.03-1.17-2.74-3.8-1.56-5.83l9.93-17.2c3.54-6.14,20.72-6.89,24.54-.27l10.09,17.47c1.17,2.04,.47,4.66-1.56,5.83h0ZM36.01,22.64c5.4,0,9.77,4.37,9.77,9.77s-4.37,9.77-9.77,9.77-9.77-4.37-9.77-9.77,4.38-9.77,9.77-9.77h0Zm44.68,2.86l12.53-10.94c.46-.41,1.04-.6,1.61-.6s1.14,.22,1.58,.62l12.52,10.93c.48,.44,.74,1.02,.78,1.62,.03,.61-.16,1.23-.6,1.72l-.02,.02c-.44,.48-1.03,.75-1.63,.78h-.01c-.61,.03-1.23-.17-1.72-.6l-8.54-7.43v16.67c0,.66-.27,1.26-.7,1.7-.43,.43-1.04,.7-1.7,.7s-1.26-.27-1.7-.7c-.43-.43-.7-1.03-.7-1.7V21.62l-8.53,7.45c-.49,.43-1.12,.62-1.72,.59-.61-.04-1.21-.3-1.65-.8-.44-.49-.63-1.12-.6-1.72,.04-.61,.31-1.2,.8-1.64Zm22.04,35.94c0,4.38-3.55,7.94-7.94,7.94s-7.94-3.55-7.94-7.94c0-4.38,3.56-7.94,7.94-7.94s7.94,3.55,7.94,7.94Zm6.42,35.93l-12.52,10.93c-.44,.4-1.01,.62-1.58,.62s-1.15-.19-1.61-.6l-12.53-10.94c-.49-.44-.76-1.03-.8-1.64-.03-.6,.16-1.23,.6-1.72,.44-.5,1.04-.76,1.65-.8,.6-.03,1.23,.16,1.72,.59l8.53,7.45v-16.67c0-.67,.27-1.27,.7-1.7,.44-.43,1.04-.7,1.7-.7s1.27,.27,1.7,.7c.43,.44,.7,1.04,.7,1.7v16.67l8.54-7.43c.49-.43,1.11-.63,1.72-.6h0c.6,.03,1.19,.3,1.63,.78l.02,.02c.44,.49,.63,1.11,.6,1.72-.04,.6-.3,1.18-.78,1.62ZM117.24,5.64c-3.47-3.48-8.28-5.64-13.56-5.64H19.2C13.92,0,9.12,2.16,5.64,5.64,2.16,9.12,0,13.92,0,19.2V103.68c0,5.28,2.16,10.08,5.64,13.56,3.47,3.48,8.28,5.64,13.56,5.64H103.68c5.28,0,10.08-2.16,13.56-5.64,3.48-3.47,5.64-8.28,5.64-13.56V19.2c0-5.28-2.16-10.08-5.64-13.56Zm-50.31,110.48H19.42c-3.42,0-6.53-1.41-8.78-3.66s-3.66-5.36-3.66-8.78V19.2c0-3.42,1.41-6.53,3.66-8.78,2.26-2.25,5.36-3.65,8.78-3.65h47.51V116.12Z"
      />
    </svg>
  );
};

export default LiftIcon;
