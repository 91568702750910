import CallButton from "./CallButton";
import MessageButton from "./MessageButton";
import WhatsAppButton from "./WhatsAppButton";

type Props = {
  className?: string;
  phone?: string;
  whatsApp?: string;
  direction: string;
};

const ListingCardContact: React.FC<Props> = (props) => {
  const { className, phone, whatsApp, direction } = props;

  return (
    <div
      className={`flex flex-row w-full gap-x-2  pb-2 text-gray-600 ${className} ${
        phone && whatsApp ? "justify-center" : "justify-end"
      }`}
    >
      {phone && <CallButton direction={direction} phone={phone} />}
      {whatsApp && <WhatsAppButton whatsApp={whatsApp} />}
      {phone && <MessageButton phone={phone} />}
    </div>
  );
};

export default ListingCardContact;
