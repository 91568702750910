type Props = {
  className?: string;
};
const SmsIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className}`}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 17.5833H5.83332C3.33332 17.5833 1.66666 16.3333 1.66666 13.4167V7.58334C1.66666 4.66667 3.33332 3.41667 5.83332 3.41667H14.1667C16.6667 3.41667 18.3333 4.66667 18.3333 7.58334V13.4167C18.3333 16.3333 16.6667 17.5833 14.1667 17.5833Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 8L11.5583 10.0833C10.7 10.7667 9.29167 10.7667 8.43334 10.0833L5.83334 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmsIcon;
