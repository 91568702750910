type Props = {
  className?: string;
};
const Note2Icon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40377 6.4058L6.40378 6.40581L6.40753 6.40457L6.93153 6.23104C6.93215 6.23083 6.93265 6.23069 6.93303 6.23059C6.93563 6.23142 6.94362 6.23481 6.95326 6.24455C6.96348 6.25487 6.97015 6.26699 6.97312 6.27726C6.97562 6.28589 6.97563 6.2929 6.97338 6.30093C6.9148 6.51022 6.85877 6.72747 6.80436 6.95235L6.80435 6.95234L6.80348 6.95605L5.82348 11.146L5.82344 11.1462C5.25911 13.5626 5.36354 15.5454 6.31649 17.0844C7.26937 18.6232 8.99855 19.6018 11.4147 20.1764L11.417 20.1769L11.7593 20.2565L10.9769 20.514C10.9764 20.5142 10.9759 20.5144 10.9755 20.5145C9.03768 21.1391 7.68191 21.1472 6.67809 20.6336C5.67387 20.1197 4.8859 19.0143 4.25591 17.0755C4.2559 17.0755 4.25588 17.0755 4.25587 17.0754L2.97622 13.1265C2.97618 13.1264 2.97614 13.1263 2.9761 13.1261C2.35105 11.1873 2.34046 9.82836 2.85219 8.82298C3.36355 7.81833 4.46593 7.03059 6.40377 6.4058Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M15.3851 3.30658L15.3865 3.30691L17.0563 3.69687C17.0564 3.69688 17.0564 3.69689 17.0565 3.69691C19.1032 4.17564 20.3119 4.89396 20.9291 5.88861C21.5462 6.88329 21.6521 8.28397 21.1734 10.3259L20.1934 14.5059L20.1932 14.5067C19.7833 16.2682 19.1921 17.4136 18.4001 18.0914C17.625 18.7547 16.581 19.0339 15.108 18.8923L15.108 18.8922L15.1001 18.8916C14.6305 18.854 14.1167 18.7689 13.5575 18.634L13.556 18.6336L11.876 18.2336L11.8757 18.2335C9.83839 17.7499 8.63119 17.0317 8.01367 16.0375C7.39651 15.0438 7.2884 13.6454 7.76698 11.6041L7.76704 11.6039L8.74689 7.41453C8.74692 7.4144 8.74695 7.41427 8.74698 7.41415C8.94209 6.585 9.1715 5.88463 9.44885 5.32067L9.44886 5.32067L9.45033 5.31764C9.99087 4.1996 10.6906 3.54259 11.5995 3.2289C12.5317 2.90719 13.7557 2.92119 15.3851 3.30658ZM15.7735 13.458L15.7736 13.458L15.7753 13.4513C15.9414 12.7868 15.5427 12.1026 14.8613 11.9349L11.9534 11.1954L11.9514 11.1949C11.2897 11.0295 10.6036 11.4247 10.4351 12.0987C10.2698 12.7602 10.6645 13.4459 11.3379 13.6148C11.3383 13.6149 11.3386 13.615 11.3389 13.6151L14.247 14.3546L14.2729 14.3612L14.2995 14.365C14.3056 14.3659 14.3125 14.3669 14.3202 14.368C14.3754 14.3762 14.4679 14.39 14.5602 14.39C15.1204 14.39 15.6238 14.0236 15.7735 13.458ZM18.7035 10.078L18.7036 10.078L18.7059 10.0685C18.8676 9.40584 18.4818 8.71142 17.7862 8.55361L12.9431 7.32535L12.9414 7.32494C12.2797 7.1595 11.5936 7.55473 11.4251 8.22874C11.2597 8.89019 11.6546 9.57598 12.328 9.74486C12.3283 9.74493 12.3286 9.74501 12.3289 9.74508L17.1773 10.9747L17.2031 10.9812L17.2295 10.985C17.2356 10.9859 17.2425 10.9869 17.2502 10.988C17.3054 10.9963 17.3979 11.01 17.4902 11.01C18.0504 11.01 18.5538 10.6436 18.7035 10.078Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default Note2Icon;
