import { ReactElement } from "react";
import { TRANSLATION } from "../static/translation";
import { Typo } from "../components/shared/typo";

export const mapPorpuse = (intl: any, key: string, value?: string) => {
  const typeMap: { [key: string]: string } = {
    sell: intl.formatMessage(
      {
        id: TRANSLATION.FOR_SELL.id,
        defaultMessage: TRANSLATION.FOR_SELL.defaultMessage,
      },
      { name: value || "" }
    ),
    rent: intl.formatMessage(
      {
        id: TRANSLATION.FOR_RENT.id,
        defaultMessage: TRANSLATION.FOR_RENT.defaultMessage,
      },
      { name: value || "" }
    ),
    mortgage: intl.formatMessage(
      {
        id: TRANSLATION.FOR_MORTGAGE.id,
        defaultMessage: TRANSLATION.FOR_MORTGAGE.defaultMessage,
      },
      { name: value || "" }
    ),
  };

  return typeMap[key];
};

export const mapPorpuseBadge = (intl: any, key: string, value?: string) => {
  const mapBadge: { [key: string]: ReactElement } = {
    rent: (
      <div className=" bg-warning-50 w-full py-2 rounded-lg">
        <Typo.body4 className="text-warning-100">
          {mapPorpuse(intl, "rent")}
        </Typo.body4>
      </div>
    ),
    mortgage: (
      <div className=" bg-info-50 w-full py-2 rounded-lg">
        <Typo.body4 className=" text-info-200">
          {mapPorpuse(intl, "mortgage")}
        </Typo.body4>
      </div>
    ),
    sell: (
      <div className=" bg-primary-50 w-full py-2 rounded-lg">
        <Typo.body4 className=" text-primary-600">
          {mapPorpuse(intl, "sell")}
        </Typo.body4>
      </div>
    ),
  };

  return mapBadge[key];
};
