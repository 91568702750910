import { FC } from "react";
import Modal from "../wrapper/modal";
import Tab from "../components/shared/Tab";
import { getNearbyTabs } from "../static/constants";
import { FormattedMessage, IntlShape } from "react-intl";
import { Typo } from "../components/shared/typo";
import Button from "../components/shared/button";
import CloseIcon from "../components/icons/CloseIcon";
import { TRANSLATION } from "../static/translation";
import { DropdownOptionModel } from "../model/DropdownOption";
import { Location } from "../model/Location";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  defaultValue?: any;
  intl: IntlShape;
  currentNearby?: DropdownOptionModel;
  setCurrentNearby: (tab: DropdownOptionModel) => void;
  location?: Location;
};

const NearbyLocationModal: FC<Props> = ({
  open,
  onClose,
  defaultValue,
  intl,
  currentNearby,
  setCurrentNearby,
  location
}) => {
  return (
    <Modal
      className="max-w-5xl text-start"
      open={open}
      onClose={() => onClose(false)}
    >
      <div className="w-full px-10 pb-8 pt-6">
        <div className=" flex justify-between">
          <Typo.headingD fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.NEARBY_LOCATION.id}
              defaultMessage={TRANSLATION.NEARBY_LOCATION.defaultMessage}
            />
          </Typo.headingD>
          <Button
            onClick={() => onClose(false)}
            icon={<CloseIcon className="h-2 w-2 text-gray-700" />}
          ></Button>
        </div>
        <div className="mt-4 space-y-8">
          <Tab
            currentTab={currentNearby}
            onTabChange={setCurrentNearby}
            type="underline"
            tabs={getNearbyTabs(intl)}
          />
          <div className="grid grid-cols-3">
            <div className=" border border-gray-200 rounded-lg p-6">
              <div className="space-y-3">
                <Typo.body3 fontWeight="font-bold">آدرس</Typo.body3>
                <Typo.heading3 className="text-gray-600">
                  توضیحات آدرس
                </Typo.heading3>
              </div>
              <div className="space-y-3">
                <Typo.body3 fontWeight="font-bold">آدرس</Typo.body3>
                <Typo.heading3 className="text-gray-600">
                  توضیحات آدرس
                </Typo.heading3>
              </div>
              <div className="space-y-3">
                <Typo.body3 fontWeight="font-bold">آدرس</Typo.body3>
                <Typo.heading3 className="text-gray-600">
                  توضیحات آدرس
                </Typo.heading3>
              </div>
              <div className="space-y-3">
                <Typo.body3 fontWeight="font-bold">آدرس</Typo.body3>
                <Typo.heading3 className="text-gray-600">
                  توضیحات آدرس
                </Typo.heading3>
              </div>
              <div className="space-y-3">
                <Typo.body3 fontWeight="font-bold">آدرس</Typo.body3>
                <Typo.heading3 className="text-gray-600">
                  توضیحات آدرس
                </Typo.heading3>
              </div>
            </div>
            <div className="col-span-2">Map description</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NearbyLocationModal;
