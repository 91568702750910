import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navigation } from "../../../model/Navigation";
import ActiveMenuIcon from "../../icons/ActiveMenuIcon";
import { Typo } from "../../shared/typo";
import ChevronDownIcon from "../../icons/CheveronDownIcon";
import Button from "../../shared/button";
import SubMenuIcon from "../../icons/SubMenuIcon";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  nav?: Navigation;
  className?: string;
  current: string;
  direction: string;
};

const SidebarNav: React.FC<Props> = (props) => {
  const { nav, className, current, direction } = props;
  const [collapse, setCollapse] = useState(false);
  var included = nav?.items?.some(function (item) {
    return item.href === current;
  });
  return (
    <>
      <li key={nav?.name} className="relative ">
        {nav?.items && nav?.items?.length > 0 ? (
          <div className="w-full px-2 relative ">
            {current == nav?.href ||
              (included && (
                <ActiveMenuIcon
                  className={clsx(
                    " absolute -top-2",
                    direction === DIRECTIONS.LTR
                      ? " transform -scale-x-100"
                      : ""
                  )}
                />
              ))}
            <Button
              onClick={() => setCollapse(!collapse)}
              theme=""
              className={`flex items-center py-2 ps-6 ${
                current == nav?.href || included
                  ? "text-primary-600"
                  : "text-gray-900"
              }`}
            >
              <div className="me-3">{nav?.icon}</div>
              <Typo.body2
                fontWeight={`${
                  current == nav?.href || included
                    ? "font-normal"
                    : "font-medium"
                }`}
              >
                {nav?.name}
              </Typo.body2>
              {nav?.items && nav?.items?.length > 0 && (
                <ChevronDownIcon
                  className={`ms-2 ${collapse ? " " : "rotate-180"}`}
                />
              )}
            </Button>
            {collapse && (
              <div className="">
                {nav?.items?.map((item) => {
                  return (
                    <div
                      key={item?.name}
                      className={`ps-6 relative ${
                        current == item?.href
                          ? " text-primary-600 font-normal"
                          : "text-gray-500"
                      }`}
                    >
                      <Link to={item?.href}>
                        <Typo.body4
                          fontWeight={`${
                            current == item?.href
                              ? "font-medium"
                              : "font-normal"
                          }`}
                          className="py-2 ps-8 pt-4"
                        >
                          {item?.name}
                        </Typo.body4>
                        <SubMenuIcon
                          className={clsx(
                            "absolute top-0 start-8 ",
                            direction === DIRECTIONS.LTR
                              ? "transform -scale-x-100"
                              : ""
                          )}
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <Link to={nav?.href || "#"} className="p-0">
            <div className="w-full px-2 relative ">
              {current == nav?.href && (
                <ActiveMenuIcon
                  className={clsx(
                    " absolute -top-2",
                    direction === DIRECTIONS.LTR
                      ? " transform -scale-x-100"
                      : ""
                  )}
                />
              )}
              <div
                className={`flex items-center py-2 ps-6 ${
                  current == nav?.href ? "text-primary-600" : "text-gray-900"
                }`}
              >
                <div className="me-3">{nav?.icon}</div>
                <Typo.body2
                  fontWeight={`${
                    current == nav?.href ? "font-normal" : "font-medium"
                  }`}
                >
                  {nav?.name}
                </Typo.body2>
              </div>
            </div>
          </Link>
        )}
      </li>
    </>
  );
};

export default SidebarNav;
