import React from "react";
import Button from "./button";
import ShareIcon from "../icons/shareIcon";
import { ListingObject } from "../../model/Listing";
import { ROUTES } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { setShareData } from "../../lib/reducers/share.slice";
type Props = {
  listing?: ListingObject;
};

const ShareButton: React.FC<Props> = (props) => {
  const { listing } = props;
  const dispatch = useDispatch();

  const onShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(
      setShareData({
        state: true,
        title: listing?.title,
        description: "",
        link: `${process.env.REACT_APP_FRONTEND_URL}${ROUTES.LISTINGS_ROUTE}/${listing?.slug}`,
        image: "",
      })
    );
  };

  return (
    <Button
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => onShare(event)}
    >
      <ShareIcon className="text-gray-900" />
    </Button>
  );
};

export default ShareButton;
