import PrivacyPolicyContent from "../../shared/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <div className="w-full">
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPolicy;
