import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../context/auth";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { handelValidationMap } from "../../util/handelValidationMap";
import { useIntl } from "react-intl";
import { AppError, HttpCode, HttpMessage } from "../../static/AppError";
import { AlertModel } from "../../model/Alert";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { hideModal } from "../../lib/reducers/modal.slice";
export const useHandelForgotPassword = () => {
    const auth = useAuth();
    const intl = useIntl();
    const navigage = useNavigate();
    const dispatch = useDispatch();
    const validations = handelValidationMap(intl);
    const serverMessages = handelServerMessageMap(intl);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<AlertModel>();
    const schema = object().shape({
        email: string()
            .required(validations["email_required"])
            .email(validations["invalid_email"]),
    });

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const mutation = useMutation({
        mutationFn: async (doUser: any) => {
            return await Fetch({
                method: "POST",
                endpoint: "/password-reset",
                data: doUser,
            });
        },
        onSuccess: (data) => {
            if (data.success) {
                navigage(ROUTES.FORGOT_PASSWORD_VERIFICATION_ROUTE)
                dispatch(hideModal('forgotPassword'))
            }
            setLoading(false)
        },
        onError: (error: AppError) => {
            if (error.status === HttpCode.BAD_REQUEST) {
                if (error?.data?.message === HttpMessage.INVALID_EMAIL) {
                    setError(
                        "email",
                        { message: serverMessages[HttpMessage.EMAIL_REQUIRED] },
                        { shouldFocus: true }
                    );
                }
            } else if (error.status === HttpCode.NOT_FOUND) {
                setError(
                    "email",
                    { message: serverMessages[HttpMessage.EMAIL_NOT_FOUND] },
                    { shouldFocus: true }
                );

            } else if (error.status === HttpCode.INTERNAL_SERVER_ERROR) {
                setAlert({
                    title: serverMessages[HttpMessage.INTERNAL_SERVER_ERROR],
                    open: true,
                    type: "error",
                });
            }
            setLoading(false);
        },
    });

    const onSubmit = async (data: any) => {
        setLoading(true);
        localStorage.setItem('email', data?.email)
        mutation.mutate(data);
    };

    return {
        register,
        errors,
        handleSubmit,
        onSubmit,
        reset,
        loading,
        alert,
        setAlert
    };
};
