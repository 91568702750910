import clsx from "clsx";
import React from "react";
import profileAvatar from "../../assets/image/user.png";
import Loader from "./Loader";
type Props = {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  image?: any;
  showState?: boolean;
  verified?: boolean;
  isOnline?: boolean;
  className?: string;
  disabled?: boolean;
  [x: string]: any;
};

const Avatar: React.FC<Props> = (props) => {
  const {
    size = "xs",
    image,
    verified = false,
    showState = false,
    isOnline = false,
    className,
    disabled = false,
  } = props;
  return (
    <div className="flex">
      <div className="relative flex justify-center ">
        <button
          type="button"
          disabled={disabled}
          className={clsx(
            image
              ? "p-0 hover:ring-4 hover:ring-gray-200 focus:ring-primary-500"
              : "",
            {
              " h-6 w-6 ": size === "xs",
              "h-8 w-8": size === "sm",
              "h-10 w-10": size === "md",
              "h-12 w-12": size === "lg",
              "h-14 w-14": size === "xl",
              "h-16 w-16": size === "2xl",
            },
            "rounded-full disabled:ring-0 disabled:hover:ring-0",
            className
          )}
        >
          <img
            className="rounded-full h-full w-full object-cover"
            src={image || profileAvatar}
            alt=""
          />
        </button>
        {showState && (
          <span
            className={clsx(
              isOnline ? "bg-success-500" : " bg-gray-300 ",
              {
                " h-1.5 w-1.5": size === "xs",
                "h-2 w-2": size === "sm",
                "h-2.5 w-2.5": size === "md",
                "h-3 w-3": size === "lg",
                "h-[14px] w-[14px]": size === "xl",
                "h-4 w-4": size === "2xl",
              },
              "absolute border-white bottom-0 right-0 rounded-full  border-[1.5px]"
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Avatar;
