import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { useIntl } from "react-intl";
import { handelValidationMap } from "../../util/handelValidationMap";
import { AlertModel } from "../../model/Alert";
import { AppError } from "../../static/AppError";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { toast } from "react-toastify";
export const useHandelSubscribe = () => {
  const intl = useIntl();
  const validations = handelValidationMap(intl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertModel>();
  const serverMessages = handelServerMessageMap(intl);

  const schema = object().shape({
    email: string()
      .required(validations["email_required"])
      .email(validations["invalid_email"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "POST",
        endpoint: "/subscribe",
        data: doUser,
      });
    },
    onSuccess: (data) => {
      toast.success(serverMessages["subscribtionMessage"]);
      setLoading(false);
    },
    onError: (error: AppError) => {
      console.error(error);
      setLoading(false);
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    mutation.mutate(data);
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    loading,
    alert,
    setAlert,
  };
};
