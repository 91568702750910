type Props = {
  className?: string;
};
const WarehouseIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35464)">
        <path
          d="M4.22828 9.68395H15.7733V19.8765H14.4583V16.4642C14.4583 16.3332 14.4056 16.2076 14.3118 16.115C14.2181 16.0224 14.0909 15.9704 13.9583 15.9704H12.4783V12.5556C12.4783 12.4246 12.4256 12.299 12.3318 12.2064C12.2381 12.1138 12.1109 12.0617 11.9783 12.0617H8.02328C7.89067 12.0617 7.7635 12.1138 7.66973 12.2064C7.57596 12.299 7.52328 12.4246 7.52328 12.5556V15.9704H6.04328C5.91067 15.9704 5.7835 16.0224 5.68973 16.115C5.59596 16.2076 5.54328 16.3332 5.54328 16.4642V19.8765H4.22828V9.68395ZM8.52328 15.9704H11.4783V13.0494H8.52328V15.9704ZM10.5008 16.958V19.8765H13.4583V16.958H10.5008ZM6.54328 19.8765H9.50078V16.958H6.54328V19.8765ZM18.9783 4.46913L10.9633 0.120983C10.6683 -0.038953 10.3373 -0.122803 10.0008 -0.122803C9.66429 -0.122803 9.33324 -0.038953 9.03828 0.120983L1.02328 4.46913C0.710074 4.63954 0.448852 4.88976 0.266819 5.19374C0.0847867 5.49773 -0.0113983 5.84436 -0.0117188 6.19753V17.9012C-0.0117188 18.4251 0.198995 18.9275 0.574068 19.298C0.949141 19.6684 1.45785 19.8765 1.98828 19.8765H3.23828V9.19012C3.23828 9.05915 3.29096 8.93354 3.38473 8.84093C3.4785 8.74832 3.60567 8.69629 3.73828 8.69629H16.2733C16.4059 8.69629 16.5331 8.74832 16.6268 8.84093C16.7206 8.93354 16.7733 9.05915 16.7733 9.19012V19.8765H18.0233C18.5537 19.8765 19.0624 19.6684 19.4375 19.298C19.8126 18.9275 20.0233 18.4251 20.0233 17.9012V6.2C20.0224 5.84541 19.9249 5.4976 19.7411 5.19305C19.5572 4.8885 19.2937 4.63844 18.9783 4.46913Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35464">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarehouseIcon;
