import clsx from "clsx";
import { ReactElement } from "react";
type TypoProps = {
  element?: keyof JSX.IntrinsicElements;
  /** Children to render inside this component. */
  children: string | JSX.Element | Array<JSX.Element> | React.ReactNode;

  /** CSS classnames. */
  className?: string;
  fontWeight?:
    | "font-extralight"
    | "font-light"
    | "font-normal"
    | "font-medium"
    | "font-semibold"
    | "font-bold"
    | "font-extrabold"
    | "font-black";
};

const headingA = (props: TypoProps): ReactElement => {
  const {
    element = "h1",
    children,
    fontWeight = "font-normal",
    className,
  } = props;
  const Tag = element as keyof React.JSX.IntrinsicElements;
  const classes = clsx("font-iransans", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingB = (props: TypoProps): ReactElement => {
  const { element = "h2", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    "font-iransans text-[30px] md:text-[40px] ",
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingC = (props: TypoProps): ReactElement => {
  const { element = "h3", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans md:text-[30px]", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingD = (props: TypoProps): ReactElement => {
  const { element = "h4", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans md:text-2xl", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading1 = (props: TypoProps): ReactElement => {
  const { element = "h5", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans md:text-5", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading2 = (props: TypoProps): ReactElement => {
  const { element = "h6", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(" font-iransans md:text-[18px] ", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading3 = (props: TypoProps): ReactElement => {
  const { element = "h6", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans md:text-[16px]", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading4 = (props: TypoProps): ReactElement => {
  const { element = "h6", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    "font-iransans text-xs md:text-sm",
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading5 = (props: TypoProps): ReactElement => {
  const { element = "h6", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans", fontWeight, className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const heading6 = (props: TypoProps): ReactElement => {
  const { element = "h6", children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx("font-iransans md:text-[10px] ", fontWeight, className);

  return (
    <>
      <Tag className={classes + "  "}>{children}</Tag>
    </>
  );
};

const bodyA = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-5xl", fontWeight);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const bodyB = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-[40px]", fontWeight);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const bodyC = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-[30px]", fontWeight);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const bodyD = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(fontWeight, "font-iransans md:text-2xl", className);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const body1 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    className,
    "font-iransans text-2xl md:text-xl",
    fontWeight
  );

  return (
    <>
      <Tag className={`${classes}    `}>{children}</Tag>
    </>
  );
};

const body2 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-[18px]", fontWeight);

  return (
    <>
      <Tag className={`${classes}    `}>{children}</Tag>
    </>
  );
};

const body3 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-4", fontWeight);

  return (
    <>
      <Tag className={`${classes}`}>{children}</Tag>
    </>
  );
};

const body4 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-base", fontWeight);

  return (
    <>
      <Tag className={`${classes}`}>{children}</Tag>
    </>
  );
};

const body5 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-sm", fontWeight);
  return (
    <>
      <Tag className={`${classes}    `}>{children}</Tag>
    </>
  );
};

const body6 = (props: TypoProps): ReactElement => {
  const { element = "div", children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, "font-iransans md:text-xs", fontWeight);
  return (
    <>
      <Tag className={`${classes}`}>{children}</Tag>
    </>
  );
};

export const Typo = {
  headingA,
  headingB,
  headingC,
  headingD,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  bodyA,
  bodyB,
  bodyC,
  bodyD,
  body1,
  body2,
  body3,
  body4,
  body5,
  body6,
};
