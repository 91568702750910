import { useState } from "react";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import { FormattedMessage, useIntl } from "react-intl";
import { Typo } from "../../../components/shared/typo";
import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import SearchIcon from "../../../components/icons/SearchIcon";
import articleMan from "../../../assets/image/article-man.svg";
import Image from "../../../components/shared/Image";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { useLang } from "../../../context/language";
import Articles from "../../shared/articles";
const MobileArticles = () => {
    const LIMIT = 5;
    const { ToGetArticles, handleSearch, searchRegister } = useHandelArticle();
    const [page, setPage] = useState(1);
    const intl = useIntl();
    const lang = useLang();
    const [title, setTitle] = useState("");
    const articles = ToGetArticles({
        page: page,
        limit: LIMIT,
        articleCategoryIds: [],
        title: title,
    });

    const onSubmit = (data: any) => {
        setTitle(data.title);
    };

    return (
        <div className="w-full max-w-7xl mx-auto">
            <div className="bg-gray-100 px-4 md:px-8 lg:px-16 w-full grid md:grid-cols-2 grid-cols-1 relative justify-between items-center">
                <div className="py-4 md:py-8">
                    <form
                        action="#"
                        method="POST"
                        onSubmit={handleSearch(onSubmit)}
                        className="mx-4 sm:mx-0"
                    >
                        <Typo.headingC fontWeight="font-bold">
                            <FormattedMessage
                                id={TRANSLATION.ARTICLE_TITLE.id}
                                defaultMessage={TRANSLATION.ARTICLE_TITLE.defaultMessage}
                            />
                        </Typo.headingC>
                        <Typo.heading2 className="text-gray-500 mt-6">
                            <FormattedMessage
                                id={TRANSLATION.ARTICLE_DESCRIPTION.id}
                                defaultMessage={TRANSLATION.ARTICLE_DESCRIPTION.defaultMessage}
                            />
                        </Typo.heading2>

                        <div className="w-full relative flex flex-col sm:flex-row gap-4 mt-4 md:mt-8 lg:mt-16 max-w-md">
                            <InputField
                                name="title"
                                type="text"
                                inputStyle="rounded-xl text-[19px]  border shadow-xs focus:ring-0 focus:border-none"
                                placeholder={handelInputMap(intl, "what_do_you_want")}
                                register={searchRegister}
                                required={true}
                            />
                            <Button
                                className=""
                                size="small"
                                rounded="rounded-xl"
                                type="submit"
                                theme="primary"
                                icon={<SearchIcon className="h-5" />}
                            >
                                <Typo.body4>
                                    <FormattedMessage
                                        id={TRANSLATION.SEARCH.id}
                                        defaultMessage={TRANSLATION.SEARCH.defaultMessage}
                                    />
                                </Typo.body4>
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="pt-16 hidden md:flex w-full justify-center">
                    <div>
                        <Image className="w-[350px]" src={articleMan} />
                    </div>
                </div>
            </div>
            <Articles page={page} setPage={setPage} direction={lang.direction} articles={articles} limit={LIMIT} pageType="mobile" />
        </div>
    );
};

export default MobileArticles;
