import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ModalState {
  login: boolean;
  signup: boolean;
  share: boolean;
  forgotPassword: boolean;
  newPassword: boolean;
  filter: boolean;
  addAdmin: boolean;
  direction: boolean;
}

const modalSlice = createSlice({
  name: "modals",
  initialState: {
    login: false, // Set initial state for each modal here
    signup: false,
    share: false,
    forgotPassword: false,
    newPassword: false,
    filter: false,
    addAdmin: false,
    direction: false,
  },
  reducers: {
    showModal: (state, action: PayloadAction<keyof ModalState>) => {
      state[action.payload] = true;
    },
    hideModal: (state, action: PayloadAction<keyof ModalState>) => {
      state[action.payload] = false;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
