type Props = {
  className?: string;
};
const SewerageIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35545)">
        <path
          d="M3.82977 9.09353C4.17324 8.58049 4.61237 8.13265 5.12106 7.78048C5.06453 7.51962 5.02977 7.25441 5.01672 6.98049V6.97614C5.01236 6.88483 5.00801 6.79354 5.00801 6.70222C5.00801 3.81961 7.37323 1.48919 10.2689 1.54136H10.3341C12.8385 1.61091 14.9558 3.5544 15.3037 5.99788C15.3211 6.15439 15.3341 6.31091 15.3428 6.47178C15.3863 7.43266 15.1646 8.34136 14.7384 9.13264C14.5428 9.51093 14.295 9.8631 14.008 10.1761C13.4592 10.7813 12.768 11.2554 11.9875 11.5456C11.5833 11.6982 11.3838 11.9591 11.4053 12.3606L11.4037 12.6631V13.3761C14.5689 12.8022 16.9645 10.0326 16.9645 6.70222C16.9645 2.80908 13.6852 -0.319339 9.73855 -0.0708726C6.2582 0.148244 3.45995 3.08143 3.39193 6.568C3.37872 7.24634 3.46589 7.9004 3.64281 8.51961C3.69499 8.71527 3.76019 8.90658 3.82977 9.09353Z"
          fill="currentColor"
        />
        <path
          d="M19.2187 -0.0847168H13.7188C16.1622 1.19355 17.8361 3.75439 17.8361 6.70223C17.8361 9.63266 16.1927 12.2022 13.7361 13.4892H19.2187C19.699 13.4892 20.0883 13.0999 20.0883 12.6196V0.784848C20.0883 0.304581 19.699 -0.0847168 19.2187 -0.0847168Z"
          fill="currentColor"
        />
        <path
          d="M13.9674 14.2816C13.7887 14.1217 13.5144 14.1355 13.3535 14.3147C13.193 14.4935 13.2078 14.7682 13.3866 14.9287C14.0396 15.515 14.3848 16.2078 14.3848 16.9323C14.3848 17.6531 14.043 18.3426 13.3968 18.9264C13.2185 19.0873 13.2045 19.3623 13.3654 19.5406C13.4511 19.6355 13.5696 19.6839 13.6881 19.6839C13.7921 19.6839 13.8965 19.647 13.9793 19.5718C14.8137 18.8184 15.2544 17.9057 15.2544 16.9323C15.2544 15.9541 14.8094 15.0374 13.9674 14.2816Z"
          fill="currentColor"
        />
        <path
          d="M0.783628 16.9322C0.783628 16.1443 1.18699 15.4019 1.95041 14.7852C2.1368 14.6343 2.1661 14.3606 2.01495 14.1738C1.86464 13.987 1.5912 13.9579 1.40353 14.1088C0.429093 14.8965 -0.0859375 15.8726 -0.0859375 16.9322C-0.0859375 18.1017 0.563264 19.194 1.74193 20.0077C1.81751 20.0599 1.90328 20.0848 1.98862 20.0848C2.12661 20.0848 2.26248 20.0192 2.34698 19.8971C2.48327 19.6994 2.43359 19.4286 2.23616 19.2923C1.57337 18.8348 0.783628 18.0539 0.783628 16.9322Z"
          fill="currentColor"
        />
        <path
          d="M11.4055 15.3283L11.4012 15.824C11.4012 16.0631 11.2099 16.2588 10.9664 16.2588C10.7273 16.2588 10.5316 16.0631 10.5316 15.824L10.5359 14.6458V13.5327C10.5359 13.524 10.5316 13.5197 10.5316 13.511C10.5316 13.5023 10.5359 13.4979 10.5359 13.4892V13.4762L10.5403 12.7153C10.5403 12.3849 10.6446 12.0805 10.8186 11.8327C11.0055 11.5588 11.2794 11.3458 11.6142 11.2458C12.4142 10.9936 13.1403 10.5327 13.7055 9.90662C14.2707 9.28489 14.6446 8.53705 14.8185 7.73706H8.00116C6.67072 7.73706 5.48813 8.36315 4.72291 9.33706C4.55333 9.55445 4.40552 9.78922 4.27942 10.0414C4.00115 10.5979 3.84464 11.2284 3.84464 11.8936V16.2284C3.84464 16.4675 3.64898 16.6631 3.40985 16.6631C3.17073 16.6631 2.97507 16.4675 2.97507 16.2284V15.6544C2.47941 16.0457 2.20117 16.4936 2.20117 16.9327C2.20117 18.2936 4.60115 19.4414 7.43595 19.4414C10.2707 19.4414 12.6664 18.2936 12.6664 16.9327C12.6664 16.3631 12.2012 15.7892 11.4055 15.3283ZM6.30552 16.9762C6.23594 17.1458 6.07507 17.2458 5.9055 17.2458C5.84898 17.2458 5.78813 17.2327 5.73596 17.211C5.51421 17.1153 5.40987 16.8631 5.50116 16.6414C5.85768 15.8023 5.50986 14.9588 5.50551 14.9501C5.40987 14.7327 5.51421 14.4762 5.7316 14.3805C5.95335 14.2849 6.2055 14.3849 6.30117 14.6066C6.3229 14.6544 6.8055 15.7849 6.30552 16.9762ZM9.31856 16.6153C9.42726 16.8284 9.3403 17.0892 9.1229 17.1979C9.06203 17.2284 8.99683 17.2458 8.9316 17.2458C8.77073 17.2458 8.61857 17.1544 8.54028 17.0023C8.02724 15.9762 8.50117 14.6805 8.5229 14.6284C8.60551 14.4023 8.85769 14.2892 9.08376 14.3718C9.30551 14.4588 9.4229 14.7066 9.33594 14.9327C9.33159 14.9414 8.97507 15.9283 9.31856 16.6153Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35545">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SewerageIcon;
