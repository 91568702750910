type Props = {
  className?: string;
};
const GardenIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35352)">
        <path
          d="M23.5912 23.7074C19.545 20.2635 14.0891 18.9603 8.97181 19.7888V15.8793C8.97181 15.7757 8.88786 15.6918 8.78431 15.6918H6.15931C6.05577 15.6918 5.97181 15.7757 5.97181 15.8793V20.5386C3.94204 21.2322 2.02785 22.2845 0.356148 23.7074C0.223488 23.8204 0.307533 24.0432 0.481758 24.0432H23.4656C23.6398 24.0432 23.7239 23.8204 23.5912 23.7074Z"
          fill="currentColor"
        />
        <path
          d="M20.2461 7.45679C22.3172 7.45679 23.9961 5.77785 23.9961 3.70679C23.9961 1.63572 22.3172 -0.0432129 20.2461 -0.0432129C18.175 -0.0432129 16.4961 1.63572 16.4961 3.70679C16.4961 5.77785 18.175 7.45679 20.2461 7.45679Z"
          fill="currentColor"
        />
        <path
          d="M0.00390625 11.1918C0.00390625 12.8494 1.3539 14.1918 3.00391 14.1918H12.0039C13.6614 14.1918 15.0039 12.8494 15.0039 11.1918C15.0039 9.88685 14.1789 8.78433 13.0164 8.37183C13.3239 7.89933 13.5039 7.33683 13.5039 6.72183C13.5039 5.07933 12.1839 3.74435 10.5414 3.72183V3.66187C10.5414 2.00435 9.19141 0.661865 7.5414 0.661865C5.88388 0.661865 4.5414 2.00435 4.5414 3.66187V3.69185H4.47388C2.81641 3.69185 1.47388 5.03434 1.47388 6.69185C1.47388 7.31437 1.66138 7.89184 1.98387 8.37184C0.828891 8.79184 0.00390625 9.89436 0.00390625 11.1918ZM10.5039 6.73684C10.7289 6.38437 11.1939 6.28686 11.5389 6.51185C11.8914 6.74435 11.9889 7.20934 11.7564 7.55436C11.5089 7.94433 11.4489 8.41683 11.5989 8.85184C11.7414 9.28686 12.0789 9.63183 12.5139 9.78184C12.9039 9.92434 13.1064 10.3518 12.9714 10.7419C12.8589 11.0493 12.5739 11.2443 12.2664 11.2443C12.1764 11.2443 12.0939 11.2293 12.0114 11.1993C11.1414 10.8918 10.4739 10.2093 10.1739 9.33185C9.88139 8.45433 9.99391 7.50937 10.5039 6.73684ZM4.47388 5.19184C5.32138 5.19184 6.0414 4.50186 6.0414 3.69184C6.0414 3.27935 6.37891 2.94184 6.7914 2.94184C7.20389 2.94184 7.5414 3.27935 7.5414 3.69184C7.5414 5.31933 6.1389 6.69184 4.47388 6.69184C4.06138 6.69184 3.72388 6.35433 3.72388 5.94184C3.72388 5.52935 4.06138 5.19184 4.47388 5.19184Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35352">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GardenIcon;
