import React from "react";
import { Typo } from "../../../components/shared/typo";
import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import SendIcon from "../../../components/icons/SendIcon";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useHandelSubscribe } from "../../../hooks/subscribe/useHandelSubscribe";
import { handelInputMap } from "../../../util/handelInputMap";
type Props = {
  className?: string;
  intl: IntlShape;
};

const ArticleSubscribe: React.FC<Props> = (props) => {
  const { intl } = props;
  const { onSubmit, handleSubmit, errors, register } = useHandelSubscribe();

  return (
    <div className="bg-white">
      <div className="flex relative  py-6 px-4 bg-primary-50 w-full max-w-6xl mx-auto rounded-none md:rounded-[20px]">
        <div className="mx-auto max-w-2xl ">
          <div className="space-y-4">
            <Typo.headingC
              fontWeight="font-bold"
              className=" text-secondary-500 text-start"
            >
              <FormattedMessage
                id={TRANSLATION.NEWS_LETTER.id}
                defaultMessage={TRANSLATION.NEWS_LETTER.defaultMessage}
              />
            </Typo.headingC>
            <Typo.heading4 className=" text-gray-800 text-justify">
              <FormattedMessage
                id={TRANSLATION.NEWS_LETTER_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.NEWS_LETTER_DESCRIPTION.defaultMessage
                }
              />
            </Typo.heading4>
          </div>

          <form
            id="login"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            className="mx-4 sm:mx-0"
          >
            <div className="w-full relative mt-12 space-y-8">
              <InputField
                name="email"
                type="text"
                inputStyle="rounded-xl text-[19px]  border shadow-xs focus:ring-0 focus:border-none"
                placeholder={handelInputMap(intl, "email_placeholder")}
                register={register}
                errors={errors.email}
                required={true}
              />
              <Button
                className="end-[14px]"
                size="medium"
                type="submit"
                theme="secandory"
              >
                <Typo.heading4 fontWeight="font-medium" className="text-white ">
                  <FormattedMessage
                    id={TRANSLATION.SEND.id}
                    defaultMessage={TRANSLATION.SEND.defaultMessage}
                  />
                </Typo.heading4>
              </Button>
              <Button
                className="absolute sm:hidden top-[14px] end-[14px]"
                size="medium"
                rounded="rounded-xl"
                type="submit"
                theme="secandory"
                icon={<SendIcon className="h-6" />}
              ></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ArticleSubscribe;
