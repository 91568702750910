import React, { useRef } from "react";
import { Typo } from "../../../components/shared/typo";
import Button from "../../../components/shared/button";
import ChevronIcon from "../../../components/icons/ChevronIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ROUTES } from "../../../constants/routes";
import AgencyCard from "../../../components/shared/Card/AgencyCard";
import { AgencyObject } from "../../../model/Agency";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { DIRECTIONS } from "../../../static/enum";
import clsx from "clsx";
type Props = {
  className?: any;
  agencies: AgencyObject[];
  direction: string;
  locale: string;
};

const PopularAgencies: React.FC<Props> = (props) => {
  const { className, agencies, direction,locale } = props;

  const popularListing = useRef<any>(null);
  const goNext = () => {
    if (popularListing?.current) {
      popularListing?.current?.swiper?.slideNext();
    }
  };

  const goPrev = () => {
    if (popularListing?.current) {
      popularListing?.current?.swiper?.slidePrev();
    }
  };

  return (
    <div className="bg-gray-50 pt-14 pb-4 sm:pb-8 md:pb-12 lg:pb-20">
      <div className="max-w-7xl mx-auto px-0 md:px-6 lg:px-8 ">
        <Typo.headingB
          fontWeight="font-black"
          className="text-center text-primary-600"
        >
          <FormattedMessage
            id={TRANSLATION.TOP_AGENCIES.id}
            defaultMessage={TRANSLATION.TOP_AGENCIES.defaultMessage}
          />
        </Typo.headingB>
        <Typo.body4 className="text-gray-500 max-w-2xl text-center mx-5 md:mx-auto mt-4">
          <FormattedMessage
            id={TRANSLATION.TOP_AGENCIES_DESCRIPTION.id}
            defaultMessage={TRANSLATION.TOP_AGENCIES_DESCRIPTION.defaultMessage}
          />
        </Typo.body4>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-4">
              <Button
                // theme="gray"
                size="medium"
                onClick={goPrev}
                icon={
                  <ChevronIcon
                    className={clsx(
                      " text-secondary-500",
                      direction === DIRECTIONS.LTR ? "rotate-90" : "-rotate-90"
                    )}
                  />
                }
              ></Button>
              <Button
                size="medium"
                onClick={goNext}
                icon={
                  <ChevronIcon
                    className={clsx(
                      "text-secondary-500",
                      direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                    )}
                  />
                }
              ></Button>
            </div>
            <div>
              <Button
                size="medium"
                iconPos="right"
                theme="gray"
                to={ROUTES.AGENCIES_ROUTE}
                icon={
                  <ChevronIcon
                    className={
                      direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                    }
                  />
                }
              >
                <Typo.heading4 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.VIEW_ALL.id}
                    defaultMessage={TRANSLATION.VIEW_ALL.defaultMessage}
                  />
                </Typo.heading4>
              </Button>
            </div>
          </div>
          <div>
            <Swiper
              ref={popularListing}
              spaceBetween={24}
              slidesPerView={1}
              breakpoints={{
                // specify different values for different screen widths
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
              }}
              scrollbar={{ draggable: true }}
            >
              {agencies?.map((item: any) => (
                <SwiperSlide key={item.id} className="w-full">
                  <AgencyCard agency={item} locale={locale} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularAgencies;
