import HeartIcon from "../../icons/HeartIcon";
import LocationPinIcon from "../../icons/LocationPinIcon";
import HeartFillIcon from "../../icons/HeartFillIcon";
import { useDispatch } from "react-redux";
import Button from "../button";
import { ListingObject } from "../../../model/Listing";
import { User } from "../../../model/User";
import { showModal } from "../../../lib/reducers/modal.slice";
import ShareButton from "../ShareButton";
type Props = {
  className?: string;
  listing?: ListingObject;
  user?: User | null;
  onLike?: (listingId: number) => void;
  onDisLike?: (listingId: number) => void;
};

const ListingCardInteraction: React.FC<Props> = (props) => {
  const { listing, user, onLike, onDisLike } = props;
  const dispatch = useDispatch();

  const addFavorite = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (user?.id) {
      onLike && onLike(Number(listing?.id));
    } else {
      dispatch(showModal("login"));
    }
  };

  const removeFavorite = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (user?.id) {
      onDisLike && onDisLike(Number(listing?.id));
    } else {
      dispatch(showModal("login"));
    }
  };

  const onDirect = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const mapUrl = `http://maps.google.com/?q=${listing?.location.latitude},${listing?.location.longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <div className="absolute top-2 right-2 flex flex-col justify-between text-gray-900 gap-y-2 bg-white/[0.62] rounded cursor-pointer px-2 py-4">
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          user && !listing?.savedListings
            ? addFavorite(event)
            : removeFavorite(event);
        }}
      >
        {user && listing?.savedListings === true ? (
          <HeartFillIcon className="text-error-200" />
        ) : (
          <HeartIcon className="text-gray-900" />
        )}
      </Button>

      <ShareButton listing={listing} />

      <Button
        target="_blank"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          onDirect(event)
        }
      >
        <LocationPinIcon className="h-[20px] w-[20px] text-gray-900" />
      </Button>
    </div>
  );
};

export default ListingCardInteraction;
