import clsx from "clsx";
import React from "react";
import Image from "../Image";
import { User } from "../../../model/User";
import { ListingObject } from "../../../model/Listing";
import ListingCardInteraction from "./ListingCardInteraction";
import ListingFileNumber from "./ListingFileNumber";
import CalenderIcon from "../../icons/CalenderIcon";
import { Typo } from "../typo";
import { timeAgo } from "../../../util/appUtil";
import { IntlShape } from "react-intl";
type Props = {
  user?: User | null;
  listing?: ListingObject;
  showInteration?: boolean;
  className?: string;
  type?: "sample" | "feature";
  intl: IntlShape;
  onLike?: (listingId: number) => void;
  onDisLike?: (listingId: number) => void;
};

const ImageThumbnail: React.FC<Props> = (props) => {
  const {
    user,
    listing,
    showInteration = false,
    type = "sample",
    intl,
    onLike,
    onDisLike,
  } = props;

  return (
    <div className="relative flex-col-1">
      <Image
        className={clsx("w-full rounded-lg h-48 ")}
        src={listing?.thumbnail}
        alt="image not found"
      ></Image>

      {showInteration && user?.id !== listing?.user?.id && (
        <>
          {type === "sample" ? (
            <div className="flex absolute gap-x-2 top-2 start-2 ">
              <div className="flex flex-row gap-x-1 items-center bg-white/[0.70] backdrop-blur-[2px] rounded-3xl text-gray-900 px-2 py-1.5">
                <CalenderIcon className="h-4 w-4" />
                <Typo.heading5 fontWeight="font-medium">
                  {listing?.createdAt
                    ? timeAgo(listing?.updatedAt.toString(), intl)
                    : ""}
                </Typo.heading5>
              </div>
            </div>
          ) : (
            <ListingCardInteraction
              onLike={onLike}
              onDisLike={onDisLike}
              user={user}
              listing={listing}
            />
          )}
        </>
      )}
      <div className="flex absolute gap-x-2 bottom-2 left-2 ">
        <ListingFileNumber
          videoCount={listing?.videoCount}
          imageCount={listing?.imageCount}
        />
      </div>
    </div>
  );
};

export default ImageThumbnail;
