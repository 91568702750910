type Props = {
  className?: string;
};
const AddCallIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0837 4.37501H15.6253V2.91667C15.6253 2.57501 15.342 2.29167 15.0003 2.29167C14.6587 2.29167 14.3753 2.57501 14.3753 2.91667V4.37501H12.917C12.5753 4.37501 12.292 4.65834 12.292 5.00001C12.292 5.34167 12.5753 5.62501 12.917 5.62501H14.3753V7.08334C14.3753 7.42501 14.6587 7.70834 15.0003 7.70834C15.342 7.70834 15.6253 7.42501 15.6253 7.08334V5.62501H17.0837C17.4253 5.62501 17.7087 5.34167 17.7087 5.00001C17.7087 4.65834 17.4253 4.37501 17.0837 4.37501Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.4"
        d="M9.82533 11.8417L7.10033 14.5667C6.80033 14.3 6.50866 14.025 6.22533 13.7417C5.36699 12.875 4.59199 11.9667 3.90033 11.0167C3.21699 10.0667 2.66699 9.11667 2.26699 8.17501C1.86699 7.22501 1.66699 6.31667 1.66699 5.45C1.66699 4.88334 1.76699 4.34167 1.96699 3.84167C2.16699 3.33334 2.48366 2.86667 2.92533 2.45001C3.45866 1.92501 4.04199 1.66667 4.65866 1.66667C4.89199 1.66667 5.12533 1.71667 5.33366 1.81667C5.55033 1.91667 5.74199 2.06667 5.89199 2.28334L7.82533 5.00834C7.97533 5.21667 8.08366 5.40834 8.15866 5.59167C8.23366 5.76667 8.27533 5.94167 8.27533 6.10001C8.27533 6.30001 8.21699 6.5 8.10033 6.69167C7.99199 6.88334 7.83366 7.08334 7.63366 7.28334L7.00033 7.94167C6.90866 8.03334 6.86699 8.14167 6.86699 8.27501C6.86699 8.34167 6.87533 8.4 6.89199 8.46667C6.91699 8.53334 6.94199 8.58334 6.95866 8.63334C7.10866 8.90834 7.36699 9.26667 7.73366 9.70001C8.10866 10.1333 8.50866 10.575 8.94199 11.0167C9.24199 11.3083 9.53366 11.5917 9.82533 11.8417Z"
        fill="#BDBDBD"
      />
      <path
        d="M18.3083 15.275C18.3083 15.5084 18.2667 15.75 18.1833 15.9834C18.1583 16.05 18.1333 16.1167 18.1 16.1834C17.9583 16.4834 17.775 16.7667 17.5333 17.0334C17.125 17.4834 16.675 17.8084 16.1667 18.0167C16.1583 18.0167 16.15 18.025 16.1417 18.025C15.65 18.225 15.1167 18.3334 14.5417 18.3334C13.6917 18.3334 12.7833 18.1334 11.825 17.725C10.8667 17.3167 9.90833 16.7667 8.95833 16.075C8.63333 15.8334 8.30833 15.5917 8 15.3334L10.725 12.6084C10.9583 12.7834 11.1667 12.9167 11.3417 13.0084C11.3833 13.025 11.4333 13.05 11.4917 13.075C11.5583 13.1 11.625 13.1084 11.7 13.1084C11.8417 13.1084 11.95 13.0584 12.0417 12.9667L12.675 12.3417C12.8833 12.1334 13.0833 11.975 13.275 11.875C13.4667 11.7584 13.6583 11.7 13.8667 11.7C14.025 11.7 14.1917 11.7334 14.375 11.8084C14.5583 11.8834 14.75 11.9917 14.9583 12.1334L17.7167 14.0917C17.9333 14.2417 18.0833 14.4167 18.175 14.625C18.2583 14.8334 18.3083 15.0417 18.3083 15.275Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default AddCallIcon;
