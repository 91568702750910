type props = {
  className?: string;
};
const UserIcon = ({ className }: props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2582_23572)">
        <path
          d="M12 12.5734C15.4662 12.5734 18.2863 9.75335 18.2863 6.28669C18.2863 2.82003 15.4662 0 12 0C8.53292 0 5.71289 2.82003 5.71289 6.28669C5.71289 9.75335 8.53292 12.5734 12 12.5734Z"
          fill="currentColor"
        />
        <path
          d="M3.68638 23.9999H20.3136C22.2045 23.9999 23.7422 22.4618 23.7422 20.5714C23.7422 17.2566 21.0452 14.5601 17.7305 14.5601H6.26953C2.9548 14.5601 0.257812 17.2566 0.257812 20.5714C0.257813 22.4618 1.79548 23.9999 3.68638 23.9999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2582_23572">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
