import React from "react";
import Button from "./button";
import { Typo } from "./typo";
import VideoAddIcon from "../icons/VideoAddIcon";
import CloseCircleIcon from "../icons/CloseCircleIcon";
import PlusIcon from "../icons/PlusIcon";
import VideoInput from "./VideoInput";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import Divider from "./Divider";
import TickCircleIcon from "../icons/TickCircleIcon";

type ImageProps = {
  register: (name: any, registerOptions: any) => any;
  errors: any;
  videos?: string[];
  setVideos: (values: string[]) => void;
  setVideoValue: (values: string[]) => void;
  className?: string;
  maxVideos?: number;
};

const VideoUploader: React.FC<ImageProps> = (props) => {
  const {
    register,
    errors,
    videos = [],
    className,
    maxVideos = 2,
    setVideos,
    setVideoValue,
  } = props;

  // const [files, setFiles] = useState<string[]>(videos || []); // Use videos as the initial value

  // Define the onAdd function
  const onAdd = () => {
    const tempVideos = [...videos, ""];
    setVideos(tempVideos);
    setVideoValue(tempVideos || []);
  };

  // Define the onDelete function
  const onDelete = (indexId: number) => {
    // Filter out the file with the given id from the files array
    const newFiles = videos.filter((file, index) => index !== indexId);
    // Update the files array with the new files
    setVideos(newFiles);
    setVideoValue(newFiles);
  };

  const onChange = (value: string, index: number) => {
    const videoData = videos.map((item, i) => (i === index ? value : item));
    setVideos(videoData);
    setVideoValue(videoData);
  };

  return (
    <div
      className={`w-full p-8 border border-dashed border-primary-600 rounded-lg ${className}`}
    >
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row  gap-y-8 gap-x-12 items-center">
          <div className="flex flex-col items-center">
            <VideoAddIcon className="w-20 h-20 sm:h-12 sm:w-12" />
            <Button
              rounded="rounded-lg"
              theme="primary"
              size="sxtra-small"
              className="mt-3 w-full sm:w-auto"
              disabled={videos?.length >= maxVideos}
              onClick={onAdd}
              icon={<PlusIcon className="h-4 w-4" />}
            >
              <Typo.heading3 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.ADD_VIDEO.id}
                  defaultMessage={TRANSLATION.ADD_VIDEO.defaultMessage}
                />
              </Typo.heading3>
            </Button>
          </div>

          <div>
            <div className="space-y-3">
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.VIDEO_NOTE1.id}
                    defaultMessage={TRANSLATION.VIDEO_NOTE1.defaultMessage}
                  />
                </Typo.body5>
              </div>
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.VIDEO_NOTE2.id}
                    defaultMessage={TRANSLATION.VIDEO_NOTE2.defaultMessage}
                  />
                </Typo.body5>
              </div>
              <div className="text-gray-500 flex gap-x-2 items-center">
                <TickCircleIcon className="h-5 w-5" />
                <Typo.body5>
                  <FormattedMessage
                    id={TRANSLATION.VIDEO_NOTE3.id}
                    defaultMessage={TRANSLATION.VIDEO_NOTE3.defaultMessage}
                  />
                </Typo.body5>
              </div>
            </div>
          </div>
        </div>
        {videos?.length > 0 && <Divider type="horizontal" className="my-8" />}
        <div className="w-full h-full space-y-3">
          {videos?.map((url, index) => {
            return (
              <div
                key={index}
                className="py-2 space-y-2 px-6 bg-gray-50 rounded-lg"
              >
                <div className="flex gap-x-2 items-center">
                  <Button
                    onClick={() => onDelete(index)}
                    icon={
                      <CloseCircleIcon className="text-error-300 h-4 w-4" />
                    }
                  ></Button>
                  <Typo.body5
                    className="text-gray-900"
                    fontWeight="font-medium"
                  >
                    <FormattedMessage
                      id={TRANSLATION.VIDEO_LINK.id}
                      defaultMessage={TRANSLATION.VIDEO_LINK.defaultMessage}
                    />
                  </Typo.body5>
                </div>
                <div dir="ltr">
                  <VideoInput
                    register={register}
                    defaultValue={url}
                    onKeyUp={(event: any) =>
                      onChange(event.target.value, index)
                    }
                    errors={errors.videos && errors.videos[index]}
                    name={`videos.${index}`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VideoUploader;
