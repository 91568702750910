type Props = {
  className?: string;
};
const DocumentTextIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.5 5.83332V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V5.83332C2.5 3.33332 3.75 1.66666 6.66667 1.66666H13.3333C16.25 1.66666 17.5 3.33332 17.5 5.83332Z"
        fill="#E0E0E0"
      />
      <path
        d="M15.4163 7.70833H13.7497C12.483 7.70833 11.458 6.68333 11.458 5.41667V3.75C11.458 3.40833 11.7413 3.125 12.083 3.125C12.4247 3.125 12.708 3.40833 12.708 3.75V5.41667C12.708 5.99167 13.1747 6.45833 13.7497 6.45833H15.4163C15.758 6.45833 16.0413 6.74167 16.0413 7.08333C16.0413 7.425 15.758 7.70833 15.4163 7.70833Z"
        fill="#BDBDBD"
      />
      <path
        d="M10.0003 11.4583H6.66699C6.32533 11.4583 6.04199 11.175 6.04199 10.8333C6.04199 10.4917 6.32533 10.2083 6.66699 10.2083H10.0003C10.342 10.2083 10.6253 10.4917 10.6253 10.8333C10.6253 11.175 10.342 11.4583 10.0003 11.4583Z"
        fill="#BDBDBD"
      />
      <path
        d="M13.3337 14.7917H6.66699C6.32533 14.7917 6.04199 14.5083 6.04199 14.1667C6.04199 13.825 6.32533 13.5417 6.66699 13.5417H13.3337C13.6753 13.5417 13.9587 13.825 13.9587 14.1667C13.9587 14.5083 13.6753 14.7917 13.3337 14.7917Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default DocumentTextIcon;
