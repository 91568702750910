// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FC, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "./Image";
import Button from "./button";
import ChevronIcon from "../icons/ChevronIcon";
import { File } from "../../model/File";
import Video from "./Video";
import clsx from "clsx";
import { DIRECTIONS } from "../../static/enum";
import { Pagination } from "swiper/modules";
// Import Swiper core and required modules

type Props = {
  className?: string;
  files: File[];
  direction: string;
};

const ListingImageSlider: FC<Props> = ({ className, files, direction }) => {
  const fileSliderRef = useRef<any>(null);
  const goNext = () => {
    if (fileSliderRef?.current) {
      fileSliderRef?.current?.swiper?.slideNext();
    }
  };

  const goPrev = () => {
    if (fileSliderRef?.current) {
      fileSliderRef?.current?.swiper?.slidePrev();
    }
  };

  return (
    <div className={clsx("relative h-full ", className)}>
      <Swiper
        ref={fileSliderRef}
        slidesPerView={1}
        centeredSlides={true}
        centeredSlidesBounds={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
      >
        {files?.map((file, index) => (
          <SwiperSlide key={index} className="w-full h-full">
            {file.isImage ? (
              <Image
                src={file.url}
                className="max-h-[500px] h-full w-full rounded-xl"
                alt={`Slide ${index + 1}`}
              ></Image>
            ) : (
              <Video
                url={file.url}
                className="max-h-[500px] h-full w-full rounded-xl"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {files?.length > 1 && (
        <div className="absolute -start-5 top-[calc((100%-40px)/2)] z-10">
          <Button
            size="sxtra-small"
            className="h-10 w-10 rounded-xl bg-white shadow-shadow2 "
            onClick={goPrev}
            icon={
              <ChevronIcon
                className={clsx(
                  "text-gray-800 h-6 ",
                  direction === DIRECTIONS.LTR ? " rotate-90" : " -rotate-90"
                )}
              />
            }
          ></Button>
        </div>
      )}

      {files?.length > 1 && (
        <div className="absolute -end-5 top-[calc((100%-40px)/2)] z-10">
          <Button
            size="sxtra-small"
            className="h-10 w-10 rounded-xl bg-white shadow-shadow2 "
            onClick={goNext}
            icon={
              <ChevronIcon
                className={clsx(
                  "text-gray-800 h-6 ",
                  direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                )}
              />
            }
          ></Button>
        </div>
      )}
    </div>
  );
};

export default ListingImageSlider;
