export enum ListingState {
  PENDING = "pending",
  ACTIVE = "active",
  EXPIRED = "expired",
  INACTIVE = "inactive",
  REJECTED = "rejected",
  DELETED = "deleted",
  EDITED = "edited",
}

export enum LANGUAGES {
  DARI = "fa",
  PASHTO = "ps",
  ENGLISH = "en",
}

export enum DIRECTIONS {
  RTL = "rtl",
  LTR = "ltr",
}


export enum NOTIFICATION_STATES {
  CREATED = "created",
  DELIVERD = "delivered",
  READ = 'read'
}