type Props = {
  className?: string;
};
const FormatSquareIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1619_37446)">
        <path
          d="M9.15039 7.49023C8.59039 7.49023 8.15039 7.94023 8.15039 8.49023C8.15039 9.04023 8.60039 9.49023 9.15039 9.49023C9.70039 9.49023 10.1504 9.04023 10.1504 8.49023C10.1504 7.94023 9.70039 7.49023 9.15039 7.49023Z"
          fill="currentColor"
        />
        <path
          d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z"
          fill="currentColor"
        />
        <path
          d="M19.4709 20.53C19.6909 20.75 19.6609 21.11 19.3909 21.26C18.5109 21.75 17.4409 22 16.1909 22H7.81087C7.52087 22 7.40087 21.66 7.60087 21.46L13.6409 15.42C13.8409 15.22 14.1509 15.22 14.3509 15.42L19.4709 20.53Z"
          fill="currentColor"
        />
        <path
          d="M21.9998 7.8099V16.1899C21.9998 17.4399 21.7498 18.5199 21.2598 19.3899C21.1098 19.6599 20.7498 19.6799 20.5298 19.4699L15.4098 14.3499C15.2098 14.1499 15.2098 13.8399 15.4098 13.6399L21.4498 7.5999C21.6598 7.3999 21.9998 7.5199 21.9998 7.8099Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1619_37446">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FormatSquareIcon;
