import Image from "../../../components/shared/Image";
import noListingFound from "../../../assets/image/no-listing-found.svg";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
type Props = {
  title?: MessageDescriptor;
  className?: string;
};

const NoListingFound: React.FC<Props> = (props) => {
  const { title, className } = props;
  return (
    <div
      className={`flex flex-col w-full gap-y-14 items-center justify-center ${className}`}
    >
      <div className="pt-10">
        <Image
          src={noListingFound}
          alt="no listing found"
          className=" mx-auto h-48 sm:h-60"
        />
      </div>
      <Typo.body2 className="text-gray-900 text-center w-full">
        {title ? (
          <FormattedMessage
            id={title.id}
            defaultMessage={title.defaultMessage}
          />
        ) : (
          <FormattedMessage
            id={TRANSLATION.NOT_FOUND_LISTING_MESSAGE.id}
            defaultMessage={
              TRANSLATION.NOT_FOUND_LISTING_MESSAGE.defaultMessage
            }
          />
        )}
      </Typo.body2>
    </div>
  );
};

export default NoListingFound;
