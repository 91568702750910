type Props = {
  className?: string;
};
const FacebookCircleIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 8.02939C16.5 3.59492 12.9182 0 8.5 0C4.08176 0 0.5 3.59492 0.5 8.02939C0.5 11.7948 3.08304 14.9546 6.56752 15.8224V10.4832H4.91792V8.02939H6.56752V6.97208C6.56752 4.2392 7.79984 2.97248 10.4731 2.97248C10.98 2.97248 11.8546 3.07236 12.2123 3.17193V5.39607C12.0235 5.37616 11.6955 5.3662 11.2882 5.3662C9.97648 5.3662 9.4696 5.86499 9.4696 7.16157V8.02939H12.0827L11.6338 10.4832H9.4696V16C13.4302 15.5198 16.5 12.1347 16.5 8.02939Z"
        fill="#0966FF"
      />
    </svg>
  );
};

export default FacebookCircleIcon;
