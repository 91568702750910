import { createSlice } from "@reduxjs/toolkit";

const shareSlice = createSlice({
  name: "share",
  initialState: {
    shareData: {
      state: false,
      title: "",
      description: "",
      link: "",
      image: "",
    },
    // Add more modal states as needed
  },
  reducers: {
    setShareData: (state, action) => {
      state.shareData = action.payload;
    },
    hideShare: (state) => {
      state.shareData.state = false;
    },
  },
});

export const { setShareData, hideShare } = shareSlice.actions;
export default shareSlice.reducer;
