import { CreateUserProp, User } from "../../../model/User";
import InputField from "../../../components/shared/InputField";
import { useEffect, useState } from "react";
import NextPrevButtons from "./NextPrevButtons";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import SingleImageUploader from "../../../components/shared/SingleImageUploader";
import { handelInputMap } from "../../../util/handelInputMap";
import { useIntl } from "react-intl";
type Props = {
  className?: string;
  disabled?: boolean;
  user: User | null;
  role: number;
  setRole: (role: number) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  onPrev: () => void;
  handleSubmit: UseFormHandleSubmit<any, undefined>;
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  reset: UseFormReset<any>;
  onUpdateProfile: (user: CreateUserProp) => Promise<void>;
  loading: boolean;
  setValue: UseFormSetValue<any>;
};

const UserAccountDetails: React.FC<Props> = (props) => {
  const {
    currentStep,
    user,
    onPrev,
    handleSubmit,
    errors,
    register,
    onUpdateProfile,
    loading,
    setValue,
  } = props;
  const intl = useIntl();
  const [image, setImage] = useState("");
  const onUpdate = async (data: any) => {
    try {
      const newData = {
        completed: true,
        ...(image && { image: image }),
        ...data,
      };
      await onUpdateProfile(newData);
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      setValue("name", user?.name);
      setValue("phone", user?.phone);
      setValue("whatsapp", user?.whatsapp);
      setImage(user?.image || "");
      if (user?.agency) {
        setValue("agencyName", user?.agency?.name);
        setValue("landline", user?.agency?.landline);
      }
    }
  }, [user]);

  return (
    <div className="flex justify-between">
      <div className="w-full">
        <form
          id="signup"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onUpdate)}
        >
          <div>
            <div className="w-full my-5 flex items-center justify-center">
              <SingleImageUploader
                image={image}
                className="mx-auto"
                onChange={setImage}
                title={""}
                description={""}
              />
            </div>
            <div className="w-full mt-5">
              <InputField
                name="name"
                key={`name`}
                placeholder={handelInputMap(intl, "name_placeholder")}
                type="text"
                label={handelInputMap(intl, "name")}
                register={register}
                errors={errors?.name}
                required={true}
              />
            </div>
            <div className="w-full mt-4">
              <InputField
                name="phone"
                type="text"
                label={handelInputMap(intl, "phone")}
                placeholder={handelInputMap(intl, "phone_placeholder")}
                defaultValue={user?.phone || ""}
                register={register}
                errors={errors?.phone}
                required={true}
              />
            </div>
            <div className="w-full mt-4">
              <InputField
                name="whatsapp"
                type="text"
                label={handelInputMap(intl, "whatsapp")}
                placeholder={handelInputMap(intl, "whatsapp_placeholder")}
                defaultValue={user?.whatsapp || ""}
                register={register}
                errors={errors?.whatsapp}
                required={false}
              />
            </div>

            {user?.roleId === 2 && (
              <div>
                <div className="w-full mt-5">
                  <InputField
                    name="agencyName"
                    label={handelInputMap(intl, "agencyName")}
                    placeholder={handelInputMap(intl, "agencyName_placeholder")}
                    type="text"
                    register={register}
                    errors={errors?.agencyName}
                    required={true}
                  />
                </div>
              </div>
            )}
            <div className="mt-10">
              <NextPrevButtons
                loading={loading}
                currentStep={currentStep}
                onPrev={onPrev}
                btnType="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserAccountDetails;
