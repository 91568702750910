import React, { ReactElement } from "react";
import { Typo } from "./typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props = {
  title?: MessageDescriptor;
  icon?: ReactElement;
  children?: string | number | ReactElement;
};
const ListingDetailsLabel: React.FC<Props> = (props) => {
  const { title, icon, children } = props;
  return (
    <div className="flex gap-x-3 items-center">
      {icon && <span className="text-primary-600">{icon}</span>}
      <Typo.body3 className="text-gray-600 flex gap-x-1 items-center">
        <>
          <FormattedMessage
            id={title?.id}
            defaultMessage={title?.defaultMessage}
          />
          {children && ":"}
        </>
        {children && (
          // Use the map method to render each key-value pair as a separate element
          <span className="font-medium text-gray-800">{children}</span>
        )}
      </Typo.body3>
    </div>
  );
};

export default ListingDetailsLabel;
