type Props = {
  className?: string;
};
const MosqueIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35494)">
        <path
          d="M16.025 13.2307C15.6205 13.2307 15.2327 13.3691 14.9467 13.6155C14.6607 13.8619 14.5 14.1961 14.5 14.5446V19.9999H17.55V14.5446C17.5491 14.1964 17.3881 13.8626 17.1023 13.6164C16.8165 13.3702 16.4292 13.2315 16.025 13.2307Z"
          fill="currentColor"
        />
        <path
          d="M10.0016 13.2307C9.5974 13.2315 9.21005 13.3702 8.92427 13.6164C8.63848 13.8626 8.47751 14.1964 8.47656 14.5446V19.9999H11.5266V14.5446C11.5256 14.1964 11.3646 13.8626 11.0789 13.6164C10.7931 13.3702 10.4057 13.2315 10.0016 13.2307Z"
          fill="currentColor"
        />
        <path
          d="M3.97617 13.2307C3.57201 13.2315 3.18466 13.3702 2.89888 13.6164C2.61309 13.8626 2.45212 14.1964 2.45117 14.5446V19.9999H5.50117V14.5446C5.50117 14.372 5.46173 14.2012 5.38509 14.0418C5.30845 13.8824 5.19612 13.7375 5.05451 13.6155C4.9129 13.4935 4.74478 13.3968 4.55976 13.3307C4.37474 13.2647 4.17644 13.2307 3.97617 13.2307Z"
          fill="currentColor"
        />
        <path
          d="M18.5293 8.61548H1.47215C1.05662 8.61629 0.65838 8.75887 0.364554 9.01201C0.0707277 9.26515 -0.0947598 9.60825 -0.0957031 9.96625V20.0001H1.3793V14.5447C1.3793 13.9514 1.65285 13.3825 2.13977 12.963C2.6267 12.5435 3.28711 12.3078 3.97573 12.3078C4.66434 12.3078 5.32475 12.5435 5.81168 12.963C6.2986 13.3825 6.57215 13.9514 6.57215 14.5447V20.0001H7.4043V14.5447C7.4043 13.9514 7.67785 13.3825 8.16477 12.963C8.6517 12.5435 9.31211 12.3078 10.0007 12.3078C10.6893 12.3078 11.3498 12.5435 11.8367 12.963C12.3236 13.3825 12.5972 13.9514 12.5972 14.5447V20.0001H13.4293V14.5447C13.4293 13.9514 13.7028 13.3825 14.1898 12.963C14.6767 12.5435 15.3371 12.3078 16.0257 12.3078C16.7143 12.3078 17.3748 12.5435 17.8617 12.963C18.3486 13.3825 18.6222 13.9514 18.6222 14.5447V20.0001H20.0972V9.96625C20.0962 9.60825 19.9307 9.26515 19.6369 9.01201C19.3431 8.75887 18.9448 8.61629 18.5293 8.61548Z"
          fill="currentColor"
        />
        <path
          d="M10.3574 3.4V0.461538C10.3574 0.339131 10.3009 0.221737 10.2005 0.135182C10.1 0.0486265 9.96373 0 9.82165 0C9.67957 0 9.54331 0.0486265 9.44284 0.135182C9.34238 0.221737 9.28594 0.339131 9.28594 0.461538V3.42769C8.02598 3.55888 6.8535 4.05036 5.95609 4.82352C5.05868 5.59667 4.48821 6.6068 4.33594 7.69231H15.6645C15.5049 6.55411 14.8859 5.5005 13.9157 4.71582C12.9455 3.93114 11.6859 3.46537 10.3574 3.4Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35494">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MosqueIcon;
