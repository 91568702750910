import { FormattedMessage } from "react-intl";
import { Typo } from "../../../components/shared/typo";
import { CreateUserProp, User } from "../../../model/User";
import { TRANSLATION } from "../../../static/translation";
import NextPrevButtons from "./NextPrevButtons";
import Dropdown from "../../../components/shared/Dropdown";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { handelInputMap } from "../../../util/handelInputMap";
import { useIntl } from "react-intl";
import InputField from "../../../components/shared/InputField";
import { getLocaleProvinces } from "../../../util/appUtil";
type Props = {
  className?: string;
  disabled?: boolean;
  user: User | null;
  role: number;
  setRole: (role: number) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  onPrev: () => void;
  onUpdateProfile: (user: CreateUserProp) => Promise<void>;
  loading: boolean;
  locale: string;
};

const UserAccountLocation: React.FC<Props> = (props) => {
  const { user, currentStep, onPrev, onUpdateProfile, loading, locale } = props;

  const intl = useIntl();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const { ToGetProvinces } = useHandelProvince();
  const provinceResponse = ToGetProvinces(1, -1);
  const [address, setAddress] = useState("");
  const provinces = getLocaleProvinces(locale, provinceResponse?.data?.rows);

  const onUpdate = async () => {
    try {
      const newUser: CreateUserProp = {
        provinceId: Number(selectedProvince?.id),
        address: address,
      };
      await onUpdateProfile(newUser);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user && provinces) {
      const findedProvince = provinces.find(
        (item) => item.id === user?.provinceId
      );
      if (findedProvince) setSelectedProvince(findedProvince);
      else setSelectedProvince(provinces[0]);
      setAddress(user?.address || "");
    }
  }, [user, provinceResponse?.data]);

  return (
    <div>
      <div className="flex flex-col w-full h-[350px] justify-between">
        <div className="space-y-10">
          <Typo.heading1 className="text-gray-400">
            <FormattedMessage
              id={TRANSLATION.ENTER_ACCOUNT_LOCATION.id}
              defaultMessage={TRANSLATION.ENTER_ACCOUNT_LOCATION.defaultMessage}
            />
          </Typo.heading1>
          <div className="space-y-6">
            <Dropdown
              label={handelInputMap(intl, "province")}
              options={provinces || []}
              defaultValue={selectedProvince}
              onChange={setSelectedProvince}
              placeholder={handelInputMap(intl, "province_placeholder")}
            />
            <div className="w-full mt-5">
              <InputField
                name="address"
                key="address"
                value={address}
                register={() => ({})}
                onChange={(event: any) => setAddress(event.target.value)}
                placeholder={handelInputMap(intl, "address_placeholder")}
                type="text"
                label={handelInputMap(intl, "address")}
              />
            </div>
          </div>
        </div>
        <NextPrevButtons
          currentStep={currentStep}
          loading={loading}
          onNext={onUpdate}
          onPrev={onPrev}
        />
      </div>
    </div>
  );
};

export default UserAccountLocation;
