import { useHandelUser } from "../../../hooks/user/useHandelUser";
import Loader from "../../../components/shared/Loader";
import { Typo } from "../../../components/shared/typo";
import Button from "../../../components/shared/button";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useNavigate } from "react-router-dom";
const DeleteProfile = () => {
  const navigate = useNavigate();
  const { loading, onDeleteProfile } = useHandelUser();
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full bg-white h-96 rounded-md flex items-center justify-center">
        {loading ? (
          <Loader className="h-16" />
        ) : (
          <div>
            <div className="w-full h-full">
              <div className=" items-center">
                <div className="text-primary-600 flex gap-x-2 justify-center">
                  <span> </span>
                  <Typo.heading1
                    fontWeight="font-bold"
                    className=" text-secondary-900 text-center "
                  >
                    <FormattedMessage
                      id={TRANSLATION.DELETE_PROFILE_TITLE.id}
                      defaultMessage={
                        TRANSLATION.DELETE_PROFILE_TITLE.defaultMessage
                      }
                    />
                  </Typo.heading1>
                </div>

                <Typo.heading5 className="text-gray-500 mt-2 text-center">
                  <FormattedMessage
                    id={TRANSLATION.DELETE_PROFILE_DESCRIPTION.id}
                    defaultMessage={
                      TRANSLATION.DELETE_PROFILE_DESCRIPTION.defaultMessage
                    }
                  />
                </Typo.heading5>
              </div>
              <div className=" flex flex-col sm:flex-row gap-4 mt-10 pb-4 ">
                <div className="flex w-full">
                  <Button
                    onClick={() => onDeleteProfile()}
                    size="medium"
                    theme="error"
                    className="w-full"
                  >
                    <Typo.heading4
                      fontWeight="font-bold"
                      className="text-white"
                    >
                      <FormattedMessage
                        id={TRANSLATION.CONFIRM.id}
                        defaultMessage={TRANSLATION.CONFIRM.defaultMessage}
                      />
                    </Typo.heading4>
                  </Button>
                </div>
                <div className="flex w-full">
                  <Button
                    size="medium"
                    onClick={() => navigate(-1)}
                    theme="gray"
                    className="w-full"
                  >
                    <Typo.heading4 fontWeight="font-bold" className="">
                      <FormattedMessage
                        id={TRANSLATION.CANCEL.id}
                        defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                      />
                    </Typo.heading4>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteProfile;
