import React from "react";
import { Typo } from "./typo";
import { handelUnitsMap } from "../../util/handelListingFeature";
import { IntlShape } from "react-intl";
type Props = {
  area?: string | number;
  unit?: string;
  intl: IntlShape;
};
const Area: React.FC<Props> = (props) => {
  const { area = "", unit = "", intl } = props;
  return (
    <div className="flex items-center">
      <Typo.body4 className="">
        {area}

        <span className="px-1">{handelUnitsMap(intl, unit)}</span>
      </Typo.body4>
    </div>
  );
};

export default Area;
