type Props = {
  className?: string;
};
const FolderIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3327 9.16675V14.1667C18.3327 17.5001 17.4993 18.3334 14.166 18.3334H5.83268C2.49935 18.3334 1.66602 17.5001 1.66602 14.1667V5.83341C1.66602 2.50008 2.49935 1.66675 5.83268 1.66675H7.08268C8.33268 1.66675 8.60768 2.03341 9.08268 2.66675L10.3327 4.33341C10.6493 4.75008 10.8327 5.00008 11.666 5.00008H14.166C17.4993 5.00008 18.3327 5.83341 18.3327 9.16675Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default FolderIcon;
