import { useLocation } from "react-router-dom";
import notFound from "../../assets/image/404.svg";
import Image from "../../components/shared/Image";
import Button from "../../components/shared/button";
import { Typo } from "../../components/shared/typo";
import pattern from "../../assets/image/404Pattern.svg";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import { getMenus } from "../../static/constants";
import { useIntl } from "react-intl";
const NotFound = () => {
  let location = useLocation();
  const intl = useIntl();
  const menus = getMenus(intl);
  return (
    <div className="w-full relative px-8 overflow-hidden">
      <Image src={pattern} className="absolute -left-20" />
      <Image src={pattern} className="absolute -right-20 rotate-45" />
      <div className="flex flex-col items-center justify-center">
        <Image src={notFound} className="mx-auto" />
        <Typo.headingC fontWeight="font-bold" className="text-gray-900 mt-6">
          <FormattedMessage
            id={TRANSLATION.NOTFOUND_TITLE.id}
            defaultMessage={TRANSLATION.NOTFOUND_TITLE.defaultMessage}
          />
        </Typo.headingC>
        <Typo.heading1 className="text-gray-500 mt-4">
          <FormattedMessage
            id={TRANSLATION.NOTFOUND_DESCRIPTION.id}
            defaultMessage={TRANSLATION.NOTFOUND_DESCRIPTION.defaultMessage}
          />
        </Typo.heading1>
        <div className="flex gap-x-6 mt-6">
          {menus?.map((item) => {
            return (
              <div key={item.name}>
                <Button theme="gray-link" size="medium">
                  <Typo.heading4
                    fontWeight={
                      item.href == location.pathname
                        ? "font-bold"
                        : "font-medium"
                    }
                    className={
                      item.href == location.pathname
                        ? "text-primary-600"
                        : "text-gray-400"
                    }
                  >
                    {item.name}
                  </Typo.heading4>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default NotFound;
