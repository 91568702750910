import React, { ReactElement } from "react";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props = {
  icon: ReactElement;
  title: MessageDescriptor;
  children: ReactElement;
  className?: string;
};

const SectionContainer: React.FC<Props> = (props) => {
  const { icon, title, children, className } = props;

  return (
    <>
      <div className={`w-full ${className}`}>
        <div className="flex gap-x-4 items-center py-4 px-6 bg-gray-50 rounded-t-lg">
          <div className="rounded-md bg-gray-100 p-3">{icon}</div>
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={title.id}
              defaultMessage={title.defaultMessage}
            />
          </Typo.heading2>
        </div>
        <div className="w-full border-x border-b px-6 pt-8 pb-10 border-gray-200 rounded-b-lg">
          {children}
        </div>
      </div>
    </>
  );
};

export default SectionContainer;
