import { useState } from "react";
import { Typo } from "../../../components/shared/typo";
import { useHandelAgency } from "../../../hooks/user/useHandelAgency";
import AgencyCard from "../../../components/shared/Card/AgencyCard";
import Pagination from "../../../components/shared/Pagination";
import AllAgenciesFilter from "./AllAgenciesFilter";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import debounce from "lodash.debounce";
import { DEBOUNCE } from "../../../static/constants";
import { AgencyObject } from "../../../model/Agency";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import NoListingFound from "../../dashboard/listings/noListingFound";
import { useLang } from "../../../context/language";
import { getLocaleProvinces } from "../../../util/appUtil";
const Agencies = () => {
  const LIMIT = 12;
  const [state, setState] = useState(false);
  const [search, setSearch] = useState<any>({
    page: 1,
    limit: LIMIT,
    provinceId: undefined,
    address: "",
    title: "",
  });
  const { ToGetAgencies } = useHandelAgency();
  const { ToGetProvinces } = useHandelProvince();
  const agencies = ToGetAgencies(search, state);
  const intl = useIntl();
  const lang = useLang();

  const onSearch = async () => {
    if (search.page !== 1) {
      onChange("page", 1);
    } else {
      setState(!state);
      agencies.refetch();
    }
  };
  const provinceResponse = ToGetProvinces(1, -1);
  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const onChange = (name: string, value: any) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const debouncedSetAddress = debounce(
    (address: string) => onChange("address", address),
    DEBOUNCE
  );
  const debouncedSetTitle = debounce(
    (title: string) => onChange("title", title),
    DEBOUNCE
  );

  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto">
        <AllAgenciesFilter
          provinces={provinces || []}
          setAddress={debouncedSetAddress}
          setAgency={debouncedSetTitle}
          province={provinces?.find(
            (item: any) => item.id === search.provinceId
          )}
          setProvince={(province: DropdownOptionModel) =>
            onChange(
              "provinceId",
              province?.id ? Number(province?.id) : undefined
            )
          }
          onSearch={onSearch}
          intl={intl}
        />

        <div className="pt-16 md:pt-28">
          <div>
            <Typo.headingC
              fontWeight="font-bold"
              className="text-center text-gray-800"
            >
              <FormattedMessage
                id={TRANSLATION.SEARCH_RESULT_WITH_VALUT.id}
                defaultMessage={
                  TRANSLATION.SEARCH_RESULT_WITH_VALUT.defaultMessage
                }
                values={{ value: agencies?.data?.count }}
              />
            </Typo.headingC>

            {agencies?.data?.count === 0 && <NoListingFound />}

            <div className="w-full max-w-7xl px-4 sm:px-6 lg:px-8 pb-10">
              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-12">
                {agencies?.data?.rows?.map((agency: AgencyObject) => {
                  return (
                    <AgencyCard
                      key={agency.id}
                      agency={agency}
                      locale={lang.language}
                    />
                  );
                })}
              </div>
              {agencies?.data && agencies?.data?.count > 0 && (
                <Pagination
                  onPageChange={(page: number) => onChange("page", page)}
                  total={agencies.data.count}
                  perPage={LIMIT}
                  currentPage={search.page || 1}
                  type="centered-page-number"
                />
              )}
            </div>

            {/* <div className="mt-[108px] bg-gray-50">
              <CountryAgencies countryAgencies={data} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agencies;
