export enum HttpCode {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum HttpMessage {
  EMAIL_REQUIRED = 'emailRequired',
  PASSWORD_REQUIRED = 'passwordRequired',
  INAVLID_USERNAME_PASSWORD = 'invalidUseramePassword',
  ACCOUNT_NOT_VERIFIED = 'accountNotVerified',
  LOGGED_IN_SUCCESSFULY = 'loggedInSuccessfuly',
  INTERNAL_SERVER_ERROR = 'internalServerError',
  REGISTER_SUCCEESSFULY = 'registerSuccessfuly',
  YOUR_ACCOUNT_EXISTS = 'yourAccountExists',
  LOGING_FIRST = 'loginFirst',
  USER_NOT_FOUND = 'userNotFound',
  RECORD_NOT_FOUND = 'recordNotFound',
  UPDATED_SUCCESSFULY = 'updateSuccessfuly',
  DELETE_PROFILE = 'deleteProfile',
  INVALID_EMAIL = 'invalidEmail',
  INVALID_OTP = 'invalidOtp',
  VALID_OTP = 'validOtp',
  INVALID_OR_EXPIRED_OTP = 'invalidOrExpiredOtp',
  EMAIL_NOT_FOUND = 'emailNotFound',
  OTP_SENT = 'optSent',
  OTP_EXPIRED = 'otpExpired',
  VERIFIED_SUCCESSFULY = 'verifiedSuccessfuly',
  INVALID_INPUT = 'invalidInput',
  SENDDING_EMAIL_FAILED = 'senddingEmailFailed',
  INVALID_PASSWORD = 'invalidPassword'
}


// OTP_SENT = 'optSent',
// OTP_EXPIRED = 'otpExpired',
// VERIFIED_SUCCESSFULY = 'verifiedSuccessfuly',
// INVALID_INPUT = 'invalidInput',
// SENDDING_EMAIL_FAILED = 'senddingEmailFailed',
// INVALID_PASSWORD = 'invalidPassword'

export type AppError = {
  status: number;
  data: { message: string };
  //   name?: string;
  //   httpCode: HttpCode;
  //   description: string;
  //   isOperational?: boolean;
};
