type Props = {
  className?: string;
};
const ArrowVictorIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 50 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        opacity="1"
        d="M36.9122 54.5615C39.6322 51.9023 43.0534 50.0309 45.7416 47.3692C46.1975 46.918 48.0125 45.821 48.1309 45.1452M48.1309 45.1452C48.2841 44.2676 46.106 43.3187 45.604 43.0829C43.073 41.8945 38.6402 39.8625 35.7514 38.8706M48.1309 45.1452C38.1004 47.7635 22.4134 43.0289 20.6746 33.7649M20.6746 33.7649C20.2142 31.3122 20.7315 28.5421 22.5903 25.5069C26.105 19.7682 33.3174 25.1388 30.2178 30.4968C28.5062 33.4548 24.2058 34.02 20.6746 33.7649ZM20.6746 33.7649C19.6603 33.6916 18.7096 33.5507 17.9018 33.3794C7.88713 31.2551 -2.72203 23.143 3.00797 1.98478"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowVictorIcon;
