import React from "react";
import LocationPinIcon from "../../icons/LocationPinIcon";
import { Typo } from "../typo";
import { ListingObject } from "../../../model/Listing";
import clsx from "clsx";
import Price from "../Price";
import { mapPorpuse } from "../../../util/mapTranslation";
import { IntlShape } from "react-intl";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import ListingCardContact from "../ListingAction/ListingCardContact";
import ListingAttributes from "../ListingAction/ListingAttributes";
import ImageThumbnail from "../ListingAction/ImageThumbnail";
import ListingCardAction from "../ListingAction/ListingCardAction";
import { User } from "../../../model/User";
import { getLocalTitle } from "../../../util/appUtil";
type ListingProp = {
  listing?: ListingObject;
  page?: "dashboard" | "public";
  background?: "gray" | "white";
  type?: "sample" | "feature";
  showAttribute?: boolean;
  showContact?: boolean;
  showInteration?: boolean;
  onDelete?: (listingId: number) => void;
  onDisable?: (listingId: number) => void;
  onActive?: (listingId: number) => void;
  user: User | null;
  direction: string;
  intl: IntlShape;
  onLike?: (listingId: number) => void;
  onDisLike?: (listingId: number) => void;
  locale: string;
};

const ListingCard: React.FC<ListingProp> = (props) => {
  const {
    listing,
    page = "public",
    background = "white",
    showAttribute = true,
    showContact = true,
    showInteration = true,
    type = "sample",
    onDelete,
    onDisable,
    onActive,
    direction,
    user,
    intl,
    onLike,
    onDisLike,
    locale,
  } = props;

  return (
    <div>
      <Link to={`${ROUTES.LISTINGS_ROUTE}/${listing?.slug}`}>
        <div
          className={clsx(
            "relative rounded-xl h-full w-full select-none hover:shadow-shadow2 p-px bg-gradient-to-b from-gray-50 to-gray-200 hover:to-white"
          )}
        >
          <div
            className={clsx(
              "py-2 px-3 rounded-[10px] h-full w-full ",
              background === "white"
                ? "bg-white"
                : "bg-gradient-to-b from-gray-50 to-white"
            )}
          >
            <div className={clsx("h-full w-full")}>
              <div>
                <ImageThumbnail
                  listing={listing}
                  user={user}
                  showInteration={showInteration}
                  intl={intl}
                  type={type}
                  onLike={onLike}
                  onDisLike={onDisLike}
                />
                <div className="pt-4 col-span-3">
                  <div>
                    <div>
                      <div className="space-y-3 w-full justify-between pb-2">
                        <div className="flex justify-between items-center ">
                          <Typo.heading3
                            fontWeight="font-medium"
                            className=" text-base  text-[#246BFD]  leading-[25px]"
                          >
                            {mapPorpuse(
                              intl,
                              listing?.purpose || "",
                              getLocalTitle(
                                locale,
                                listing?.subCategory.title_fa,
                                listing?.subCategory.title_ps,
                                listing?.subCategory.title_en
                              ) || ""
                            )}
                          </Typo.heading3>

                          <Price
                            price={listing?.price || ""}
                            currency={listing?.currency || ""}
                            intl={intl}
                          />
                        </div>
                        <Typo.heading2
                          fontWeight="font-bold"
                          className="text-gray-900 leading-[28.17px] overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                          {listing?.title}
                        </Typo.heading2>
                        <div className="flex flex-row text-gray-600 gap-x-2 items-center">
                          <LocationPinIcon className="h-5 w-5 text-gray-400" />
                          <Typo.heading4>
                            {listing?.location.address}
                          </Typo.heading4>
                        </div>
                        {showAttribute && (
                          <ListingAttributes
                            area={listing?.area}
                            unit={listing?.unit}
                            bathrooms={listing?.bathrooms}
                            bedrooms={listing?.bedrooms}
                            intl={intl}
                          />
                        )}

                        {showContact &&
                          listing?.user?.id !== user?.id &&
                          type === "feature" && (
                            <ListingCardContact
                              phone={listing?.phone}
                              whatsApp={listing?.user?.whatsapp}
                              direction={direction}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {page === "dashboard" && (
                <ListingCardAction
                  onDelete={onDelete}
                  onDisable={onDisable}
                  onActive={onActive}
                  listing={listing}
                />
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ListingCard;
