import EmailIcon from "../icons/EmailIcon";
import LocationPinIcon from "../icons/LocationPinIcon";
import PhoneIcon from "../icons/PhoneIcon";
import Divider from "../shared/Divider";
import { Typo } from "../shared/typo";
import Image from "../shared/Image";
// import logo from "../../assets/image/logo.svg";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import WhatsAppIcon from "../icons/WhatsappIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import NavLink from "../shared/NavLink";
import { useIntl } from "react-intl";
import { ROUTES } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { addFilter } from "../../lib/reducers/filter.slice";
import { useNavigate } from "react-router-dom";
import Button from "../shared/button";
import AHLogo from "../icons/AHLogo";
import AHName from "../icons/AHName";
export default function Footer() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = (id: string | number) => {
    dispatch(
      addFilter({
        page: 1,
        purpose: id,
      })
    );
    navigate(`${ROUTES.LISTINGS_ROUTE}`);
  };
  return (
    <footer className="bg-gray-900 relative select-none ">
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:grid sm:grid-cols-3 lg:grid-cols-5 sm:py-16">
        <div className=" sm:col-span-3  lg:col-span-2 ">
          <div className="flex gap-x-3 items-center pt-6 lg:pt-0">
            <div>
              <AHLogo type="secondary" className="h-8 text-primary-600"/>
            </div>
            <AHName className="text-white h-6"/>
          </div>
          <div className="pt-6 space-y-4 max-w-sm md:me-8">
            <Typo.body5 className="text-gray-300 text-justify">
              <FormattedMessage
                id={TRANSLATION.AFGHAN_HOUSE_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.AFGHAN_HOUSE_DESCRIPTION.defaultMessage
                }
              />
            </Typo.body5>
          </div>
          <div className="">
            <Typo.body3 fontWeight="font-bold" className="text-white mt-6">
              <FormattedMessage
                id={TRANSLATION.SOCIAL_MEDIA.id}
                defaultMessage={TRANSLATION.SOCIAL_MEDIA.defaultMessage}
              />
            </Typo.body3>
            <div className=" flex gap-x-5 items-center mt-6">
              <Button
                to="https://www.facebook.com/afghanhouse.af"
                target="_blank"
                icon={<FacebookIcon className="text-gray-300 h-6 w-6" />}
              />
              <Button
                to="https://www.instagram.com/afghan.house/"
                target="_blank"
                icon={<InstagramIcon className="text-gray-300 h-6 w-6" />}
              />
              <Button
                to="https://www.youtube.com/@afghanhouse"
                target="_blank"
                icon={<YoutubeIcon className="text-gray-300 h-6 w-6" />}
              />
              <Button
                icon={<WhatsAppIcon className="text-gray-300 h-6 w-6" />}
              />
            </div>
          </div>
        </div>
        <div className="pt-6 lg:pt-0">
          <Typo.body3 className="text-white" fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.LINKS.id}
              defaultMessage={TRANSLATION.LINKS.defaultMessage}
            />
          </Typo.body3>
          <div className="mt-6 flex flex-col items-start space-y-4">
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.HOME.id,
                defaultMessage: TRANSLATION.HOME.defaultMessage,
              })}
              link={ROUTES.HOME_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.SELL.id,
                defaultMessage: TRANSLATION.SELL.defaultMessage,
              })}
              onClick={() => onClick(1)}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.RENT.id,
                defaultMessage: TRANSLATION.RENT.defaultMessage,
              })}
              onClick={() => onClick(2)}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.MORTGAGE.id,
                defaultMessage: TRANSLATION.MORTGAGE.defaultMessage,
              })}
              onClick={() => onClick(3)}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.AGENCY.id,
                defaultMessage: TRANSLATION.AGENCY.defaultMessage,
              })}
              link={ROUTES.AGENCIES_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.ARTICLES.id,
                defaultMessage: TRANSLATION.ARTICLES.defaultMessage,
              })}
              link={ROUTES.ARTICLES_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
          </div>
        </div>
        <div className="pt-6 lg:pt-0 text-start">
          <Typo.body3 className="text-white" fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.CONTACT_US.id}
              defaultMessage={TRANSLATION.CONTACT_US.defaultMessage}
            />
          </Typo.body3>
          <div className="pt-6 flex flex-col items-start space-y-4">
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.CONTACT_US.id,
                defaultMessage: TRANSLATION.CONTACT_US.defaultMessage,
              })}
              link={ROUTES.CONTACT_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.PRIVACY_POLICY.id,
                defaultMessage: TRANSLATION.PRIVACY_POLICY.defaultMessage,
              })}
              link={ROUTES.PRIVACY_POLICY_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.COOPERATION_IN_SALRE.id,
                defaultMessage: TRANSLATION.COOPERATION_IN_SALRE.defaultMessage,
              })}
              link=""
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
            <NavLink
              title={intl.formatMessage({
                id: TRANSLATION.ABOUT_US.id,
                defaultMessage: TRANSLATION.ABOUT_US.defaultMessage,
              })}
              link={ROUTES.ABOUT_ROUTE}
              currentPath={""}
              className="text-gray-400"
            ></NavLink>
          </div>
        </div>
        <div className="pt-6 lg:pt-0">
          <Typo.body3 className="text-white" fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.CONTACT_WAYS.id}
              defaultMessage={TRANSLATION.CONTACT_WAYS.defaultMessage}
            />
          </Typo.body3>
          <div className="mt-6 space-y-4">
            <div className=" flex items-center gap-x-2">
              <PhoneIcon className="text-gray-300" />
              <Typo.body4 className="text-gray-300">+93- 791263434</Typo.body4>
            </div>
            <div className=" flex items-center gap-x-2">
              <EmailIcon className="text-gray-300" />
              <Typo.body4 className="text-gray-300">
                info@afghouse.com
              </Typo.body4>
            </div>
            <div className=" flex items-center gap-x-2">
              <LocationPinIcon className="text-gray-300 w-5 h-5" />
              <Typo.body4 className="text-gray-300">
                Salim Karvan, Kabul Afghanistan
              </Typo.body4>
            </div>
          </div>
          <div className="w-full lg:border-b border-dashed my-8 border-b-gray-300"></div>
        </div>
      </div>
      <Divider type="horizontal" className="lg:col-span-5" />
      <div className="lg:py-4 text-center">
        <Typo.heading5 className="text-gray-500 py-3 md:my-0 ">
          <FormattedMessage
            id={TRANSLATION.COPYRIGHT.id}
            defaultMessage={TRANSLATION.COPYRIGHT.defaultMessage}
          />
        </Typo.heading5>
      </div>
    </footer>
  );
}
