import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../context/auth";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { handelValidationMap } from "../../util/handelValidationMap";
import { useIntl } from "react-intl";
import { AppError, HttpCode, HttpMessage } from "../../static/AppError";
import { AlertModel } from "../../model/Alert";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { toast } from "react-toastify";
export const useHandelLogin = () => {
  const auth = useAuth();
  const intl = useIntl();
  const validations = handelValidationMap(intl);
  const serverMessages = handelServerMessageMap(intl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertModel>();
  const schema = object().shape({
    email: string()
      .required(validations["email_required"])
      .email(validations["invalid_email"]),
    password: string()
      .required(validations["enter_your_password"])
      .min(6, validations["must_be_six_characters"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "POST",
        endpoint: "/login",
        data: doUser,
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        toast.success(serverMessages[HttpMessage.LOGGED_IN_SUCCESSFULY]);
        if (data?.data) {
          auth.login(data?.data);
          setLoading(false);
        }
      }
    },
    onError: (error: AppError) => {
      if (error.status === HttpCode.BAD_REQUEST) {
        if (error?.data?.message === HttpMessage.EMAIL_REQUIRED) {
          setError(
            "email",
            { message: serverMessages[HttpMessage.EMAIL_REQUIRED] },
            { shouldFocus: true }
          );
        } else if (error?.data?.message === HttpMessage.PASSWORD_REQUIRED) {
          setError(
            "password",
            { message: serverMessages[HttpMessage.PASSWORD_REQUIRED] },
            { shouldFocus: true }
          );
        }
      } else if (error.status === HttpCode.UNAUTHORIZED) {
        setAlert({
          title: serverMessages[HttpMessage.INAVLID_USERNAME_PASSWORD],
          open: true,
          type: "error",
        });
      } else if (error.status === HttpCode.INTERNAL_SERVER_ERROR) {
        setAlert({
          title: serverMessages[HttpMessage.INTERNAL_SERVER_ERROR],
          open: true,
          type: "error",
        });
      }
      setLoading(false);
    },
  });

  const googleMutation = useMutation({
    mutationFn: async (doUser: any) => {
      console.log("dddddddddd ", doUser);
      return await Fetch({
        method: "POST",
        endpoint: "/google",
        headers: {
          authorization: `Bearer ${doUser.token}`,
        },
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        setAlert({
          title: serverMessages[HttpMessage.LOGGED_IN_SUCCESSFULY],
          open: true,
          type: "success",
        });
        auth.login(data?.data);
      }
    },
    onError: (error: AppError) => {
      if (error?.status === HttpCode.UNAUTHORIZED) {
        setAlert({
          title: serverMessages[HttpMessage.YOUR_ACCOUNT_EXISTS],
          open: true,
          type: "error",
        });
      } else if (error?.status === HttpCode.INTERNAL_SERVER_ERROR) {
        setAlert({
          title: serverMessages[HttpMessage.INTERNAL_SERVER_ERROR],
          open: true,
          type: "error",
        });
      }
    },
  });

  const facebookMutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "POST",
        endpoint: "/facebook",
        headers: {
          authorization: `Bearer ${doUser.token}`,
        },
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        setAlert({
          title: serverMessages[HttpMessage.LOGGED_IN_SUCCESSFULY],
          open: true,
          type: "success",
        });
        auth.login(data?.data);
      }
    },
    onError: (error: AppError) => {
      if (error.status === HttpCode.UNAUTHORIZED) {
        setAlert({
          title: serverMessages[HttpMessage.YOUR_ACCOUNT_EXISTS],
          open: true,
          type: "error",
        });
      } else if (error.status === HttpCode.INTERNAL_SERVER_ERROR) {
        setAlert({
          title: serverMessages[HttpMessage.INTERNAL_SERVER_ERROR],
          open: true,
          type: "error",
        });
      }
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    mutation.mutate(data);
  };

  const responseFacebook = async (response: any) => {
    const accessToken = response?.accessToken;
    const userData: any = { token: accessToken, body: {} };
    facebookMutation.mutate(userData);
  };

  const onGoogleLogin = async (response: any) => {
    const accessToken = response?.credential;
    const data: any = { token: accessToken, body: {} };
    googleMutation.mutate(data);
  };

  const onTapLogin = useGoogleOneTapLogin({
    disabled: !auth?.token,
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse?.credential;
      const data: any = { token: accessToken, body: {} };
      googleMutation.mutate(data);
    },
  });

  return {
    onTapLogin,
    onGoogleLogin,
    responseFacebook,
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    loading,
    alert,
    setAlert,
  };
};
