import React, { ReactElement } from "react";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props = {
  icon: ReactElement;
  title: MessageDescriptor;
  className?: string;
};

const PropertyLabel: React.FC<Props> = (props) => {
  const { icon, title, className } = props;

  return (
    <>
      <div className={`w-full ${className}`}>
        <div className="flex gap-x-2 items-center">
          {icon && icon}
          <Typo.body4 fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={title.id}
              defaultMessage={title.defaultMessage}
            />
          </Typo.body4>
        </div>
      </div>
    </>
  );
};

export default PropertyLabel;
