type Props = {
  className?: string;
};
const CameraIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-4 w-4 ${className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.66675H11.0267L10.7026 3.69279C10.498 3.07946 9.92596 2.66675 9.2793 2.66675H6.7207C6.07404 2.66675 5.50203 3.07946 5.29736 3.69279L4.97331 4.66675H4C2.388 4.66675 1.5 5.55475 1.5 7.16675V12.5001C1.5 14.1121 2.388 15.0001 4 15.0001H12C13.612 15.0001 14.5 14.1121 14.5 12.5001V7.16675C14.5 5.55475 13.612 4.66675 12 4.66675ZM13.5 12.5001C13.5 13.5514 13.0513 14.0001 12 14.0001H4C2.94867 14.0001 2.5 13.5514 2.5 12.5001V7.16675C2.5 6.11541 2.94867 5.66675 4 5.66675H5.33333C5.54867 5.66675 5.73928 5.52879 5.80794 5.32479L6.24675 4.00806C6.31474 3.80339 6.50537 3.6661 6.7207 3.6661H9.2793C9.49463 3.6661 9.68526 3.80339 9.75326 4.00806L10.1921 5.32414C10.2607 5.52814 10.4513 5.6661 10.6667 5.6661H12C13.0513 5.6661 13.5 6.11476 13.5 7.1661V12.5001ZM8 7.33341C6.622 7.33341 5.5 8.45475 5.5 9.83341C5.5 11.2121 6.622 12.3334 8 12.3334C9.378 12.3334 10.5 11.2121 10.5 9.83341C10.5 8.45475 9.378 7.33341 8 7.33341ZM8 11.3334C7.17333 11.3334 6.5 10.6607 6.5 9.83341C6.5 9.00608 7.17333 8.33341 8 8.33341C8.82667 8.33341 9.5 9.00608 9.5 9.83341C9.5 10.6607 8.82667 11.3334 8 11.3334ZM12.3333 7.50008C12.3333 7.86808 12.0347 8.16675 11.6667 8.16675C11.2987 8.16675 11 7.86808 11 7.50008C11 7.13208 11.2987 6.83341 11.6667 6.83341C12.0347 6.83341 12.3333 7.13208 12.3333 7.50008Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CameraIcon;
