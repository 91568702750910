type Props = {
  className?: string;
};
const MaximizeIcon = ({ className }: Props) => {
  return (
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 600"
      className={className}
    >
      <path fill="currentColor" d="M533.33,0H66.67C29.85,0,0,29.85,0,66.67v466.66c0,36.82,29.85,66.67,66.67,66.67h466.66c36.82,0,66.67-29.85,66.67-66.67V66.67c0-36.82-29.85-66.67-66.67-66.67Zm-233.33,500H100v-200h66.67v133.33h133.33v66.67Zm200-200h-66.67V166.67h-133.33V100h200v200Z" />
    </svg>
  );
};

export default MaximizeIcon;
