import { FC, useEffect } from "react";
import Modal from "../wrapper/modal";
import InputField from "../components/shared/InputField";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { useHandelCategory } from "../hooks/category/useHandelCategory";
import { Category } from "../model/Category";
import { handelInputMap } from "../util/handelInputMap";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  defaultValue?: Category;
  intl: IntlShape;
};

const CategoryModal: FC<Props> = ({ open, onClose, defaultValue, intl }) => {
  const {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    setValue,
    reset,
  } = useHandelCategory({
    intl: intl,
    onClose: onClose,
  });

  const onSubmitCategory = async (data: any) => {
    if (defaultValue) {
      await onUpdate(data, Number(defaultValue.id));
    } else {
      await onSubmit(data);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue("title_fa", defaultValue.title_fa);
      setValue("title_ps", defaultValue.title_ps);
      setValue("title_en", defaultValue.title_en);
    } else {
      reset();
    }
  }, [defaultValue]);

  return (
    <Modal className="max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full py-10">
        <div className="items-center">
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.ADD_NEW_CATEGORY.id}
              defaultMessage={TRANSLATION.ADD_NEW_CATEGORY.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.ENTER_CATEGORY_INFORMATION.id}
              defaultMessage={
                TRANSLATION.ENTER_CATEGORY_INFORMATION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmitCategory)}
        >
          <div className="w-full mt-5">
            <InputField
              name="title_fa"
              label={handelInputMap(intl, "title_fa")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              type="text"
              register={register}
              errors={errors?.title_fa}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="title_ps"
              label={handelInputMap(intl, "title_ps")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              type="text"
              register={register}
              errors={errors?.title_ps}
              required={true}
            />
          </div>
          <div className="w-full mt-5">
            <InputField
              name="title_en"
              label={handelInputMap(intl, "title_en")}
              placeholder={handelInputMap(intl, "title_placeholder")}
              type="text"
              register={register}
              errors={errors?.title_en}
              required={true}
            />
          </div>
          <div className="flex flex-row gap-x-2 pt-6">
            <Button
              onClick={() => onClose(false)}
              size="medium"
              theme="error"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button
              size="medium"
              type="submit"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.ADD.id}
                  defaultMessage={TRANSLATION.ADD.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CategoryModal;
