import React from "react";
import ReactPlayer from "react-player/youtube";
type VideoPorps = {
  url: string;
  className?: string;
};
const Video: React.FC<VideoPorps> = (props) => {
  const { url, className } = props;
  return (
    <div>
      <ReactPlayer
        className={className}
        url={url}
        width="100%"
        height="500px"
        controls
      />
    </div>
  );
};

export default Video;
