type Props = {
    className?: string;
};
const AHNameColumn = ({ className }: Props) => {
    return (
        <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 600 270" >
            <g>
                <rect fill="currentColor" x="555.44" y="47.69" className="st0" width="17.68" height="75.52" />
                <path fill="currentColor" className="st0" d="M167.45,46.43v76.79H56.58c-21.17,0-38.34-17.17-38.34-38.34v-0.1c0-21.17,17.17-38.34,38.34-38.34h23.2v17.22
		H55.57c-10.99,0-19.96,8.91-19.96,19.91c0,5.52,2.23,10.54,5.88,14.13c3.6,3.6,8.56,5.82,14.08,5.82h94.67V46.43H167.45z"/>
                <path fill="currentColor" className="st0" d="M167.45,163.13v76.79H56.58c-21.17,0-38.34-17.17-38.34-38.34v-0.1c0-21.17,17.17-38.34,38.34-38.34h23.2
		v17.22H55.57c-10.99,0-19.96,8.91-19.96,19.91c0,5.52,2.23,10.54,5.88,14.13c3.6,3.6,8.56,5.82,14.08,5.82h94.67v-57.08H167.45z"/>
                <circle fill="currentColor" className="st0" cx="428.08" cy="34.09" r="10.91" />
                <circle fill="currentColor" className="st0" cx="509.67" cy="34.09" r="10.91" />
                <path fill="currentColor" className="st0" d="M511.93,47.69c-20.41,0-36.92,16.56-36.92,36.98v18.64h-12.66c2.43-4.91,3.8-10.43,3.8-16.26V48.5h-73.9v38.55
		c0,5.83,1.37,11.35,3.8,16.26H206.15V46.43H186.4v76.74h288.61v0.05h16.41V85.02c0-5.37,2.18-10.23,5.67-13.73
		c3.55-3.55,8.41-5.72,13.78-5.72c10.74,0,19.45,8.71,19.45,19.45v38.19h18.59V84.67C548.91,64.25,532.35,47.69,511.93,47.69z
		 M449.78,86.69c0,5.37-2.18,10.23-5.72,13.78c-1.06,1.06-2.28,2.03-3.65,2.84c-2.94,1.82-6.38,2.84-10.13,2.84
		c-3.7,0-7.14-1.01-10.08-2.84c-5.62-3.39-9.37-9.57-9.37-16.61V66.13h38.95V86.69z"/>
                <circle fill="currentColor" className="st0" cx="110.74" cy="55.91" r="10.91" />
                <path fill="currentColor" className="st0" d="M347.22,201.52c0,10.59-4.31,20.11-11.19,27.05c-6.94,6.94-16.51,11.19-27.05,11.19h-75.72V223.1h74.91
		c5.77,0,10.94-2.33,14.69-6.08c3.75-3.8,6.08-8.97,6.08-14.69c0-11.45-9.27-20.77-20.77-20.77h-56.88v15.85h40.32v17.68h-58.35
		v-51.82h75.72C330.09,163.28,347.22,180.4,347.22,201.52z"/>
                <rect fill="currentColor" x="175.97" y="163.52" className="st0" width="18.2" height="76.39" />
                <rect fill="currentColor" x="201.13" y="163.52" className="st0" width="18.2" height="76.39" />
                <path fill="currentColor" className="st0" d="M578.24,201.92c0,10.54-4.25,20.11-11.19,27s-16.46,11.19-27.05,11.19H392.24
		c-21.07,0-38.19-17.12-38.19-38.19v-38.19h18.89v28.67c0,14.74,10.94,26.9,25.17,28.77c-3.49-5.72-5.47-12.46-5.47-19.6
		c0-10.54,4.25-20.01,11.14-26.9c6.89-6.89,16.41-11.14,26.9-11.14h60.12c21.02,0,38.04,17.02,38.04,38.04
		c0,3.49-0.46,6.89-1.42,10.08h-23.55c1.47-2.74,2.33-5.83,2.33-9.17c0-10.54-8.51-19.04-19.04-19.04h-57.34
		c-5.27,0-9.98,2.13-13.42,5.57s-5.57,8.21-5.57,13.47c0,10.48,8.51,18.99,18.99,18.99h101.56c8.05,0,15.35-3.24,20.62-8.51
		c1.57-1.57,2.94-3.29,4.15-5.17c0.51-1.67,0.76-3.44,0.76-5.27v-1.67c0-4.41-1.52-8.46-4-11.65c-0.96-1.22-2.03-2.33-3.24-3.24
		l-22.24-22.24l-0.46-0.46l-13.93-13.93l14.18-14.23l39.81,39.81c5.01,4.96,8.61,11.3,10.18,18.44
		C577.88,196.1,578.24,198.99,578.24,201.92z"/>
            </g>
        </svg>
    );
};

export default AHNameColumn;
