type Props = {
  className?: string;
};
const FacebookIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3334 13.4918C18.3334 16.5251 16.5251 18.3334 13.4918 18.3334H12.5001C12.0417 18.3334 11.6667 17.9584 11.6667 17.5001V12.6918C11.6667 12.4668 11.8501 12.2751 12.0751 12.2751L13.5417 12.2501C13.6584 12.2418 13.7584 12.1584 13.7834 12.0418L14.0751 10.4501C14.1001 10.3001 13.9834 10.1584 13.8251 10.1584L12.0501 10.1834C11.8167 10.1834 11.6334 10.0001 11.6251 9.77509L11.5918 7.73341C11.5918 7.60008 11.7001 7.48342 11.8417 7.48342L13.8417 7.45008C13.9834 7.45008 14.0918 7.34176 14.0918 7.20009L14.0584 5.20007C14.0584 5.05841 13.9501 4.95008 13.8084 4.95008L11.5584 4.98342C10.1751 5.00842 9.07509 6.14175 9.10009 7.52508L9.14175 9.81675C9.15008 10.0501 8.96676 10.2334 8.73342 10.2418L7.73341 10.2584C7.59175 10.2584 7.48342 10.3667 7.48342 10.5084L7.50842 12.0918C7.50842 12.2334 7.61675 12.3417 7.75841 12.3417L8.75842 12.3251C8.99176 12.3251 9.17507 12.5084 9.18341 12.7334L9.2584 17.4834C9.26674 17.9501 8.89174 18.3334 8.42507 18.3334H6.50841C3.47508 18.3334 1.66675 16.5251 1.66675 13.4834V6.50841C1.66675 3.47508 3.47508 1.66675 6.50841 1.66675H13.4918C16.5251 1.66675 18.3334 3.47508 18.3334 6.50841V13.4918Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FacebookIcon;
