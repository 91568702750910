import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    alertData: {
      type: "",
      title: "",
      description: "",
      link: "",
      confirmTitle: "",
      cancelTitle: "",
      image: "",
    },
    // Add more modal states as needed
  },
  reducers: {
    setAlertData: (state, action) => {
      state.alertData = action.payload;
    },
  },
});

export const { setAlertData } = alertSlice.actions;
export default alertSlice.reducer;
