type Props = {
  className?: string;
};
const LocationPinIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00016 10.0725C10.2925 10.0725 11.3402 9.0248 11.3402 7.73246C11.3402 6.44011 10.2925 5.39246 9.00016 5.39246C7.70781 5.39246 6.66016 6.44011 6.66016 7.73246C6.66016 9.0248 7.70781 10.0725 9.00016 10.0725Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M2.71527 6.3675C4.19277 -0.127498 13.8153 -0.119998 15.2853 6.375C16.1478 10.185 13.7778 13.41 11.7003 15.405C10.1928 16.86 7.80777 16.86 6.29277 15.405C4.22277 13.41 1.85277 10.1775 2.71527 6.3675Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LocationPinIcon;
