import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/auth";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { handelValidationMap } from "../../util/handelValidationMap";
import { useIntl } from "react-intl";
import { CreateUserProp } from "../../model/User";
import { PHONE_NUMBER_REGULAR_EXPRESSION } from "../../static/constants";
export const useHandelProfile = () => {
  const auth = useAuth();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const queryClient = useQueryClient();
  const validations = handelValidationMap(intl);

  type FormValuesType = {
    name: string;
    phone: string;
    whatsapp?: string;
    agencyName?: string;
  };

  const userSchema = object().shape({
    name: string().required(validations["name_required"]),
    phone: string()
      .required(validations["phone_required"])
      .matches(PHONE_NUMBER_REGULAR_EXPRESSION, validations["invalid_phone"]),
    whatsapp: string().test(
      "whatsapp",
      validations["invalid_whatsapp"],
      (value) => {
        if (value) {
          const match = value.match(PHONE_NUMBER_REGULAR_EXPRESSION);
          return match ? true : false;
        } else {
          return true;
        }
      }
    ),
  });

  const agencySchema = object().shape({
    name: string().required(validations["name_required"]),
    phone: string()
      .required(validations["phone_required"])
      .matches(PHONE_NUMBER_REGULAR_EXPRESSION, validations["invalid_phone"]),
    whatsapp: string().test(
      "whatsapp",
      validations["invalid_whatsapp"],
      (value) => {
        if (value) {
          const match = value.match(PHONE_NUMBER_REGULAR_EXPRESSION);
          return match ? true : false;
        } else {
          return true;
        }
      }
    ),

    agencyName: string().required(validations["agencyName_required"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormValuesType>({
    resolver: yupResolver(auth?.user?.roleId === 2 ? agencySchema : userSchema),
    mode: "onChange",
  });

  const updateMutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "PUT",
        endpoint: `/update-profile`,
        data: doUser.data,
        requiredAuth: true,
      });
    },
    onSuccess: (data) => {
      setLoading(false);
      setCurrentStep(currentStep + 1);
      queryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
    onError: (error) => {
      setLoading(false);
      console.error("eeeeeee", error);
    },
  });

  // UserProp
  const onUpdateProfile = async (user: CreateUserProp) => {
    setLoading(true);
    const userData = {
      data: user,
    };
    updateMutation.mutate(userData);
  };

  return {
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    onUpdateProfile,
    loading,
    currentStep,
    setCurrentStep,
  };
};
