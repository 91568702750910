type Props = {
  className?: string;
};
const MapIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.62533 6.24219C7.15866 6.24219 6.79199 6.61719 6.79199 7.07552C6.79199 7.53385 7.16699 7.90885 7.62533 7.90885C8.08366 7.90885 8.45866 7.53385 8.45866 7.07552C8.45866 6.61719 8.08366 6.24219 7.62533 6.24219Z"
        fill="#B7C1CF"
      />
      <path
        d="M17.8837 4.2013C17.1837 2.5763 15.642 1.66797 13.492 1.66797H6.50866C3.83366 1.66797 1.66699 3.83464 1.66699 6.50964V13.493C1.66699 15.643 2.57533 17.1846 4.20033 17.8846C4.35866 17.9513 4.54199 17.9096 4.65866 17.793L17.792 4.65964C17.917 4.53464 17.9587 4.3513 17.8837 4.2013ZM8.77533 10.2013C8.45033 10.518 8.02533 10.668 7.60033 10.668C7.17533 10.668 6.75033 10.5096 6.42533 10.2013C5.57533 9.4013 4.64199 8.1263 5.00033 6.60964C5.31699 5.23464 6.53366 4.61797 7.60033 4.61797C8.66699 4.61797 9.88366 5.23464 10.2003 6.61797C10.5503 8.1263 9.61699 9.4013 8.77533 10.2013Z"
        fill="#B7C1CF"
      />
      <path
        d="M16.2251 17.1099C16.4084 17.2932 16.3834 17.5932 16.1584 17.7182C15.4251 18.1266 14.5334 18.3349 13.4917 18.3349H6.50841C6.26674 18.3349 6.16674 18.0516 6.33341 17.8849L11.3667 12.8516C11.5334 12.6849 11.7917 12.6849 11.9584 12.8516L16.2251 17.1099Z"
        fill="#1BC457"
      />
      <path
        d="M18.3335 6.50743V13.4908C18.3335 14.5324 18.1251 15.4324 17.7168 16.1574C17.5918 16.3824 17.2918 16.3991 17.1085 16.2241L12.8418 11.9574C12.6751 11.7908 12.6751 11.5324 12.8418 11.3658L17.8751 6.33243C18.0501 6.16577 18.3335 6.26577 18.3335 6.50743Z"
        fill="#1BC457"
      />
    </svg>
  );
};

export default MapIcon;
