type Props = {
  className?: string;
};
const SubMenuIcon = ({ className }: Props) => {
  return (
    <svg
      width="15"
      height="33"
      className={className}
      viewBox="0 0 15 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5C13.7239 0.5 13.5 0.723858 13.5 1H14.5ZM14 30V30.5H14.5V30H14ZM0.833333 30C0.833333 31.4728 2.02724 32.6667 3.5 32.6667C4.97276 32.6667 6.16667 31.4728 6.16667 30C6.16667 28.5272 4.97276 27.3333 3.5 27.3333C2.02724 27.3333 0.833333 28.5272 0.833333 30ZM13.5 1V30H14.5V1H13.5ZM14 29.5H3.5V30.5H14V29.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SubMenuIcon;
