import PlusIcon from "../../../components/icons/PlusIcon";
import ChartCard from "../../../components/shared/Card/ChartCard";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import Loader from "../../../components/shared/Loader";
import { ROUTES } from "../../../constants/routes";
const Dashboard = () => {
  const intl = useIntl();
  const { ToGetMyListingCount } = useHandelListing({ intl });
  const response = ToGetMyListingCount();
  return (
    <>
      {response?.isLoading ? <div className="min-h-screen flex items-center justify-center"><Loader /> </div> : <div className="space-y-6 py-5 px-6">
        <div className="rounded-lg bg-white">
          <div className=" p-6">
            <Typo.body3 fontWeight="font-bold" className=" text-gray-900">
              <FormattedMessage id={TRANSLATION.DASHBOARD_PAGE_TITLE.id} defaultMessage={TRANSLATION.DASHBOARD_PAGE_TITLE.defaultMessage} />
            </Typo.body3>
            <Typo.body4 className="text-gray-600 mt-2">
              <FormattedMessage id={TRANSLATION.DASHBOARD_PAGE_DESCRIPTION.id} defaultMessage={TRANSLATION.DASHBOARD_PAGE_DESCRIPTION.defaultMessage} />
            </Typo.body4>
            <Button to={ROUTES.NEW_LISTING_ROUTE} theme="primary" size="medium" icon={<PlusIcon className="h-5" />} className="mt-4">
              <Typo.heading2 fontWeight="font-medium">
                <FormattedMessage id={TRANSLATION.ADD_NEW_LISTING.id} defaultMessage={TRANSLATION.ADD_NEW_LISTING.defaultMessage} />
              </Typo.heading2>
            </Button>
          </div>
        </div>
        <div className="pt-4 pb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 justify-between gap-4">
            <ChartCard intl={intl} type="sell" totalCount={response?.data?.count || 0} count={response?.data?.data?.sell || 0} />
            <ChartCard intl={intl} type="mortgage" totalCount={response?.data?.count || 0} count={response?.data?.data?.mortgage || 0} />
            <ChartCard intl={intl} type="rent" totalCount={response?.data?.count || 0} count={response?.data?.data?.rent || 0} />
          </div>
        </div>
      </div>}
    </>
  );
};

export default Dashboard;
