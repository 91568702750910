import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ChartOptions, registerables } from "chart.js";
import { Typo } from "../typo";

// Register the necessary chart components
Chart.register(...registerables);

type Props = {
  color: string;
  totalCount: number;
  count: number
}
const MyDoughnut: React.FC<Props> = (props) => {
  const { color, totalCount = 0, count = 0 } = props
  const renderChart = (percents: number[]) => {
    const data = {
      datasets: [
        {
          label: "جایداد",
          data: percents,
          backgroundColor: [color, "#F5F5F5"],
          borderColor: ["transparent", "#F5F5F5"],
          hoverOffset: 4,
          cutout: "75%",
          radius: "90%",
        },
      ],
    };


    return <Doughnut data={data} />;
  };

  const percents = [count, totalCount - count]; // Example data

  const getPercentage = () => {
    if (totalCount === 0) {
      // Handle the case where totalCount is 0 to avoid division by zero
      return 0;
    }
    const percentage = (count * 100) / totalCount;
    // Use the toFixed method to round the number to 1 decimal place
    return parseFloat(percentage.toFixed(1));
  };

  return (
    <div className="h-full w-full relative flex items-center justify-center">
      {renderChart(percents)}
      <div className="absolute">
        <Typo.body1 fontWeight="font-bold">{getPercentage()}%</Typo.body1>
      </div>
    </div>
  );
};

export default MyDoughnut;
