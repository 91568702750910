import React from "react";
import { Typo } from "../typo";
import Image from "../Image";
import { User } from "../../../model/User";
import ListingCardContact from "../ListingAction/ListingCardContact";
import { Agency } from "../../../model/Agency";
import clsx from "clsx";
type UserProp = {
  user?: User;
  agency?: Agency;
  phone?: string;
  className?: string;
  direction: string;
};

const UserInfo: React.FC<UserProp> = (props) => {
  const { user, phone, agency, className, direction } = props;
  return (
    <div className={clsx("w-full", className)}>
      <div className="flex flex-col items-center justify-center">
        <Image className="h-16 w-16 mx-auto rounded-full" src={user?.image} />
        <Typo.heading3 fontWeight="font-medium" className="text-gray-900 mt-2">
          {user?.name}
        </Typo.heading3>
        {agency?.name && (
          <Typo.body4 className="text-gray-600">
            {`${agency?.province?.state_fa} ${
              agency?.province?.state_fa && agency.name ? " ، " : " "
            } ${agency?.name}`}
          </Typo.body4>
        )}
        <div className="mt-6">
          <ListingCardContact
            phone={phone || user?.phone}
            whatsApp={user?.whatsapp}
            direction={direction}
          />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
