import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelUser } from "../../../hooks/user/useHandelUser";

const ChangePassword = () => {
  // Define the columns and data for the table
  const { register, errors, handleSubmit, onSubmit } = useHandelUser();

  const onLoging = async (data: any) => {
    await onSubmit(data);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onLoging)}
        >
          <div className="w-full rounded-lg bg-white py-10 px-14 space-y-8">
            <div className="">
              <div className="w-full flex flex-col space-y-8 py-10">
                <InputField
                  name="email"
                  label="رمز عبور قدیمی را وارد کنید"
                  placeholder="رمز عبور قدیمی را وارد کنید"
                  type="email"
                  register={register}
                  errors={errors?.email}
                  required={true}
                />
                <InputField
                  name="email"
                  label="رمز عبور جدید را وارد کنید"
                  placeholder="رمز عبور جدید را وارد کنید"
                  type="email"
                  register={register}
                  errors={errors?.email}
                  required={true}
                />
                <InputField
                  name="email"
                  label="رمز عبور جدید را وارد کنید"
                  placeholder="رمز عبور جدید را وارد کنید"
                  type="email"
                  register={register}
                  errors={errors?.email}
                  required={true}
                />
              </div>

              <div className="">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    ذخیره تغییرات
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
