import React from "react";
import { useTable } from "react-table";
import { Typo } from "./typo";

type Props = {
  columns: any;
  data: any;
};

const Table: React.FC<Props> = (props) => {
  // Use the useTable hook to create a table instance
  const { columns, data } = props;
  console.log("cccccccccc ", columns);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // Return a JSX element to render the table
  return (
    <div className="overflow-hidden rounded-lg">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-100"
      >
        <thead className="bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()} className="py-2">
              {headerGroup.headers.map((column) => (
                <th
                  scope="col"
                  className="px-3 py-3 text-center text-gray-900"
                  {...column?.getHeaderProps()}
                >
                  <Typo.body4>{column?.render("Header")}</Typo.body4>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="divide-y divide-gray-100 bg-white rounded-lg"
          {...getTableBodyProps()}
        >
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="py-3">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-2 text-center text-gray-700"
                    >
                      <Typo.body4>{cell.render("Cell")}</Typo.body4>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
