import { Outlet } from "react-router-dom";
import Footer from "../components/website/Footer";
import Header from "../components/website/Header";
import ShareModal from "../modal/ShareModal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../lib/hooks";
import { hideShare } from "../lib/reducers/share.slice";
import DownloadApp from "../pages/website/home/DownloadApp";
import ScrollToTop from "../util/scrollToTop";
import { useLang } from "../context/language";

export const PublicLayout = () => {
  const shareData = useAppSelector((state) => state.share.shareData);
  const dispatch = useDispatch();
  const lang = useLang();
  return (
    <ScrollToTop>
      <div className="relative">
        <Header />
        <main className="py-10">
          <Outlet />
        </main>

        <DownloadApp direction={lang.direction} />
        <Footer />
        <ShareModal
          data={shareData}
          open={shareData.state}
          onClose={() => dispatch(hideShare())}
        />
      </div>
    </ScrollToTop>
  );
};
