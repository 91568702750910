import React from "react";
import { Typo } from "../typo";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import AreaIcon from "../../icons/AreaIcon";
import Area from "../Area";
import BedIcon from "../../icons/BedIcon";
import BathIcon from "../../icons/BathIcon";
type Props = {
  unit?: string;
  area?: number;
  bathrooms?: number;
  bedrooms?: number;
  intl: IntlShape;
};

const ListingAttributes: React.FC<Props> = (props) => {
  const { area, unit, bathrooms, bedrooms, intl } = props;
  return (
    <div className="flex flex-row gap-x-4 pb-2">
      {area && area !== 0 && unit && (
        <div className="flex gap-x-1  items-center whitespace-nowrap">
          <AreaIcon className="h-4 w-4 text-gray-400" />
          <Area area={area} unit={unit} intl={intl} />
        </div>
      )}
      {bedrooms && bedrooms > 0 ? (
        <div className="flex gap-x-1 items-center whitespace-nowrap">
          <BedIcon className="h-4 w-4 text-gray-400" />
          <Typo.body4>
            <FormattedMessage
              id={TRANSLATION.BEDROOM_NUMBER.id}
              defaultMessage={TRANSLATION.BEDROOM_NUMBER.defaultMessage}
              values={{ value: bedrooms }}
            />
          </Typo.body4>
        </div>
      ) : (
        ""
      )}
      {bathrooms && bathrooms > 0 ? (
        <div className="flex gap-x-1 items-center whitespace-nowrap">
          <BathIcon className="h-4 w-4 text-gray-400" />
          <Typo.body4>
            <FormattedMessage
              id={TRANSLATION.BATHROOM_NUMBER.id}
              defaultMessage={TRANSLATION.BATHROOM_NUMBER.defaultMessage}
              values={{ value: bathrooms }}
            />
          </Typo.body4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListingAttributes;
