import { useState } from "react";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import { useAuth } from "../../../context/auth";
import NoListingFound from "../listings/noListingFound";
import Loader from "../../../components/shared/Loader";
import Pagination from "../../../components/shared/Pagination";
import ListingCardRow from "../../../components/shared/Card/ListingCardRow";
import { useLang } from "../../../context/language";
import { useIntl } from "react-intl";
const Favorites = () => {
  // Define the columns and data for the table
  const [page, setPage] = useState(1);
  const auth = useAuth();
  const user = auth?.user || null;
  const lang = useLang();
  const intl = useIntl();
  const { ToGetFavoriteListings, onLike, onDisLike } = useHandelListing({
    intl,
  });

  const listings = ToGetFavoriteListings({
    page: page,
    limit: 4,
    userId: user?.id,
    showFavorites: true,
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full bg-white rounded-lg">
        <div>
          {listings?.isLoading && <Loader className="h-screen" />}
          {!listings?.isLoading && listings?.data?.count === 0 && (
            <NoListingFound />
          )}
          <div className="divide-y divide-gray-200 divide-solid px-4 pt-6 pb-10">
            {!listings?.isLoading &&
            listings?.data?.count &&
            listings?.data?.count > 0
              ? listings?.data?.rows?.map((listing: any) => {
                  return (
                    <ListingCardRow
                      key={listing.id}
                      listing={listing}
                      user={user}
                      showUserInfo={true}
                      direction={lang.direction}
                      onLike={onLike}
                      onDisLike={onDisLike}
                      locale={lang.language}
                    />
                  );
                })
              : ""}
          </div>

          {listings?.data?.count && listings?.data?.count > 0 ? (
            <div className="px-10 pb-5">
              <Pagination
                onPageChange={setPage}
                total={listings?.data?.count}
                perPage={10}
                currentPage={page}
                type="centered-page-number"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
