type Props = {
  className?: string;
};
const Wind2Icon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35526)">
        <path
          d="M15.9583 8.14282C15.6042 8.14282 15.2708 8.26186 14.9792 8.45234C14.5625 7.71425 13.8542 7.21424 13.0417 7.21424C12.5833 7.21424 12.1458 7.38091 11.7708 7.64282C11.3542 7.04758 10.7083 6.66663 9.97917 6.66663C9.25 6.66663 8.625 7.04758 8.1875 7.64282C7.83333 7.3571 7.39583 7.21424 6.9375 7.21424C6.10417 7.21424 5.39583 7.71425 5 8.45234C4.70833 8.26186 4.375 8.14282 4.02083 8.14282C3.04167 8.14282 2.20833 8.95234 2.0625 10.0476H17.8958C17.7708 8.97615 16.9375 8.14282 15.9583 8.14282Z"
          fill="currentColor"
        />
        <path
          d="M19.1868 11.0952H0.811849C0.416016 11.0952 0.103516 11.4524 0.103516 11.9047V13.0714C0.103516 13.5238 0.416016 13.8809 0.811849 13.8809H1.10352V16.0952C1.10352 18.1905 2.58268 19.9047 4.43685 19.9047H15.6035C17.4368 19.9047 18.9368 18.2143 18.9368 16.0952V13.8809H19.2285C19.6243 13.8809 19.9368 13.5238 19.9368 13.0714V11.9047C19.8952 11.4762 19.5827 11.0952 19.1868 11.0952ZM17.6452 16.0952C17.6452 17.4047 16.7077 18.4762 15.5618 18.4762H12.1452C11.8952 18.4762 11.6868 18.2381 11.6868 17.9524C11.6868 17.6666 11.8952 17.4285 12.1452 17.4285H15.5827C16.2077 17.4285 16.7285 16.8333 16.7285 16.119V15.8095C16.7285 15.5238 16.9368 15.2857 17.1868 15.2857C17.4368 15.2857 17.6452 15.5238 17.6452 15.8095V16.0952Z"
          fill="currentColor"
        />
        <path
          d="M7.27057 5.2619C7.35391 5.35714 7.58307 5.54761 7.79141 5.40475C7.99974 5.2619 8.08307 5.04761 7.91641 4.64285C7.74974 4.23809 7.77057 3.7619 7.99974 3.16666C8.22891 2.54761 8.45807 1.97618 8.39557 1.35714C8.33307 0.738088 7.99974 0.309517 7.89557 0.190469C7.79141 0.095231 7.60391 0.0714215 7.47891 0.14285C7.29141 0.238088 7.20807 0.452374 7.31224 0.833326C7.41641 1.21428 7.52057 1.42856 7.20807 2.11904C6.91641 2.83333 6.68724 3.5238 6.81224 4.14285C6.91641 4.7619 7.18724 5.16666 7.27057 5.2619Z"
          fill="currentColor"
        />
        <path
          d="M9.66706 5.2619C9.75039 5.35714 9.97956 5.54761 10.1879 5.40475C10.3962 5.2619 10.4796 5.04761 10.3129 4.64285C10.1671 4.23809 10.1671 3.7619 10.3962 3.16666C10.6254 2.54761 10.8546 1.97618 10.7921 1.35714C10.7296 0.738088 10.3962 0.309517 10.2921 0.190469C10.1879 0.095231 10.0004 0.0714215 9.87539 0.14285C9.70872 0.238088 9.62539 0.452374 9.70872 0.809517C9.79206 1.16666 9.91706 1.40475 9.60456 2.09523C9.31289 2.80952 9.08372 3.49999 9.20872 4.11904C9.33372 4.7619 9.58372 5.16666 9.66706 5.2619Z"
          fill="currentColor"
        />
        <path
          d="M12.0831 5.2619C12.1664 5.35714 12.3956 5.54761 12.6039 5.40475C12.8122 5.2619 12.8956 5.04761 12.7289 4.64285C12.5831 4.23809 12.5831 3.7619 12.8122 3.16666C13.0414 2.54761 13.2706 1.97618 13.2081 1.35714C13.1456 0.738088 12.8122 0.309517 12.7081 0.190469C12.6039 0.095231 12.4164 0.0714215 12.2914 0.14285C12.1247 0.238088 12.0414 0.452374 12.1247 0.809517C12.2081 1.16666 12.3331 1.40475 12.0206 2.09523C11.7289 2.80952 11.4997 3.49999 11.6247 4.11904C11.7289 4.7619 11.9997 5.16666 12.0831 5.2619Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35526">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Wind2Icon;
