import React, { ReactElement } from "react";
import { Typo } from "../typo";
import { FormattedMessage, MessageDescriptor } from "react-intl";
type Prop = {
  children?: ReactElement | any;
  title: MessageDescriptor;
  description?: string;
};

const ListingDetailsContainer: React.FC<Prop> = (props) => {
  const { children, title, description } = props;
  return (
    <div className="rounded-xl border flex flex-col gap-y-10 border-gray-200 bg-white pt-6 px-4 sm:px-6 md:px-8 lg:px-10 pb-16">
      <div className="space-y-3">
        <Typo.headingD fontWeight="font-bold">
          <FormattedMessage
            id={title.id}
            defaultMessage={title.defaultMessage}
          />
        </Typo.headingD>
        {description && (
          <Typo.body4 className="text-gray-600">{description}</Typo.body4>
        )}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default ListingDetailsContainer;
