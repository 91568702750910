import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { useEffect, useState } from "react";
import { useHandelCategory } from "../../../../hooks/category/useHandelCategory";
import { useHandelProvince } from "../../../../hooks/location/useHandelProvince";
import {
  getBuildYears,
  getCurrencies,
  getDocuments,
  getElectricityBackups,
  getFloors,
  getGolas,
  getNomas,
  getUnits,
} from "../../../../static/constants";
import { ImageFile } from "../../../../model/Image";
import { useHandelListing } from "../../../../hooks/listing/useHandelListing";
import { TRANSLATION } from "../../../../static/translation";
import { Typo } from "../../../../components/shared/typo";
import SectionContainer from "../sectionContainer";
import Home3Icon from "../../../../components/icons/Home3Icon";
import PropertyLabel from "../propertyLabel";
import Mondy3Icon from "../../../../components/icons/Money3Icon";
import Button from "../../../../components/shared/button";
import BulkHomeIcon from "../../../../components/icons/BulkHomeIcon";
import Tab from "../../../../components/shared/Tab";
import GpsIcon from "../../../../components/icons/GpsIcon";
import Dropdown from "../../../../components/shared/Dropdown";
import { handelInputMap } from "../../../../util/handelInputMap";
import ProvinceDropdown from "../../../../components/shared/ProvinceDropdown";
import DollarSquareIcon from "../../../../components/icons/DollarSquare";
import InputField from "../../../../components/shared/InputField";
import Maximize3Icon from "../../../../components/icons/Maximize3";
import HomeHashtagIcon from "../../../../components/icons/HomeHashtagIcon";
import BedIcon from "../../../../components/icons/BedIcon";
import BathIcon from "../../../../components/icons/BathIcon";
import DocumentTextIcon from "../../../../components/icons/DocumentTextIcons";
import RankingIcon from "../../../../components/icons/RankingIcon";
import FeatureBadges from "../FeatureBadges";
import BulkTextIcon from "../../../../components/icons/BulkTextIcon";
import ReceiptTextIcon from "../../../../components/icons/ReceiptTextIcon";
import MessageTextIcon from "../../../../components/icons/MessageTextIcon";
import TextField from "../../../../components/shared/TextField";
import GalleryAddIcon from "../../../../components/icons/GalleryAddIcon";
import ImageUploader from "../../../../components/shared/ImageUploader";
import VideoAddIcon from "../../../../components/icons/VideoAddIcon";
import VideoUploader from "../../../../components/shared/VideoUploader";
import CallCallingIcon from "../../../../components/icons/CallCallingIcon";
import AddEmailIcon from "../../../../components/icons/AddEmailIcon";
import AddCallIcon from "../../../../components/icons/AddCallIcon";
import FeatureModal from "../../../../modal/FeatureModal";
import Map from "../../../../components/shared/Map";
import { useParams } from "react-router-dom";
import { Location } from "../../../../model/Location";
import Edit2Icon from "../../../../components/icons/Edit2Icon";
import { Category } from "../../../../model/Category";
import { ListingFile } from "../../../../model/Listing";
import { useLang } from "../../../../context/language";
import {
  getLocaleCategories,
  getLocaleProvinces,
  getLocalTitle,
} from "../../../../util/appUtil";
const EditListing = () => {
  // Define the columns and data for the table
  const intl = useIntl();
  const lang = useLang();
  const nomas = getNomas(intl);
  const floors = getFloors(intl);
  const goals = getGolas(intl);
  const documents = getDocuments(intl);
  const currencies = getCurrencies(intl);
  const units = getUnits(intl);
  const electricityBackups = getElectricityBackups(intl);
  const { slug } = useParams();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetProvinces } = useHandelProvince();
  const [location, setLocation] = useState<Location>();
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<DropdownOptionModel>();
  const [purpose, setPurpose] = useState<DropdownOptionModel>(goals[0]);
  const [document, setDocument] = useState<DropdownOptionModel>(documents[0]);
  const [selectedUnit, setSelectedUnit] = useState<DropdownOptionModel>(
    units[0]
  );
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownOptionModel>(
    currencies[0]
  );
  const [images, setImages] = useState<ImageFile[]>([]);
  const [thumbnail, setThumbnail] = useState("");
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [defaultFacilities, setDefaultFacilities] = useState<any>();
  const [showFeature, setShowFeature] = useState(false);
  const provinceResponse = ToGetProvinces(1, -1);
  const categoryResponse = ToGetCategories();
  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const {
    register,
    errors,
    handleSubmit,
    onUpdate,
    valueChange,
    setValue,
    featureValues,
    defaultFeatures,
    setFeatureValues,
    loading,
    ToGetListingDetails,
    onCancel,
  } = useHandelListing({ defaultFacilities: defaultFacilities, intl });

  const listing = ToGetListingDetails(`${slug}`);

  const onSubmitListing = async (data: any) => {
    if (location && selectedSubCategory && selectedProvince && listing?.data) {
      await onUpdate(
        data,
        images,
        thumbnail,
        deletedImages,
        listing?.data,
        selectedSubCategory,
        selectedCurrency,
        selectedUnit,
        document,
        purpose,
        selectedProvince,
        location
      );
    }
  };

  const onCloseFeature = (state: boolean) => {
    if (!state) {
      setFeatureValues(defaultFacilities);
    }
    setShowFeature(false);
  };

  const onChangeCategory = (category: DropdownOptionModel) => {
    setSelectedCategory(category);
    categoryResponse?.data?.map((cat: any) => {
      if (cat.id === category.id) {
        const subCategory: any = selectedSubCategory;
        if (subCategory?.categoryId !== category?.id) {
          setSelectedSubCategory(cat?.subCategories[0]);
        }
      }
    });
  };

  useEffect(() => {
    if (listing?.data) {
      setValue("phone", listing.data.phone);
      setValue("email", listing.data.email);
      setValue("title", listing?.data?.title || "");
      setValue("description", listing.data.description);
      setValue("room", listing?.data?.bedrooms ?? undefined);
      setValue("bath", listing?.data?.bathrooms ?? undefined);
      setValue("description", listing.data.description);
      setValue("price", listing?.data?.price);
      setValue("area", listing?.data?.area);

      const locationData = {
        id: listing?.data?.locationId,
        address: listing?.data?.location?.address,
        longitude: listing?.data?.location?.longitude,
        latitude: listing?.data?.location?.latitude,
        placeId: listing?.data?.location?.placeId,
      };

      const category: any = categoryResponse?.data?.find(
        (item) => item.id === listing?.data?.subCategory?.categoryId
      );
      const subCategory = category?.subCategories.find(
        (item: any) => item.id === listing.data.subCategoryId
      );

      if (locationData) {
        setLocation(locationData);
      }

      if (category) {
        setSelectedCategory(category);
      }
      if (subCategory) {
        setSelectedSubCategory(subCategory);
      }

      const goal = goals.find((item) => item.id === listing.data.purpose);
      if (goal) setPurpose(goal);

      const currencyItem = currencies.find(
        (item) => item.id === listing.data.currency
      );
      const unitItem = units?.find((item) => item.id === listing.data.unit);

      if (unitItem) {
        setSelectedUnit(unitItem);
      }
      if (currencyItem) {
        setSelectedCurrency(currencyItem);
      }

      setSelectedProvince(
        provinces.find(
          (item: any) => item.id === listing.data?.location?.provinceId
        )
      );

      const nomaItem = nomas.find((item) => item.id === listing.data.noma);
      const floorItem = floors.find((item) => item.id === listing.data.floor);

      const builtYearItem = getBuildYears().find(
        (item) => item.id === listing.data.builtYear
      );

      const electricityBackupItem = electricityBackups.find(
        (item) => item.id === listing.data.electricityBackup
      );

      const features = {
        noma: nomaItem || { id: "", title: "" },
        floor: floorItem || { id: "", title: "" },
        builtYear: builtYearItem || { id: "", title: "" },
        floors: listing.data.floors ? listing.data.floors : undefined,
        parkingSpace: listing.data.parkingSpace
          ? listing.data.parkingSpace
          : undefined,
        electricityBackup: electricityBackupItem || { id: "", title: "" },
        otherFeature: listing.data.otherFeature,
        kitchens: listing.data.kitchens ? listing.data.kitchens : undefined,
        warehouse: listing.data.warehouse ? listing.data.warehouse : undefined,
        cabinet: listing.data.cabinet,
        water: listing.data.water,
        electricity: listing.data.electricity,
        centralHeating: listing.data.centralHeating,
        sewerage: listing.data.sewerage,
        mosque: listing.data.mosque,
        facilitiesForDisabled: listing.data.facilitiesForDisabled,
        nearbySchools: listing.data.nearbySchools,
        nearbyMalls: listing.data.nearbyMalls,
        nearbyHospitals: listing.data.nearbyHospitals,
        nearbyRestaurants: listing.data.nearbyRestaurants,
        nearbyPublicTransports: listing.data.nearbyPublicTransports,
        otherNearby: listing.data.otherNearby,
        otherFacilities: listing.data.otherFacilities,
        furnished: listing.data.furnished,
        lift: listing.data.lift,
        securityComera: listing.data.securityComera,
        internetCabel: listing.data.internetCabel,
        boundryWall: listing.data.boundryWall,
        corner: listing.data.corner,
        securityStaff: listing.data.securityStaff,
        maintenanceStaff: listing.data.maintenanceStaff,
        airConditioning: listing.data.airConditioning,
        swimmingPool: listing.data.swimmingPool,
        jacuzzi: listing.data.jacuzzi,
        sauna: listing.data.sauna,
        gym: listing.data.gym,
        garden: listing.data.garden,
        otherRecreation: listing.data.otherRecreation,
      };
      const imagesFiles = listing?.data?.listingFiles?.filter(
        (item: any) => item.isImage
      );
      const videosFiles = listing?.data?.listingFiles?.filter(
        (item: any) => !item?.isImage && item !== null
      );

      if (listing?.data?.thumbnail) {
        setThumbnail(listing?.data?.thumbnail);
        const thumbnailImage: ListingFile = {
          id: 0, // Replace with the actual id
          url: listing?.data?.thumbnail,
          isImage: true,
          listingId: listing.data.id,
          alt: "thumbnail",
        };
        imagesFiles.unshift(thumbnailImage);
      }

      setImages(
        imagesFiles?.map((item: any) => ({ id: item.id, file: item.url }))
      );
      setVideos(videosFiles.map((file) => file.url));
      setDefaultFacilities(features);
    }
  }, [listing?.data]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full bg-white pb-24 rounded-lg">
        <form
          id="listing"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmitListing)}
        >
          <div className="w-full p-8 shadow-shadow2">
            <Typo.body2
              fontWeight="font-bold"
              className="text-gray-900 text-center"
            >
              <FormattedMessage
                id={TRANSLATION.EDIT_LISTING_TITLE.id}
                defaultMessage={TRANSLATION.EDIT_LISTING_TITLE.defaultMessage}
              />
            </Typo.body2>
            <Typo.body4
              fontWeight="font-light"
              className="text-gray-400 mt-3 text-center"
            >
              <FormattedMessage
                id={TRANSLATION.REGISTER_NEW_LISTING_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.REGISTER_NEW_LISTING_DESCRIPTION.defaultMessage
                }
              />
            </Typo.body4>
          </div>
          <div className=" max-w-3xl mx-auto w-full ">
            <SectionContainer
              className="mt-14"
              title={TRANSLATION.DEAL_AND_LOCATION_TITLE}
              icon={<Home3Icon className="h-6" />}
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.GOAL}
                    icon={<Mondy3Icon />}
                  />
                  <div className="flex flex-wrap gap-4 ps-7 items-center">
                    {goals?.map((goal) => {
                      return (
                        <Button
                          key={goal.id}
                          size="small"
                          onClick={() => setPurpose(goal)}
                          theme={
                            goal.id === purpose.id ? "stroke-primary" : "stroke"
                          }
                        >
                          <Typo.heading5 fontWeight="font-medium">
                            {goal.title}
                          </Typo.heading5>
                        </Button>
                      );
                    })}
                  </div>
                </div>

                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.LISTING_TYPE}
                    icon={<BulkHomeIcon />}
                  />
                  <Tab
                    type="underline"
                    tabs={
                      getLocaleCategories(
                        lang.language,
                        categoryResponse?.data
                      ) || []
                    }
                    currentTab={selectedCategory}
                    onTabChange={onChangeCategory}
                    showDropdown={false}
                    label={lang.language}
                  />
                  {categoryResponse?.data?.map((category: Category) => {
                    return (
                      <div
                        key={category?.id}
                        className={` gap-4 ps-7 items-center ${
                          selectedCategory?.id === category?.id
                            ? "flex flex-wrap"
                            : "hidden"
                        } `}
                      >
                        {category?.subCategories?.map((subCategory: any) => {
                          return (
                            <Button
                              key={subCategory?.id}
                              size="small"
                              onClick={() =>
                                setSelectedSubCategory(subCategory)
                              }
                              theme={
                                selectedSubCategory &&
                                subCategory?.id === selectedSubCategory?.id
                                  ? "stroke-primary"
                                  : "stroke"
                              }
                            >
                              <Typo.heading5 fontWeight="font-medium">
                                {getLocalTitle(
                                  lang.language,
                                  subCategory?.title_fa,
                                  subCategory?.title_ps,
                                  subCategory?.title_en
                                )}
                              </Typo.heading5>
                            </Button>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.LISTING_LOCATION}
                    icon={<GpsIcon />}
                  />
                  <div className="w-full mt-5 ps-7 flex flex-col sm:grid sm:grid-cols-2 gap-6">
                    <Dropdown
                      options={provinces || []}
                      defaultValue={selectedProvince}
                      onChange={setSelectedProvince}
                      placeholder={handelInputMap(intl, "province_placeholder")}
                    />
                    <ProvinceDropdown
                      defaultValue={{
                        address: listing?.data?.location?.address || "",
                        placeId: listing?.data?.location?.placeId || "",
                      }}
                      onItemSelect={(location: any) => setLocation(location)}
                      placeholder={handelInputMap(intl, "address_placeholder")}
                    />
                    <div className=" hidden sm:flex col-span-2 sm:flex-col gap-y-8">
                      <Map />
                      {/* <div className="flex justify-between items-center">
                        <div className="flex items-center gap-x-2">
                          <DangerIcon />
                          <Typo.body4 className="text-gray-900">
                            موقعیت مکانی شما دقیق نشان داده نشود
                          </Typo.body4>
                        </div>
                        <div>
                          <Toggle defaultValue={true} onChange={() => ({})} />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.PRICE_AND_AREA}
              icon={<DollarSquareIcon className="h-6" />}
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.PRICE}
                    icon={<Mondy3Icon />}
                  />
                  <div className="w-full mt-5 ps-7 grid grid-cols-7 sm:grid-cols-6 gap-x-6 gap-y-8">
                    <div className="col-span-4 md:col-span-5">
                      <InputField
                        placeholder={handelInputMap(intl, "price_placeholder")}
                        register={register}
                        name="price"
                        errors={errors.price}
                      />
                    </div>
                    <div className=" col-span-3 sm:col-span-2 md:col-span-1">
                      <Dropdown
                        options={currencies || []}
                        defaultValue={selectedCurrency}
                        onChange={setSelectedCurrency}
                        placeholder={handelInputMap(intl, "currency")}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.AREA}
                    icon={<Maximize3Icon />}
                  />
                  <div className="w-full mt-5 ps-7 grid grid-cols-7 sm:grid-cols-6 gap-x-6 gap-y-8">
                    <div className="col-span-4 md:col-span-5">
                      <InputField
                        placeholder={handelInputMap(intl, "area_placeholder")}
                        register={register}
                        name="area"
                        errors={errors.area}
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-2 md:col-span-1">
                      <Dropdown
                        options={units || []}
                        defaultValue={selectedUnit}
                        onChange={setSelectedUnit}
                        placeholder={handelInputMap(intl, "unit_placeholder")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.FACILITIES_AND_CONVENIENVE}
              icon={<HomeHashtagIcon className="h-6" />}
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.BEDROOM}
                    icon={<BedIcon className="h-5 w-5 text-gray-300" />}
                  />
                  <div className="w-full mt-5 ps-7">
                    <InputField
                      placeholder={handelInputMap(intl, "bedroom_placeholder")}
                      register={register}
                      name="bath"
                      errors={errors.bath}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.BATHROOM}
                    icon={<BathIcon className="h-5 w-5 text-gray-300" />}
                  />
                  <div className="w-full mt-5 ps-7">
                    <InputField
                      placeholder={handelInputMap(intl, "bathroom_placeholder")}
                      register={register}
                      name="room"
                      errors={errors.room}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.DOCUMENT_TYPE}
                    icon={<DocumentTextIcon />}
                  />
                  <div className="w-full mt-5 ps-7 flex flex-wrap gap-4">
                    {documents?.map((item) => {
                      return (
                        <Button
                          key={item.id}
                          onClick={() => setDocument(item)}
                          theme={
                            item.id === document.id
                              ? "stroke-primary"
                              : "stroke"
                          }
                        >
                          <Typo.heading5 fontWeight="font-medium">
                            {item.title}
                          </Typo.heading5>
                        </Button>
                      );
                    })}
                  </div>
                </div>

                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.FACILITIES_AND_CONVENIENVE}
                    icon={<RankingIcon />}
                  />
                  <div className="w-full mt-5 ps-7 flex flex-col gap-y-6">
                    <Typo.body5 className="text-gray-600">
                      <FormattedMessage
                        id={TRANSLATION.MORE_FEATURE_DESCRIPTION.id}
                        defaultMessage={
                          TRANSLATION.MORE_FEATURE_DESCRIPTION.defaultMessage
                        }
                      />
                    </Typo.body5>
                    <div>
                      <Button
                        onClick={() => setShowFeature(true)}
                        theme="primary"
                        size="small"
                      >
                        <Typo.heading5 fontWeight="font-medium">
                          <FormattedMessage
                            id={TRANSLATION.ADD_MORE_FEATURE.id}
                            defaultMessage={
                              TRANSLATION.ADD_MORE_FEATURE.defaultMessage
                            }
                          />
                        </Typo.heading5>
                      </Button>
                    </div>
                  </div>
                  <div className="w-full">
                    <FeatureBadges
                      valueChange={valueChange}
                      featureValues={featureValues}
                    />
                  </div>
                </div>
              </div>
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.ADDITIONAL_INFORMATION}
              icon={<ReceiptTextIcon className="h-6" />}
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.TITLE}
                    icon={<BulkTextIcon className="h-5 w-5" />}
                  />
                  <div className="w-full mt-5 ps-7">
                    <InputField
                      placeholder={handelInputMap(intl, "listing_title")}
                      register={register}
                      name="title"
                      errors={errors.title}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.DESCRIPTION}
                    icon={<MessageTextIcon className="h-5 w-5" />}
                  />
                  <div className="w-full mt-5 ps-7">
                    <TextField
                      placeholder={handelInputMap(intl, "listing_description")}
                      register={register}
                      name="description"
                      errors={errors?.description}
                    />
                  </div>
                </div>
              </div>
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.ADD_IMAGE}
              icon={<GalleryAddIcon className="h-6 w-6" />}
            >
              <ImageUploader
                images={images}
                maxImages={20}
                setImages={setImages}
                deletedImages={deletedImages}
                setDeletedImages={setDeletedImages}
                thumbnail={thumbnail}
                setThumbnail={setThumbnail}
              />
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.ADD_VIDEO}
              icon={<VideoAddIcon className="h-6" />}
            >
              <VideoUploader
                register={register}
                errors={errors}
                videos={videos}
                maxVideos={2}
                setVideoValue={(values: string[]) => setValue("videos", values)}
                setVideos={setVideos}
              />
            </SectionContainer>

            <SectionContainer
              className="mt-14"
              title={TRANSLATION.CONTACT_INFROMATION}
              icon={<CallCallingIcon className="h-6" />}
            >
              <div className="space-y-8">
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.EMAIL}
                    icon={<AddEmailIcon className="h-5 w-5" />}
                  />
                  <div className="w-full mt-5 ps-7">
                    <InputField
                      placeholder={handelInputMap(intl, "email_placeholder")}
                      register={register}
                      name="email"
                      dir="ltr"
                      errors={errors?.email}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <PropertyLabel
                    title={TRANSLATION.PHONE}
                    icon={<AddCallIcon className="h-5 w-5" />}
                  />
                  <div className="w-full mt-5 ps-7 gap-x-6 gap-y-8">
                    <InputField
                      name="phone"
                      label={handelInputMap(intl, "phone_placeholder")}
                      placeholder="07xxxxxxxx"
                      register={register}
                      defaultValue={listing?.data?.phone || ""}
                      errors={errors?.phone}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </SectionContainer>
            <div className="mt-10 flex gap-x-4">
              <Button
                theme="primary"
                type="submit"
                loading={loading}
                icon={<Edit2Icon className="h-5" />}
                className=""
              >
                <Typo.heading3 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.EDIT_LISTING.id}
                    defaultMessage={TRANSLATION.EDIT_LISTING.defaultMessage}
                  />
                </Typo.heading3>
              </Button>

              <Button onClick={onCancel} theme="secandory" className="">
                <Typo.heading3 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.CANCEL.id}
                    defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                  />
                </Typo.heading3>
              </Button>
            </div>
          </div>
        </form>
      </div>
      <FeatureModal
        featureValues={featureValues}
        valueChange={valueChange}
        open={showFeature}
        onClose={onCloseFeature}
      />
    </div>
  );
};

export default EditListing;
