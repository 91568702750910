import clsx from "clsx";
import React, { useState } from "react";
import { Typo } from "./typo";
import HomeFilterTab from "./HomeFilterTab";
import Button from "./button";
import Dropdown from "./Dropdown";
import { useHandelProvince } from "../../hooks/location/useHandelProvince";
import { DropdownOptionModel } from "../../model/DropdownOption";
import CategoryDropdown from "../CategoryDropdown";
import RangeDropdown from "./RangeDropdown";
import { useDispatch } from "react-redux";
import { addFilter } from "../../lib/reducers/filter.slice";
import {
  getCurrenciesWithLabel,
  getGolas,
  getUnitsWithLabel,
} from "../../static/constants";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { handelInputMap } from "../../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import { getLocaleProvinces } from "../../util/appUtil";
type Props = {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  className?: string;
  categories: any;
  locale: string;
};

const HomeFilter: React.FC<Props> = (props) => {
  const { className, categories = [], locale } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const goals = getGolas(intl);
  const units = getUnitsWithLabel(intl);
  const currencies = getCurrenciesWithLabel(intl);
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const [minArea, setMinArea] = useState<number>();
  const [maxArea, setMaxArea] = useState<number>();
  const [unit, setUnit] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedGoal, setSelectedGoal] = useState<DropdownOptionModel>(
    goals[0]
  );
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const { ToGetProvinces } = useHandelProvince();
  const provinceResponse = ToGetProvinces(1, -1);
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const provinces = getLocaleProvinces(locale, provinceResponse.data?.rows);
  const onFilter = () => {
    dispatch(
      addFilter({
        page: 1,
        purpose: selectedGoal.id,
        provinceId: selectedProvince?.id,
        subCategoryId: selectedCategory?.id,
        minArea: minArea,
        maxArea: maxArea,
        minPrice: minPrice,
        maxPrice: maxPrice,
        currency: currency,
        unit: unit,
      })
    );
    navigate(ROUTES.LISTINGS_ROUTE);
  };
  return (
    <div
      className={clsx(
        "w-full flex-col gap-y-[1px] justify-center mx-auto ",
        className
      )}
    >
      <HomeFilterTab
        selectedTab={selectedGoal}
        onChange={setSelectedGoal}
        options={goals}
      />
      <div
        className={clsx(
          "bg-white shadow-shadow2 pb-8 mt-[1px] pt-3 grid sm:max-lg:grid-cols-2  px-4  rounded-none lg:rounded-e-3xl lg:flex items-end  gap-x-5 rounded-b-3xl "
        )}
      >
        <CategoryDropdown
          options={categories || []}
          defaultValue={selectedCategory}
          onChange={setSelectedCategory}
          placeholder={handelInputMap(intl, "listing_type")}
          label={handelInputMap(intl, "listing_type")}
        />
        <Dropdown
          options={provinces || []}
          defaultValue={selectedProvince}
          onChange={setSelectedProvince}
          placeholder={handelInputMap(intl, "province")}
          label={handelInputMap(intl, "province_placeholder")}
        />
        <RangeDropdown
          minValue={minPrice}
          maxValue={maxPrice}
          setMaxValue={setMaxPrice}
          setMinValue={setMinPrice}
          label={handelInputMap(intl, "price")}
          placeholder={handelInputMap(intl, "price")}
          options={currencies}
          defaultOption={currencies[0]}
          intl={intl}
          onOptionSelect={(currency: string) => setCurrency(currency)}
          optionDefaultValue={currency}
        />
        <RangeDropdown
          minValue={minArea}
          maxValue={maxArea}
          setMaxValue={setMaxArea}
          setMinValue={setMinArea}
          label={handelInputMap(intl, "area")}
          options={units}
          placeholder={handelInputMap(intl, "area")}
          defaultOption={units[0]}
          intl={intl}
          onOptionSelect={(unit: string) => setUnit(unit)}
          optionDefaultValue={unit}
        />
        <div className="">
          <Button
            size="medium"
            theme="secandory"
            // to={ROUTES.LISTINGS_ROUTE}
            className="shadow-shadow1 mt-3 w-full sm:max-lg:w-[100px]"
            onClick={onFilter}
          >
            <Typo.heading4 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.SEARCH.id}
                defaultMessage={TRANSLATION.SEARCH.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeFilter;
