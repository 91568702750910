import { useHandelCategory } from "../../../hooks/category/useHandelCategory";
import HomeCategory from "./HomeCategory";
import Hero from "./Hero";
import FeaturedListings from "./FeaturedListings";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import PopularAgencies from "./PopularAgencies";
import Subscribe from "./Subscribe";
import { useHandelAgency } from "../../../hooks/user/useHandelAgency";
import { Category } from "../../../model/Category";
import { useIntl } from "react-intl";
import TopArticles from "./TopArticles";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import { useLang } from "../../../context/language";
import { useAuth } from "../../../context/auth";
import { getLocalTitle } from "../../../util/appUtil";
const Home = () => {
  const intl = useIntl();
  const lang = useLang();
  const auth = useAuth();
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetListings, onLike, onDisLike } = useHandelListing({ intl });
  const { ToGetPopularAgencies } = useHandelAgency();
  const { ToGetArticles } = useHandelArticle();
  const articles = ToGetArticles({ page: 1, limit: 10 });
  const categoryResponse = ToGetCategories();
  const listings = ToGetListings(
    {
      page: 1,
      limit: 10,
      featured: true,
    },
    false
  );

  const agencies = ToGetPopularAgencies({ page: 1, limit: 10 });
  const categories = categoryResponse?.data?.map((item: Category) => ({
    id: item?.id,
    label: getLocalTitle(
      lang.language,
      item?.title_fa,
      item?.title_ps,
      item?.title_en
    ),
    options: item.subCategories.map((subItem: any) => ({
      title: getLocalTitle(
        lang.language,
        subItem?.title_fa,
        subItem?.title_ps,
        subItem?.title_en
      ),
      id: subItem?.id || "",
      supportingTitle: subItem?.listingCount,
    })),
  }));
  return (
    <div>
      <Hero
        locale={lang.language}
        categories={categories || []}
        direction={lang.direction}
      />
      <HomeCategory categories={categories} direction={lang.direction} />
      <FeaturedListings
        listings={listings?.data?.rows}
        direction={lang.direction}
        user={auth?.user}
        intl={intl}
        onLike={onLike}
        onDisLike={onDisLike}
        locale={lang.language}
      />
      {/* <Services /> */}
      <Subscribe intl={intl} />
      <PopularAgencies
        direction={lang.direction}
        agencies={agencies?.data?.rows || []}
        locale={lang.language}
      />
      <TopArticles
        direction={lang.direction}
        articles={articles?.data?.rows || []}
      />
    </div>
  );
};

export default Home;
