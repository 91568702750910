import { TRANSLATION } from "../static/translation";

export const handelInputMap = (intl: any, key: string) => {
  const validations: { [key: string]: string } = {
    name: intl.formatMessage({
      id: TRANSLATION.NAME.id,
      defaultMessage: TRANSLATION.NAME.defaultMessage,
    }),
    name_placeholder: intl.formatMessage({
      id: TRANSLATION.NAME_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NAME_PLACEHOLDER.defaultMessage,
    }),
    phone_placeholder: intl.formatMessage({
      id: TRANSLATION.PHONE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.PHONE_PLACEHOLDER.defaultMessage,
    }),
    password_placeholder: intl.formatMessage({
      id: TRANSLATION.PASSWORD_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.PASSWORD_PLACEHOLDER.defaultMessage,
    }),
    privilege: intl.formatMessage({
      id: TRANSLATION.PRIVILEGE.id,
      defaultMessage: TRANSLATION.PRIVILEGE.defaultMessage,
    }),
    privilege_placeholder: intl.formatMessage({
      id: TRANSLATION.PRIVILEGE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.PRIVILEGE_PLACEHOLDER.defaultMessage,
    }),
    email: intl.formatMessage({
      id: TRANSLATION.EMAIL.id,
      defaultMessage: TRANSLATION.EMAIL.defaultMessage,
    }),
    email_placeholder: intl.formatMessage({
      id: TRANSLATION.EMAIL_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.EMAIL_PLACEHOLDER.defaultMessage,
    }),
    phone: intl.formatMessage({
      id: TRANSLATION.PHONE.id,
      defaultMessage: TRANSLATION.PHONE.defaultMessage,
    }),
    whatsapp: intl.formatMessage({
      id: TRANSLATION.WHATSAPP.id,
      defaultMessage: TRANSLATION.WHATSAPP.defaultMessage,
    }),
    country: intl.formatMessage({
      id: TRANSLATION.COUNTRY.id,
      defaultMessage: TRANSLATION.COUNTRY.defaultMessage,
    }),
    country_placeholder: intl.formatMessage({
      id: TRANSLATION.COUNTRY_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.COUNTRY_PLACEHOLDER.defaultMessage,
    }),
    province: intl.formatMessage({
      id: TRANSLATION.PROVINCE.id,
      defaultMessage: TRANSLATION.PROVINCE.defaultMessage,
    }),
    state_fa: intl.formatMessage({
      id: TRANSLATION.STATE_FA.id,
      defaultMessage: TRANSLATION.STATE_FA.defaultMessage,
    }),
    state_ps: intl.formatMessage({
      id: TRANSLATION.STATE_PS.id,
      defaultMessage: TRANSLATION.STATE_PS.defaultMessage,
    }),
    state_en: intl.formatMessage({
      id: TRANSLATION.STATE_EN.id,
      defaultMessage: TRANSLATION.STATE_EN.defaultMessage,
    }),
    province_placeholder: intl.formatMessage({
      id: TRANSLATION.PROVINCE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.PROVINCE_PLACEHOLDER.defaultMessage,
    }),
    address: intl.formatMessage({
      id: TRANSLATION.ADDRESS.id,
      defaultMessage: TRANSLATION.ADDRESS.defaultMessage,
    }),
    listing_address_placeholder: intl.formatMessage({
      id: TRANSLATION.LISTING_ADDRESS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.LISTING_ADDRESS_PLACEHOLDER.defaultMessage,
    }),
    address_placeholder: intl.formatMessage({
      id: TRANSLATION.ADDRESS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.ADDRESS_PLACEHOLDER.defaultMessage,
    }),
    noma_placeholder: intl.formatMessage({
      id: TRANSLATION.NOMA_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NOMA_PLACEHOLDER.defaultMessage,
    }),
    floor_placeholder: intl.formatMessage({
      id: TRANSLATION.FLOOR_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.FLOOR_PLACEHOLDER.defaultMessage,
    }),
    builtYear_placeholder: intl.formatMessage({
      id: TRANSLATION.builtYear_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.builtYear_PLACEHOLDER.defaultMessage,
    }),
    floors_placeholder: intl.formatMessage({
      id: TRANSLATION.FLOORS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.FLOORS_PLACEHOLDER.defaultMessage,
    }),
    parkingSpace_placeholder: intl.formatMessage({
      id: TRANSLATION.parkingSpace_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.parkingSpace_PLACEHOLDER.defaultMessage,
    }),
    electricityBackup_placeholder: intl.formatMessage({
      id: TRANSLATION.electricityBackup_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.electricityBackup_PLACEHOLDER.defaultMessage,
    }),
    kitchens_placeholder: intl.formatMessage({
      id: TRANSLATION.KITCHENS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.KITCHENS_PLACEHOLDER.defaultMessage,
    }),
    otherFeature_placeholder: intl.formatMessage({
      id: TRANSLATION.otherFeature_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.otherFeature_PLACEHOLDER.defaultMessage,
    }),
    nearbySchools_placeholder: intl.formatMessage({
      id: TRANSLATION.NEARBY_SCHOOLS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NEARBY_SCHOOLS_PLACEHOLDER.defaultMessage,
    }),
    nearbyMalls_placeholder: intl.formatMessage({
      id: TRANSLATION.NEARBY_MALLS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NEARBY_MALLS_PLACEHOLDER.defaultMessage,
    }),
    nearbyHospitals_placeholder: intl.formatMessage({
      id: TRANSLATION.NEARBY_HOSPITALS_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NEARBY_HOSPITALS_PLACEHOLDER.defaultMessage,
    }),
    nearbyRestaurants_placeholder: intl.formatMessage({
      id: TRANSLATION.NEARBY_RESTAURANT_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NEARBY_RESTAURANT_PLACEHOLDER.defaultMessage,
    }),
    nearbyPublicTransports_placeholder: intl.formatMessage({
      id: TRANSLATION.NEARBY_PUBLIC_TRANSPORTS_PLACEHOLDER.id,
      defaultMessage:
        TRANSLATION.NEARBY_PUBLIC_TRANSPORTS_PLACEHOLDER.defaultMessage,
    }),
    warehouse_placeholder: intl.formatMessage({
      id: TRANSLATION.WAREHOUSE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.WAREHOUSE_PLACEHOLDER.defaultMessage,
    }),
    otherFacilities_placeholder: intl.formatMessage({
      id: TRANSLATION.OTHER_FACILITIES_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.OTHER_FACILITIES_PLACEHOLDER.defaultMessage,
    }),
    price: intl.formatMessage({
      id: TRANSLATION.PRICE.id,
      defaultMessage: TRANSLATION.PRICE.defaultMessage,
    }),
    price_placeholder: intl.formatMessage({
      id: TRANSLATION.PRICE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.PRICE_PLACEHOLDER.defaultMessage,
    }),
    currency: intl.formatMessage({
      id: TRANSLATION.CURRENCY.id,
      defaultMessage: TRANSLATION.CURRENCY.defaultMessage,
    }),
    area: intl.formatMessage({
      id: TRANSLATION.AREA.id,
      defaultMessage: TRANSLATION.AREA.defaultMessage,
    }),
    area_placeholder: intl.formatMessage({
      id: TRANSLATION.AREA_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.AREA_PLACEHOLDER.defaultMessage,
    }),
    unit: intl.formatMessage({
      id: TRANSLATION.UNIT.id,
      defaultMessage: TRANSLATION.UNIT.defaultMessage,
    }),
    listing_title: intl.formatMessage({
      id: TRANSLATION.LISTING_TITLE.id,
      defaultMessage: TRANSLATION.LISTING_TITLE.defaultMessage,
    }),
    listing_code: intl.formatMessage({
      id: TRANSLATION.LISTING_CODE.id,
      defaultMessage: TRANSLATION.LISTING_CODE.defaultMessage,
    }),
    listing_state: intl.formatMessage({
      id: TRANSLATION.LISTING_STATE.id,
      defaultMessage: TRANSLATION.LISTING_STATE.defaultMessage,
    }),
    listing_description: intl.formatMessage({
      id: TRANSLATION.LISTING_DESCRIPTION.id,
      defaultMessage: TRANSLATION.LISTING_DESCRIPTION.defaultMessage,
    }),
    goal: intl.formatMessage({
      id: TRANSLATION.GOAL.id,
      defaultMessage: TRANSLATION.GOAL.defaultMessage,
    }),
    goal_placeholder: intl.formatMessage({
      id: TRANSLATION.GOAL_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.GOAL_PLACEHOLDER.defaultMessage,
    }),
    listing_type: intl.formatMessage({
      id: TRANSLATION.LISTING_TYPE.id,
      defaultMessage: TRANSLATION.LISTING_TYPE.defaultMessage,
    }),
    listing_type_placeholder: intl.formatMessage({
      id: TRANSLATION.LISTING_TYPE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.LISTING_TYPE_PLACEHOLDER.defaultMessage,
    }),
    bedroom: intl.formatMessage({
      id: TRANSLATION.BEDROOM.id,
      defaultMessage: TRANSLATION.BEDROOM.defaultMessage,
    }),
    bedroom_placeholder: intl.formatMessage({
      id: TRANSLATION.BEDROOM_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.BEDROOM_PLACEHOLDER.defaultMessage,
    }),
    bathroom: intl.formatMessage({
      id: TRANSLATION.BATHROOM.id,
      defaultMessage: TRANSLATION.BATHROOM.defaultMessage,
    }),
    bathroom_placeholder: intl.formatMessage({
      id: TRANSLATION.BATHROOM_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.BATHROOM_PLACEHOLDER.defaultMessage,
    }),
    title: intl.formatMessage({
      id: TRANSLATION.TITLE.id,
      defaultMessage: TRANSLATION.TITLE.defaultMessage,
    }),
    title_fa: intl.formatMessage({
      id: TRANSLATION.TITLE_FA.id,
      defaultMessage: TRANSLATION.TITLE_FA.defaultMessage,
    }),
    title_ps: intl.formatMessage({
      id: TRANSLATION.TITLE_PS.id,
      defaultMessage: TRANSLATION.TITLE_PS.defaultMessage,
    }),
    title_en: intl.formatMessage({
      id: TRANSLATION.TITLE_EN.id,
      defaultMessage: TRANSLATION.TITLE_EN.defaultMessage,
    }),
    title_placeholder: intl.formatMessage({
      id: TRANSLATION.TITLE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.TITLE_PLACEHOLDER.defaultMessage,
    }),
    agencyName: intl.formatMessage({
      id: TRANSLATION.AGENCY_NAME.id,
      defaultMessage: TRANSLATION.AGENCY_NAME.defaultMessage,
    }),
    agencyName_placeholder: intl.formatMessage({
      id: TRANSLATION.AGENCY_NAME_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.AGENCY_NAME_PLACEHOLDER.defaultMessage,
    }),
    landline: intl.formatMessage({
      id: TRANSLATION.LANDLINE.id,
      defaultMessage: TRANSLATION.LANDLINE.defaultMessage,
    }),
    landline_placeholder: intl.formatMessage({
      id: TRANSLATION.LANDLINE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.LANDLINE_PLACEHOLDER.defaultMessage,
    }),
    password: intl.formatMessage({
      id: TRANSLATION.PASSWORD.id,
      defaultMessage: TRANSLATION.PASSWORD.defaultMessage,
    }),
    what_do_you_want: intl.formatMessage({
      id: TRANSLATION.WHAT_DO_YOU_WANT.id,
      defaultMessage: TRANSLATION.WHAT_DO_YOU_WANT.defaultMessage,
    }),
    article_description_placeholder: intl.formatMessage({
      id: TRANSLATION.ARTICLE_DESCRIPTION_PLACEHOLDER.id,
      defaultMessage:
        TRANSLATION.ARTICLE_DESCRIPTION_PLACEHOLDER.defaultMessage,
    }),
    article_title_placeholder: intl.formatMessage({
      id: TRANSLATION.ARTICLE_TITLE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.ARTICLE_TITLE_PLACEHOLDER.defaultMessage,
    }),
    description: intl.formatMessage({
      id: TRANSLATION.DESCRIPTION.id,
      defaultMessage: TRANSLATION.DESCRIPTION.defaultMessage,
    }),
    article_image: intl.formatMessage({
      id: TRANSLATION.ARTICLE_IMAGE.id,
      defaultMessage: TRANSLATION.ARTICLE_IMAGE.defaultMessage,
    }),
    article_image_placeholder: intl.formatMessage({
      id: TRANSLATION.ARTICLE_IMAGE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.ARTICLE_IMAGE_PLACEHOLDER.defaultMessage,
    }),
    article_category: intl.formatMessage({
      id: TRANSLATION.ARTICLE_CATEGORY.id,
      defaultMessage: TRANSLATION.ARTICLE_CATEGORY.defaultMessage,
    }),
    article_category_placeholder: intl.formatMessage({
      id: TRANSLATION.ARTICLE_CATEGORY_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.ARTICLE_CATEGORY_PLACEHOLDER.defaultMessage,
    }),
    minimum: intl.formatMessage({
      id: TRANSLATION.MINIMUM.id,
      defaultMessage: TRANSLATION.MINIMUM.defaultMessage,
    }),
    maximum: intl.formatMessage({
      id: TRANSLATION.MAXIMUM.id,
      defaultMessage: TRANSLATION.MAXIMUM.defaultMessage,
    }),
    to: intl.formatMessage({
      id: TRANSLATION.TO.id,
      defaultMessage: TRANSLATION.TO.defaultMessage,
    }),
    actions: intl.formatMessage({
      id: TRANSLATION.ACTIONS.id,
      defaultMessage: TRANSLATION.ACTIONS.defaultMessage,
    }),
    city: intl.formatMessage({
      id: TRANSLATION.CITY.id,
      defaultMessage: TRANSLATION.CITY.defaultMessage,
    }),
    city_placeholder: intl.formatMessage({
      id: TRANSLATION.CITY_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.CITY_PLACEHOLDER.defaultMessage,
    }),
    agency: intl.formatMessage({
      id: TRANSLATION.AGENCY.id,
      defaultMessage: TRANSLATION.AGENCY.defaultMessage,
    }),
    agency_placeholder: intl.formatMessage({
      id: TRANSLATION.AGENCY_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.AGENCY_PLACEHOLDER.defaultMessage,
    }),
    documentType: intl.formatMessage({
      id: TRANSLATION.DOCUMENT_TYPE.id,
      defaultMessage: TRANSLATION.DOCUMENT_TYPE.defaultMessage,
    }),
    country_code: intl.formatMessage({
      id: TRANSLATION.COUNTRY_CODE.id,
      defaultMessage: TRANSLATION.COUNTRY_CODE.defaultMessage,
    }),
    country_code_placeholder: intl.formatMessage({
      id: TRANSLATION.COUNTRY_CODE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.COUNTRY_CODE_PLACEHOLDER.defaultMessage,
    }),
    country_name: intl.formatMessage({
      id: TRANSLATION.COUNTRY_NAME.id,
      defaultMessage: TRANSLATION.COUNTRY_NAME.defaultMessage,
    }),
    country_name_fa: intl.formatMessage({
      id: TRANSLATION.COUNTRY_NAME_FA.id,
      defaultMessage: TRANSLATION.COUNTRY_NAME_FA.defaultMessage,
    }),
    country_name_ps: intl.formatMessage({
      id: TRANSLATION.COUNTRY_NAME_PS.id,
      defaultMessage: TRANSLATION.COUNTRY_NAME_PS.defaultMessage,
    }),
    country_name_en: intl.formatMessage({
      id: TRANSLATION.COUNTRY_NAME_EN.id,
      defaultMessage: TRANSLATION.COUNTRY_NAME_EN.defaultMessage,
    }),
    country_name_placeholder: intl.formatMessage({
      id: TRANSLATION.COUNTRY_NAME_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.COUNTRY_NAME_PLACEHOLDER.defaultMessage,
    }),
    number: intl.formatMessage({
      id: TRANSLATION.NUMBER.id,
      defaultMessage: TRANSLATION.NUMBER.defaultMessage,
    }),
    main_category: intl.formatMessage({
      id: TRANSLATION.MAIN_CATEGORY.id,
      defaultMessage: TRANSLATION.MAIN_CATEGORY.defaultMessage,
    }),
    main_category_placeholder: intl.formatMessage({
      id: TRANSLATION.MAIN_CATEGORY_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.MAIN_CATEGORY_PLACEHOLDER.defaultMessage,
    }),
    confirmed: intl.formatMessage({
      id: TRANSLATION.CONFIRMED.id,
      defaultMessage: TRANSLATION.CONFIRMED.defaultMessage,
    }),
    role_placeholder: intl.formatMessage({
      id: TRANSLATION.ROLE_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.ROLE_PLACEHOLDER.defaultMessage,
    }),
    user_account_state: intl.formatMessage({
      id: TRANSLATION.USER_ACCOUNT_STATE.id,
      defaultMessage: TRANSLATION.USER_ACCOUNT_STATE.defaultMessage,
    }),
    user_search_placeholder: intl.formatMessage({
      id: TRANSLATION.USER_TITLE_SEARCH_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.USER_TITLE_SEARCH_PLACEHOLDER.defaultMessage,
    }),
    new_password: intl.formatMessage({
      id: TRANSLATION.ENTER_NEW_PASSWORD.id,
      defaultMessage: TRANSLATION.ENTER_NEW_PASSWORD.defaultMessage,
    }),
    new_password_placeholder: intl.formatMessage({
      id: TRANSLATION.NEW_PASSWORD_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.NEW_PASSWORD_PLACEHOLDER.defaultMessage,
    }),
    confirm_password: intl.formatMessage({
      id: TRANSLATION.ENTER_CONFIRM_PASSWORD.id,
      defaultMessage: TRANSLATION.ENTER_CONFIRM_PASSWORD.defaultMessage,
    }),
    confirm_password_placeholder: intl.formatMessage({
      id: TRANSLATION.CONFIRM_PASSWORD_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.CONFIRM_PASSWORD_PLACEHOLDER.defaultMessage,
    }),
    enter_otp: intl.formatMessage({
      id: TRANSLATION.ENTER_OTP.id,
      defaultMessage: TRANSLATION.ENTER_OTP.defaultMessage,
    }),
    otp_placeholder: intl.formatMessage({
      id: TRANSLATION.OTP_PLACEHOLDER.id,
      defaultMessage: TRANSLATION.OTP_PLACEHOLDER.defaultMessage,
    }),
    date: intl.formatMessage({
      id: TRANSLATION.DATE.id,
      defaultMessage: TRANSLATION.DATE.defaultMessage,
    }),
    notification_content: intl.formatMessage({
      id: TRANSLATION.NOTIFICATION_CONTENT.id,
      defaultMessage: TRANSLATION.NOTIFICATION_CONTENT.defaultMessage,
    }),
  };
  return validations[key];
};

export const handelStateMap = (intl: any, key: string) => {
  const states: { [key: string]: string } = {
    pending: intl.formatMessage({
      id: TRANSLATION.PENDING.id,
      defaultMessage: TRANSLATION.PENDING.defaultMessage,
    }),
    edited: intl.formatMessage({
      id: TRANSLATION.EDITED.id,
      defaultMessage: TRANSLATION.EDITED.defaultMessage,
    }),
    deleted: intl.formatMessage({
      id: TRANSLATION.DELETED.id,
      defaultMessage: TRANSLATION.DELETED.defaultMessage,
    }),
    active: intl.formatMessage({
      id: TRANSLATION.ACTIVE.id,
      defaultMessage: TRANSLATION.ACTIVE.defaultMessage,
    }),
    inactive: intl.formatMessage({
      id: TRANSLATION.INACTIVE.id,
      defaultMessage: TRANSLATION.INACTIVE.defaultMessage,
    }),
    expired: intl.formatMessage({
      id: TRANSLATION.EXPIRED.id,
      defaultMessage: TRANSLATION.EXPIRED.defaultMessage,
    }),
    rejected: intl.formatMessage({
      id: TRANSLATION.REJECTED.id,
      defaultMessage: TRANSLATION.REJECTED.defaultMessage,
    }),
  };
  return states[key];
};

export const handelAccountStepMap = (intl: any, key: string) => {
  const states: { [key: string]: string } = {
    account_type: intl.formatMessage({
      id: TRANSLATION.ACCOUNT_TYPE.id,
      defaultMessage: TRANSLATION.ACCOUNT_TYPE.defaultMessage,
    }),
    account_details: intl.formatMessage({
      id: TRANSLATION.ACCOUNT_DETAILS.id,
      defaultMessage: TRANSLATION.ACCOUNT_DETAILS.defaultMessage,
    }),
    account_location: intl.formatMessage({
      id: TRANSLATION.ACCOUNT_LOCATION.id,
      defaultMessage: TRANSLATION.ACCOUNT_LOCATION.defaultMessage,
    }),
    account_completion: intl.formatMessage({
      id: TRANSLATION.ACCOUNT_COMPLETION.id,
      defaultMessage: TRANSLATION.ACCOUNT_COMPLETION.defaultMessage,
    }),
  };
  return states[key];
};
