type Props = {
  className?: string;
};
const InstagramIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4917 1.66675H6.50841C3.47508 1.66675 1.66675 3.47508 1.66675 6.50841V13.4834C1.66675 16.5251 3.47508 18.3334 6.50841 18.3334H13.4834C16.5167 18.3334 18.3251 16.5251 18.3251 13.4917V6.50841C18.3334 3.47508 16.5251 1.66675 13.4917 1.66675ZM10.0001 13.2334C8.21675 13.2334 6.76675 11.7834 6.76675 10.0001C6.76675 8.21675 8.21675 6.76675 10.0001 6.76675C11.7834 6.76675 13.2334 8.21675 13.2334 10.0001C13.2334 11.7834 11.7834 13.2334 10.0001 13.2334ZM14.9334 5.73342C14.8917 5.83342 14.8334 5.92508 14.7584 6.00841C14.6751 6.08342 14.5834 6.14175 14.4834 6.18341C14.3834 6.22508 14.2751 6.25008 14.1667 6.25008C13.9417 6.25008 13.7334 6.16675 13.5751 6.00841C13.5001 5.92508 13.4417 5.83342 13.4001 5.73342C13.3584 5.63341 13.3334 5.52508 13.3334 5.41675C13.3334 5.30841 13.3584 5.20008 13.4001 5.10008C13.4417 4.99175 13.5001 4.90841 13.5751 4.82508C13.7667 4.63341 14.0584 4.54175 14.3251 4.60008C14.3834 4.60841 14.4334 4.62508 14.4834 4.65008C14.5334 4.66675 14.5834 4.69175 14.6334 4.72508C14.6751 4.75008 14.7167 4.79175 14.7584 4.82508C14.8334 4.90841 14.8917 4.99175 14.9334 5.10008C14.9751 5.20008 15.0001 5.30841 15.0001 5.41675C15.0001 5.52508 14.9751 5.63341 14.9334 5.73342Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstagramIcon;
