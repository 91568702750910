import React from "react";
import { Typo } from "../../../components/shared/typo";
import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import vector from "../../../assets/image/vector.svg";
import building from "../../../assets/image/building.svg";
import groupBuilding from "../../../assets/image/group-building.svg";
import Image from "../../../components/shared/Image";
import SendIcon from "../../../components/icons/SendIcon";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useHandelSubscribe } from "../../../hooks/subscribe/useHandelSubscribe";
import { handelInputMap } from "../../../util/handelInputMap";
type Props = {
  className?: string;
  intl: IntlShape;
};

const Subscribe: React.FC<Props> = (props) => {
  const { intl } = props;
  const { onSubmit, handleSubmit, errors, register } = useHandelSubscribe();

  return (
    <div className="bg-white py-32">
      <div className="flex relative pt-14 pb-14 md:pb-[86px]  bg-primary-600 w-full max-w-6xl mx-auto rounded-none md:rounded-[20px]">
        <div className="mx-auto max-w-2xl ">
          <div className=" space-y-4">
            <Typo.headingC
              fontWeight="font-bold"
              className=" text-secondary-500 text-center"
            >
              <FormattedMessage
                id={TRANSLATION.SUBSCRIBE_TITLE.id}
                defaultMessage={TRANSLATION.SUBSCRIBE_TITLE.defaultMessage}
              />
            </Typo.headingC>
            <Typo.heading4 className=" text-gray-800 text-center mx-4">
              <FormattedMessage
                id={TRANSLATION.SUBSCRIBE_DESCRIPTION.id}
                defaultMessage={
                  TRANSLATION.SUBSCRIBE_DESCRIPTION.defaultMessage
                }
              />
            </Typo.heading4>
          </div>

          <form
            id="login"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            className="mx-4 sm:mx-0"
          >
            <div className="w-full relative mt-12 " dir="ltr">
              <InputField
                name="email"
                type="text"
                inputStyle="rounded-xl text-[19px]  border shadow-xs focus:ring-0 focus:border-none"
                placeholder={handelInputMap(intl, "email_placeholder")}
                register={register}
                className="py-2 h-20 "
                errors={errors.email}
                required={true}
              />
              <Button
                className="absolute hidden sm:block top-[14px] end-[14px] w-32 "
                size="medium"
                type="submit"
                theme="secandory"
              >
                <Typo.heading4 fontWeight="font-medium" className="text-white ">
                  <FormattedMessage
                    id={TRANSLATION.SEND.id}
                    defaultMessage={TRANSLATION.SEND.defaultMessage}
                  />
                </Typo.heading4>
              </Button>
              <Button
                className="absolute sm:hidden top-[14px] end-[14px]"
                size="medium"
                rounded="rounded-xl"
                type="submit"
                theme="secandory"
                icon={<SendIcon className="h-6" />}
              ></Button>
            </div>
          </form>
        </div>
        <div>
          <Image
            className="absolute end-24 top-16 hidden md:block"
            src={vector}
            alt="vector"
          />
          <Image
            className="absolute end-20 -bottom-8 hidden md:block"
            src={building}
            alt="building"
          />
          <Image
            className="absolute -end-[2px] -bottom-[0px] hidden md:block"
            src={groupBuilding}
            alt="group building"
          />
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
