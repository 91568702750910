import React, { useCallback, useState } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import Button from "./button";
import ComeraIcon from "../icons/ComeraIcon";
import Avatar from "./Avatar";
import { useHandelFile } from "../../hooks/file/useHandelFile";
import Loader from "./Loader";

type ImageProps = {
  image?: string;
  onChange?: (image: string) => void;
  className?: string;
  disabled?: boolean;
};

const ProfileUploader: React.FC<ImageProps> = (props) => {
  const { image, onChange, className, disabled } = props;
  const [loading, setLoading] = useState(false);
  const { onCompresed, onUpload } = useHandelFile();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true);
      if (acceptedFiles?.length > 0) {
        const compressedFile = await onCompresed(acceptedFiles[0]);
        const uploadResult = await onUpload(compressedFile);
        onChange && onChange(uploadResult);
      }
      setLoading(false);
    },
    [image, onChange]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".svg"],
    },
    maxFiles: 1,
    multiple: true,
    noClick: true,
    noKeyboard: true,
  };

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneOptions);
  return (
    <div className={`relative ${className}`}>
      <Avatar image={image} size="2xl" />
      <div
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />
        <Button
          circle={true}
          disabled={disabled}
          onClick={open}
          className="absolute -bottom-1 -right-1 shadow-sm bg-white text-primary-600 p-1 rounded-full"
          icon={
            loading ? (
              <Loader className="h-5 w-5" />
            ) : (
              <ComeraIcon className="h-5" />
            )
          }
        ></Button>
      </div>
    </div>
  );
};

export default ProfileUploader;
