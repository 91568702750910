import { createContext, useContext, useMemo, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./auth";
import { useHandelNotification } from "../hooks/notification/useHandelNotification";
import { NotificationModel } from "../model/Notification";
import { SocketState } from "./socket";
interface ContextType {
  notifications?: NotificationModel[];
  count?: number;
  unread?: number;
  page: number;
  setPage: (page: number) => void;
  showUnRead: boolean;
  setShowUnRead: (state: boolean) => void;
  onReadNotifications: () => void;
  onDeleteNotifications: () => void;
}

const NotificationContext = createContext<ContextType>(null!);

export const NotificationProvider = ({ children }: any) => {
  const auth = useAuth();
  const LIMIT = 4;
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [showUnRead, setShowUnRead] = useState(true);
  const { ToGetNotifications, onReadNotifications, onDeleteNotifications } = useHandelNotification();
  const notifications = ToGetNotifications(
    { page: page, limit: LIMIT, showUnRead: showUnRead, refresh: refresh },
    auth?.user?.id
  );
  const value = useMemo(
    () => ({
      notifications: notifications?.data?.rows || [],
      count: notifications.data?.count,
      unread: notifications.data?.unread,
      page: page,
      setPage: setPage,
      showUnRead: showUnRead,
      setShowUnRead: setShowUnRead,
      onReadNotifications,
      onDeleteNotifications
    }),
    [notifications.data]
  );

  const { socket } = SocketState();

  socket?.addEventListener("message", (event: any) => {
    console.log("Received message:", event.data);
    setRefresh(!refresh)
    // toast.success(event.data);
    // Handle incoming messages
  });

  socket?.addEventListener("open", (event: any) => {
    console.log("WebSocket connection opened");
    // You can send messages here
  });

  socket?.addEventListener("close", (event: any) => {
    console.log("WebSocket connection closed");
  });

  socket?.addEventListener("error", (event: any) => {
    console.error("WebSocket error:", event);
  });

  return (
    <>
      <NotificationContext.Provider value={value}>
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
