import { useState } from "react";
import Dropdown from "../../../components/shared/Dropdown";
import Toggle from "../../../components/shared/Toggle";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { getCurrencies, getUnits } from "../../../static/constants";
import { useHandelProfile } from "../../../hooks/profile/useHandelProfile";
import { useIntl } from "react-intl";
const Preference = () => {
  // Define the columns and data for the table
  const { register, errors, handleSubmit } = useHandelProfile();
  const intl = useIntl();
  const units = getUnits(intl);
  const currencies = getCurrencies(intl);
  const defaultPreference = {
    email: false,
    news: false,
    unit: units[0],
    currency: currencies[0],
  };

  const [preference, setPreference] = useState(defaultPreference);

  const valueChange = (
    value: DropdownOptionModel | boolean | string,
    name: string
  ) => {
    setPreference({
      ...preference,
      [name]: value,
    });
  };

  const onLoging = async (data: any) => {
    // await onSubmit(data);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onLoging)}
        >
          <div className="w-full rounded-lg divide-y divide-gray-100 bg-white py-10 px-14 space-y-8">
            <div className="w-full flex py-4 justify-between items-center">
              <div className="space-y-2">
                <Typo.body3 fontWeight="font-medium" className="text-gray-900">
                  اعلان ایمیل
                </Typo.body3>
                <Typo.body4 className="text-gray-500">
                  برای دریافت اعلان ایمل از طرف کاربر دکمه را فعال کنید{" "}
                </Typo.body4>
              </div>
              <Toggle
                defaultValue={preference.email}
                onChange={(state: boolean) => valueChange(state, "email")}
              />
            </div>
            <div className="w-full py-6 flex justify-between items-center">
              <div className="space-y-2">
                <Typo.body3 fontWeight="font-medium" className="text-gray-900">
                  اعلان خبرنامه
                </Typo.body3>
                <Typo.body4 className="text-gray-500">
                  اجازه دهید از خبرهای جدید افغان هاوس باخبر شوید
                </Typo.body4>
              </div>
              <Toggle
                defaultValue={preference.news}
                onChange={(state: boolean) => valueChange(state, "news")}
              />
            </div>
            <div className="">
              <div className="w-full flex gap-x-5 py-10">
                <Dropdown
                  label=" واحد اندازه گیری مساحت"
                  placeholder="ایمیل خود را وارد کنید"
                  defaultValue={preference.unit}
                  onChange={(option: DropdownOptionModel) =>
                    valueChange(option, "unit")
                  }
                  options={units}
                />
                <Dropdown
                  label="انتخاب واحد پولی"
                  placeholder="ایمیل خود را وارد کنید"
                  defaultValue={preference.currency}
                  onChange={(option: DropdownOptionModel) =>
                    valueChange(option, "currency")
                  }
                  options={currencies}
                />
              </div>

              <div className="pt-6">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    ذخیره تغییرات
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Preference;
