type Props = {
  className?: string;
};
const KitchenIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 10H10.375V3H8.375V10H6.375V3H4.375V11C4.375 12.654 5.721 14 7.375 14H8.375V21H10.375V14H11.375C13.029 14 14.375 12.654 14.375 11V3H12.375V10ZM19.375 3H18.375C17.216 3 16.375 4.262 16.375 6V14H18.375V21H20.375V4C20.375 3.73478 20.2696 3.48043 20.0821 3.29289C19.8946 3.10536 19.6402 3 19.375 3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default KitchenIcon;
