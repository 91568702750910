type Props = {
  className?: string;
};
const NearbyHospitalIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 26.5833H28.3332C28.4656 26.5833 28.5832 26.7009 28.5832 26.8333C28.5832 26.9658 28.4656 27.0833 28.3332 27.0833H1.6665C1.53405 27.0833 1.4165 26.9658 1.4165 26.8333C1.4165 26.7009 1.53405 26.5833 1.6665 26.5833Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M8.33333 0.916656H21.6667C23.5129 0.916656 24.6124 1.46159 25.2678 2.21907C25.941 2.99701 26.2569 4.12605 26.2633 5.50173V26.0833H19.7633V18.7533C19.7633 17.6406 18.849 16.75 17.76 16.75H12.2533C11.1458 16.75 10.25 17.6458 10.25 18.7533V26.0833H3.75V5.49999C3.75 4.12452 4.063 2.99588 4.73431 2.21851C5.38785 1.4617 6.48687 0.916656 8.33333 0.916656ZM16.75 12.1667V10.5833H18.3333C19.2942 10.5833 20.0833 9.7942 20.0833 8.83332C20.0833 7.87244 19.2942 7.08332 18.3333 7.08332H16.75V5.49999C16.75 4.53911 15.9609 3.74999 15 3.74999C14.0391 3.74999 13.25 4.53911 13.25 5.49999V7.08332H11.6667C10.7058 7.08332 9.91667 7.87244 9.91667 8.83332C9.91667 9.7942 10.7058 10.5833 11.6667 10.5833H13.25V12.1667C13.25 13.1275 14.0391 13.9167 15 13.9167C15.9609 13.9167 16.75 13.1275 16.75 12.1667Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default NearbyHospitalIcon;
