import { FC } from "react";
import Modal from "../wrapper/modal";
import shareImage from "../../src/assets/image/share.svg";
import Image from "../components/shared/Image";
import {
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import { Typo } from "../components/shared/typo";
import Button from "../components/shared/button";
import WhatsAppIcon from "../components/icons/WhatsappIcon";
import FacebookCircleIcon from "../components/icons/FacebookCircleIcon";
import EmailFillIcon from "../components/icons/EmailFillIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../static/translation";
type Props = {
  data: any;
  open: boolean;
  onClose: (state: boolean) => void;
};

const ShareModal: FC<Props> = ({ open, onClose, data }) => {
  return (
    <Modal className="max-w-xl" open={open} onClose={() => onClose(false)}>
      <div className="w-full py-12 space-y-10 px-8">
        <div className="bg-gray-100 rounded-lg gap-y-7 flex flex-col items-center pt-6">
          <Typo.heading2 fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.SHARE_LISTING.id}
              defaultMessage={TRANSLATION.SHARE_LISTING.defaultMessage}
            />
          </Typo.heading2>
          <Image src={shareImage} />
        </div>
        <div className=" flex flex-col w-full gap-y-4">
          <FacebookShareButton
            // url="https://www.bbc.com/news/world-europe-68646380"
            url="http://127.0.0.1:3000/listings/Adsuesco-calamitas-venio-aperiam-ratione-vestrum-assentator-vestigium-decretum-cogito-vereor-tremo-armarium-adeptio-abbas-versus-reprehenderit-acerbitas."
            title={"Facebook"}
            className="w-full flex "
          >
            <div className=" flex w-full">
              <Button
                icon={<FacebookCircleIcon className="h-6" />}
                className="w-full"
                theme="stroke"
              >
                <Typo.heading3
                  fontWeight="font-medium"
                  className="text-gray-500"
                >
                  <FormattedMessage
                    id={TRANSLATION.SHARE_WITH_FACEBOOK.id}
                    defaultMessage={
                      TRANSLATION.SHARE_WITH_FACEBOOK.defaultMessage
                    }
                  />
                </Typo.heading3>
              </Button>
            </div>
          </FacebookShareButton>

          <WhatsappShareButton
            url={data?.link}
            title={"WhatsApp"}
            separator="::"
            className="Demo__some-network__share-button"
          >
            <div className=" flex w-full">
              <Button
                icon={<WhatsAppIcon className="h-6 text-primary-600" />}
                theme="stroke"
                className="w-full"
              >
                <Typo.heading3
                  fontWeight="font-medium"
                  className="text-gray-500"
                >
                  <FormattedMessage
                    id={TRANSLATION.SHARE_WITH_WHATSAPP.id}
                    defaultMessage={
                      TRANSLATION.SHARE_WITH_WHATSAPP.defaultMessage
                    }
                  />
                </Typo.heading3>
              </Button>
            </div>
          </WhatsappShareButton>

          <TelegramShareButton
            url={data.link}
            title={"Telegram"}
            className="Demo__some-network__share-button"
          >
            <div className=" flex w-full">
              <Button
                icon={<WhatsAppIcon className="h-6" />}
                theme="stroke"
                className="w-full"
              >
                <Typo.heading3
                  fontWeight="font-medium"
                  className="text-gray-500"
                >
                  <FormattedMessage
                    id={TRANSLATION.SHARE_WITH_TELEGRAM.id}
                    defaultMessage={
                      TRANSLATION.SHARE_WITH_TELEGRAM.defaultMessage
                    }
                  />
                </Typo.heading3>
              </Button>
            </div>
          </TelegramShareButton>

          <EmailShareButton
            url={data.link}
            subject={data.title}
            body="body"
            className="Demo__some-network__share-button"
          >
            <div className=" flex w-full">
              <Button
                icon={<EmailFillIcon className="h-6" />}
                theme="stroke"
                className="w-full"
              >
                <Typo.heading3
                  fontWeight="font-medium"
                  className="text-gray-500"
                >
                  <FormattedMessage
                    id={TRANSLATION.SHARE_WITH_EMAIL.id}
                    defaultMessage={TRANSLATION.SHARE_WITH_EMAIL.defaultMessage}
                  />
                </Typo.heading3>
              </Button>
            </div>
          </EmailShareButton>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
