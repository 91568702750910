type Props = {
  className?: string;
};
const BulkStarIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M4.78313 13.3334C4.8748 12.925 4.70813 12.3417 4.41647 12.05L2.39147 10.025C1.75813 9.39169 1.50813 8.71669 1.69147 8.13335C1.88313 7.55002 2.4748 7.15002 3.35813 7.00002L5.95813 6.56669C6.33313 6.50002 6.79147 6.16669 6.96647 5.82502L8.3998 2.95002C8.81647 2.12502 9.38313 1.66669 9.9998 1.66669C10.6165 1.66669 11.1831 2.12502 11.5998 2.95002L13.0331 5.82502C13.1415 6.04169 13.3665 6.25002 13.6081 6.39169L4.63313 15.3667C4.51647 15.4834 4.31647 15.375 4.3498 15.2084L4.78313 13.3334Z"
        fill="#E0E0E0"
      />
      <path
        d="M15.584 12.0499C15.284 12.3499 15.1173 12.9249 15.2173 13.3332L15.7923 15.8416C16.034 16.8832 15.884 17.6666 15.3673 18.0416C15.159 18.1916 14.909 18.2666 14.6173 18.2666C14.1923 18.2666 13.6923 18.1082 13.1423 17.7832L10.7007 16.3332C10.3173 16.1082 9.68398 16.1082 9.30065 16.3332L6.85898 17.7832C5.93398 18.3249 5.14232 18.4166 4.63398 18.0416C4.44232 17.8999 4.30065 17.7082 4.20898 17.4582L14.3423 7.32489C14.7256 6.94156 15.2673 6.76656 15.7923 6.85823L16.634 6.99989C17.5173 7.14989 18.109 7.54989 18.3007 8.13323C18.484 8.71656 18.234 9.39156 17.6007 10.0249L15.584 12.0499Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export default BulkStarIcon;
