import ListingFilter from "./ListingFilter";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import { useEffect, useState } from "react";
import { ListingObject, ListingProp } from "../../../model/Listing";
import ListingCard from "../../../components/shared/Card/ListingCard";
import { useHandelCategory } from "../../../hooks/category/useHandelCategory";
import { useAppSelector } from "../../../lib/hooks";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import Pagination from "../../../components/shared/Pagination";
import clsx from "clsx";
import { Typo } from "../../../components/shared/typo";
import NoListingFound from "../../dashboard/listings/noListingFound";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useIntl } from "react-intl";
import { useLang } from "../../../context/language";
import { useAuth } from "../../../context/auth";
import { getLocaleSubCategories } from "../../../util/appUtil";

const Listings = () => {
  const LIMIT = 9;
  const lang = useLang();
  const intl = useIntl();
  const auth = useAuth();
  const [state, setState] = useState(false);
  const filterData = useAppSelector((state) => state.filter);
  const [category, setCategory] = useState<DropdownOptionModel>();
  const [filter, setFilter] = useState<ListingProp>({
    page: 1,
    limit: LIMIT,
    purpose: "",
    provinceId: undefined,
    subCategoryId: undefined,
    address: "",
    minArea: undefined,
    maxArea: undefined,
    currency: "",
    minPrice: undefined,
    maxPrice: undefined,
    unit: "",
    title: "",
    bedrooms: undefined,
    bathrooms: undefined,
    featured: false,
  });
  const { ToGetListings, onLike, onDisLike } = useHandelListing({ intl });
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const listings = ToGetListings(filter, state);
  const categoryResponse = ToGetCategories();
  const categories = getLocaleSubCategories(
    lang.language,
    categoryResponse?.data
  );

  const onSearch = () => {
    if (filter.page !== 1) {
      onChange("page", 1);
    } else {
      setState(!state);
    }
  };

  const onChange = (name: string, value: any) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const resetFilter = (filter: ListingProp) => {
    setFilter(filter);
    setState(!state);
  };

  useEffect(() => {
    if (filterData) {
      setFilter(filterData);
      onSearch();
      if (filterData.subCategoryId) {
        categories?.map((item: any) => {
          const sub = item.options.find(
            (subItem: any) => subItem.id === filterData.subCategoryId
          );
          if (sub) {
            setCategory(sub);
          }
        });
      }
    }
  }, [filterData]);

  return (
    <div className=" max-w-7xl mx-auto">
      <div
        className={clsx(
          "w-full flex flex-col gap-y-[1px] py-10 mx-auto relative bg-gray-100"
        )}
      >
        <div className="z-1 text-center">
          <Typo.headingC className="text-primary-600" fontWeight="font-bold">
            <FormattedMessage
              id={TRANSLATION.SEARCH_LISTING_TITLE.id}
              defaultMessage={TRANSLATION.SEARCH_LISTING_TITLE.defaultMessage}
            />
          </Typo.headingC>
          <Typo.body3
            className="text-gray-600 mt-4 text-center max-w-xl mx-auto"
            fontWeight="font-normal"
          >
            <FormattedMessage
              id={TRANSLATION.SEARCH_LISTING_DESCRIPTION.id}
              defaultMessage={
                TRANSLATION.SEARCH_LISTING_DESCRIPTION.defaultMessage
              }
            />
          </Typo.body3>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 gap-10 lg:px-8 mt-10">
        <div>
          <ListingFilter
            filter={filter}
            category={category}
            setCategory={setCategory}
            onChange={onChange}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            categories={categories || []}
            intl={intl}
            locale={lang.language}
          />
        </div>
        <div className=" mt-10">
          <Typo.headingC
            fontWeight="font-bold"
            className="text-center text-gray-800"
          >
            <FormattedMessage
              id={TRANSLATION.SEARCH_RESULT_WITH_VALUT.id}
              defaultMessage={
                TRANSLATION.SEARCH_RESULT_WITH_VALUT.defaultMessage
              }
              values={{ value: listings?.data?.count }}
            />
          </Typo.headingC>
          {listings?.data?.count === 0 && <NoListingFound />}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 my-12">
            {listings?.data?.rows?.map((listing: ListingObject) => {
              return (
                <ListingCard
                  type="feature"
                  showInteration={true}
                  key={listing?.id}
                  listing={listing}
                  background="white"
                  user={auth?.user}
                  direction={lang.direction}
                  intl={intl}
                  onLike={onLike}
                  onDisLike={onDisLike}
                  locale={lang.language}
                />
              );
            })}
          </div>
          <div className="mt-16">
            {listings?.data?.count && listings?.data?.count > 0 && (
              <Pagination
                onPageChange={(page: number) => onChange("page", page)}
                total={listings?.data?.count}
                type="simple-card-footer"
                perPage={LIMIT}
                currentPage={filter.page || 1}
                direction={lang.direction}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listings;
