import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { useIntl } from "react-intl";
import { handelValidationMap } from "../../util/handelValidationMap";
import { AlertModel } from "../../model/Alert";
import { AppError } from "../../static/AppError";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { toast } from "react-toastify";
import { showModal } from "../../lib/reducers/modal.slice";
export const useHandelEmailConfirmation = () => {
    const intl = useIntl();
    const validations = handelValidationMap(intl);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<AlertModel>();

    const serverMessages = handelServerMessageMap(intl);
    const schema = object({
        token: string()
            .required(validations['otp_token_required'])
            .matches(/^\d{6}$/, validations['enter_otp']),
    });
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const mutation = useMutation({
        mutationFn: async (doUser: any) => {
            return await Fetch({
                method: "POST",
                endpoint: "/user/verify-email",
                data: doUser,
            });
        },
        onSuccess: (data) => {
            setLoading(false);
            toast.success(serverMessages['verifiedSuccessfuly']);
            localStorage.removeItem('email')
            dispatch(showModal("login"));
        },
        onError: (error: AppError) => {
            console.error(error);
            setAlert({
                title: serverMessages[error.data.message],
                open: true,
                type: "error",
            });
            setLoading(false);
        },
    });

    const onSubmit = async (data: any) => {
        setLoading(true);
        const email = localStorage.getItem('email');
        const newData = {
            email: email,
            token: data.token,
        };
        mutation.mutate(newData);
    };

    return {
        register,
        errors,
        handleSubmit,
        onSubmit,
        reset,
        loading,
        alert,
        setAlert,
    };
};
