import React from "react";
import Image from "../../../components/shared/Image";
import { Typo } from "../../../components/shared/typo";
import LocationPinIcon from "../../../components/icons/LocationPinIcon";
import Map from "../../../components/shared/Map";
import Home2Icon from "../../../components/icons/Home2Icon";
import ProfileIcon from "../../../components/icons/ProfileIcon";
import { AgencyObject } from "../../../model/Agency";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import ListingCardContact from "../../../components/shared/ListingAction/ListingCardContact";
import { getLocalTitle } from "../../../util/appUtil";

type Props = {
  agency?: AgencyObject;
  direction: string;
  locale: string;
};

const AgencyInfo: React.FC<Props> = (props) => {
  const { agency, direction, locale } = props;

  return (
    <div className="bg-gray-100 p-4  lg:p-10">
      <div className="  max-w-7xl mx-auto flex flex-col lg:flex-row items-start gap-6 justify-between">
        <div className="flex flex-col sm:flex-row gap-10 ">
          <div>
            <Image
              src={
                agency?.users?.length && agency?.users?.length > 0
                  ? agency.users[0].image
                  : undefined
              }
              className="rounded-full w-[156px] h-[156px]"
            />
          </div>
          <div className="">
            <div className="flex gap-1.5">
              <Typo.headingD fontWeight="font-bold" className="text-gray-800">
                <FormattedMessage
                  id={TRANSLATION.AGENCY.id}
                  defaultMessage={TRANSLATION.AGENCY.defaultMessage}
                />
              </Typo.headingD>
              <Typo.headingD
                fontWeight="font-bold"
                className="text-primary-800"
              >
                {agency?.name}
              </Typo.headingD>
            </div>
            <div className=" flex items-center gap-x-2 mt-4">
              <LocationPinIcon className="text-gray-700 w-5 h-5" />
              <Typo.body4 className="text-gray-700">
                {getLocalTitle(
                  locale,
                  agency?.province.state_fa,
                  agency?.province.state_ps,
                  agency?.province.state_en
                )}
              </Typo.body4>
            </div>
            <div className="mt-3 flex flex-col sm:flex-row justify-between gap-3">
              <div className=" flex items-center gap-x-2 ">
                <Home2Icon className="text-gray-700 w-5 h-5" />
                <div dir="ltr">
                  <Typo.heading3 className="text-gray-700 flex divide-x-2 divide-gray-500">
                    {agency?.sells && agency?.sells > 0 ? (
                      <span dir="rtl" className="px-1">
                        <FormattedMessage
                          id={TRANSLATION.FOR_SELL.id}
                          defaultMessage={TRANSLATION.FOR_SELL.defaultMessage}
                          values={{ name: agency?.sells }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                    {agency?.rents && agency?.rents > 0 ? (
                      <span dir="rtl" className="px-1">
                        <FormattedMessage
                          id={TRANSLATION.FOR_RENT.id}
                          defaultMessage={TRANSLATION.FOR_RENT.defaultMessage}
                          values={{ name: agency?.rents }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                    {agency?.mortgages && agency?.mortgages > 0 ? (
                      <span dir="rtl" className="px-1">
                        <FormattedMessage
                          id={TRANSLATION.FOR_MORTGAGE.id}
                          defaultMessage={
                            TRANSLATION.FOR_MORTGAGE.defaultMessage
                          }
                          values={{ name: agency?.mortgages }}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </Typo.heading3>
                </div>
              </div>
              <div className=" flex items-center gap-x-2 ">
                <ProfileIcon className="text-gray-700 w-5 h-5" />
                <Typo.body4 className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATION.ceoName.id}
                    defaultMessage={TRANSLATION.ceoName.defaultMessage}
                    values={{ name: "name" }}
                  />
                </Typo.body4>
              </div>
            </div>
            <div className="flex gap-4 mt-10">
              <ListingCardContact
                phone={agency?.landline}
                whatsApp={agency?.users[0]?.whatsapp}
                direction={direction}
              />
            </div>
          </div>
        </div>
        {/* <div className="mt-6 lg:mt-0">
          <Map
            coords={[
              agency?.province.latitude || 0,
              agency?.province?.longitude || 0,
            ]}
            className="min-w-[336px] w-full min-h-[196px] h-full"
          />
        </div> */}
      </div>
    </div>
  );
};

export default AgencyInfo;
