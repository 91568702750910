import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import clsx from "clsx";
import { Typo } from "./typo";
import LocationDropdownItem from "./LocationDropdownItem";
import { LocationModel } from "../../model/Location";
import Loader from "./Loader";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";

export type DropdownProps = {
  address: string;
  placeId?: string;
};

type Props = {
  defaultValue?: any;
  countryCode?: string;
  disabled?: boolean;
  onItemSelect?: (item: LocationModel) => void;
  placeholder?: string;
  label?: string;
  hintText?: string;
  className?: string;
  showError?: boolean;
};

const ProvinceDropdown: React.FC<Props> = (props) => {
  const {
    onItemSelect,
    countryCode = "af",
    disabled = false,
    defaultValue,
    placeholder,
    label = "",
    hintText,
    className,
    showError,
  } = props;
  const [place, setPlace] = useState<DropdownProps | undefined>(defaultValue);
  const [bounds, setBounds] = useState<{
    north: number;
    south: number;
    east: number;
    west: number;
  }>({ north: -90, south: 90, east: -180, west: 180 }); // Default
  const handleChange = (address: string) => {
    setPlace({ address: address });
  };

  const handleSelect = async (address: string, placeId: string) => {
    try {
      geocodeByPlaceId(placeId)
        .then((results) => {
          const result = results[0];
          const locationData: LocationModel = {
            address: result?.formatted_address,
            latitude: result?.geometry?.location?.lat(),
            longitude: result?.geometry?.location?.lng(),
            country:
              result?.address_components.find((address) =>
                address.types.includes("country")
              )?.long_name || "",
            city: "",
            state:
              result?.address_components.find((address) =>
                address.types.includes("administrative_area_level_1")
              )?.long_name || "",
            street: "",
            countryCode: countryCode,
            placeId: placeId,
            nLat: result?.geometry?.bounds?.getNorthEast().lat(),
            nLng: result?.geometry?.bounds?.getNorthEast().lng(),
            sLat: result?.geometry?.bounds?.getSouthWest().lat(),
            sLng: result?.geometry?.bounds?.getSouthWest().lng(),
          };

          onItemSelect && onItemSelect(locationData);
        })
        .catch((error) => console.error("Error", error));

      setPlace({
        address: address,
        placeId: placeId,
      });
    } catch (error) {
      // Handel any errors
      console.error(error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: [countryCode] }, // restrict to Afghanistan
    types: [],
    language: "fa",
    bounds: bounds,
  };

  useEffect(() => {
    if (defaultValue) {
      setPlace({
        address: defaultValue.state,
        placeId: defaultValue.placeId,
      });
    }
  }, [defaultValue]);
  return (
    <div className={clsx("realative flex flex-col", className)}>
      {label && (
        <Typo.heading4
          fontWeight="font-normal"
          className="text-gray-700 mb-1.5"
        >
          <span> {label || ""}</span>
        </Typo.heading4>
      )}
      <PlacesAutocomplete
        value={place?.address || ""}
        onSelect={handleSelect}
        onChange={handleChange}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative w-full">
            <input
              {...getInputProps({
                placeholder: placeholder,
                types: ["(regions)"],
                disabled: disabled,
                className: clsx(
                  " relative w-full rounded-lg border border-gray-300 pl-10 shadow-xs bg-white py-3 px-3.5 font-inter text-base placeholder:text-gray-500 text-gray-900 focus:outline-none  focus:ring-4 focus:ring-gray-100 focus:border-gray-300"
                ),
              })}
            />
            <div
              className={`z-10 absolute w-full rounded-lg mt-[6px] ${
                suggestions.length > 0
                  ? " border border-gray-300 block"
                  : " hidden "
              }`}
            >
              {loading && <Loader className=" h-20 rounded-lg items-center" />}
              {!loading &&
                suggestions?.map((suggestion, index: number) => {
                  const className = suggestion.active
                    ? " text-gray-900"
                    : " text-gray-700 ";
                  return (
                    <div key={index}>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: clsx(className),
                        })}
                      >
                        <LocationDropdownItem
                          placeId={suggestion.placeId}
                          isSelected={suggestion.active}
                          description={suggestion.description}
                          className={`${
                            suggestions.length - 1 === index
                              ? "rounded-b-lg"
                              : ""
                          } ${index === 0 ? "rounded-t-lg" : ""}`}
                        ></LocationDropdownItem>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {showError || hintText ? (
        <div className=" relative">
          <Typo.heading4
            fontWeight="font-normal"
            className={`w-full text-start absolute -bottom-6  ${
              showError ? "text-error-500" : "text-gray-500"
            }`}
          >
            {showError ? (
              <FormattedMessage
                id={TRANSLATION.ADDRESS_PLACEHOLDER.id}
                defaultMessage={TRANSLATION.ADDRESS_PLACEHOLDER.defaultMessage}
              />
            ) : (
              hintText
            )}
          </Typo.heading4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProvinceDropdown;
