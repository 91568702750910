import { useAuth } from "../../../context/auth";
import { useLang } from "../../../context/language";
import { FormattedMessage, useIntl } from "react-intl";
import { useNotification } from "../../../context/notification";
import React, { useEffect, useState } from "react";
import { handelInputMap } from "../../../util/handelInputMap";
import { Typo } from "../../../components/shared/typo";
import { TRANSLATION } from "../../../static/translation";
import Table from "../../../components/shared/Table";
import Pagination from "../../../components/shared/Pagination";
import { timeAgo } from "../../../util/appUtil";
import Button from "../../../components/shared/button";
import TrashIcon from "../../../components/icons/TrashIcon";
import ConfirmModal from "../../../modal/ConfirmModal";
import NotFound from "../../shared/404";
import NoListingFound from "../listings/noListingFound";
const Messages = () => {
  // Define the columns and data for the table
  const notificaitons = useNotification();
  const [showModal, setShowModal] = useState(false);

  // notificaitons.onReadNotifications()
  const lang = useLang();
  const intl = useIntl();

  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "number"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "notification_content"),
        accessor: "content",
      },
      {
        Header: handelInputMap(intl, "date"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {timeAgo(cell.row.original.updatedAt, intl)}
            </div>
          );
        },
      },
    ],
    []
  );

  const onConfirm = (state: boolean) => {
    if (state) {
      notificaitons.onDeleteNotifications();
    }
    setShowModal(false)
  }

  useEffect(() => {
    if (notificaitons.unread && notificaitons.unread > 0) {
      notificaitons.setShowUnRead(false);
    }
  }, [notificaitons.notifications])
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 bg-white min-h-screen py-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto mt-4">
          <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATION.NOTIFICATION_TITLE.id}
              defaultMessage={TRANSLATION.NOTIFICATION_TITLE.defaultMessage}
            />
          </Typo.heading2>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none">
          <Button
            onClick={() => setShowModal(true)}
            size="small"
            theme="error"
            icon={<TrashIcon className="h-5" />}
            disabled={notificaitons.count === 0}
          >
            <Typo.heading5 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.DELETE_ALL.id}
                defaultMessage={TRANSLATION.DELETE_ALL.defaultMessage}
              />
            </Typo.heading5>
          </Button>
        </div>
      </div>
      {notificaitons.count && notificaitons.count > 0 ? <Table columns={columns} data={notificaitons?.notifications} /> : <NoListingFound title={TRANSLATION.NOTIFICATION_LIST_EMPTY} />}
      {notificaitons?.count && notificaitons?.count > 0 ? (
        <Pagination
          onPageChange={notificaitons.setPage}
          total={notificaitons?.count}
          perPage={10}
          currentPage={notificaitons.page}
          type="centered-page-number"
          direction={lang.direction}
        />
      ) : (
        ""
      )}
      <ConfirmModal title={TRANSLATION.DELETE_NOTIFICATION_TITLE} description={TRANSLATION.DELETE_NOTIFICATION_DESCRIPTION} open={showModal} onClose={onConfirm} icon={<TrashIcon className="h-6" />} />
    </div>
  );
};

export default Messages;
