import React from "react";
import tower from "../../../assets/image/tower.png";
import Image from "../../../components/shared/Image";
import HomeFilter from "../../../components/shared/HomeFilter";
import { Typo } from "../../../components/shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import ArrowVictorIcon from "../../../components/icons/ArrowVictorIcon";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  categories?: any;
  direction: string;
  locale: string;
};

const Hero: React.FC<Props> = (props) => {
  const { categories, direction,locale } = props;
  return (
    <div className="max-w-7xl mx-auto px-0 md:px-6 lg:px-8 ">
      <div
        dir="auto"
        className="h-[430px] md:h-[510px] relative w-full bg-gradient-to-r from-gray-300 to-gray-400/[0.36] rounded-none md:rounded-[30px]"
      >
        <div className="px-10 md:max-lg:pt-24 pt-10 lg:pt-28 ">
          <Typo.headingB
            fontWeight="font-bold"
            className="max-w-[584px] md:max-lg:w-[320px] lg:max-w-[448px] text-[#181A20]"
          >
            <FormattedMessage
              id={TRANSLATION.HERO_TITLE.id}
              defaultMessage={TRANSLATION.HERO_TITLE.defaultMessage}
            />
          </Typo.headingB>
          <Typo.body1 className="text-gray-800 max-w-[448px] pl-1 mt-6 md:max-lg:w-[310px]">
            <FormattedMessage
              id={TRANSLATION.HERO_DESCRIPTION.id}
              defaultMessage={TRANSLATION.HERO_DESCRIPTION.defaultMessage}
            />
          </Typo.body1>
        </div>
        <Image
          className={clsx(
            "hidden md:block md:w-[300px] overflow-x-visible lg:w-[530px] h-[580px] absolute end-0 bottom-0 ",
            { "transform -scale-x-100": direction === DIRECTIONS.LTR }
          )}
          src={tower}
        />
        <ArrowVictorIcon
          className={clsx(
            "w-[130px] h-[40px] text-white absolute -end-3 bottom-40 start-96",
            { "transform -scale-x-100": direction === DIRECTIONS.LTR }
          )}
        />
      </div>
      <div className=" relative flex w-full justify-center">
        <HomeFilter
          categories={categories}
          locale={locale}
          className=" w-full max-w-5xl mx-auto absolute -top-20 z-10 h-32"
        />
      </div>
    </div>
  );
};

export default Hero;
