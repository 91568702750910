type Props = {
  className?: string;
};
const DangerIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M18.134 13.7666L12.8007 4.16663C12.084 2.87496 11.0924 2.16663 10.0007 2.16663C8.90905 2.16663 7.91738 2.87496 7.20071 4.16663L1.86738 13.7666C1.19238 14.9916 1.11738 16.1666 1.65905 17.0916C2.20071 18.0166 3.26738 18.525 4.66738 18.525H15.334C16.734 18.525 17.8007 18.0166 18.3424 17.0916C18.884 16.1666 18.809 14.9833 18.134 13.7666Z"
        fill="currentColor"
      />
      <path
        d="M10 12.7917C9.65833 12.7917 9.375 12.5083 9.375 12.1667V8C9.375 7.65833 9.65833 7.375 10 7.375C10.3417 7.375 10.625 7.65833 10.625 8V12.1667C10.625 12.5083 10.3417 12.7917 10 12.7917Z"
        fill="currentColor"
      />
      <path
        d="M10.0003 15.5C9.95033 15.5 9.89199 15.4917 9.83366 15.4834C9.78366 15.475 9.73366 15.4584 9.68366 15.4334C9.63366 15.4167 9.58366 15.3917 9.53366 15.3584C9.49199 15.325 9.45033 15.2917 9.40866 15.2584C9.25866 15.1 9.16699 14.8834 9.16699 14.6667C9.16699 14.45 9.25866 14.2334 9.40866 14.075C9.45033 14.0417 9.49199 14.0084 9.53366 13.975C9.58366 13.9417 9.63366 13.9167 9.68366 13.9C9.73366 13.875 9.78366 13.8584 9.83366 13.85C9.94199 13.825 10.0587 13.825 10.1587 13.85C10.217 13.8584 10.267 13.875 10.317 13.9C10.367 13.9167 10.417 13.9417 10.467 13.975C10.5087 14.0084 10.5503 14.0417 10.592 14.075C10.742 14.2334 10.8337 14.45 10.8337 14.6667C10.8337 14.8834 10.742 15.1 10.592 15.2584C10.5503 15.2917 10.5087 15.325 10.467 15.3584C10.417 15.3917 10.367 15.4167 10.317 15.4334C10.267 15.4584 10.217 15.475 10.1587 15.4834C10.1087 15.4917 10.0503 15.5 10.0003 15.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DangerIcon;
