export enum ROUTES {
  HOME_ROUTE = "/",
  ARTICLES_ROUTE = "/articles",
  CONTACT_ROUTE = "/contact",
  ABOUT_ROUTE = "/about",
  PRIVACY_POLICY_ROUTE = "/privacy-policy",
  MASTER_PAGE_ROUTE = "master-page",
  // CREATE_ACOUNT_ROUTE = "/complete-profile",
  FORGOT_PASSWORD_VERIFICATION_ROUTE = "/forgot-password-varification",
  RESET_PASSWORD_ROUTE = "/reset-password",
  EMAIL_VERIFICATION_ROUTE = "/email-varification",
  EMAIL_CONFIRMATION_ROUTE = "/email-confirmation",
  AGENCIES_ROUTE = "/agencies",
  AGENCY_DETAILS_ROUTE = "/agencies/:id",
  ARTICLE_DETAILS_ROUTE = "/articles/:id",
  DASHBOARD_ROUTE = "/dashboard",
  CATEGORIES_ROUTE = "/dashboard/categories",
  SUB_CATEGORIES_ROUTE = "/dashboard/sub-categories",
  PREFERENCE_ROUTE = "/dashboard/preference",
  SETTINGS_ROUTE = "/dashboard/settings",
  FAVORITES_ROUTE = "/dashboard/favorites",
  CHANGE_PASSWORD_ROUTE = "/dashboard/change-password",
  DASHBOARD_USERS_ROUTE = "/dashboard/users",
  DASHBOARD_USERS_DETAILS_ROUTE = "/dashboard/users/:id",
  LISTINGS_ROUTE = "/listings",
  LISTING_DETAILS = "/listings/:slug",
  DASHBOARD_LISTINGS_ROUTE = "/dashboard/listings",
  MY_LISTINGS_ROUTE = "/dashboard/my-listings",
  NEW_LISTING_ROUTE = "/dashboard/new-listing",
  COMPELETE_PROFILE_ROUTE = "/dashboard/compelete-profile",
  EDIT_LISTING_DETAILS_ROUTE = "/dashboard/edit-listing/:slug",
  EDIT_LISTING_ROUTE = "/dashboard/edit-listing",
  DASHBOARD_LISTING_STATE = "/dashboard/states",
  DASHBOARD_LISTING_DETAILS = "/dashboard/listings/:slug",
  DASHBOARD_PROVINCES_ROUTE = "/dashboard/provinces",
  DASHBOARD_COUNTRIES_ROUTE = "/dashboard/countries",
  DASHBOARD_ARTICLES_ROUTE = "/dashboard/articles",
  DASHBOARD_EDIT_ARTICLE_ROUTE = "/dashboard/articles/:id",
  DASHBOARD_NEW_ARTICLE_ROUTE = "/dashboard/new-article",
  DASHBOARD_ARTICLE_CATEGORY_ROUTE = "/dashboard/article-category",
  DASHBOARD_MESSAGES_ROUTE = "/dashboard/messages",
  DELETE_PROFILE = "/dashboard/delete-profile",
  MOBILE_ABOUT_US = "/mobile/about-us",
  MOBILE_CONTACT_US = "/mobile/contact-us",
  MOBILE_PRIVACY_POLICY = "/mobile/privacy-policy",
  MOBILE_ARTICLES = "/mobile/articles",
  MOBILE_ARTICLES_DETAILS = "/mobile/articles/:id",
  NOT_FOUND_ROUTE = "*",
}
