import AdProductIcon from "../../../../components/icons/AdProductIcon";
import BulkStarIcon from "../../../../components/icons/BulkStarIcon";
import DisabilityIcon from "../../../../components/icons/DisabilityIcon";
import DropIcon from "../../../../components/icons/DropIcon";
import ElectricityIcon from "../../../../components/icons/ElectricityIcon";
import Mondy3Icon from "../../../../components/icons/Money3Icon";
import MosqueIcon from "../../../../components/icons/MosqueIcon";
import OceanProtocolIcon from "../../../../components/icons/OceanProtocolIcon";
import SewerageIcon from "../../../../components/icons/SewerageIcon";
import WarehouseIcon from "../../../../components/icons/WarehouseIcon";
import InputField from "../../../../components/shared/InputField";
import Toggle from "../../../../components/shared/Toggle";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { TRANSLATION } from "../../../../static/translation";
import { handelInputMap } from "../../../../util/handelInputMap";
import PropertyLabel from "../propertyLabel";

type Props = {
  intl: any;
  featureValues: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string,
    name: string
  ) => void;
};

const PossibilityTab: React.FC<Props> = (props) => {
  const { featureValues, valueChange, intl } = props;
  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-8 gap-x-6">
        <div className="space-y-4 w-full">
          <PropertyLabel title={TRANSLATION.WAREHOUSE} icon={<WarehouseIcon className="h-5 w-5 text-gray-300" />} />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "warehouse_placeholder")}
              register={() => ({})}
              defaultValue={featureValues.warehouse}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="warehouse"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <PropertyLabel
            title={TRANSLATION.OTHER_FACILITIES}
            icon={<Mondy3Icon />}
          />
          <div className="w-full mt-5">
            <InputField
              placeholder={handelInputMap(intl, "otherFacilities_placeholder")}
              register={() => ({})}
              defaultValue={featureValues.otherFacilities}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                valueChange(event.target.value, event.target.name)
              }
              name="otherFacilities"
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.WATER}
                  icon={<DropIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.water}
              onChange={(state) => valueChange(state, "water")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.ELECTRICITY}
                  icon={<ElectricityIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.electricity}
              onChange={(state) => valueChange(state, "electricity")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.CENTRAL_HEATING}
                  icon={<OceanProtocolIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.centralHeating}
              onChange={(state) => valueChange(state, "centralHeating")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.SEWERAGE}
                  icon={<SewerageIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.sewerage}
              onChange={(state) => valueChange(state, "sewerage")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.MOSQUE}
                  icon={<MosqueIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.mosque}
              onChange={(state) => valueChange(state, "mosque")}
            />
          </div>
        </div>
        <div className="space-y-4 w-full">
          <div className="w-full mt-5 flex justify-between items-center ">
            <Toggle
              size="sm"
              label={
                <PropertyLabel
                  title={TRANSLATION.FACILITIES_FOR_DISABLED}
                  icon={<DisabilityIcon className="h-5 w-5 text-gray-300" />}
                />
              }
              defaultValue={featureValues.facilitiesForDisabled}
              onChange={(state) => valueChange(state, "facilitiesForDisabled")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PossibilityTab;
