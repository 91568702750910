import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { hideModal } from "../../lib/reducers/modal.slice";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { useIntl } from "react-intl";
import { handelValidationMap } from "../../util/handelValidationMap";
import { AlertModel } from "../../model/Alert";
import { AppError, HttpCode, HttpMessage } from "../../static/AppError";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
export const useHandelSignup = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const validations = handelValidationMap(intl);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertModel>();

  const serverMessages = handelServerMessageMap(intl);

  const schema = object().shape({
    name: string().required(validations['name_required']),
    email: string().required(validations['email_required']).email(validations['invalid_email']),
    password: string()
      .required(validations["enter_your_password"])
      .min(6, validations["must_be_six_characters"])
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])/,
        validations["password_validation"]
      ),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "POST",
        endpoint: "/signup",
        data: doUser,
      });
    },
    onSuccess: (data) => {
      if (data?.data) {
        dispatch(hideModal("signup"));
        localStorage.setItem('email', data?.data?.email);
        navigate(ROUTES.EMAIL_CONFIRMATION_ROUTE)
      }
      toast.success(serverMessages[HttpMessage.REGISTER_SUCCEESSFULY]);
      setLoading(false);
    },
    onError: (error: AppError) => {
      console.error(error);
      if (error.status === HttpCode.BAD_REQUEST) {
        if (error?.data?.message === HttpMessage.EMAIL_REQUIRED) {
          setError(
            "email",
            { message: serverMessages[HttpMessage.EMAIL_REQUIRED] },
            { shouldFocus: true }
          );
        } else if (error?.data?.message === HttpMessage.PASSWORD_REQUIRED) {
          setError(
            "password",
            { message: serverMessages[HttpMessage.PASSWORD_REQUIRED] },
            { shouldFocus: true }
          );
        }
      } else if (error.status === HttpCode.UNAUTHORIZED) {
        setAlert({
          title: serverMessages[HttpMessage.YOUR_ACCOUNT_EXISTS],
          open: true,
          type: "error",
        });
      } else if (error.status === HttpCode.INTERNAL_SERVER_ERROR) {
        setAlert({
          title: serverMessages[HttpMessage.INTERNAL_SERVER_ERROR],
          open: true,
          type: "error",
        });
      }
      setLoading(false);
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const newData = {
      roleId: 1,
      username: data.email,
      ...data,
    };
    mutation.mutate(newData);
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    loading,
    alert,
    setAlert,
  };
};
