import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useQuery } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { buildEndpoint } from "../../util/buildEndpoint";
import { AgencyObject, AgencyResponse, agencyProp } from "../../model/Agency";

export const useHandelAgency = () => {
  const schema = object().shape({
    email: string().required().email(),
    password: string().required(""),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  function ToGetPopularAgencies(agencyProp: agencyProp) {
    const result = useQuery({
      queryKey: ["popularAgencies"],
      queryFn: async () => await getAgencies(agencyProp),
    });
    return result;
  }

  function ToGetAgencies(agencyProp: agencyProp, state: boolean) {
    const result = useQuery({
      queryKey: ["agencies", agencyProp.page, state],
      queryFn: async () => await getAgencies(agencyProp),
    });
    return result;
  }

  function ToGetAgency(id: number) {
    const result = useQuery({
      queryKey: ["agency"],
      queryFn: async () => await getAgency(id),
      enabled: !!id,
    });
    return result;
  }

  const getAgency = async (id: number): Promise<AgencyObject> => {
    const response = await Fetch({
      endpoint: `agency/${id}`,
    });
    return response?.data;
  };

  const getAgencies = async (
    agencyProp: agencyProp
  ): Promise<AgencyResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("agency", agencyProp),
      requiredAuth: true,
    });
    return response?.data;
  };

  const onSubmit = async (data: any) => {
    // mutation.mutate(data);
  };

  return {
    ToGetAgencies,
    ToGetAgency,
    ToGetPopularAgencies,
    errors,
    register,
    handleSubmit,
    reset,
    onSubmit,
  };
};
