import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import {
  ArticleModel,
  ArticleProp,
  ArticleResponse,
} from "../../model/Article";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { buildEndpoint } from "../../util/buildEndpoint";
import { handelValidationMap } from "../../util/handelValidationMap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
export const useHandelArticle = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intl = useIntl();
  const validations = handelValidationMap(intl);
  const messages = handelServerMessageMap(intl);

  const schema = object().shape({
    title: string().required(validations["title_required"]),
    description: string(),
  });

  const searchSchema = object().shape({
    title: string(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { register: searchRegister, handleSubmit: handleSearch } = useForm({
    resolver: yupResolver(searchSchema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doArticle: any) => {
      const response = await Fetch({
        method: "POST",
        data: doArticle,
        endpoint: "/article",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      navigate(ROUTES.DASHBOARD_ARTICLES_ROUTE);
      toast.success(messages["recordAddedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["serverErrorOccured"]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: number) => {
      const response = await Fetch({
        method: "DELETE",
        endpoint: `/article/${id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["articles"] });
      toast.success(messages["recordDeletedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["serverErrorOccured"]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (article: any) => {
      const response = await Fetch({
        method: "PUT",
        data: article,
        endpoint: `/article/${article.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      navigate(ROUTES.DASHBOARD_ARTICLES_ROUTE);
      toast.success(messages["recordUpdatedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["serverErrorOccured"]);
    },
  });

  const onUpdate = async (article: ArticleModel, id: number) => {
    const data = {
      id: id,
      ...article,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSubmit = async (data: any) => {
    mutation.mutate(data);
  };

  function ToGetArticle(id: number) {
    const result = useQuery({
      queryKey: ["article", id],
      queryFn: async () => await getArticle(id),
      enabled: !!id,
    });
    return result;
  }

  function ToGetArticles(props: ArticleProp) {
    const result = useQuery({
      queryKey: ["articles", props.page, props.articleCategoryIds, props.title],
      queryFn: async () => await getArticles(props),
    });
    return result;
  }

  const getArticles = async (
    articleProps: ArticleProp
  ): Promise<ArticleResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("/article", articleProps),
    });
    return response?.data;
  };

  const getArticle = async (id: number): Promise<ArticleModel> => {
    const response = await Fetch({
      endpoint: `/article/${id}`,
    });
    return response?.data;
  };

  return {
    register,
    searchRegister,
    errors,
    handleSubmit,
    handleSearch,
    onSubmit,
    reset,
    setValue,
    ToGetArticle,
    ToGetArticles,
    onDelete,
    onUpdate,
  };
};
