import { ChangeEvent, FC } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { FormattedMessage, IntlShape, MessageDescriptor } from "react-intl";
import { TRANSLATION } from "../static/translation";
import InputField from "../components/shared/InputField";
import { handelInputMap } from "../util/handelInputMap";
import RangeDropdown from "../components/shared/RangeDropdown";
import { ListingProp } from "../model/Listing";
import { getCurrenciesWithLabel, getUnitsWithLabel } from "../static/constants";
import Dropdown from "../components/shared/Dropdown";
import { DropdownOptionModel } from "../model/DropdownOption";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  intl: IntlShape;
  title?: MessageDescriptor;
  description?: MessageDescriptor;
  documents: DropdownOptionModel[];
  filter: ListingProp;
  onChange: (name: string, value: any) => void;
};

const ListingFilterModal: FC<Props> = ({
  open,
  onClose,
  intl,
  documents,
  filter,
  onChange,
}) => {
  const units = getUnitsWithLabel(intl);
  const currencies = getCurrenciesWithLabel(intl);
  return (
    <Modal
      className="max-w-5xl overflow-visible px-4 sm:px-6 lg:px-8"
      open={open}
      onClose={() => onClose(false)}
    >
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 sm:grid-ols-3 lg:grid-cols-4 gap-4">
        <div className="col-span-1">
          <Dropdown
            options={documents || []}
            defaultValue={documents.find(
              (item) => item.id === filter.documentType
            )}
            onChange={(document: DropdownOptionModel) =>
              onChange("documentType", document.id)
            }
            placeholder={handelInputMap(intl, "documentType_placeholder")}
            label={handelInputMap(intl, "documentType")}
          />
        </div>
        <RangeDropdown
          minValue={filter.minArea}
          maxValue={filter.maxArea}
          setMaxValue={(area) => onChange("maxArea", area)}
          setMinValue={(area) => onChange("minArea", area)}
          label={handelInputMap(intl, "area")}
          placeholder={handelInputMap(intl, "area_placeholder")}
          intl={intl}
          options={units}
          onOptionSelect={(unit) => onChange("unit", unit)}
          optionDefaultValue={String(filter?.unit)}
        />

        <RangeDropdown
          minValue={filter.minPrice}
          maxValue={filter.maxPrice}
          setMaxValue={(price) => onChange("maxPrice", price)}
          setMinValue={(price) => onChange("minPrice", price)}
          label={handelInputMap(intl, "price")}
          placeholder={handelInputMap(intl, "price_placeholder")}
          intl={intl}
          options={currencies}
          onOptionSelect={(currency) => onChange("currency", currency)}
          optionDefaultValue={String(filter.currency)}
        />

        <div className="col-span-1">
          <InputField
            label={handelInputMap(intl, "bedroom")}
            placeholder={handelInputMap(intl, "bedroom_placeholder")}
            defaultValue={filter?.bedrooms ? String(filter?.bedrooms) : ""}
            register={() => ({})}
            type="number"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange("bedrooms", event.target.value)
            }
            name="bedrooms"
          />
        </div>
        <div className="col-span-1">
          <InputField
            label={handelInputMap(intl, "bathroom")}
            placeholder={handelInputMap(intl, "bathroom_placeholder")}
            defaultValue={filter?.bathrooms ? String(filter?.bathrooms) : ""}
            register={() => ({})}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange("bathrooms", event.target.value)
            }
            type="number"
            name="bathrooms"
          />
        </div>
        <div className="col-span-1">
          <InputField
            label={handelInputMap(intl, "title")}
            placeholder={handelInputMap(intl, "title_placeholder")}
            defaultValue={filter.title}
            register={() => ({})}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange("title", event.target.value)
            }
            name="title"
          />
        </div>
        <div className="col-span-1">
          <InputField
            label={handelInputMap(intl, "address")}
            placeholder={handelInputMap(intl, "address_placeholder")}
            defaultValue={filter.address}
            register={() => ({})}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange("address", event.target.value)
            }
            name="address"
          />
        </div>
        <div className=" w-full mt-4 flex items-end">
          <Button
            size="medium"
            theme="primary"
            className="shadow-shadow w-full"
            onClick={() => onClose(false)}
          >
            <Typo.heading4 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.SAVE.id}
                defaultMessage={TRANSLATION.SAVE.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ListingFilterModal;
