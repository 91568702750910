import { Helmet } from "react-helmet-async";
import { Seo } from "../../model/Seo";
export default function SEO({
  image,
  url,
  title,
  description,
  name,
  type,
}: Seo) {
  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{"title"}</title>
      <meta name="description" content={"description"} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {/* <meta property="og:type" content={type} /> */}
      <meta property="og:title" content={"title"} />
      <meta property="og:description" content={"description"} />
      <meta
        property="og:url"
        content="http://127.0.0.1:3000/listings/Adsuesco-calamitas-venio-aperiam-ratione-vestrum-assentator-vestigium-decretum-cogito-vereor-tremo-armarium-adeptio-abbas-versus-reprehenderit-acerbitas."
      />

      <meta
        property="og:image"
        content={
          "https://img.freepik.com/premium-photo/full-shot-girl-learning-math-school_23-2150470852.jpg?w=900"
        }
      />

      {/* End Facebook tags */}
      {/* Twitter tags */}
      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} /> */}

      {/* End Twitter tags */}
    </Helmet>
  );
}
