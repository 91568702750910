import { Typo } from "../../components/shared/typo";
import PrivacyPolicyItem from "./PrivacyPolicyItem";
type Props = {
  className?: string;
};
const PrivacyPolicyContent: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <div className="w-full">
      <div dir="rtl" className="max-w-5xl mx-auto p-4 sm:p-6 md:p-8 lg:p-10">
        <Typo.headingD fontWeight="font-bold">
          سیاست حفظ حریم خصوصی برای افغان هاوس
        </Typo.headingD>
        <Typo.heading3 className="text-gray-900 mt-4">
          به افغان هاوس خوش آمدید! این خط‌مشی رازداری نحوه جمع‌آوری را توضیح
          می‌دهد، وقتی از اطلاعات واقعی ما استفاده می کنید، استفاده کنید و از
          اطلاعات شخصی خود محافظت کنید پلت فرم املاک با دسترسی یا استفاده از
          افغان هاوس، شما موافقت می کنید شرایط مندرج در این سیاست
        </Typo.heading3>

        <div className="space-y-8 mt-8">
          <PrivacyPolicyItem
            title="اطلاعاتی که ما جمع آوری می کنیم"
            description="انواع اطلاعاتی که از کاربران جمع‌آوری می‌کنید را با جزئیات شرح دهید. برای یک پلت فرم املاک و مستغلات، این ممکن است شامل موارد زیر باشد:"
            items={[
              "مشخصات شخصی (نام، ایمیل، شماره تلفن)",
              "ترجیحات ملکی",
              "داده های موقعیت مکانی",
              "کوکی ها و داده های تجزیه و تحلیل",
            ]}
          />

          <PrivacyPolicyItem
            title=" چگونه از اطلاعات شما استفاده می کنیم"
            description="نحوه استفاده از اطلاعات جمع آوری شده را توضیح دهید:"
            items={[
              "برای ارائه لیست املاک و ارتباط خریداران با فروشندگان",
              "برای بهبود خدمات و تجربه کاربری ما",
              "برای برقراری ارتباط با کاربران در مورد سوالات، نمایش ها و به روز رسانی ها",
              "برای انجام تعهدات قانونی",
            ]}
          />

          <PrivacyPolicyItem
            title="ثبت داده ها"
            description="ما می خواهیم به شما اطلاع دهیم که هر زمان که از وب سایت ما بازدید می کنید، ما اطلاعاتی را که مرورگر شما برای ما ارسال می کند جمع آوری می کنیم که Log Data نامیده می شود. این داده گزارش ممکن است شامل اطلاعاتی مانند آدرس پروتکل اینترنت رایانه شما ، نسخه مرورگر، صفحات سرویس ما که بازدید می کنید، زمان و تاریخ بازدید شما، زمان صرف شده در آن صفحات و سایر آمارها باشد."
          />
          <PrivacyPolicyItem
            title="تمهیدات امنیتی"
            description="ما برای اعتماد شما در ارائه اطلاعات شخصی خود به ما ارزش قائل هستیم، بنابراین ما در تلاش هستیم تا از ابزارهای قابل قبول تجاری برای محافظت از آنها استفاده کنیم. اما به یاد داشته باشید که هیچ روش انتقال از طریق اینترنت یا روش ذخیره سازی الکترونیکی 100٪ ایمن و قابل اعتماد نیست و ما نمی توانیم امنیت مطلق آن را تضمین کنیم."
          />

          <PrivacyPolicyItem
            title="تغییرات در این سیاست حفظ حریم خصوصی"
            description="ممکن است سیاست حفظ حریم خصوصی خود را هر از چند گاهی به روز کنیم. بنابراین، ما به شما توصیه می کنیم برای هر گونه تغییر، این صفحه را به صورت دوره ای مرور کنید. ما با ارسال سیاست حفظ حریم خصوصی جدید در این صفحه شما را از هرگونه تغییر مطلع خواهیم کرد. این تغییرات بلافاصله پس از ارسال در این صفحه اعمال می شوند."
          />

          <PrivacyPolicyItem
            title="با ما تماس بگیرید"
            description="اگر در مورد سیاست حفظ حریم خصوصی ما سؤال یا پیشنهادی دارید، دریغ نکنید با ما تماس بگیرید."
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
