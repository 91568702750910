import { defineMessage } from "react-intl";

export const TRANSLATION = {
  TITLE: defineMessage({
    id: "shared.title",
    defaultMessage: "عنوان",
  }),
  TITLE_FA: defineMessage({
    id: "shared.title_fa",
    defaultMessage: "عنوان فارسی ",
  }),
  TITLE_PS: defineMessage({
    id: "shared.title_ps",
    defaultMessage: "عنوان پشتو ",
  }),
  TITLE_EN: defineMessage({
    id: "shared.title_en",
    defaultMessage: "عنوان انگلیسی",
  }),
  HOME_TITLE: defineMessage({
    id: "home.title",
    defaultMessage: "Home page title ",
  }),
  CANCEL: defineMessage({
    id: "shared.cancel",
    defaultMessage: "لغو",
  }),
  ADD_IMAGE: defineMessage({
    id: "shared.add_image",
    defaultMessage: "افزودن تصویر",
  }),
  ADD_IMAGE_DESCRIPTION: defineMessage({
    id: "shared.add_image_description",
    defaultMessage: "لطفا تصویر را در اینجا قرار بدهید",
  }),
  LOADING: defineMessage({
    id: "shared.loading",
    defaultMessage: "بارگذاری ...",
  }),
  FOR_SELL: defineMessage({
    id: "shared.for_sale",
    defaultMessage: "{name} کرایی",
  }),
  FOR_RENT: defineMessage({
    id: "shared.for_rent",
    defaultMessage: "{name} فروشی",
  }),
  FOR_MORTGAGE: defineMessage({
    id: "shared.for_mortgage",
    defaultMessage: "{name} گروی",
  }),
  MORE_DETAILS: defineMessage({
    id: "shared.more_details",
    defaultMessage: "جزییات بیشتر ",
  }),
  VIEW_ALL: defineMessage({
    id: "shared.view_all",
    defaultMessage: "مشاهده همه",
  }),
  TOP_AGENCIES: defineMessage({
    id: "page.home.top_agencies",
    defaultMessage: "راهنمایی املاک برتر منطقه",
  }),
  TOP_AGENCIES_DESCRIPTION: defineMessage({
    id: "page.home.top_agency_description",
    defaultMessage:
      ".در افغان هاوس شما میتوانید به صد ها راهنمایی معاملات دسترسی داشته باشید و در اسرع وقت جایداد و یا ملک مورد نظر خود را پیدا کنید",
  }),
  FEATURED_LISTINGS: defineMessage({
    id: "page.home.featured_listings",
    defaultMessage: " جایداد های ویژه",
  }),
  FEATURED_LISTINGS_DESCRIPTION: defineMessage({
    id: "page.home.featured_listings_description",
    defaultMessage:
      "آیا به دنبال خانه‌ای با طرح بی‌نظیر و امکانات فوق‌العاده برای زندگی شگفت‌انگیز در شهر دلپذیر هستید؟ پس از این فرصت‌ها عقب نمانید و با تازه‌ترین و خیال‌انگیزترین خانه ها همراه ما باشید!",
  }),
  LISTING_CATEGORY: defineMessage({
    id: "page.home.listing_category",
    defaultMessage: "انواع جایداد",
  }),
  LISTING_SCRIPTION: defineMessage({
    id: "page.home.listing_category_description",
    defaultMessage:
      "آیا به دنبال خانه رویایی خود میگردید؟ در سایت ما، انواع خانه‌ها و آپارتمان‌های متنوع را برای شما آماده کرده‌ایم. با توجه به نیاز و سلیقه شما، می‌توانید خانه موردنظر خود را از میان گزینه‌ها و امکانات زیر پیدا کنید.",
  }),
  DELETE_CATEGORY: defineMessage({
    id: "page.category.delete_category",
    defaultMessage: "حذف دسته / گروپ اصلی ",
  }),
  DELETE_CATEGORY_MESSAGE: defineMessage({
    id: "page.category.delete_category_message",
    defaultMessage:
      "در صورتیکه حذف گروپ اصلی تمامی اطلاعات مربوط به آن حذف میشود ",
  }),
  ENTER_CATEGORY_INFORMATION: defineMessage({
    id: "page.category.enter_category_information",
    defaultMessage: "معلومات در مورد دسته یا گروپ را وارد کنید",
  }),
  CATEGORY_TITLE: defineMessage({
    id: "page.category.title",
    defaultMessage: "گروپ املاک",
  }),
  CATEGORY_DESCRIPTION: defineMessage({
    id: "page.category.description",
    defaultMessage:
      "از این دسته ها برای تقسیمات راحتر املاک استفاده صورت میگیرد",
  }),
  DELETE_SUB_CATEGORY: defineMessage({
    id: "page.sub_category.delete_sub_category",
    defaultMessage: "حذف گروپ فرعی",
  }),
  DELETE_SUB_CATEGORY_MESSAGE: defineMessage({
    id: "page.sub_category.delete_sub_category_message",
    defaultMessage:
      "در صورتیکه که گروپ فرعی را حذف بکنید تمامی اطلاعات مربوط به آن حذف میشود ",
  }),
  ADD_SUB_CATEGORY: defineMessage({
    id: "page.sub_category.add_sub_category",
    defaultMessage: "افزودن گروپ فرعی",
  }),
  ENTER_SUBCATEGORY_INFORMATION: defineMessage({
    id: "page.sub_category.enter_sub_category_information",
    defaultMessage: "لطفا اطلاعات مربوط به گروپ فرعی را وارد بکنید",
  }),
  MAIN_CATEGORY_PLACEHOLDER: defineMessage({
    id: "page.sub_category.main_category_placehoder",
    defaultMessage: "گروپ اصلی را انتخاب کنید ",
  }),
  SUB_CATEGORY_TITLE: defineMessage({
    id: "page.sub_category.title",
    defaultMessage: "گروپ فرعی املاک",
  }),
  SUB_CATEGORY_DESCRIPTION: defineMessage({
    id: "page.sub_category.description",
    defaultMessage:
      "از این دسته ها برای تقسیمات راحتر املاک استفاده صورت میگیرد",
  }),
  CREATE_ACCOUNT: defineMessage({
    id: "page.user.create_account",
    defaultMessage: "ایجاد حساب کاربری",
  }),
  NAME_PLACEHOLDER: defineMessage({
    id: "page.user.name_placeholder",
    defaultMessage: "نام خود را وارد کنید  ",
  }),
  PHONE_PLACEHOLDER: defineMessage({
    id: "page.user.phone_placeholder",
    defaultMessage: "شماره موبایل خود را وارد کنید",
  }),
  PASSWORD_PLACEHOLDER: defineMessage({
    id: "page.user.password_placeholder",
    defaultMessage: "رمز عبور خود را وارد کنید",
  }),
  PRIVILEGE: defineMessage({
    id: "page.user.privilege",
    defaultMessage: "صلاحیت  ",
  }),
  PRIVILEGE_PLACEHOLDER: defineMessage({
    id: "page.user.privilege_placeholder",
    defaultMessage: "صلاحیت کاربر ",
  }),
  CONFIRMED: defineMessage({
    id: "page.user.confirmed",
    defaultMessage: "تایید شده",
  }),
  DOES_NOT_CONFIRMED: defineMessage({
    id: "page.user.does_not_confirmed",
    defaultMessage: "تایید نشده ",
  }),
  YES: defineMessage({
    id: "page.user.yes",
    defaultMessage: "بله",
  }),
  NO: defineMessage({
    id: "page.user.no",
    defaultMessage: "نخیر",
  }),
  USER_TITLE_WITH_VALUE: defineMessage({
    id: "page.user.title_with_value",
    defaultMessage: "لیست کاربران {value}",
  }),
  ROLE_PLACEHOLDER: defineMessage({
    id: "page.user.role_placeholder",
    defaultMessage: "نوعیت حساب کاربری",
  }),
  USER_ACCOUNT_STATE: defineMessage({
    id: "paga.user.state",
    defaultMessage: "حالت",
  }),
  USER_TITLE_SEARCH_PLACEHOLDER: defineMessage({
    id: "page.user.title_search_placeholder",
    defaultMessage: "نام ، نام کاربری ، ایمل آدرس ",
  }),
  PAGE: defineMessage({
    id: "shared.pagination.page",
    defaultMessage: "صفحه",
  }),
  OF: defineMessage({
    id: "shared.pagination.of",
    defaultMessage: "از",
  }),
  CALL: defineMessage({
    id: "shared.listing.call",
    defaultMessage: "تماس",
  }),
  WHATSAPP: defineMessage({
    id: "shared.listing.whatsapp",
    defaultMessage: "واتسپ",
  }),
  ITEM: defineMessage({
    id: "shared.listing.item",
    defaultMessage: "{name} مورد",
  }),
  LOGOUT: defineMessage({
    id: "shared.logout",
    defaultMessage: "خروج",
  }),

  PHONE: defineMessage({
    id: "shared.phone",
    defaultMessage: "تلفن",
  }),

  ADDRESS: defineMessage({
    id: "shared.address",
    defaultMessage: "آدرس",
  }),
  LISTING_ADDRESS_PLACEHOLDER: defineMessage({
    id: "shared.listing_address_placeholder",
    defaultMessage: "آدرس  جایداد خود را وارد کنید",
  }),
  ADDRESS_PLACEHOLDER: defineMessage({
    id: "shared.address_placeholder",
    defaultMessage: "لطفا آدرس خود را وارد کنید",
  }),
  EMAIL: defineMessage({
    id: "shared.email",
    defaultMessage: "ایمیل",
  }),
  EMAIL_PLACEHOLDER: defineMessage({
    id: "shared.email_placeholder",
    defaultMessage: "ایمل آدرس شما",
  }),
  NAME: defineMessage({
    id: "shared.name",
    defaultMessage: "نام",
  }),
  COUNTRY: defineMessage({
    id: "shared.country",
    defaultMessage: "کشور",
  }),
  COUNTRY_PLACEHOLDER: defineMessage({
    id: "shared.country_placeholder",
    defaultMessage: "کشور شما ",
  }),
  DELETE_COUNTRY: defineMessage({
    id: "page.country.delete_country",
    defaultMessage: "حذف کشور",
  }),
  DELETE_COUNTRY_MESSAGE: defineMessage({
    id: "page.country.delete_country_message",
    defaultMessage:
      "اگر کشور را حذف بکنید تمامی اطلاعات مرتبط به آن حذف میشود ",
  }),
  ADD_NEW_COUNTRY: defineMessage({
    id: "page.country.add_new_country",
    defaultMessage: "افزودن کشور جدید",
  }),
  ENTER_COUNTRY_INFORMATION: defineMessage({
    id: "page.country.enter_country_information",
    defaultMessage: "لطفا مشخصات کشور را وارد بکنید",
  }),
  COUNTRY_CODE: defineMessage({
    id: "page.country.code",
    defaultMessage: "کد کشور",
  }),
  COUNTRY_CODE_PLACEHOLDER: defineMessage({
    id: "page.country.code_placeholder",
    defaultMessage: "کد کشور را وارد کنید",
  }),
  COUNTRY_NAME: defineMessage({
    id: "page.country.name",
    defaultMessage: "نام کشور",
  }),
  COUNTRY_NAME_FA: defineMessage({
    id: "page.country.name_fa",
    defaultMessage: "نام کشور فارسی",
  }),
  COUNTRY_NAME_PS: defineMessage({
    id: "page.country.name_ps",
    defaultMessage: " نام کشور پشتو",
  }),
  COUNTRY_NAME_EN: defineMessage({
    id: "page.country.name_en",
    defaultMessage: "نام کشور انگلیسی",
  }),
  COUNTRY_TITLE: defineMessage({
    id: "page.country.title",
    defaultMessage: " کشور ها",
  }),
  COUNTRY_DESCRIPTION: defineMessage({
    id: "page.country.description",
    defaultMessage: "در این قسمت شما میتوانید لست کشورها را مشاهده بکنید",
  }),
  COUNTRY_NAME_PLACEHOLDER: defineMessage({
    id: "page.counyry.name_placeholder",
    defaultMessage: "نام کشور را وارد کنید",
  }),
  PROVINCE: defineMessage({
    id: "shared.province",
    defaultMessage: "ولایت",
  }),
  STATE_FA: defineMessage({
    id: "shared.state_fa",
    defaultMessage: "نام ولایت فارسی",
  }),
  STATE_PS: defineMessage({
    id: "shared.state_ps",
    defaultMessage: "نام ولایت پشتو",
  }),
  STATE_EN: defineMessage({
    id: "shared.state_en",
    defaultMessage: "نام ولایت انگلیسی",
  }),
  PROVINCE_PLACEHOLDER: defineMessage({
    id: "shared.province_placeholder",
    defaultMessage: "ولایت شما ",
  }),
  DELETE_PROVINCE: defineMessage({
    id: "page.province.delete_province",
    defaultMessage: "حذف ولایت",
  }),
  DELETE_PROVINCE_MESSAGE: defineMessage({
    id: "page.province.delete_province_message",
    defaultMessage:
      "اگر شما ولایت را حذف بکنید تمامی اطلاعات مربوط به آن حذف میشود ",
  }),
  ADD_NEW_PROVINCE: defineMessage({
    id: "page.province.add_new_province",
    defaultMessage: "افزودن ولایت جدید",
  }),
  ENTER_PROVINCE_INFORMATION: defineMessage({
    id: "page.province.enter_province_information",
    defaultMessage: "لطفا مشخصات کشور و ولایت خود را انتخاب بکنید",
  }),
  PROVINCE_TITLE: defineMessage({
    id: "page.province.title",
    defaultMessage: "ولایت ها",
  }),
  PROVINCE_DESCRIPTION: defineMessage({
    id: "page.province.description",
    defaultMessage:
      "در این قسمت شما میتوانید لست تمامی ولایت ها را مشاهده بکنید",
  }),
  WHAT_DO_YOU_WANT: defineMessage({
    id: "shared.what_do_you_want",
    defaultMessage: "دنبال چه چیز میگردی ؟",
  }),
  SAVE_CHANGES: defineMessage({
    id: "shared.save_changes",
    defaultMessage: "ذخیره تغییرات",
  }),
  NAME_REQUIRED: defineMessage({
    id: "shared.validation.name_required",
    defaultMessage: "لطفا نام خود را وارد کنید",
  }),
  EMAIL_REQUIRED: defineMessage({
    id: "shared.validation.email_required",
    defaultMessage: "ایمیل آدرس شما ضروری میباشد",
  }),
  INVALID_EMAIL: defineMessage({
    id: "shared.validation.invalid_email",
    defaultMessage: "لطفا ایمیل درست وارد نمایید !",
  }),
  PHONE_REQUIRED: defineMessage({
    id: "shared.validation.phone_required",
    defaultMessage: "شماره تماس شما ضروری میباشد",
  }),
  INVALID_PHONE: defineMessage({
    id: "shared.validation.invalid_phone",
    defaultMessage: "لطفا شماره درست وارد کنید !",
  }),
  WHATSAPP_REQUIRED: defineMessage({
    id: "shared.validation.whatsapp_required",
    defaultMessage: "واتسپ شما ضروری میباشد",
  }),
  INVALID_WHATSAPP: defineMessage({
    id: "shared.validation.invalid_whatsapp",
    defaultMessage: "شماره واتسپ شما نادرست میباشد!",
  }),
  INVALID_LANDLINE: defineMessage({
    id: "shared.validation.landline_required",
    defaultMessage: "شماره شما نادرست میباشد",
  }),
  LANDLINE_REQUIRED: defineMessage({
    id: "shared.validation.invalid_landline",
    defaultMessage: "شماره شما نادرست میباشد!",
  }),
  AGENCY_NAME_REQUIRED: defineMessage({
    id: "shared.validation.agencyName_required",
    defaultMessage: "نام راهنمایی شما ضروری میباید",
  }),
  REQUIRED_ADDRESS: defineMessage({
    id: "shared.validation.address_required",
    defaultMessage: "آدرس خود را وارد کنید",
  }),
  PRICE_REQUIRED: defineMessage({
    id: "shared.validation.price_required",
    defaultMessage: "قیمت ضروری میباشد",
  }),
  INVALID_PRICE: defineMessage({
    id: "shared.validation.invalid_price",
    defaultMessage: "قیمت باید عدد باشد",
  }),
  INVALID_BATHROOM: defineMessage({
    id: "shared.validation.invalid_bathroom",
    defaultMessage: "تعداد حمام باید عدد باشد",
  }),
  AREA_REQUIRED: defineMessage({
    id: "shared.validation.area_required",
    defaultMessage: "مساحت ضروری میباشد",
  }),
  INVALID_AREA: defineMessage({
    id: "shared.validation.invalid_area",
    defaultMessage: "مساحت باید عدد باشد",
  }),
  INVALID_BEDROOM: defineMessage({
    id: "shared.validation.invalid_bedroom",
    defaultMessage: "تعداد حمام باید عدد باشد",
  }),
  INVALID_LINK: defineMessage({
    id: "shared.validation.invalid_link",
    defaultMessage: "لینک نادرست میباشد",
  }),
  LINK_REQUIRED: defineMessage({
    id: "shared.validation.link_required",
    defaultMessage: "لینک ضروری میباشد",
  }),
  OTP_TOKEN_REQUIRED: defineMessage({
    id: "shared.validation.otp_token_placeholder",
    defaultMessage: "کد ۶ رقمی را وارد کنید",
  }),
  CONFIRM_YOUR_PASSWORD: defineMessage({
    id: "shared.validation.confirm_your_password",
    defaultMessage: "لطفا رمز عبور خود را تایید کنید",
  }),
  PASSWORD_DONOT_MATCH: defineMessage({
    id: "shared.validation.password_donot_match",
    defaultMessage: "رمز عبور مطابقت ندارد",
  }),
  NOMA: defineMessage({
    id: "listing.noma",
    defaultMessage: "نمای بیرونی",
  }),
  FLOOR: defineMessage({
    id: "listing.floor",
    defaultMessage: "کف زمین",
  }),
  BUILT_YEAR: defineMessage({
    id: "listing.builtYear",
    defaultMessage: "سال ساخت",
  }),
  FLOORS: defineMessage({
    id: "listing.floors",
    defaultMessage: "تعداد منزل",
  }),
  PARKING_SPACE: defineMessage({
    id: "listing.parkingSpace",
    defaultMessage: "فضای پارکینگ",
  }),
  ELECTRICITY_BACKUP: defineMessage({
    id: "listing.electricityBackup",
    defaultMessage: "پشتیبانی برق",
  }),
  OTHER_FEATURE: defineMessage({
    id: "listing.otherFeature",
    defaultMessage: " ویژه گی دیگر",
  }),
  KITCHENS: defineMessage({
    id: "listing.kitchens",
    defaultMessage: "آشپزخانه",
  }),
  WAREHOUSE: defineMessage({
    id: "listing.warehouse",
    defaultMessage: "انباری",
  }),
  WAREHOUSE_PLACEHOLDER: defineMessage({
    id: "shared.warehouse_placeholder",
    defaultMessage: "تعداد انباری",
  }),
  CABINET: defineMessage({
    id: "listing.cabinet",
    defaultMessage: "کابینت",
  }),
  WATER: defineMessage({
    id: "listing.water",
    defaultMessage: "آب",
  }),
  ELECTRICITY: defineMessage({
    id: "listing.electricity",
    defaultMessage: "برق",
  }),
  CENTRAL_HEATING: defineMessage({
    id: "listing.centralHeating",
    defaultMessage: "مرکز گرمی",
  }),
  SEWERAGE: defineMessage({
    id: "listing.sewerage",
    defaultMessage: "فاضلاب",
  }),
  MOSQUE: defineMessage({
    id: "listing.mosque",
    defaultMessage: "مسجد",
  }),
  FACILITIES_FOR_DISABLED: defineMessage({
    id: "listing.facilitiesForDisabled",
    defaultMessage: "امکانات برای افراد ناتوان",
  }),
  NEARBY_SCHOOLS: defineMessage({
    id: "listing.nearbySchools",
    defaultMessage: "مکتب",
  }),
  NEARBY_SCHOOLS_PLACEHOLDER: defineMessage({
    id: "listing.nearbySchools_placeholder",
    defaultMessage: "مکاتب نزدیک به شما",
  }),
  NEARBY_MALLS: defineMessage({
    id: "listing.nearbyMalls",
    defaultMessage: "فروشگاه",
  }),
  NEARBY_MALLS_PLACEHOLDER: defineMessage({
    id: "listing.nearbyMalls_placeholder",
    defaultMessage: "فروشگاه های نزدیک به شما",
  }),
  NEARBY_HOSPITALS: defineMessage({
    id: "listing.nearbyHospitals",
    defaultMessage: "شفاخانه",
  }),
  NEARBY_HOSPITALS_PLACEHOLDER: defineMessage({
    id: "listing.nearbyHospitals_placeholder",
    defaultMessage: "شفاخانه های نزدیک به شما",
  }),
  NEARBY_RESTAURANT: defineMessage({
    id: "listing.nearby_restaurant",
    defaultMessage: "رستورانت",
  }),
  NEARBY_RESTAURANT_PLACEHOLDER: defineMessage({
    id: "listing.nearbyRestaurants_placeholder",
    defaultMessage: "رستورانت های نزدیک به شما",
  }),
  NEARBY_PUBLIC_TRANSPORTS: defineMessage({
    id: "listing.nearbyPublicTransports",
    defaultMessage: "ترانسپورت عامه ",
  }),
  NEARBY_PUBLIC_TRANSPORTS_PLACEHOLDER: defineMessage({
    id: "listing.nearbyPublicTransports_placeholder",
    defaultMessage: "ایستگاه های نزدیک به شما",
  }),
  OTHER_NEARBY: defineMessage({
    id: "listing.otherNearby",
    defaultMessage: "مکان های بیشتر",
  }),
  OTHER_NEARBY_PLACEHOLDER: defineMessage({
    id: "listing.otherNearby_placeholder",
    defaultMessage: "دیگر مکان های نزدیک",
  }),
  OTHER_FACILITIES: defineMessage({
    id: "listing.otherFacilities",
    defaultMessage: "امکانات بیشتر ",
  }),
  OTHER_FACILITIES_PLACEHOLDER: defineMessage({
    id: "shared.otherFacilities_placeholder",
    defaultMessage: "امکانات بیشتر ",
  }),
  FURNISHED: defineMessage({
    id: "listing.furnished",
    defaultMessage: "مبلمان شده",
  }),
  LIFT: defineMessage({
    id: "listing.lift",
    defaultMessage: " لیفت",
  }),
  SECURITY_COMERA: defineMessage({
    id: "listing.securityComera",
    defaultMessage: "کمره امنیتی",
  }),
  INTERNET_CABLE: defineMessage({
    id: "listing.internetCabel",
    defaultMessage: "انترنت",
  }),
  BOUNDY_WALL: defineMessage({
    id: "listing.boundryWall",
    defaultMessage: "احاطه شده",
  }),
  CORNER: defineMessage({
    id: "listing.corner",
    defaultMessage: "سرنبش",
  }),
  SECURITY_STAFF: defineMessage({
    id: "listing.securityStaff",
    defaultMessage: "گارد",
  }),
  MAINTANANCE_STAFF: defineMessage({
    id: "listing.maintenanceStaff",
    defaultMessage: "کارمند تعمیراتی",
  }),
  AIR_CONDITIONING: defineMessage({
    id: "listing.airConditioning",
    defaultMessage: "ایرکندیشن",
  }),
  SWIMMING_POOL: defineMessage({
    id: "listing.swimmingPool",
    defaultMessage: "حوض آب بازی",
  }),
  JACUZZI: defineMessage({
    id: "listing.jacuzzi",
    defaultMessage: "جکوزی",
  }),
  SAUNA: defineMessage({
    id: "listing.sauna",
    defaultMessage: "سونا بخار",
  }),
  GYM: defineMessage({
    id: "listing.gym",
    defaultMessage: "جیم",
  }),
  GARDEN: defineMessage({
    id: "listing.garden",
    defaultMessage: "باغ",
  }),
  OTHER_RECREATION: defineMessage({
    id: "listing.otherRecreation",
    defaultMessage: "سرگرمی بشتر",
  }),
  REGISTER_NEW_LISTING: defineMessage({
    id: "page.listing.register_new_listing",
    defaultMessage: "ثبت جایداد شما",
  }),
  EDIT_LISTING: defineMessage({
    id: "page.listing.edit_listing",
    defaultMessage: "ویرایش جایداد ",
  }),
  EDIT_LISTING_TITLE: defineMessage({
    id: "page.listing.edit_listing_title",
    defaultMessage: "ویرایش جایداد شما",
  }),
  REGISTER_NEW_LISTING_DESCRIPTION: defineMessage({
    id: "page.listing.register_new_listing_description",
    defaultMessage:
      "با چند مرحله جایداد خود را به هزاران خریدار و مشتری نمایش بدهید",
  }),
  DEAL_AND_LOCATION_TITLE: defineMessage({
    id: "page.listing.deal_and_location_title",
    defaultMessage: "نوع معامله و موقعیت",
  }),
  PRICE_AND_AREA: defineMessage({
    id: "page.listing.price_and_area",
    defaultMessage: "قیمت و مساحت",
  }),
  FACILITIES_AND_CONVENIENVE: defineMessage({
    id: "page.listing.facilities_and_convenience",
    defaultMessage: "امکانات و سهولت",
  }),
  ADDITIONAL_INFORMATION: defineMessage({
    id: "page.listing.additional_information",
    defaultMessage: "معلومات تکمیلی",
  }),
  ADD_VIDEO: defineMessage({
    id: "page.listing.add_video",
    defaultMessage: " افزودن ویدیو",
  }),
  CONTACT_INFROMATION: defineMessage({
    id: "page.listing.contact_information",
    defaultMessage: "اطلاعات تماس",
  }),
  GOAL: defineMessage({
    id: "page.listing.goal",
    defaultMessage: " هدف",
  }),
  GOAL_PLACEHOLDER: defineMessage({
    id: "page.listing.goal_placeholder",
    defaultMessage: "مثال : کرایی ، گرویی ...",
  }),
  LISTING_TYPE: defineMessage({
    id: "page.listing.listing_type",
    defaultMessage: "نوع جایداد",
  }),
  LISTING_TYPE_PLACEHOLDER: defineMessage({
    id: "page.listing.listing_type_placeholder",
    defaultMessage: " مثال : خانه ، ...",
  }),
  LISTING_LOCATION: defineMessage({
    id: "page.listing.listing_location",
    defaultMessage: "موقعیت مکانی ",
  }),
  FIND_YOUR_LISTING_LOCATION: defineMessage({
    id: "page.listing.find_your_listing_location",
    defaultMessage: "منطقه خود را پیدا کنید",
  }),
  ALL_LISTING_TITLE: defineMessage({
    id: "page.listing.all_listing_title",
    defaultMessage: "لست جایداد ها",
  }),
  LISTING_CODE: defineMessage({
    id: "page.listing.listing_code",
    defaultMessage: "کد جایداد ",
  }),
  DELETE_LISTING: defineMessage({
    id: "page.listing.delete_listing",
    defaultMessage: "حذف جایداد",
  }),
  DELETE_LISTING_MESSAGE: defineMessage({
    id: "page.listing.delete_listing_message",
    defaultMessage: "آیا مطمین هستید ، میخواهید این جایداد را حذف کنید ؟",
  }),
  SEARCH_LISTING_TITLE: defineMessage({
    id: "page.listing.search_listing_title",
    defaultMessage: "جستجوی سریع املاک و یا جایداد",
  }),
  SEARCH_LISTING_DESCRIPTION: defineMessage({
    id: "page.listing.search_listing_description",
    defaultMessage:
      "شما میتوانید بسیار به ساده گی و آسانی جایداد و املاک مورد نیاز خود را جستجو و پیدا کنید",
  }),

  PRICE: defineMessage({
    id: "shared.price",
    defaultMessage: "قیمت",
  }),
  PRICE_PLACEHOLDER: defineMessage({
    id: "shared.price_placeholder",
    defaultMessage: "بازه قیمتی خود را انتخاب کنید",
  }),
  CURRENCY: defineMessage({
    id: "shared.currency",
    defaultMessage: "واحد پولی",
  }),
  AREA: defineMessage({
    id: "shared.area",
    defaultMessage: "مساحت",
  }),
  AREA_PLACEHOLDER: defineMessage({
    id: "shared.area_placeholder",
    defaultMessage: "مساحت جایداد خود را بنویسید",
  }),
  UNIT: defineMessage({
    id: "shared.unit",
    defaultMessage: "واحد مساحت",
  }),
  BEDROOM: defineMessage({
    id: "shared.bedroom",
    defaultMessage: " اتاق",
  }),
  BEDROOM_PLACEHOLDER: defineMessage({
    id: "shared.bedroom_placeholder",
    defaultMessage: "  تعداد اتاق را بنوسید",
  }),
  BATHROOM: defineMessage({
    id: "shared.bathroom",
    defaultMessage: " حمام",
  }),
  BATHROOM_PLACEHOLDER: defineMessage({
    id: "shared.bathroom_placeholder",
    defaultMessage: "  تعداد حمام را بنوسید",
  }),
  BEDROOM_NUMBER: defineMessage({
    id: "shared.bedroom_number",
    defaultMessage: " {value} اتاق",
  }),
  BATHROOM_NUMBER: defineMessage({
    id: "shared.bathroom_number",
    defaultMessage: " {value} حمام",
  }),
  DOCUMENT_TYPE: defineMessage({
    id: "shared.documentType",
    defaultMessage: "نوع سند",
  }),
  DESCRIPTION: defineMessage({
    id: "shared.description",
    defaultMessage: "توضیحات",
  }),
  DESCRIPTION_CONTENT: defineMessage({
    id: "shared.description_content",
    defaultMessage: "توضیحات مختصر برای جایداد خود بنوسید",
  }),
  IMAGE_NOTE1: defineMessage({
    id: "shared.image_note1",
    defaultMessage: "لطفا تصاویر باکیفیت و با نور مناسب قرار دهید",
  }),
  IMAGE_NOTE2: defineMessage({
    id: "shared.image_note2",
    defaultMessage: "لطفا تصاویر باکیفیت و با نور مناسب قرار دهید",
  }),
  IMAGE_NOTE3: defineMessage({
    id: "shared.image_note3",
    defaultMessage:
      "فرمت تصاویر: jpg , png , لطفا حجم تصاویر بیش از 5 مگابایت نباشد",
  }),
  NOMA_PLACEHOLDER: defineMessage({
    id: "shared.noma_placeholder",
    defaultMessage: "نمای بیرونی جایداد خود را انتخاب کنید",
  }),
  FLOOR_PLACEHOLDER: defineMessage({
    id: "shared.floor_placeholder",
    defaultMessage: "نوع کف جایداد خود را انتخاب کنید",
  }),
  builtYear_PLACEHOLDER: defineMessage({
    id: "shared.builtYear_placeholder",
    defaultMessage: "سال ساخت جایداد خود را انتخاب کنید",
  }),
  FLOORS_PLACEHOLDER: defineMessage({
    id: "shared.floors_placeholder",
    defaultMessage: "ساختمان شما چند منزل دارد",
  }),
  parkingSpace_PLACEHOLDER: defineMessage({
    id: "shared.parkingSpace_placeholder",
    defaultMessage: "فضای پارکنیک ",
  }),
  electricityBackup_PLACEHOLDER: defineMessage({
    id: "shared.electricityBackup_placeholder",
    defaultMessage: "پشتیبانی برق",
  }),
  KITCHENS_PLACEHOLDER: defineMessage({
    id: "shared.kitchens_placeholder",
    defaultMessage: "تعداد آشپزخانه",
  }),
  otherFeature_PLACEHOLDER: defineMessage({
    id: "shared.otherFeature_placeholder",
    defaultMessage: "امکانات دیگر",
  }),
  FACILITY_MORE_FEATURES: defineMessage({
    id: "shared.facility_more_features",
    defaultMessage: "سهولت و امکانات بیشتر",
  }),
  ADD_FACILITY_MORE_FEATURES: defineMessage({
    id: "shared.add_facility_more_feature",
    defaultMessage: "افزودن امکانات رفاهی",
  }),
  ADD_MORE_FEATURE: defineMessage({
    id: "shared.add_more_feature",
    defaultMessage: "افزودن امکانات بیشتر",
  }),
  MORE_FEATURE_DESCRIPTION: defineMessage({
    id: "shared.more_feature_description",
    defaultMessage:
      "اضافه کردن ویژگی های اضافی مانند فضای پارکینگ، نمای ملک، تعداد طبقه و....",
  }),
  LISTING_TITLE: defineMessage({
    id: "shared.listing_title",
    defaultMessage: "عنوان برای نمایش جایداد خود بنوسید ",
  }),
  LISTING_DESCRIPTION: defineMessage({
    id: "shared.listing_description",
    defaultMessage: "توضیحات مختصر برای جایداد خود بنوسید",
  }),
  VIDEO_NOTE1: defineMessage({
    id: "shared.video_note1",
    defaultMessage: "لطفا ویدئو باکیفیت و با نور مناسب قرار دهید ",
  }),
  VIDEO_NOTE2: defineMessage({
    id: "shared.video_note2",
    defaultMessage:
      "دقت کنید: آگهی هایی که ویدئو دارند شانس دیده شدن بیشتری دارند.",
  }),
  VIDEO_NOTE3: defineMessage({
    id: "shared.video_note3",
    defaultMessage:
      "ویدئوخود را در یوتیوب قرار دهید بعد لینک آن را در اینجا بگزارید.",
  }),
  ADD: defineMessage({
    id: "shared.add",
    defaultMessage: "افزودن",
  }),
  VIDEO_LINK: defineMessage({
    id: "shared.video_link",
    defaultMessage: "لینک ویدئو",
  }),
  MAXIMUM_VALID_VIDEO: defineMessage({
    id: "shared.maximum_valid_video",
    defaultMessage: "حداکثر ویدئو مجاز: {value}",
  }),
  UPLOAD: defineMessage({
    id: "shared.upload",
    defaultMessage: "آپلود",
  }),
  MAXIMUM_VALID_IMAGE: defineMessage({
    id: "shared.maximum_valid_image",
    defaultMessage: "حداکثر عکس مجاز: {value}",
  }),
  NOTHING_FOUND: defineMessage({
    id: "shared.nothing_found",
    defaultMessage: "چیزی یافت نشد",
  }),
  SEARCH: defineMessage({
    id: "shared.search",
    defaultMessage: "جستجو",
  }),
  TITLE_PLACEHOLDER: defineMessage({
    id: "shared.title_placeholder",
    defaultMessage: "مثال : خانه ، زمین ، دوبلکس ....",
  }),
  SHARE_LISTING: defineMessage({
    id: "shared.share_listing",
    defaultMessage: "اشتراک گذاری جایداد",
  }),
  SHARE_WITH_FACEBOOK: defineMessage({
    id: "shared.share_with_facebook",
    defaultMessage: "اشتراک گذاری با فیسبوک",
  }),
  SHARE_WITH_WHATSAPP: defineMessage({
    id: "shared.share_with_whatsapp",
    defaultMessage: "اشتراک گذاری با واتسپ",
  }),
  SHARE_WITH_TELEGRAM: defineMessage({
    id: "shared.share_with_telegram",
    defaultMessage: "اشتراک گذاری با تلگرام",
  }),
  SHARE_WITH_EMAIL: defineMessage({
    id: "shared.share_with_email",
    defaultMessage: "اشتراک گذاری با ایمل",
  }),
  AGENCY: defineMessage({
    id: "shared.agency",
    defaultMessage: "راهنمایی معاملات",
  }),
  AGENCY_PLACEHOLDER: defineMessage({
    id: "shared.agency_placehoder",
    defaultMessage: "نام راهنمایی معاملات را وارد کنید",
  }),
  ceoName: defineMessage({
    id: "shared.ceoName",
    defaultMessage: "نام مدیر: {name}",
  }),
  SHARE: defineMessage({
    id: "shared.share",
    defaultMessage: "اشتراک گزاری",
  }),
  PENDING: defineMessage({
    id: "listing.state.pending",
    defaultMessage: "در انتظار",
  }),
  EDITED: defineMessage({
    id: "listing.state.edited",
    defaultMessage: "ویرایش شده",
  }),
  DELETED: defineMessage({
    id: "listing.state.deleted",
    defaultMessage: "حذف شده",
  }),
  ACTIVE: defineMessage({
    id: "listing.state.active",
    defaultMessage: "فعال",
  }),
  INACTIVE: defineMessage({
    id: "listing.state.inactive",
    defaultMessage: "غیر فعال",
  }),
  EXPIRED: defineMessage({
    id: "listing.state.expired",
    defaultMessage: "منقضی",
  }),
  REJECTED: defineMessage({
    id: "listing.state.rejected",
    defaultMessage: "رد شده",
  }),
  DOUBLE_CLICK: defineMessage({
    id: "listing.double_click",
    defaultMessage: "برای انتخاب تصویر اصلی 2 بار روی عکس کلیک کنید",
  }),
  MAIN_IMAGE: defineMessage({
    id: "listing.main_image",
    defaultMessage: "تصویر اصلی",
  }),
  NOT_FOUND_LISTING_MESSAGE: defineMessage({
    id: "listing.not_found_listing_message",
    defaultMessage: "چیزی یافت نشد، لطفا مشخصات ملک خود را وارد کنید",
  }),
  NEXT: defineMessage({
    id: "shared.next",
    defaultMessage: "بعدی",
  }),
  PREV: defineMessage({
    id: "shared.prev",
    defaultMessage: "قبلی",
  }),
  CHOOSE_ACCOUNT_TYPE: defineMessage({
    id: "page.complete_profile.choose_account_type",
    defaultMessage: " خوش آمدید! لطفا نوع حساب خود را انتخاب کنید",
  }),
  ENTER_ACCOUNT_LOCATION: defineMessage({
    id: "page.complete_profile.enter_account_location",
    defaultMessage: " معلومات در مورد موقعیت خود را وارد کنید ",
  }),
  I_AM_USER: defineMessage({
    id: "page.complete_profile.i_am_user",
    defaultMessage: "کاربر عادی هستم",
  }),
  I_AM_AGENCY: defineMessage({
    id: "page.complete_profile.i_am_agency",
    defaultMessage: "راهنمایی معاملات هستم",
  }),
  SUCCESSFULY_YOUR_ACCOUNT_COMPLETED: defineMessage({
    id: "page.complete_profile.successfuly_your_account_completed",
    defaultMessage: "حساب کاربری شما با موفقیت تکمیل شد",
  }),
  MAIN_PAGE: defineMessage({
    id: "page.dashboard.main_page",
    defaultMessage: "صفحه اصلی ",
  }),
  ADD_NEW_LISTING: defineMessage({
    id: "page.dashboard.add_new_listing",
    defaultMessage: "افزودن جایداد",
  }),
  ACCOUNT_TYPE: defineMessage({
    id: "page.complete_profile.account_type",
    defaultMessage: "نوع حساب",
  }),
  ACCOUNT_DETAILS: defineMessage({
    id: "page.complete_profile.account_details",
    defaultMessage: "مشخصات",
  }),
  ACCOUNT_LOCATION: defineMessage({
    id: "page.complete_profile.account_location",
    defaultMessage: "موقعیت ",
  }),
  ACCOUNT_COMPLETION: defineMessage({
    id: "page.complete_profile.account_completion",
    defaultMessage: "پایان",
  }),
  NOTFOUND_TITLE: defineMessage({
    id: "page.404.notfound.title",
    defaultMessage: "صفحه مورد نظر یافت نشد ): ",
  }),
  NOTFOUND_DESCRIPTION: defineMessage({
    id: "page.404.notfound.description",
    defaultMessage: "به نظر میرسد صفحه ای که به دنبال آن هستید وجود نداره",
  }),
  AGENCY_NAME: defineMessage({
    id: "page.profile.agencyName",
    defaultMessage: "نام راهنمایی معاملات",
  }),
  AGENCY_NAME_PLACEHOLDER: defineMessage({
    id: "page.profile.agencyName_placeholder",
    defaultMessage: "نام راهنمایی معاملات خود را بنوسید",
  }),
  LANDLINE: defineMessage({
    id: "page.profile.landline",
    defaultMessage: "شماره ثابت",
  }),
  LANDLINE_PLACEHOLDER: defineMessage({
    id: "page.profile.landline_placeholder",
    defaultMessage: "شماره ثابت خود را وارد کنید ",
  }),
  LOGIN_TITLE: defineMessage({
    id: "page.profile.login_title",
    defaultMessage: "ورود به حساب کاربری",
  }),
  LOGIN_DESCRIPTION: defineMessage({
    id: "page.profile.login_description",
    defaultMessage: "خوش آمدید! لطفا اطلاعات خواسته شده را وارد کنید",
  }),
  FORGOT_YOUR_PASSWORD: defineMessage({
    id: "page.profile.forgot_your_password",
    defaultMessage: " رمز عبور خود را فراموش کردید؟",
  }),
  LOGIN: defineMessage({
    id: "page.profile.login",
    defaultMessage: "ورود",
  }),
  ENTER_OTP_CODE: defineMessage({
    id: "page.profile.enter_otp_code",
    defaultMessage: "کد 6 رقمی که به شماره شما ارسال شد را وارد کنید",
  }),
  CONTINUE_WITH: defineMessage({
    id: "page.profile.continue_with",
    defaultMessage: "یا ادامه دادن با",
  }),
  LOGITN_WITH_FACEBOOK: defineMessage({
    id: "page.profile.login_with_facebook",
    defaultMessage: "ورود با فیسبوک",
  }),
  YOU_DONOT_HAVE_ACCOUNT: defineMessage({
    id: "page.profile.you_donot_have_account",
    defaultMessage: "آیا حساب کاربری ندارید؟",
  }),
  DO_YOUT_HAVE_ACCOUNT: defineMessage({
    id: "page.profile.you_donot_have_account",
    defaultMessage: "آیا حساب کاربری دارید؟    ",
  }),
  REGISTER: defineMessage({
    id: "page.profile.register",
    defaultMessage: "ایجاد حساب",
  }),
  ENTER_YOUR_PASSWORD: defineMessage({
    id: "page.profile.enter_your_passwprd",
    defaultMessage: "کلمه عبور خود را وارد کنید ",
  }),
  MUST_BE_SIX_CHARACTERS: defineMessage({
    id: "page.profile.must_be_six_characters",
    defaultMessage: "باید حل اقل 6 حرف باشد",
  }),
  PASSWORD_VALIDATION: defineMessage({
    id: "page.profile.password_validation",
    defaultMessage:
      "باید حداقل شامل یکی از موارد زیر باشد: حرف بزرگ، حرف کوچک، عدد و نماد.",
  }),
  PASSWORD: defineMessage({
    id: "page.profile.password",
    defaultMessage: " رمز عبور",
  }),
  REGISTER_TITLE: defineMessage({
    id: "page.profile.register_title",
    defaultMessage: "ایجاد حساب کاربری",
  }),
  REGISTER_DESCRIPTION: defineMessage({
    id: "page.profile.register_description",
    defaultMessage: " خوش آمدید! لطفا اطلاعات خواسته شده را وارد کنید",
  }),
  REGISTER_WITH_FACEBOOK: defineMessage({
    id: "page.profile.register_with_facebook",
    defaultMessage: "ثبت با فیسبوک",
  }),
  COMPELETE_YOUR_REGISTRATION_STEPS: defineMessage({
    id: "page.profile.compelete_your_registration",
    defaultMessage: "مراحل ثبت نام خود را تکمیل بکنید ",
  }),
  DELETE_PROFILE_TITLE: defineMessage({
    id: "page.profile.delete_title",
    defaultMessage: "حذف حساب کاربری",
  }),
  DELETE_PROFILE_DESCRIPTION: defineMessage({
    id: "page.profile.delete_description",
    defaultMessage:
      "در صورتیکه شما حساب کاربری خود را حذف کنید تمامی اطلاعات مربوط  به آن حذف میشود",
  }),
  PASSWORD_REQUIRED: defineMessage({
    id: "server.message.password_required",
    defaultMessage: "کلمه عبور ضروری میباشد",
  }),
  INCORRECT_EMAIL_PASSWORD: defineMessage({
    id: "server.message.email_or_password_incorrect",
    defaultMessage: "ایمل و یا کلمه عبور شما نادرست میباشد ",
  }),
  YOUR_ACCOUNT_NOT_VERIFIED: defineMessage({
    id: "server.message.your_account_not_verified",
    defaultMessage: "حساب کاربری شما تایید نشده! لطفا منتظر بمانید",
  }),
  SUCCESSFULY_LOGGED_IN: defineMessage({
    id: "server.message.successfuly_logged_in",
    defaultMessage: "موفقانه به حساب خود وارد شدید",
  }),
  INTERNAL_SERVER_ERROR: defineMessage({
    id: "server.message.internal_server_error",
    defaultMessage: "عملیات نا موفقانه بود لطفا دوباره تلاش کنید",
  }),
  SUCCESSFULY_REGISTERED: defineMessage({
    id: "server.message.successfuly_your_account_created",
    defaultMessage: "حساب کاربری شما موفقانه ایجاد شد",
  }),
  YOUR_ACCOUNT_EXISTS: defineMessage({
    id: "server.message.your_account_exists",
    defaultMessage: "این حساب وجود دارد ، لطفا به حساب خود وارد شوید",
  }),
  RECORD_ADDED_SUCCESSFULY: defineMessage({
    id: "server.message.record_added_successfuly",
    defaultMessage: "رکورد جدید با موفقیت به پایگاه داده افزوده شد.",
  }),
  RECORD_UPDATED_SUCCESSFULY: defineMessage({
    id: "server.message.record_updated_successfuly",
    defaultMessage: "تغییرات با موفقیت ذخیره شدند.",
  }),
  RECORD_DELETED_SUCCESSFULY: defineMessage({
    id: "server.message.record_deleted_successfuly",
    defaultMessage: "رکورد مورد نظر با موفقیت از پایگاه داده حذف شد.",
  }),
  SERVER_ERROR_OCCURED: defineMessage({
    id: "server.message.server_error_occured",
    defaultMessage:
      "متاسفانه در هنگام ارتباط با دیتابیس، خطایی رخ داده است. لطفاً بعداً دوباره تلاش کنید.",
  }),
  OPERATION_FAILED: defineMessage({
    id: "server.message.operation_failed",
    defaultMessage:
      "متاسفانه خطایی در عملیات رخ داده است. لطفاً بعداً دوباره تلاش کنید.",
  }),
  LISTING_CREATED_SUCCESSFULY: defineMessage({
    id: "client.message.listing.successfuly_created",
    defaultMessage:
      "کمی منتظر بمانید تا آگهی ایجاد شده شما از طرف افغان هاوس تایید شود",
  }),
  LISTING_EDITED_SUCCESSFULY: defineMessage({
    id: "client.message.listing.successfuly_edited",
    defaultMessage:
      "کمی منتظر بمانید تا آگهی  ویرایش شده شما از طرف افغان هاوس تایید شود",
  }),
  FAILED_LISTING_CREATION: defineMessage({
    id: "client.message.listing.failed_creation",
    defaultMessage: "خطا در افزودن جایداد ، لطفا دوباره تلاش نمایید",
  }),
  FAILED_LISTING_EDITION: defineMessage({
    id: "client.message.listing.failed_edition",
    defaultMessage: "خطا در ویرایش جایداد ، لطفا دوباره تلاش نمایید",
  }),
  ADDED_TO_FAVORITES: defineMessage({
    id: "client.message.favorite.successfuly_added",
    defaultMessage: "جایداد موفقانه در دلست علاقه مندی شما اضافه شد",
  }),
  DELETED_FROM_FAVORITES: defineMessage({
    id: "client.message.favorite.successfuly_deleted",
    defaultMessage: "جایداد موفقانه از دلست علاقه مندی شما حذف شد",
  }),
  ERROR_ADDITION_TO_FAVORITES: defineMessage({
    id: "client.message.favotite.failed_addition",
    defaultMessage: "خطا در افزودن جایداد در لست علاقه مندی شما",
  }),
  ERROR_DELETION_FROM_FAVORITES: defineMessage({
    id: "client.message.listing.failed_deletion",
    defaultMessage: "خطا در حذف جایداد از لست علاقه مندی شما",
  }),
  INVALID_EMAIL_MESSAGE: defineMessage({
    id: "client.message.register.invalid_email",
    defaultMessage:
      "آدرس ایمیل وارد شده معتبر نیست. لطفاً یک آدرس ایمیل معتبر وارد کنید.",
  }),
  INVALID_OTP: defineMessage({
    id: "client.message.register.invalid_otp",
    defaultMessage: "کد وارد شده نامعتبر است ، لطفا کد درست را وارد کنید",
  }),
  VALID_OTP: defineMessage({
    id: "client.message.register.valid_otp",
    defaultMessage: "کد وارد شده معتبر است ، لطفا ادامه دهید",
  }),
  INVALID_OR_EXPIRED_OTP: defineMessage({
    id: "client.message.register.invalid_or_expired_otp",
    defaultMessage: "کد وارد شده نامعتبر است یا منقضی شده است",
  }),
  EMAIL_NOT_FOUND: defineMessage({
    id: "client.message.register.email_not_found",
    defaultMessage: "آدرس ایمیل وارد شده پیدا نشد.",
  }),
  SECS_AGO: defineMessage({
    id: "shared.secs_ago",
    defaultMessage: " {value} ثانیه قبل ",
  }),
  MIN_AGO: defineMessage({
    id: "shared.min_ago",
    defaultMessage: "{value} دقیقه قبل  ",
  }),
  MINS_AGO: defineMessage({
    id: "shared.mins_ago",
    defaultMessage: "{value} دقیقه قبل ",
  }),
  HOUR_AGO: defineMessage({
    id: "shared.hour_ago",
    defaultMessage: "{value}  ساعت قبل ",
  }),
  HOURS_AGO: defineMessage({
    id: "shared.hours_ago",
    defaultMessage: "{value} ساعت قبل  ",
  }),
  DAY_AGO: defineMessage({
    id: "shared.day_ago",
    defaultMessage: "{value} روز قبل  ",
  }),
  DAYS_AGO: defineMessage({
    id: "shared.days_ago",
    defaultMessage: "{value} روز قبل  ",
  }),
  WEEK_AGO: defineMessage({
    id: "shared.week_ago",
    defaultMessage: "{value} هفته قبل  ",
  }),
  WEEKS_AGO: defineMessage({
    id: "shared.weeks_ago",
    defaultMessage: "{value}  هفته قبل ",
  }),
  MONTH_AGO: defineMessage({
    id: "shared.month_ago",
    defaultMessage: "{value} ماه قبل  ",
  }),
  MONTHS_AGO: defineMessage({
    id: "shared.months_ago",
    defaultMessage: "{value} ماه قبل  ",
  }),
  YEAR_AGO: defineMessage({
    id: "shared.year_ago",
    defaultMessage: "{value} سال قبل  ",
  }),
  YEARS_AGO: defineMessage({
    id: "shared.years_ago",
    defaultMessage: "{value} سال قبل  ",
  }),
  AFGHAN_HOUSE_TITLE: defineMessage({
    id: "shared.footer.afghan_house",
    defaultMessage: "افغان هاوس",
  }),
  AFGHAN_HOUSE_DESCRIPTION: defineMessage({
    id: "shared.footer.afghan_house_description",
    defaultMessage:
      "  در افغان هاوس بین صدها جایداد ثبت‌ شده جست‌وجو کنید، به سرعت ملک  مورد نظر خود را پیدا کنید و برای انجام معامله‌ای مطمئن، با مشاورین  املاک معتمد و متخصص در ارتباط باشید.",
  }),
  SOCIAL_MEDIA: defineMessage({
    id: "shared.footer.social_media",
    defaultMessage: "شبکه های اجتماعی",
  }),
  LINKS: defineMessage({
    id: "shared.footer.links",
    defaultMessage: "  پیوند ها",
  }),
  CONTACT_US: defineMessage({
    id: "shared.footer.contact_us",
    defaultMessage: "تماس با ما",
  }),
  COPYRIGHT: defineMessage({
    id: "shared.footer.copyright_message",
    defaultMessage: "تمامی حقوق مادی و معنوی برای افغان هوس محفوظ است",
  }),
  CONTACT_WAYS: defineMessage({
    id: "shared.footer.contact_ways",
    defaultMessage: " راه های ارتباطی",
  }),
  PROFILE: defineMessage({
    id: "shared.profile",
    defaultMessage: "پروفایل",
  }),
  SETTINGS: defineMessage({
    id: "shared.setting",
    defaultMessage: "تنظیمات",
  }),
  SUBSCRIBE_TITLE: defineMessage({
    id: "page.home.subscribe_title",
    defaultMessage: "دریافت آخرین خبرها",
  }),
  SUBSCRIBE_DESCRIPTION: defineMessage({
    id: "page.home.subscribe_description",
    defaultMessage:
      "ما در این خبرنامه به شما آخرین اخبار و اطلاعات مربوط به بازار خرید و فروش خانه را ارائه می دهیم. اینجا میتوانید راهنمایی هایی درباره  فرآیند خرید و فروش خانه دریافت کنید و از تحولات بازار مسکن مطلع  شوید",
  }),
  SEND: defineMessage({
    id: "page.home.send",
    defaultMessage: "ارسال",
  }),
  SUBSCRIBTION_MESSAGE: defineMessage({
    id: "page.home.subscribtion_message",
    defaultMessage: "با تشکر از شما برای عضویت در afghouse.com",
  }),
  SELL: defineMessage({
    id: "shared.sell",
    defaultMessage: "فروشی",
  }),
  RENT: defineMessage({
    id: "shared.rent",
    defaultMessage: "کرایی",
  }),
  MORTGAGE: defineMessage({
    id: "shared.mortgage",
    defaultMessage: "گرویی",
  }),
  HOME: defineMessage({
    id: "shared.home",
    defaultMessage: "خانه",
  }),
  ARTICLES: defineMessage({
    id: "shared.articles",
    defaultMessage: "مقالات",
  }),
  ABOUT_US: defineMessage({
    id: "shared.about_us",
    defaultMessage: "درباره ما",
  }),
  MY_LISTINGS: defineMessage({
    id: "shared.sidebar.my_listings",
    defaultMessage: "جایداد من",
  }),
  FAVORITES: defineMessage({
    id: "shared.sidebar.favorites",
    defaultMessage: "علاقه مندی ها",
  }),
  PREFERENCE: defineMessage({
    id: "shared.sidebar.preference",
    defaultMessage: "پیش فرض",
  }),
  CHANGE_PASSWORD: defineMessage({
    id: "shared.sidebar.change_password",
    defaultMessage: "تغییر رمز عبور",
  }),
  CATEGORIES: defineMessage({
    id: "shared.sidebar.categories",
    defaultMessage: " دسته بندی ها",
  }),
  MAIN_CATEGORY: defineMessage({
    id: "shared.sidebar.main_category",
    defaultMessage: "دسته بندی اصلی",
  }),
  SUB_CATEGORY: defineMessage({
    id: "shared.sidebar.sub_category",
    defaultMessage: " دسته بندی فرعی",
  }),
  USER: defineMessage({
    id: "shared.sidebar.user",
    defaultMessage: "کاربر",
  }),
  USERS: defineMessage({
    id: "shared.sidebar.users",
    defaultMessage: "کاربران",
  }),
  USER_DETAILS: defineMessage({
    id: "shared.sidebar.user_details",
    defaultMessage: "جزییات کاربر",
  }),
  LISTING_MANAGEMENT: defineMessage({
    id: "shared.sidebar.listing_management",
    defaultMessage: "مدیریت جایداد",
  }),
  LISTING_STATE: defineMessage({
    id: "shared.sidebar.listing_state",
    defaultMessage: "حالت",
  }),
  LISTINGS: defineMessage({
    id: "shared.sidebar.listings",
    defaultMessage: "جایدادها",
  }),
  LISTING_DETAILS: defineMessage({
    id: "shared.sidebar.listing_details",
    defaultMessage: "جزییات ملک",
  }),
  REGISTER_LISTING: defineMessage({
    id: "shared.sidebar.register_listing",
    defaultMessage: "ثبت آگهی خانه",
  }),
  MANAGE_MESSAGES: defineMessage({
    id: "shared.sidebar.manage_messages",
    defaultMessage: " پیام ها",
  }),
  PROVINCE_AND_COUNTRY: defineMessage({
    id: "shared.sidebar.province_and_country",
    defaultMessage: "کشور و ولایت",
  }),
  ARTICLE_CATEGORY: defineMessage({
    id: "shared.sidebar.article_category",
    defaultMessage: "دسته بندی مقاله",
  }),
  ARTICLE: defineMessage({
    id: "shared.sidebar.article",
    defaultMessage: "مقاله",
  }),
  NEW_ARTICLE: defineMessage({
    id: "shared.sidebar.new_article",
    defaultMessage: "مقاله جدید",
  }),
  DARI: defineMessage({
    id: "languages.dari",
    defaultMessage: "دری",
  }),
  PASHTO: defineMessage({
    id: "languages.pashto",
    defaultMessage: "پشتو",
  }),
  ENGLISH: defineMessage({
    id: "languages.english",
    defaultMessage: "انگلیسی",
  }),
  PRIVACY_POLICY: defineMessage({
    id: "privacy_policy",
    defaultMessage: "قوانین و مقررات",
  }),
  COOPERATION_IN_SALRE: defineMessage({
    id: "cooperationi_n_sales",
    defaultMessage: "همکاری در فروش ",
  }),
  NEED_COUNSELING: defineMessage({
    id: "page.home.need_counseling",
    defaultMessage: "نیاز به مشاوره دارید؟",
  }),
  NEED_COUNSELING_DESCRIPTION: defineMessage({
    id: "page.home.need_counseling_description",
    defaultMessage:
      "اگر سوالی دارید، نیاز به راهنمایی دارید یا میخواهید درباره ی نیاز ها  و اهداف خرید یا فروش ملک خود صحبت کنید، با ما تماس بگیرید. تیم ما از  حرفه ایان متخصص در زمینه ی املاک تشکیل شده است که آماده اند شمارا راهنمایی کنند.",
  }),
  CONTACT_INFORMATION: defineMessage({
    id: "page.home.contact_information",
    defaultMessage: "اطلاعات تماس",
  }),
  READ_MORE: defineMessage({
    id: "shared.article.read_more",
    defaultMessage: "ادامه مطلب",
  }),
  TOP_ARTICLE: defineMessage({
    id: "page.home.top_article",
    defaultMessage: "مقالات برتر",
  }),
  TOP_ARTICLE_DESCRIPTION: defineMessage({
    id: "page.home.top_article_description",
    defaultMessage:
      "ما به شما آخرین اخبار و مقالات مرتبط با بازار  املاک را ارائه می‌دهیم. از نکات مهم در خرید و فروش منزل گرفته تا  راه‌های افزایش ارزش ملک، همه چیز در اینجا قرار دارد. ما تلاش می‌کنیم  تا شما همیشه در جریان آخرین تغییرات باشید و تصمیم‌گیری‌های بهتری در  مورد خرید یا فروش ملک خود داشته باشید.",
  }),
  ARTICLE_TITLE: defineMessage({
    id: "page.article.article_title",
    defaultMessage: " مقالات تازه و الهام بخش افغان هاوس",
  }),
  ARTICLE_DESCRIPTION: defineMessage({
    id: "page.article.article_description",
    defaultMessage:
      "در اینجا مقاله های زیادی مرتبط با حوزه املاک به شما ارائه شده است. بدون محدودیت موضوعی، از آخرین روندها، نکات تخصصی، و راهنمایی‌های عملی در بازار مسکن افغانستان بهره‌برداری کنید.",
  }),
  NEWS_LETTER: defineMessage({
    id: "page.article.news_letter",
    defaultMessage: "خبرنامه",
  }),
  NEWS_LETTER_DESCRIPTION: defineMessage({
    id: "page.article.news_letter_description",
    defaultMessage:
      "اگر مایل هستید ار خبرهای جدید ما اطلاع حاصل بکنید همین حالا ایمل خود را وارد بکنید.",
  }),
  RELATED_ARTICLE: defineMessage({
    id: "page.article.related_article",
    defaultMessage: " مقالات مرتبط",
  }),
  RELATED_ARTICLE_DETAILS: defineMessage({
    id: "page.article.related_article_details",
    defaultMessage: " مقاله هایی که شاید دوست داشته باشید مطالعه کنید",
  }),
  WE_CONSIDER: defineMessage({
    id: "page.article.we_consider",
    defaultMessage: "مطالبی که در اینجا به آن میپردازیم",
  }),
  ADD_NEW_ARTICLE: defineMessage({
    id: "page.article.add_new_article",
    defaultMessage: "افزودن مقاله جدید",
  }),
  ADD_NEW_ARTICLE_DESCRIPTION: defineMessage({
    id: "page.article.add_new_article_description",
    defaultMessage: "مشخصات مقاله را درقسمت پایین اضافه بکنید",
  }),
  ARTICLE_TITLE_PLACEHOLDER: defineMessage({
    id: "page.article.article_title_placeholder",
    defaultMessage: "عنوان مقاله خود را وارد کنید",
  }),
  ARTICLE_IMAGE: defineMessage({
    id: "page.article.article_image",
    defaultMessage: "تصویر مقاله",
  }),
  ARTICLE_IMAGE_PLACEHOLDER: defineMessage({
    id: "page.article.article_image_placeholder",
    defaultMessage: "برای مقاله خود تصویر اضافه بکنید",
  }),
  ARTICLE_DESCRIPTION_PLACEHOLDER: defineMessage({
    id: "page.article.description_placeholder",
    defaultMessage: "در مورد مقاله خود توضیحات خلاصه بنوسید ",
  }),
  ARTICLE_CATEGORY_PLACEHOLDER: defineMessage({
    id: "page.article.article_category_placeholder",
    defaultMessage: "مثال : فروش املاک ، ...",
  }),
  REQUIRED_TITLE: defineMessage({
    id: "page.article.required_title",
    defaultMessage: "عنوان ضروری میباشد",
  }),
  REQUIRED_DESCRIPTION: defineMessage({
    id: "page.article.required_description",
    defaultMessage: "توضیحات ضروری میباشد",
  }),
  ADD_NEW_CATEGORY: defineMessage({
    id: "page.article.add_new_category",
    defaultMessage: "افزودن گروپ جدید",
  }),
  EDIT_CATEGORY: defineMessage({
    id: "page.article.edit_category",
    defaultMessage: "ویرایش گروپ ",
  }),
  NEW_CATEGORY_DESCRIPTION: defineMessage({
    id: "page.article.new_category_description",
    defaultMessage: "معلومات در مورد دسته یا گروپ را وارد کنید",
  }),
  ARTICLE_CATEGORY_DESCRIPTION: defineMessage({
    id: "page.article.article_category_description",
    defaultMessage:
      "از این دسته ها برای تقسیمات راحتر مقاله ها استفاده صورت میگیرد",
  }),
  DELETE_ARTICLE: defineMessage({
    id: "page.article.delete_article",
    defaultMessage: "حذف مقاله",
  }),
  DELETE_ARTICLE_MESSAGE: defineMessage({
    id: "page.article.delete_article_message",
    defaultMessage: "آیا مطمین هستید ، میخواهید این مقاله را حذف کنید",
  }),
  ARTICLE_CATEGORY_TITLE: defineMessage({
    id: "page.article.category_title",
    defaultMessage: "گروپ مقاله ها",
  }),
  ARTICLE_TITLE_REQUIRED: defineMessage({
    id: "page.article.category_title_required",
    defaultMessage: "رده یا گروه مقاله ضروری است",
  }),
  NUMBER: defineMessage({
    id: "shared.number",
    defaultMessage: "نمبر",
  }),
  ACTIONS: defineMessage({
    id: "shared.actions",
    defaultMessage: "عملیات",
  }),
  LISTING_DESCRIPTION_TITLE: defineMessage({
    id: "listing.details.listing_description",
    defaultMessage: "توضیحات جایداد",
  }),
  LISTING_PROPERTY: defineMessage({
    id: "listing.details.listing_property",
    defaultMessage: "مشخصات جایداد",
  }),
  BEDROOM_AMOUNT: defineMessage({
    id: "listing.details.bedroom_amount",
    defaultMessage: "تعداد اتاق",
  }),
  BATHROOM_AMOUNT: defineMessage({
    id: "listing.details.bathroom_amount",
    defaultMessage: "تعداد حمام",
  }),
  METER: defineMessage({
    id: "listing.details.meter",
    defaultMessage: "{value} متر ",
  }),
  POSIBLITY_FEATURES: defineMessage({
    id: "listing.details.posibility_features",
    defaultMessage: "تجهیزات و امکانات",
  }),
  LOCATION_ON_MAP: defineMessage({
    id: "listing.details.location_on_map",
    defaultMessage: "موقعیت روی نقشه",
  }),
  NEARBY_LOCATION: defineMessage({
    id: "listing.details.nearby_location",
    defaultMessage: "مکان های نزدیک",
  }),
  NEARBY_LOCATION_DESCRIPTION: defineMessage({
    id: "listing.details.nearby_location_description",
    defaultMessage:
      "برای دیدن مکان های نزدیک جایداد مورد نظر لطفا روی نام آنها کلیک بکنید",
  }),
  NEARBY_STORE: defineMessage({
    id: "listing.details.nearby_store",
    defaultMessage: "فروشگاه",
  }),
  NEARBY_SCHOOL: defineMessage({
    id: "listing.details.nearby_school",
    defaultMessage: "مکتب",
  }),
  NEARBY_BUS: defineMessage({
    id: "listing.details.nearby_bus",
    defaultMessage: "ایستگاه موتر",
  }),
  NEARBY_HOSPITAL: defineMessage({
    id: "listing.details.nearby_hospital",
    defaultMessage: "شفاخانه",
  }),
  NEARBY_MOSQUE: defineMessage({
    id: "listing.details.nearby_mosque",
    defaultMessage: "مسجد",
  }),
  NREABY_RESTUARANT: defineMessage({
    id: "listing.details.nearby_restuarant",
    defaultMessage: "رستورانت",
  }),
  NEARBY_LIBRARY: defineMessage({
    id: "listing.details.nearby_library",
    defaultMessage: "کتابخانه",
  }),
  RELATED_LISTING: defineMessage({
    id: "listing.related_listings",
    defaultMessage: "جایداد های مشابه",
  }),
  ADD_TO__FREATURE_LISTING: defineMessage({
    id: "listing.add_to_feature_listing",
    defaultMessage: "افزودن به جایداد ویژه",
  }),
  HERO_TITLE: defineMessage({
    id: "page.home.hero_title",
    defaultMessage: "خانه رویایی و دلخواهتان را در افغان هاوس بیابید",
  }),
  HERO_DESCRIPTION: defineMessage({
    id: "page.home.hero_description",
    defaultMessage:
      "ما به شما کمک میکنیم تا با سهولت و سرعت بیشتر خانه خود را پیدا کنید  و یا خانه خود را به فروش برسانید.",
  }),
  MINIMUM: defineMessage({
    id: "shared.minimum",
    defaultMessage: "حداقل",
  }),
  MAXIMUM: defineMessage({
    id: "shared.maximum",
    defaultMessage: "حداکثر",
  }),
  TO: defineMessage({
    id: "shared.to",
    defaultMessage: "تا",
  }),
  SQUARE_METERS: defineMessage({
    id: "units.squareMters",
    defaultMessage: "مترمربع",
  }),
  BISOU: defineMessage({
    id: "units.bisou",
    defaultMessage: "بسوه",
  }),
  ACRE: defineMessage({
    id: "units.acre",
    defaultMessage: "جریب",
  }),
  AF_CURRENCY: defineMessage({
    id: "currencies.af",
    defaultMessage: "افغانی",
  }),
  US_CURRENCY: defineMessage({
    id: "currencies.us",
    defaultMessage: "دالر",
  }),
  DOCUMENT_CUSTOMARY: defineMessage({
    id: "documents.customary",
    defaultMessage: "عرفی",
  }),
  DOCUMENT_SHARIA: defineMessage({
    id: "documents.sharia",
    defaultMessage: "شرعی",
  }),
  DOCUMENT_PROPERTY: defineMessage({
    id: "documents.property",
    defaultMessage: "ملکی",
  }),
  DOCUMENT_NO_MATTER: defineMessage({
    id: "documents.no_matter",
    defaultMessage: "مهم نیست",
  }),
  NOMA_CERAMIC: defineMessage({
    id: "nomas.ceramic",
    defaultMessage: "سرامیک",
  }),
  NOMA_TRAVERTINE: defineMessage({
    id: "nomas.travertine",
    defaultMessage: "تراورتن",
  }),
  NOMA_CEMENT: defineMessage({
    id: "nomas.cement",
    defaultMessage: "سمنت",
  }),
  NOMA_GLASS: defineMessage({
    id: "nomas.glass",
    defaultMessage: "شیشه",
  }),
  NOMA_ALKOBAN: defineMessage({
    id: "nomas.alkoban",
    defaultMessage: "الکوبان",
  }),
  NOMA_OTHER: defineMessage({
    id: "nomas.other",
    defaultMessage: "دیگر",
  }),

  FLOOR_CERAMIC: defineMessage({
    id: "floors.ceramic",
    defaultMessage: "سرامیک",
  }),
  FLOOR_MOSAIC: defineMessage({
    id: "floors.mosaic",
    defaultMessage: "موزایک",
  }),
  FLOOR_CEMENT: defineMessage({
    id: "floors.cement",
    defaultMessage: "سمنت",
  }),
  FLOOR_WOOD: defineMessage({
    id: "floors.wood",
    defaultMessage: "چوب",
  }),
  FLOOR_OTHER: defineMessage({
    id: "floors.other",
    defaultMessage: "دیگر",
  }),
  ELECTRICITY_NOTHING: defineMessage({
    id: "electricity.nothing",
    defaultMessage: "ندارد",
  }),
  ELECTRICITY_SOLAR: defineMessage({
    id: "electricity.solar",
    defaultMessage: "سولر",
  }),
  ELECTRICITY_GENERATOR: defineMessage({
    id: "electricity.generator",
    defaultMessage: "جنراتور",
  }),
  ELECTRICITY_UPS: defineMessage({
    id: "electricity.ups",
    defaultMessage: "یو پی اس",
  }),
  ELECTRICITY_OTHER: defineMessage({
    id: "electricity.other",
    defaultMessage: "دیگر",
  }),
  HAS_IT: defineMessage({
    id: "shared.has_it",
    defaultMessage: "دارد",
  }),
  DOES_NOT_HAVE: defineMessage({
    id: "shared.does_not_have",
    defaultMessage: "ندارد",
  }),
  FEATURE_TAB_MAIN_FEATURE: defineMessage({
    id: "feature.tab.main_feature",
    defaultMessage: "ویژگی های اصلی",
  }),
  FEATURE_TAB_NEARBY_LOCATIONS: defineMessage({
    id: "feature.tab.nearby_locations",
    defaultMessage: "مکان های نزدیک",
  }),
  FEATURE_TAB_POSSIBILITY: defineMessage({
    id: "feature.tab.possibility",
    defaultMessage: "امکانات",
  }),
  FEATURE_TAB_POINTS: defineMessage({
    id: "feature.tab.points",
    defaultMessage: "امتیازات",
  }),
  FEATURE_TAB_RECREATION: defineMessage({
    id: "feature.tab.recreation",
    defaultMessage: "تفریحی",
  }),
  DOWNLOAD_APP_DESCRIPTION: defineMessage({
    id: "page.home.download_app.description",
    defaultMessage:
      "آیا می‌خواهید جایداد خود را بفروشید یا خریداری کنید؟ اپلیکیشن افغان هاوس را نصب کنید و ملک خود را در سریع‌ترین زمان ممکن به فروش برسانید. 🏡🔑",
  }),
  DOWNLOAD_FROM_APP_STORE: defineMessage({
    id: "page.home.download_app.download_from_app_store",
    defaultMessage: "دانلودمستقیم از اپ استور",
  }),
  DOWNLOAD_FROM_PLAY_STORE: defineMessage({
    id: "page.home.download_app.download_from_play_store",
    defaultMessage: "دانلودمستقیم از گوگل پلی",
  }),
  SCAN_QR_CODE: defineMessage({
    id: "page.home.download_app.scan_qr_code",
    defaultMessage: "اسکن کد QR برای دریافت اپلیکیشن",
  }),
  AGENCY_TITLE: defineMessage({
    id: "page.agency.agency_title",
    defaultMessage: " جستجوی آسان راهنمایی معاملات",
  }),
  AGENCY_DESCRIPTION: defineMessage({
    id: "page.agency.agency_description",
    defaultMessage:
      "آماده ایم تا به شما در فرآیند خرید ، فروش ، کرایه و یا اجاره ملک و مشاوره تخصصی بهترین و بزرگترین راهنمایی های افغانستان را برای شما معرفی بکنیم .",
  }),
  SEARCH_RESULT: defineMessage({
    id: "shared.search_result",
    defaultMessage: "نتایج جستجو ",
  }),
  SEARCH_RESULT_WITH_VALUT: defineMessage({
    id: "shared.search_result_with_value",
    defaultMessage: "نتایج جستجو ({value})",
  }),
  CITY: defineMessage({
    id: "shared.city",
    defaultMessage: "شهر",
  }),
  CITY_PLACEHOLDER: defineMessage({
    id: "shared.city_placehoder",
    defaultMessage: "َشهر خود را انتخاب کنید",
  }),
  EDIT: defineMessage({
    id: "shared.edit",
    defaultMessage: "ویرایش",
  }),
  VIEW: defineMessage({
    id: "shared.view",
    defaultMessage: "نمایش",
  }),
  CONFIRM: defineMessage({
    id: "shared.confirm",
    defaultMessage: "تایید",
  }),
  SAVE: defineMessage({
    id: "shared.save",
    defaultMessage: "ذخیره",
  }),
  MORE: defineMessage({
    id: "shared.more",
    defaultMessage: "بیشتر",
  }),
  DELETE_FILTER: defineMessage({
    id: "shared.delete_filter",
    defaultMessage: " حذف فیلتر",
  }),
  FILTER: defineMessage({
    id: "shared.filter",
    defaultMessage: "فلترها",
  }),
  ROLE_INDIVIDUAL: defineMessage({
    id: "shared.role.individual",
    defaultMessage: "شخصی",
  }),
  ROLE_AGENCY: defineMessage({
    id: "shared.role.agent",
    defaultMessage: "راهنمایی",
  }),
  ROLE_ADMIN: defineMessage({
    id: "shared.role.admin",
    defaultMessage: "مدیر",
  }),
  ROLE_SUPER_ADMIN: defineMessage({
    id: "shared.role.superAdmin",
    defaultMessage: "مدیر ارشد",
  }),
  OTP_REQUIRED: defineMessage({
    id: "shared.validation.otp_required",
    defaultMessage: "کد تایید ضروری میباشد",
  }),
  INVALID_OTP_MESSAGE: defineMessage({
    id: "shared.validation.invalid_otp",
    defaultMessage: "کد تایید درست وارد کنید",
  }),
  FORGOT_PASSWORD_TITLE: defineMessage({
    id: "page.forgot_password.title",
    defaultMessage: "رمز عبور خود را فراموش کرده اید؟",
  }),
  FORGOT_PASSWORD_DESCRIPTION: defineMessage({
    id: "page.forgot_password.description",
    defaultMessage: "ایمیل خود را وارد بکنید  تا ۶ کد برایتان ارسال شود",
  }),
  FORGOT_PASSWORD_VERIFICATION_TITLE: defineMessage({
    id: "page.forgot_password_verification.title",
    defaultMessage: "بازنشانی رمز عبور",
  }),
  FOGOT_PASSWORD_VERIFICATION_DESCRIPTION: defineMessage({
    id: "page.forgot_password-verification.description",
    defaultMessage:
      "لطفا ایمل خود را چک بکنید ما یک کد ۶ رقمی برای شما ارسال کردیم لطفا برای تایید بازنشانی رمز عبور خود این کد را رد قسمت پایین وارد کنید",
  }),
  PASSWORD_RESET_TITLE: defineMessage({
    id: "page.reset_password.title",
    defaultMessage: "تغییر رمز عبور",
  }),
  PASSWORD_RESET_DESCRIPTION: defineMessage({
    id: "page.reset_password.description",
    defaultMessage: "لطفا رمز عبور جدید خود را با دقت وارد کنید",
  }),
  ENTER_NEW_PASSWORD: defineMessage({
    id: "page.reset_password.enter_new_password",
    defaultMessage: "رمز عبور جدید را وارد کنید",
  }),
  NEW_PASSWORD_PLACEHOLDER: defineMessage({
    id: "page.reset_password.new_password_placeholder",
    defaultMessage: "رمز عبور جدید را وارد کنید",
  }),
  ENTER_CONFIRM_PASSWORD: defineMessage({
    id: "page.reset_password.enter_confirm_password",
    defaultMessage: "تکرار رمز عبور",
  }),
  CONFIRM_PASSWORD_PLACEHOLDER: defineMessage({
    id: "page.reset_password.confirm_password_placeholder",
    defaultMessage: "رمز عبور خود را دوباره وارد کنید ",
  }),
  ENTER_OTP: defineMessage({
    id: "page.email_confirmation.enter_otp",
    defaultMessage: "کد تاییدیه را وارد کنید",
  }),
  OTP_PLACEHOLDER: defineMessage({
    id: "page.email_confirmation.otp_placeholder",
    defaultMessage: "کد ۶ رقمی را وارد کنید",
  }),
  SERVER_MESSAGE_INVALID_EMAIL: defineMessage({
    id: "server.message.invalid_email",
    defaultMessage:
      "آدرس ایمیلی که وارد کردید معتبر نیست. لطفاً بررسی کنید و دوباره تلاش کنید.",
  }),
  SERVER_MESSAGE_INVALID_OTP: defineMessage({
    id: "server.message.invalid_otp",
    defaultMessage:
      "کد تأییدیه‌ای که وارد کردید نادرست است. لطفاً کد را بررسی کرده و مجدداً وارد کنید.",
  }),
  SERVER_MESSAGE_VALID_OTP: defineMessage({
    id: "server.message.valid_otp",
    defaultMessage: "کد تأییدیه‌ی شما با موفقیت تأیید شد. به سیستم خوش آمدید",
  }),
  SERVER_MESSAGE_OTP_SENT: defineMessage({
    id: "server.message.otp_sent",
    defaultMessage:
      "کد تاییدیه ی موفقانه به ایمل شما ارسال شد ، لطفا ایمل خود را چک کنید",
  }),
  SERVER_MESSAGE_INVALID_OT_EXPIRED_OTP: defineMessage({
    id: "server.message.invalid_or_expired_otp",
    defaultMessage:
      "متأسفانه کد تأییدیه‌ی شما نامعتبر است یا منقضی شده است. لطفاً مجدداً تلاش کنید یا در صورت نیاز، کد جدیدی درخواست کنید.",
  }),
  SERVER_MESSAGE_EMAIL_NOT_FOUND: defineMessage({
    id: "server.message.email_not_found",
    defaultMessage:
      "آدرس ایمیلی که وارد کردید پیدا نشد یا قادر به دریافت ایمیل نیست. لطفاً بررسی کنید و دوباره تلاش کنید.",
  }),
  SERVER_MESSAGE_OTP_EXPIRED: defineMessage({
    id: "server.message.otp_expired",
    defaultMessage:
      "پیام تأییدیه‌ای که برای شما ارسال شد، منقضی شده است. لطفاً مجدداً درخواست کد تأییدیه را ارسال کنید.",
  }),
  SERVER_MESSAGE_VERIFIED_SUCCESSFULY: defineMessage({
    id: "server.message.verified_successfuly",
    defaultMessage: "تأیید شما با موفقیت انجام شد. خوش آمدید! 😊",
  }),
  SERVER_MESSAGE_SENDDING_EMAIL_FAILED: defineMessage({
    id: "server.message.sendding_email_failed",
    defaultMessage:
      "ارسال ایمیل با مشکل مواجه شده است. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
  }),
  SERVER_MESSAGE_INVALID_PASSWORD: defineMessage({
    id: "server.message.invalid_password",
    defaultMessage:
      "رمز عبور نامعتبر است. لطفاً رمز عبور خود را بررسی کنید و دوباره تلاش کنید",
  }),
  SERVER_MESSAGE_INVALID_INPUT: defineMessage({
    id: "server.message.invalid_input",
    defaultMessage:
      "ورودی نامعتبر است. لطفاً ورودی خود را بررسی کنید و دوباره تلاش کنید.",
  }),
  EMAIL_CONFIRMATION_TITLE: defineMessage({
    id: "page.email_confirmation.title",
    defaultMessage: "تایید حساب شما",
  }),
  EMAIL_CONFIRMATION_DESCRIPTION: defineMessage({
    id: "page.email_confirmation.description",
    defaultMessage:
      "لطفا ایمل خود را چک بکنید ما یک کد ۶ رقمی برای شما ارسال کردیم لطفا برای تایید حساب خود این کد را رد قسمت پایین وارد کنید",
  }),
  DASHBOARD_PAGE_TITLE: defineMessage({
    id: "page.dashboard.title",
    defaultMessage: "ثبت آگهی رایگان جایداد شما",
  }),
  DASHBOARD_PAGE_DESCRIPTION: defineMessage({
    id: "page.dashboard.description",
    defaultMessage:
      "آیا قصد فروش یا اجاره خانه خود را دارید؟ پس همین حالا آگهی خود را منتشر کنید تا در سریعترین زمان ممکن ملک شما به فروش ، اجاره و یا کرایه برسد.",
  }),
  LISTING_FOR_SALE: defineMessage({
    id: "page.dashboard.listing_for_sell",
    defaultMessage: "جایداد برای فروش",
  }),
  LISTING_FOR_RENT: defineMessage({
    id: "page.dashboard.listing_for_rent",
    defaultMessage: "جایداد برای کرایه",
  }),
  LISTING_FOR_MORTGAGE: defineMessage({
    id: "page.dashboard.listing_for_mortgage",
    defaultMessage: "جایداد برای گروی",
  }),
  NOTIFICATION_TITLE: defineMessage({
    id: "page.notification.title",
    defaultMessage: "پیام ها",
  }),
  DATE: defineMessage({
    id: "page.notification.date",
    defaultMessage: "تاریخ",
  }),
  NOTIFICATION_CONTENT: defineMessage({
    id: "page.notification.content",
    defaultMessage: "متن پیام",
  }),
  DELETE_ALL: defineMessage({
    id: "page.notification.delete_all",
    defaultMessage: "حذف همه",
  }),
  DELETE_NOTIFICATION_TITLE: defineMessage({
    id: "page.notification.delete_all_title",
    defaultMessage: "حذف همه پیام ها",
  }),
  DELETE_NOTIFICATION_DESCRIPTION: defineMessage({
    id: "page.notification.delete_all_description",
    defaultMessage: "آیا مطمیین هستید ، میخواهید پیام ها را حذف بکنید ؟",
  }),
  NOTIFICATION_LIST_EMPTY: defineMessage({
    id: "page.notification.notifications_empty",
    defaultMessage: "لست پیام خالی میباشد",
  }),
};
