import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { ArticleCategoryModel } from "../../model/Article";
import { handelValidationMap } from "../../util/handelValidationMap";
import { handelServerMessageMap } from "../../util/handelServerMessageMap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
// Define the type of the onClose parameter
type props = (state: boolean) => void;
export const useHandelArticleCategory = (onClose?: props) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const validations = handelValidationMap(intl);
  const messages = handelServerMessageMap(intl);
  const schema = object().shape({
    name: string().required(validations["title_required"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doArticleCategory: any) => {
      const response = await Fetch({
        method: "POST",
        data: doArticleCategory,
        endpoint: "/article/category",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["articleCategory"] });
      toast.success(messages["recordAddedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["operationFailed"]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: number) => {
      const response = await Fetch({
        method: "DELETE",
        endpoint: `/article/category/${id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["articleCategory"] });
      toast.success(messages["recordDeletedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["operationFailed"]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (articleCategory: any) => {
      const response = await Fetch({
        method: "PUT",
        data: articleCategory,
        endpoint: `/article/category/${articleCategory.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["articleCategory"] });
      toast.success(messages["recordUpdatedSuccessfuly"]);
    },
    onError: (error) => {
      toast.error(messages["operationFailed"]);
    },
  });

  const onUpdate = async (
    articleCategory: ArticleCategoryModel,
    id: number
  ) => {
    const data = {
      id: id,
      name: articleCategory.name,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSubmit = async (data: any) => {
    mutation.mutate(data);
  };

  function ToGetArticleCategory() {
    const result = useQuery({
      queryKey: ["articleCategory"],
      queryFn: async () => await getArticleCategories(),
    });
    return result;
  }

  const getArticleCategories = async (): Promise<ArticleCategoryModel[]> => {
    const response = await Fetch({
      endpoint: "/article/category",
    });
    return response?.data;
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    reset,
    setValue,
    ToGetArticleCategory,
    onDelete,
    onUpdate,
  };
};
