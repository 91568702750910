type Props = {
  className?: string;
};
const ThreeDotsIcon = ({ className }: Props) => {
  return (
    <svg viewBox="0 0 150 550" className={className}>
      <g id="dots">
        <circle fill="currentColor" cx="75" cy="275" r="75" />
        <circle fill="currentColor" cx="75" cy="75" r="75" />
        <circle fill="currentColor" cx="75" cy="475" r="75" />
      </g>
    </svg>
  );
};

export default ThreeDotsIcon;
