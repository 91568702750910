import { TRANSLATION } from "../static/translation";

export const handelServerMessageMap = (intl: any) => {
  const errors: { [key: string]: string } = {
    emailRequired: intl.formatMessage({
      id: TRANSLATION.EMAIL_REQUIRED.id,
      defaultMessage: TRANSLATION.EMAIL_REQUIRED.defaultMessage,
    }),
    passwordRequired: intl.formatMessage({
      id: TRANSLATION.PASSWORD_REQUIRED.id,
      defaultMessage: TRANSLATION.PASSWORD_REQUIRED.defaultMessage,
    }),
    invalidUseramePassword: intl.formatMessage({
      id: TRANSLATION.INCORRECT_EMAIL_PASSWORD.id,
      defaultMessage: TRANSLATION.INCORRECT_EMAIL_PASSWORD.defaultMessage,
    }),
    accountNotVerified: intl.formatMessage({
      id: TRANSLATION.YOUR_ACCOUNT_NOT_VERIFIED.id,
      defaultMessage: TRANSLATION.YOUR_ACCOUNT_NOT_VERIFIED.defaultMessage,
    }),
    successfulyLoggedIn: intl.formatMessage({
      id: TRANSLATION.SUCCESSFULY_LOGGED_IN.id,
      defaultMessage: TRANSLATION.SUCCESSFULY_LOGGED_IN.defaultMessage,
    }),
    internalServerError: intl.formatMessage({
      id: TRANSLATION.INTERNAL_SERVER_ERROR.id,
      defaultMessage: TRANSLATION.INTERNAL_SERVER_ERROR.defaultMessage,
    }),
    registerSuccessfuly: intl.formatMessage({
      id: TRANSLATION.SUCCESSFULY_REGISTERED.id,
      defaultMessage: TRANSLATION.SUCCESSFULY_REGISTERED.defaultMessage,
    }),
    yourAccountExists: intl.formatMessage({
      id: TRANSLATION.YOUR_ACCOUNT_EXISTS.id,
      defaultMessage: TRANSLATION.YOUR_ACCOUNT_EXISTS.defaultMessage,
    }),
    subscribtionMessage: intl.formatMessage({
      id: TRANSLATION.SUBSCRIBTION_MESSAGE.id,
      defaultMessage: TRANSLATION.SUBSCRIBTION_MESSAGE.defaultMessage,
    }),
    recordAddedSuccessfuly: intl.formatMessage({
      id: TRANSLATION.RECORD_ADDED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.RECORD_ADDED_SUCCESSFULY.defaultMessage,
    }),
    recordUpdatedSuccessfuly: intl.formatMessage({
      id: TRANSLATION.RECORD_UPDATED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.RECORD_UPDATED_SUCCESSFULY.defaultMessage,
    }),
    recordDeletedSuccessfuly: intl.formatMessage({
      id: TRANSLATION.RECORD_DELETED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.RECORD_DELETED_SUCCESSFULY.defaultMessage,
    }),
    serverErrorOccured: intl.formatMessage({
      id: TRANSLATION.SERVER_ERROR_OCCURED.id,
      defaultMessage: TRANSLATION.SERVER_ERROR_OCCURED.defaultMessage,
    }),
    operationFailed: intl.formatMessage({
      id: TRANSLATION.OPERATION_FAILED.id,
      defaultMessage: TRANSLATION.OPERATION_FAILED.defaultMessage,
    }),
    listingCreated: intl.formatMessage({
      id: TRANSLATION.LISTING_CREATED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.LISTING_CREATED_SUCCESSFULY.defaultMessage,
    }),
    listingEdited: intl.formatMessage({
      id: TRANSLATION.LISTING_EDITED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.LISTING_EDITED_SUCCESSFULY.defaultMessage,
    }),
    failedListingCreation: intl.formatMessage({
      id: TRANSLATION.FAILED_LISTING_CREATION.id,
      defaultMessage: TRANSLATION.FAILED_LISTING_CREATION.defaultMessage,
    }),
    failedListingEdition: intl.formatMessage({
      id: TRANSLATION.FAILED_LISTING_EDITION.id,
      defaultMessage: TRANSLATION.FAILED_LISTING_EDITION.defaultMessage,
    }),
    addedToFavorites: intl.formatMessage({
      id: TRANSLATION.ADDED_TO_FAVORITES.id,
      defaultMessage: TRANSLATION.ADDED_TO_FAVORITES.defaultMessage,
    }),
    deletedFromFavorites: intl.formatMessage({
      id: TRANSLATION.DELETED_FROM_FAVORITES.id,
      defaultMessage: TRANSLATION.DELETED_FROM_FAVORITES.defaultMessage,
    }),
    errorAdditionToFavorites: intl.formatMessage({
      id: TRANSLATION.ERROR_ADDITION_TO_FAVORITES.id,
      defaultMessage: TRANSLATION.ERROR_ADDITION_TO_FAVORITES.defaultMessage,
    }),
    errorDeletionFromFavorites: intl.formatMessage({
      id: TRANSLATION.ERROR_DELETION_FROM_FAVORITES.id,
      defaultMessage: TRANSLATION.ERROR_DELETION_FROM_FAVORITES.defaultMessage,
    }),

    invalidEmail: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_INVALID_EMAIL.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_INVALID_EMAIL.defaultMessage,
    }),
    invalidOtp: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_INVALID_OTP.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_INVALID_OTP.defaultMessage,
    }),
    validOtp: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_VALID_OTP.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_VALID_OTP.defaultMessage,
    }),
    invalidOrExpiredOtp: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_INVALID_OT_EXPIRED_OTP.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_INVALID_OT_EXPIRED_OTP.defaultMessage,
    }),
    emailNotFound: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_EMAIL_NOT_FOUND.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_EMAIL_NOT_FOUND.defaultMessage,
    }),
    optSent: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_OTP_SENT.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_OTP_SENT.defaultMessage,
    }),
    otpExpired: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_OTP_EXPIRED.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_OTP_EXPIRED.defaultMessage,
    }),
    verifiedSuccessfuly: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_VERIFIED_SUCCESSFULY.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_VERIFIED_SUCCESSFULY.defaultMessage,
    }),
    senddingEmailFailed: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_SENDDING_EMAIL_FAILED.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_SENDDING_EMAIL_FAILED.defaultMessage,
    }),
    invalidPassword: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_INVALID_PASSWORD.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_INVALID_PASSWORD.defaultMessage,
    }),
    invalidInput: intl.formatMessage({
      id: TRANSLATION.SERVER_MESSAGE_INVALID_INPUT.id,
      defaultMessage: TRANSLATION.SERVER_MESSAGE_INVALID_INPUT.defaultMessage,
    }),
    "": "",
  };
  return errors;
};
