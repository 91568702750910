import React from "react";
import Button from "../button";
import CallIcon from "../../icons/CallIcon";
import { Typo } from "../typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";
type Props = {
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  phone?: string;
  direction: string;
};

const CallButton: React.FC<Props> = (props) => {
  const { children, disabled, loading, className, phone, direction } = props;

  const makeCall = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (phone) {
      window.open(`tel:${phone}`);
    }
  };
  return (
    <>
      <div>
        <Button
          size="sxtra-small"
          theme="primary"
          rounded="rounded-lg"
          disabled={!phone}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            makeCall(event)
          }
          icon={
            <CallIcon
              className={clsx("h-5 w-5", {
                " transform -scale-x-100": direction === DIRECTIONS.LTR,
              })}
            />
          }
        >
          <Typo.heading4>
            <FormattedMessage
              id={TRANSLATION.CALL.id}
              defaultMessage={TRANSLATION.CALL.defaultMessage}
            />
          </Typo.heading4>
        </Button>
      </div>
    </>
  );
};

export default CallButton;
