type Props = {
  className?: string;
};
const VideoIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-4 w-4 ${className}`}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35325 14.1134H4.13992C2.03325 14.1134 1.33325 12.7134 1.33325 11.3067V5.69339C1.33325 3.58672 2.03325 2.88672 4.13992 2.88672H8.35325C10.4599 2.88672 11.1599 3.58672 11.1599 5.69339V11.3067C11.1599 13.4134 10.4533 14.1134 8.35325 14.1134Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0132 11.9001L11.1599 10.6001V6.39342L13.0132 5.09342C13.9199 4.46009 14.6666 4.84675 14.6666 5.96009V11.0401C14.6666 12.1534 13.9199 12.5401 13.0132 11.9001Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66675 7.83325C8.21903 7.83325 8.66675 7.38554 8.66675 6.83325C8.66675 6.28097 8.21903 5.83325 7.66675 5.83325C7.11446 5.83325 6.66675 6.28097 6.66675 6.83325C6.66675 7.38554 7.11446 7.83325 7.66675 7.83325Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
