type Props = {
  className?: string;
};
const OceanProtocolIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3068 15.306C14.8652 13.2951 15.693 12.0245 16.1339 11.2465C16.2099 11.1124 16.2747 10.9923 16.33 10.8852C17.6431 12.2958 18.2627 13.5833 18.4648 14.7171C18.6912 15.9873 18.404 17.1224 17.887 18.0985C16.9822 19.8066 15.3974 20.982 14.7692 21.4022C14.3494 21.0995 13.5201 20.4325 12.9593 19.5144C12.6183 18.956 12.3889 18.3245 12.3946 17.6373C12.4003 16.9563 12.6371 16.1738 13.3068 15.306Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M11.5429 2.51889L11.5429 2.51891L11.5437 2.51583C11.5534 2.5149 11.57 2.51662 11.5889 2.52927C12.4213 3.65854 13.2598 5.63406 13.2598 7.79006C13.2598 8.45778 12.9931 9.14579 12.5398 9.89537C12.1515 10.5373 11.6546 11.181 11.126 11.8658C11.035 11.9836 10.9431 12.1027 10.8506 12.2231C9.63138 13.8119 8.29445 15.6598 8.46126 17.8284L8.46117 17.8284L8.46212 17.8382C8.61675 19.4362 9.29141 20.6353 9.69889 21.3476C8.17601 20.8355 6.66344 19.4443 5.92282 17.6264C5.16178 15.7584 5.21949 13.4478 6.95578 11.1953L6.95724 11.1934C9.89812 7.33984 11.1265 4.08018 11.5429 2.51889ZM10.2938 21.4844C10.2938 21.4844 10.2926 21.4845 10.29 21.4846L10.2938 21.4844Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default OceanProtocolIcon;
