import { ReactElement, useEffect, useState } from "react";
import ProgressSteps from "../../../components/shared/Stepper/ProgressSteps";
import UserAccountType from "./UserAccountType";
import UserAccountDetails from "./UserAccountDetails";
import UserAccountLocation from "./UserAccountLocation";
import { useAuth } from "../../../context/auth";
import { Typo } from "../../../components/shared/typo";
import Divider from "../../../components/shared/Divider";
import { handelAccountStepMap } from "../../../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import CompeleteProfileImage from "../../../components/icons/CompleteProfileImage";
import { useHandelProfile } from "../../../hooks/profile/useHandelProfile";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { TRANSLATION } from "../../../static/translation";
import Loader from "../../../components/shared/Loader";
import { useLang } from "../../../context/language";
const CompeleteProfile = () => {
  const auth = useAuth();
  const intl = useIntl();
  const lang = useLang();
  const [role, setRole] = useState(1);
  const navigate = useNavigate();
  const {
    currentStep,
    setCurrentStep,
    loading,
    onUpdateProfile,
    handleSubmit,
    register,
    errors,
    reset,
    setValue,
  } = useHandelProfile();
  const steps = [
    { id: 1, title: handelAccountStepMap(intl, "account_type") },
    { id: 2, title: handelAccountStepMap(intl, "account_location") },
    { id: 3, title: handelAccountStepMap(intl, "account_details") },
  ];

  const mapStep: { [key: string]: ReactElement } = {
    1: (
      <UserAccountType
        role={role}
        setRole={setRole}
        currentStep={currentStep}
        loading={loading}
        onUpdateProfile={onUpdateProfile}
      ></UserAccountType>
    ),
    2: (
      <UserAccountLocation
        role={role}
        setRole={setRole}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        user={auth?.user}
        onPrev={() => setCurrentStep(currentStep - 1)}
        onUpdateProfile={onUpdateProfile}
        loading={loading}
        locale={lang.language}
      ></UserAccountLocation>
    ),
    3: (
      <UserAccountDetails
        role={role}
        setRole={setRole}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        user={auth?.user}
        onPrev={() => setCurrentStep(currentStep - 1)}
        onUpdateProfile={onUpdateProfile}
        handleSubmit={handleSubmit}
        errors={errors}
        register={register}
        reset={reset}
        loading={loading}
        setValue={setValue}
      ></UserAccountDetails>
    ),
  };

  useEffect(() => {
    if (auth.user) {
      if (auth?.user?.completed) {
        navigate(ROUTES.NEW_LISTING_ROUTE);
      }
      setRole(auth.user.roleId);
    }
  }, [auth?.user]);

  return (
    <div className="">
      {auth?.user ? (
        <div className="grid grid-cols-2 gap-5 h-full px-4 ">
          <div className="w-full flex h-full flex-col col-span-2 py-8 md:col-span-1 px-4 bg-white rounded-lg">
            <div>
              <Typo.headingA
                fontWeight="font-bold"
                className="text-gray-900 mb-12"
              >
                <FormattedMessage
                  id={TRANSLATION.COMPELETE_YOUR_REGISTRATION_STEPS.id}
                  defaultMessage={
                    TRANSLATION.COMPELETE_YOUR_REGISTRATION_STEPS.defaultMessage
                  }
                />
              </Typo.headingA>
              <div className="relative flex justify-center">
                <ProgressSteps steps={steps} currentStep={currentStep} />
              </div>
              <Divider type="horizontal" className="bg-gray-200" />
            </div>
            <div className="h-full w-full pt-8 px-6 flex-grow">
              {mapStep[currentStep]}
            </div>
          </div>
          <div className="w-full bg-gray-100 hidden md:block">
            <CompeleteProfileImage className="" />
          </div>
        </div>
      ) : (
        <Loader className=" h-screen" />
      )}
    </div>
  );
};

export default CompeleteProfile;
