type Props = {
  className?: string;
};
const BulkTextIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M18.3337 6.50835V13.4917C18.3337 16.525 16.5253 18.3334 13.492 18.3334H6.50866C6.34199 18.3334 6.17533 18.325 6.01699 18.3167C4.99199 18.25 4.12533 17.9584 3.44199 17.4584C3.09199 17.2167 2.78366 16.9084 2.54199 16.5584C1.96699 15.7667 1.66699 14.7334 1.66699 13.4917V6.50835C1.66699 3.64169 3.28366 1.86669 6.01699 1.69169C6.17533 1.67502 6.34199 1.66669 6.50866 1.66669H13.492C14.7337 1.66669 15.767 1.96669 16.5587 2.54169C16.9087 2.78335 17.217 3.09169 17.4587 3.44169C18.0337 4.23335 18.3337 5.26669 18.3337 6.50835Z"
        fill="#E0E0E0"
      />
      <path
        d="M13.8917 4.70001H6.10833C5.15 4.70001 4.375 5.47501 4.375 6.43335V7.41668C4.375 7.75835 4.65833 8.04168 5 8.04168C5.34167 8.04168 5.625 7.75835 5.625 7.41668V6.43335C5.625 6.16668 5.84167 5.95001 6.10833 5.95001H9.375V14.05H7.89167C7.55 14.05 7.26667 14.3333 7.26667 14.675C7.26667 15.0167 7.55 15.3 7.89167 15.3H12.1167C12.4583 15.3 12.7417 15.0167 12.7417 14.675C12.7417 14.3333 12.4583 14.05 12.1167 14.05H10.6333V5.95001H13.9C14.1667 5.95001 14.3833 6.16668 14.3833 6.43335V7.41668C14.3833 7.75835 14.6667 8.04168 15.0083 8.04168C15.35 8.04168 15.6333 7.75835 15.6333 7.41668V6.43335C15.625 5.48335 14.85 4.70001 13.8917 4.70001Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default BulkTextIcon;
