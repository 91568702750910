type Props = {
    className?: string;
};
const AHName = ({ className }: Props) => {
    return (
        <svg className={className} id="Layer_1" data-name="Layer 1" viewBox="0 0 809.31 87.15">
            <circle fill="currentColor" className="cls-1" cx="689.26" cy="13.37" r="9.03" />
            <circle fill="currentColor" className="cls-1" cx="756.79" cy="13.37" r="9.03" />
            <rect fill="currentColor" className="cls-1" x="794.68" y="24.63" width="14.63" height="62.51" />
            <path fill="currentColor" className="cls-1" d="M608.52,23.59v63.56h-91.78c-17.53,0-31.74-14.21-31.74-31.74v-.08c0-17.53,14.21-31.74,31.74-31.74h19.2v14.26h-20.04c-9.1,0-16.52,7.38-16.52,16.48,0,4.57,1.84,8.72,4.86,11.7,2.98,2.98,7.09,4.82,11.66,4.82h78.36V23.59h14.26Z" />
            <path fill="currentColor" className="cls-1" d="M758.67,24.63c-16.9,0-30.56,13.71-30.56,30.61v15.43h-10.49c2.02-4.07,3.15-8.64,3.15-13.46V25.3h-61.17v31.91c0,4.82,1.13,9.39,3.14,13.46h-29.19V23.58h-16.35v63.52h110.91v.04h13.58v-31.61c0-4.44,1.8-8.47,4.7-11.36,2.93-2.94,6.96-4.74,11.4-4.74,8.89,0,16.1,7.21,16.1,16.1v31.61h15.39v-31.9c0-16.9-13.71-30.61-30.61-30.61Zm-51.44,32.28c0,4.45-1.81,8.47-4.74,11.41-.88,.88-1.89,1.67-3.02,2.35-2.43,1.51-5.28,2.34-8.39,2.34s-5.91-.83-8.34-2.34c-4.65-2.81-7.76-7.93-7.76-13.76v-17.02h32.25v17.02Z" />
            <circle fill="currentColor" className="cls-1" cx="563.57" cy="31.43" r="9.03" />
            <g>
                <path fill="currentColor" className="cls-1" d="M123.52,23.19v63.56H31.74C14.21,86.75,0,72.53,0,55.01v-.08c0-17.53,14.21-31.74,31.74-31.74h19.2v14.26H30.9c-9.1,0-16.52,7.38-16.52,16.48,0,4.57,1.84,8.72,4.86,11.7,2.98,2.98,7.09,4.82,11.66,4.82H109.26V23.19h14.26Z" />
                <path fill="currentColor" className="cls-1" d="M272.32,54.97c0,8.76-3.56,16.65-9.27,22.39-5.74,5.74-13.67,9.27-22.39,9.27h-62.68v-13.79h62.01c4.78,0,9.06-1.93,12.16-5.03,3.1-3.14,5.03-7.42,5.03-12.16,0-9.48-7.67-17.19-17.19-17.19h-47.08v13.12h33.37v14.63h-48.3V23.31h62.68c17.48,0,31.66,14.17,31.66,31.66Z" />
                <rect fill="currentColor" className="cls-1" x="130.57" y="23.51" width="15.06" height="63.24" />
                <rect fill="currentColor" className="cls-1" x="151.4" y="23.51" width="15.06" height="63.24" />
                <path fill="currentColor" className="cls-1" d="M463.55,55.3c0,8.72-3.52,16.65-9.27,22.35-5.74,5.7-13.63,9.27-22.39,9.27h-122.3c-17.44,0-31.61-14.17-31.61-31.61V23.69h15.64v23.73c0,12.2,9.06,22.26,20.84,23.82-2.89-4.74-4.53-10.31-4.53-16.23,0-8.72,3.52-16.56,9.22-22.26,5.7-5.7,13.58-9.22,22.26-9.22h49.77c17.4,0,31.49,14.09,31.49,31.49,0,2.89-.38,5.7-1.17,8.34h-19.5c1.22-2.26,1.93-4.82,1.93-7.59,0-8.72-7.04-15.76-15.76-15.76h-47.46c-4.36,0-8.26,1.76-11.11,4.61-2.85,2.85-4.61,6.79-4.61,11.15,0,8.68,7.04,15.72,15.72,15.72h84.06c6.67,0,12.7-2.68,17.06-7.04,1.3-1.3,2.43-2.73,3.44-4.28,.42-1.38,.63-2.85,.63-4.36v-1.38c0-3.65-1.26-7-3.31-9.64-.8-1.01-1.68-1.93-2.68-2.68l-18.41-18.41-.38-.38-11.53-11.53,11.74-11.78,32.95,32.96c4.15,4.11,7.13,9.35,8.43,15.26,.55,2.26,.84,4.65,.84,7.09Z" />
            </g>
        </svg>
    );
};

export default AHName;
