type props = {
  className?: string;
};
const AgencyIcon = ({ className }: props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2582_23599)">
        <path
          d="M8.36239 2.625C8.36239 4.05 7.27148 5.25 5.81694 5.25C4.43512 5.25 3.27148 4.05 3.27148 2.625C3.27148 1.2 4.43512 0 5.81694 0C7.19876 0 8.36239 1.2 8.36239 2.625Z"
          fill="currentColor"
        />
        <path
          d="M17.3811 7.80019C17.3811 8.55019 16.7993 9.1502 16.072 9.1502H9.01747V22.7252C9.01747 23.4752 8.36293 24.0752 7.56293 24.0002C6.98111 23.8502 6.54474 23.3252 6.54474 22.6502V18.2252C6.54474 17.7752 6.18111 17.4002 5.74474 17.4002C5.30838 17.4002 4.94474 17.7752 4.94474 18.2252V22.6502C4.94474 23.2502 4.50838 23.8502 3.92656 24.0002C3.12656 24.1502 2.47202 23.4752 2.47202 22.7252V14.3252H2.39929C1.45384 14.3252 0.726562 13.5002 0.726562 12.6002V7.8752C0.726562 7.0502 1.38111 6.4502 2.10838 6.4502H16.072C16.7993 6.4502 17.3811 7.05019 17.3811 7.80019Z"
          fill="currentColor"
        />
        <path
          d="M23.7094 16.6498L17.673 11.0248C17.3094 10.7248 16.8003 10.7248 16.4366 11.0248L13.3094 13.9498V12.5998C13.3094 12.2998 13.0912 12.1498 12.873 12.1498H11.9276C11.6366 12.1498 11.4912 12.3748 11.4912 12.5998V14.3998L11.8548 14.0248C12.0003 13.7998 12.2912 13.7998 12.5094 14.0248C12.7276 14.1748 12.7276 14.4748 12.5094 14.6998L11.4912 15.7498L10.4003 16.7998C10.1821 17.0248 10.1094 17.2498 10.1094 17.4748C10.1094 17.6998 10.1821 17.9248 10.3276 18.0748C10.6912 18.4498 11.273 18.5248 11.6366 18.1498V23.1748C11.6366 23.6998 12.073 24.1498 12.5821 24.1498H15.4185V20.7748C15.4185 20.2498 15.8548 19.7998 16.3639 19.7998H17.8185C18.3276 19.7998 18.7639 20.2498 18.7639 20.7748V23.9998H21.6003C22.1094 23.9998 22.5457 23.5498 22.5457 23.0248V17.9998C22.9094 18.3748 23.4912 18.2998 23.8548 17.9248C24.073 17.6248 24.073 17.0248 23.7094 16.6498ZM17.0185 17.4748C16.4366 17.4748 15.9276 16.9498 15.9276 16.3498C15.9276 15.7498 16.4366 15.2248 17.0185 15.2248C17.6003 15.2248 18.1094 15.7498 18.1094 16.3498C18.1094 16.9498 17.673 17.4748 17.0185 17.4748Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2582_23599">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AgencyIcon;
