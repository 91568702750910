export const handelProvinceLocale = (key: string) => {
  const porovince: { [key: string]: string } = {
    fa: "location.province.state_fa",
    pa: "location.province.state_ps",
    en: "location.province.state_en",
  };
  return porovince[key];
};

export const handelSubCategoryLocale = (key: string) => {
  const subCategory: { [key: string]: string } = {
    fa: "subCategory.title_fa",
    pa: "subCategory.title_ps",
    en: "subCategory.title_en",
  };
  return subCategory[key];
};
