import { FC, useEffect } from "react";
import Modal from "../wrapper/modal";
import InputField from "../components/shared/InputField";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import { useHandelArticleCategory } from "../hooks/article/useHandelArticleCategory";
import { ArticleCategoryModel } from "../model/Article";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { handelInputMap } from "../util/handelInputMap";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  defaultValue?: ArticleCategoryModel;
  intl: IntlShape;
};

const ArticleCategoryModal: FC<Props> = ({
  open,
  onClose,
  defaultValue,
  intl,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    setValue,
    reset,
  } = useHandelArticleCategory(onClose);

  const onSubmitCategory = async (data: any) => {
    if (defaultValue) {
      await onUpdate(data, Number(defaultValue.id));
    } else {
      await onSubmit(data);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue.name);
    } else {
      reset();
    }
  }, [defaultValue]);

  return (
    <Modal className="max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full py-10">
        <div className="items-center">
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            {defaultValue ? "" : " "}
            <FormattedMessage
              id={
                defaultValue
                  ? TRANSLATION.EDIT_CATEGORY.id
                  : TRANSLATION.ADD_NEW_CATEGORY.id
              }
              defaultMessage={
                defaultValue
                  ? TRANSLATION.EDIT_CATEGORY.defaultMessage
                  : TRANSLATION.ADD_NEW_CATEGORY.defaultMessage
              }
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.NEW_CATEGORY_DESCRIPTION.id}
              defaultMessage={
                TRANSLATION.NEW_CATEGORY_DESCRIPTION.defaultMessage
              }
            />
          </Typo.heading5>
        </div>
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmitCategory)}
        >
          <div className="w-full mt-5">
            <InputField
              name="name"
              label={handelInputMap(intl, "title")}
              placeholder={handelInputMap(intl, "article_title_placeholder")}
              type="text"
              register={register}
              errors={errors?.name}
              required={true}
            />
          </div>
          <div className="flex flex-row gap-x-2 pt-8">
            <Button
              onClick={() => onClose(false)}
              size="medium"
              theme="error"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold">
                <FormattedMessage
                  id={TRANSLATION.CANCEL.id}
                  defaultMessage={TRANSLATION.CANCEL.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button
              size="medium"
              type="submit"
              theme="primary"
              className="w-full"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.ADD.id}
                  defaultMessage={TRANSLATION.ADD.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ArticleCategoryModal;
