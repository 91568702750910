type Props = {
  className?: string;
};
const MaintananceUserIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 114.07"
    >
      <path
        className="cls-1"
        fill="currentColor"
        d="M0,114.07H32.06v-39.54c0-.26,.03-.52,.09-.77C5.45,77.57,6.81,89.89,0,114.07H0ZM40.66,34.12v4.98l1.7,2.33c.26,.35,.4,.76,.42,1.17h0c.61,8.65,2.92,14.03,6.38,17.41,3.37,3.29,7.97,4.8,13.21,5.64,5.66-.93,10.1-3.3,13.26-7.02,3.29-3.87,5.29-9.26,5.91-16.06,.03-.35,.14-.67,.31-.95h0l1.51-2.5,.03-5.18,4.46,.02-.03,5.65c.03,.44-.07,.9-.32,1.31l-1.57,2.6c-.77,7.49-3.1,13.53-6.91,18.01-3.94,4.63-9.38,7.53-16.26,8.6-.24,.04-.48,.05-.73,.01-6.21-.95-11.74-2.77-15.99-6.92-4.14-4.04-6.91-10.17-7.68-19.67l-1.63-2.24c-.35-.4-.56-.92-.56-1.49v-5.69h4.48ZM61.16,0c2.14,0,4.22,.22,6.23,.64l-.38,17.93,6.53-15.95c9.2,4.09,16,12.59,17.71,22.82h3.53v4.19h-3.12v.06H30.65v-.06h-2.55v-4.19h2.96c1.77-10.56,8.96-19.28,18.62-23.2l5.71,16.06L55.99,.44c1.68-.29,3.41-.44,5.17-.44h0Zm29.66,114.07h32.06c-6.81-24.18-5.45-36.5-32.16-40.3,.06,.25,.09,.5,.09,.77v39.53h.01Zm-29.38-40.77l-23.41-.14c.2,.41,.32,.88,.32,1.37v39.54h46.18v-39.54c0-.49,.11-.96,.32-1.37l-23.41,.14Zm3.4,33.24h-6.81v-6.81h6.81v6.81Zm0-16.84h-6.81v-6.81h6.81v6.81Z"
      />
    </svg>
  );
};

export default MaintananceUserIcon;
