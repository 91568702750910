type Props = {
  className?: string;
};
const Mondy3Icon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M14.167 17.0834H5.83366C3.33366 17.0834 1.66699 15.8334 1.66699 12.9167V7.08341C1.66699 4.16675 3.33366 2.91675 5.83366 2.91675H14.167C16.667 2.91675 18.3337 4.16675 18.3337 7.08341V12.9167C18.3337 15.8334 16.667 17.0834 14.167 17.0834Z"
        fill="#E0E0E0"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        fill="#BDBDBD"
      />
      <path
        d="M15.833 6.45825H13.333C12.9913 6.45825 12.708 6.17492 12.708 5.83325C12.708 5.49159 12.9913 5.20825 13.333 5.20825H15.833C16.1747 5.20825 16.458 5.49159 16.458 5.83325C16.458 6.17492 16.1747 6.45825 15.833 6.45825Z"
        fill="#BDBDBD"
      />
      <path
        d="M6.66699 14.7917H4.16699C3.82533 14.7917 3.54199 14.5084 3.54199 14.1667C3.54199 13.8251 3.82533 13.5417 4.16699 13.5417H6.66699C7.00866 13.5417 7.29199 13.8251 7.29199 14.1667C7.29199 14.5084 7.00866 14.7917 6.66699 14.7917Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default Mondy3Icon;
