import { SubCategory } from "./SubCategory";
import { User } from "./User";
import { Location } from "./Location";
import { Agency } from "./Agency";
export interface Listing {
  // info details
  id?: number;
  listingState: string;
  userId: number;
  agencyId?: number;
  locationId: number;
  subCategoryId: number;
  slug: string;
  title: string;
  description: string;
  price: number;
  currency: string;
  area: number;
  unit: string;
  bedrooms?: number;
  bathrooms?: number;
  purpose: string;
  documentType?: string;
  featured: boolean;

  //   features
  noma?: string;
  floor?: string;
  builtYear: string;
  floors?: number;
  furnished?: boolean;
  parkingSpace?: number;
  electricityBackup?: string;
  otherFeature?: string;

  //   possibilities
  kitchens?: number;
  cabinet?: boolean;
  water?: boolean;
  electricity?: boolean;
  warehouse?: number;
  centralHeating?: boolean;
  sewerage?: boolean;
  mosque?: boolean;
  garden?: boolean;
  facilitiesForDisabled?: boolean;
  otherFacilities?: string;

  // nearby locations
  nearbySchools?: string;
  nearbyMalls?: string;
  nearbyHospitals?: string;
  nearbyRestaurants?: string;
  nearbyPublicTransports?: string;
  otherNearby?: string;

  // points
  lift?: boolean;
  securityComera?: boolean;
  internetCabel?: boolean;
  boundryWall?: boolean;
  corner?: boolean;
  securityStaff?: boolean;
  maintenanceStaff?: boolean;
  airConditioning?: boolean;
  // recreation
  swimmingPool?: boolean;
  jacuzzi?: boolean;
  sauna?: boolean;
  gym?: boolean;
  otherRecreation?: string;

  // contact
  phone?: string;
  email?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface SavedListing {
  id: number;
  listingId: number;
  userId: number;
}

export interface ListingProp {
  page?: number;
  limit?: number;
  order?: string;
  purpose?: string | number;
  provinceId?: number;
  address?: string;
  subCategoryId?: number;
  minArea?: number;
  maxArea?: number;
  unit?: string;
  minPrice?: number;
  maxPrice?: number;
  currency?: string;
  bathrooms?: number;
  bedrooms?: number;
  documentType?: string;
  listingState?: string;
  featured?: boolean;
  title?: string;
  userId?: number;
  agencyId?: number;
  code?: number;
  showFavorites?: boolean;
}


export interface ListingUpdateProp {
  listingId: number,
  title?: string,
  content?: string,
  listingState?: string,
  feature?: boolean
}




export const defaultFeatures = {
  noma: { id: "", title: "" },
  floor: { id: "", title: "" },
  builtYear: { id: "", title: "" },
  floors: undefined,
  parkingSpace: undefined,
  electricityBackup: { id: "", title: "" },
  otherFeature: "",
  kitchens: undefined,
  warehouse: undefined,
  cabinet: false,
  water: false,
  electricity: false,
  centralHeating: false,
  sewerage: false,
  mosque: false,
  facilitiesForDisabled: false,
  nearbySchools: "",
  nearbyMalls: "",
  nearbyHospitals: "",
  nearbyRestaurants: "",
  nearbyPublicTransports: "",
  otherNearby: "",
  otherFacilities: "",
  furnished: false,
  lift: false,
  securityComera: false,
  internetCabel: false,
  boundryWall: false,
  corner: false,
  securityStaff: false,
  maintenanceStaff: false,
  airConditioning: false,
  swimmingPool: false,
  jacuzzi: false,
  sauna: false,
  gym: false,
  garden: false,
  otherRecreation: "",
};

export interface ListingObject {
  id: number;
  listingState: string;
  userId: number;
  agencyId: number;
  locationId: number;
  subCategoryId: number;
  videoCount?: number;
  imageCount?: number;
  thumbnail?: string;
  slug: string;
  title: string;
  description: string;
  price: number;
  currency: string;
  area: number;
  unit: string;
  bedrooms: number;
  bathrooms: number;
  purpose: string;
  documentType: string;
  noma: string;
  floor: string;
  builtYear: string;
  floors: number;
  furnished: boolean;
  parkingSpace: number;
  electricityBackup: string;
  otherFeature: string;
  kitchens: number;
  cabinet: boolean;
  water: boolean;
  electricity: boolean;
  warehouse: boolean;
  centralHeating: boolean;
  sewerage: boolean;
  mosque: boolean;
  garden: boolean;
  facilitiesForDisabled: boolean;
  otherFacilities: string;
  nearbySchools: string;
  nearbyMalls: string;
  nearbyHospitals: string;
  nearbyRestaurants: string;
  nearbyPublicTransports: string;
  otherNearby: string;
  lift: boolean;
  securityComera: boolean;
  internetCabel: boolean;
  boundryWall: boolean;
  corner: boolean;
  securityStaff: boolean;
  maintenanceStaff: boolean;
  airConditioning: boolean;
  swimmingPool: boolean;
  jacuzzi: boolean;
  sauna: boolean;
  gym: boolean;
  otherRecreation: string;
  phone: string;
  whatsapp: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  location: Location;
  user: User;
  agency: Agency;
  subCategory: SubCategory;
  savedListings: boolean;
  listingFiles: ListingFile[];
  listings?: Listing[]
}

export interface ListingFile {
  id: number;
  url: string;
  isImage: boolean;
  listingId: number;
  alt: string;
}

export interface ListingResponse {
  count: number;
  rows: ListingObject[];
  states?: { state: string, count: number }[]
}


export interface ListingCountResponse {
  count: number;
  data: {
    mortgage: number,
    rent: number,
    sell: number
  };
}