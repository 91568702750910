import clsx from "clsx";
import { FC } from "react";
import { Typo } from "./typo";
type Props = {
  defaultValue?: string;
  errors?: any;
  placeholder?: string;
  register: (name: any, registerOptions: any) => any;
  name: string;
  registerOptions?: any;
  label?: string;
  hintText?: string;
  maxLength?: string;
  disabled?: boolean;
  className?: string;
  verificationtext?: string;
  labelStyle?: string;
  rows?: number;
  required?: boolean;
  [x: string]: any;
};

const TextField: FC<Props> = ({
  defaultValue,
  errors,
  placeholder,
  register,
  name,
  maxLength = "none",
  hintText = "",
  registerOptions = {},
  disabled = false,
  className = undefined,
  label = "",
  labelStyle = "",
  rows = 10,
  required = false,
  ...rest
}) => {
  return (
    <div className={clsx("w-full relative space-y-1.5", className)}>
      {label && (
        <label htmlFor={name || ""} className="text-start">
          <Typo.heading4
            fontWeight="font-medium"
            className={` text-gray-800 ${labelStyle}`}
          >
            {label}
            {required ? " * " : ""}
          </Typo.heading4>
        </label>
      )}
      <div className="relative">
        <textarea
          id={name}
          {...register(name, registerOptions)}
          placeholder={placeholder}
          rows={rows}
          defaultValue={defaultValue}
          disabled={disabled}
          maxLength={maxLength}
          className={clsx(
            " px-[14px] py-[10px] w-full rounded-lg border bg-white placeholder:text-gray-400 shadow-xs font-inter text-sm leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 disabled:bg-gray-50 disabled:text-gray-500  ",
            errors?.message == null
              ? " border-gray-300  focus:border-gray-300 focus:ring-gray-100 focus:ring-4"
              : " border-error-300 focus:border-error-300 focus:ring-error-100"
          )}
          {...rest}
        />
      </div>
      {errors?.message || hintText ? (
        <Typo.heading4
          fontWeight="font-normal"
          className={`w-full text-start absolute pb-4 ${
            errors?.message ? "text-error-500" : "text-gray-500"
          }`}
        >
          {errors?.message ? errors?.message : hintText}
        </Typo.heading4>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextField;
