import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { User, UserProp, UserResponse } from "../../model/User";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { buildEndpoint } from "../../util/buildEndpoint";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useLocalStorage } from "../useLocalStorage";
export const useHandelUser = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useLocalStorage("token", null);
  const schema = object().shape({
    email: string().required().email(),
    password: string().required(""),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  function ToGetMe() {
    const result = useQuery({
      queryKey: ["user"],
      queryFn: async () => await getMe(),
      retry: 0,
      refetchOnWindowFocus: false,
    });
    return result;
  }

  const getMe = async (): Promise<User> => {
    const response = await Fetch({
      endpoint: "/user",
      requiredAuth: true,
    });
    return response;
  };

  function ToGetUser(id?: number) {
    const result = useQuery({
      queryKey: ["userProfile"],
      queryFn: async () => await getUser(Number(id)),
      enabled: !!id,
      retry: 0,
      refetchOnWindowFocus: false,
    });
    return result;
  }

  const getUser = async (id: number): Promise<User> => {
    const response = await Fetch({
      endpoint: `/user/${id}`,
      requiredAuth: true,
    });
    return response?.data;
  };

  function ToGetUsers(props: UserProp) {
    const result = useQuery({
      queryKey: ["users", props],
      queryFn: async () => await getUsers(props),
      refetchOnWindowFocus: false,
    });
    return result;
  }

  const getUsers = async (userProp: UserProp): Promise<UserResponse> => {
    const response = await Fetch({
      endpoint: buildEndpoint("users", userProp),
      requiredAuth: true,
    });
    return response?.data;
  };

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const response = await Fetch({
        method: "DELETE",
        endpoint: `/delete-profile`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      setLoading(false);
      setToken(null);
      localStorage.setItem("redirect-path", "/dashboard");
      navigate(ROUTES.HOME_ROUTE);
    },
    onError: (error) => {
      setLoading(false);
      // toast.error(messages["errorDeletionFromFavorites"]);
    },
  });

  const onDeleteProfile = () => {
    setLoading(true);
    deleteMutation.mutate();
  };

  const onSubmit = async (data: any) => {
    // mutation.mutate(data);
  };

  return {
    ToGetMe,
    ToGetUser,
    ToGetUsers,
    errors,
    register,
    handleSubmit,
    reset,
    onSubmit,
    loading,
    setLoading,
    onDeleteProfile,
  };
};
