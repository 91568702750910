import EmptyImage from "../icons/EmptyImage";
import React from "react";
type ImagePorps = {
  alt?: string;
  src?: string | null | undefined;
  className?: string;
};
const Image: React.FC<ImagePorps> = (props) => {
  const { src, alt, className } = props;
  return (
    <div className="h-full w-full">
      {src && src !== "" ? (
        <img
          src={src || ""}
          alt={alt || ""}
          className={` object-cover ${className}`}
        />
      ) : (
        <EmptyImage className={`bg-gray-100 ${className}`} />
      )}
    </div>
  );
};

export default Image;
