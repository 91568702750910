import { ChangeEvent, useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../../model/DropdownOption";
import { useHandelListing } from "../../../../hooks/listing/useHandelListing";
import ListingTab from "../ListingTab";
import Loader from "../../../../components/shared/Loader";
import NoListingFound from "../noListingFound";
import Pagination from "../../../../components/shared/Pagination";
import clsx from "clsx";
import CategoryDropdown from "../../../../components/CategoryDropdown";
import Dropdown from "../../../../components/shared/Dropdown";
import { useHandelCategory } from "../../../../hooks/category/useHandelCategory";
import { useHandelProvince } from "../../../../hooks/location/useHandelProvince";
import InputField from "../../../../components/shared/InputField";
import { useAuth } from "../../../../context/auth";
import { getGolas } from "../../../../static/constants";
import ListingCard from "../../../../components/shared/Card/ListingCard";
import { handelInputMap } from "../../../../util/handelInputMap";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmModal from "../../../../modal/ConfirmModal";
import DangerIcon from "../../../../components/icons/DangerIcon";
import { TRANSLATION } from "../../../../static/translation";
import { ListingState } from "../../../../static/enum";
import Button from "../../../../components/shared/button";
import { Typo } from "../../../../components/shared/typo";
import { useLang } from "../../../../context/language";
import {
  getLocaleProvinces,
  getLocaleSubCategories,
} from "../../../../util/appUtil";
const MyListings = () => {
  // Define the columns and data for the table
  const auth = useAuth();
  const intl = useIntl();
  const lang = useLang();
  const [tab, setTab] = useState<DropdownOptionModel>();
  const [tabs, setTabs] = useState<DropdownOptionModel[]>();
  const [selectedListingId, setSelectedListingId] = useState(0);
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(1);
  const { ToGetMyListings, onDeleteListing, onUpdateState, onLike, onDisLike } =
    useHandelListing({
      intl,
    });
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetProvinces } = useHandelProvince();
  const [porpuse, setPorpuse] = useState<DropdownOptionModel>();
  const provinceResponse = ToGetProvinces(1, -1);

  const user = auth?.user;
  const LIMIT_ITEM = 2;
  const goals = getGolas(intl);

  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const categoryResponse = ToGetCategories();
  const categories = getLocaleSubCategories(
    lang.language,
    categoryResponse?.data
  );

  const listings = ToGetMyListings({
    page: page,
    limit: LIMIT_ITEM,
    userId: Number(user?.id),
    listingState: `${tab?.id}`,
    subCategoryId: Number(selectedCategory?.id) || undefined,
    provinceId: Number(selectedProvince?.id) || undefined,
    address: address,
    title: title,
    purpose: porpuse?.id ? `${porpuse?.id}` : undefined,
  });

  useEffect(() => {
    const states = listings?.data?.states?.map((state: any) => ({
      id: state?.state,
      title: state?.state,
      supportingTitle: state?.count || 0,
    }));
    setTabs(states);
    if (!tab && states) {
      setTab(states[0]);
    }
  }, [listings?.data]);

  const onTabChange = (tab: DropdownOptionModel) => {
    setPage(1);
    setTab(tab);
  };

  const onDelete = (listingId: number) => {
    setSelectedListingId(listingId);
  };

  const onConfirm = async (state: boolean) => {
    if (state && selectedListingId > 0) {
      onDeleteListing(selectedListingId);
    }
    setSelectedListingId(0);
  };

  const onDisable = async (listingId: number) => {
    await onUpdateState({
      listingId: listingId,
      listingState: ListingState.INACTIVE,
    });
  };

  const onActive = async (listingId: number) => {
    await onUpdateState({
      listingId: listingId,
      listingState: ListingState.ACTIVE,
    });
  };

  const onResetFilter = () => {
    console.log("");
    setPorpuse(undefined);
    setSelectedCategory(undefined);
    setSelectedProvince(undefined);
    setAddress("");
    setTitle("");
    setPage(1);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5">
      <div className=" flex flex-col-reverse xl:grid xl:grid-cols-4 gap-5 py-5">
        <div className="w-full col-span-3 bg-white rounded-lg">
          <div className=" py-7 px-5 shadow-shadow3">
            <ListingTab
              tabs={tabs}
              currentTab={tab}
              onTabChange={onTabChange}
            />
          </div>
          <div>
            {listings?.isLoading && <Loader className="h-screen" />}
            {!listings?.isLoading && listings?.data?.count === 0 && (
              <NoListingFound />
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 px-4 pt-6 pb-10">
              {!listings?.isLoading &&
              listings?.data?.count &&
              listings?.data?.count > 0
                ? listings?.data?.rows?.map((listing: any) => {
                    return (
                      <ListingCard
                        key={listing.id}
                        listing={listing}
                        page="dashboard"
                        background="white"
                        onDelete={onDelete}
                        onDisable={onDisable}
                        onActive={onActive}
                        direction={lang.direction}
                        intl={intl}
                        user={user}
                        onLike={onLike}
                        onDisLike={onDisLike}
                        locale={lang.language}
                      />
                    );
                  })
                : ""}
            </div>

            {listings?.data?.count && listings?.data?.count > 0 ? (
              <div className="px-10 pb-5">
                <Pagination
                  onPageChange={setPage}
                  total={listings.data.count}
                  perPage={LIMIT_ITEM}
                  currentPage={page}
                  type="centered-page-number"
                  direction={lang.direction}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-full">
          <div
            className={clsx(
              "bg-white shadow-shadow2 px-4 pt-6 pb-8 rounded-lg grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-1 items-end gap-5"
            )}
          >
            <Dropdown
              options={goals || []}
              defaultValue={porpuse}
              onChange={setPorpuse}
              placeholder={handelInputMap(intl, "goal")}
              label={handelInputMap(intl, "goal")}
            />
            <CategoryDropdown
              options={categories}
              defaultValue={selectedCategory}
              onChange={setSelectedCategory}
              placeholder={handelInputMap(intl, "listing_type")}
              label={handelInputMap(intl, "listing_type")}
            />
            <Dropdown
              options={provinces || []}
              defaultValue={selectedProvince}
              onChange={setSelectedProvince}
              placeholder={handelInputMap(intl, "province")}
              label={handelInputMap(intl, "province")}
            />
            <InputField
              label={handelInputMap(intl, "address")}
              placeholder={handelInputMap(intl, "listing_address_placeholder")}
              register={() => ({})}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setAddress(event.target.value)
              }
              name="address"
            />
            <InputField
              label={handelInputMap(intl, "title")}
              placeholder={handelInputMap(intl, "listing_title")}
              register={() => ({})}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setTitle(event.target.value)
              }
              name="title"
            />

            <Button
              size="medium"
              theme="primary"
              className="shadow-shadow w-full"
              onClick={onResetFilter}
            >
              <Typo.heading4 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.DELETE_FILTER.id}
                  defaultMessage={TRANSLATION.DELETE_FILTER.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        open={selectedListingId > 0}
        onClose={onConfirm}
        icon={<DangerIcon className="h-6 text-error-600" />}
        title={TRANSLATION.DELETE_LISTING}
        description={TRANSLATION.DELETE_LISTING_MESSAGE}
      />
    </div>
  );
};

export default MyListings;
