type Props = {
  className?: string;
};
const BulkHomeIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M8.39076 2.35005L2.61575 6.97504C1.96575 7.4917 1.54908 8.58337 1.69075 9.40004L2.79908 16.0334C2.99908 17.2167 4.13241 18.175 5.33241 18.175H14.6658C15.8574 18.175 16.9991 17.2084 17.1991 16.0334L18.3074 9.40004C18.4408 8.58337 18.0241 7.4917 17.3824 6.97504L11.6074 2.35836C10.7157 1.6417 9.27409 1.64171 8.39076 2.35005Z"
        fill="#E0E0E0"
      />
      <path
        d="M10.0003 12.9167C11.1509 12.9167 12.0837 11.9839 12.0837 10.8333C12.0837 9.68274 11.1509 8.75 10.0003 8.75C8.84973 8.75 7.91699 9.68274 7.91699 10.8333C7.91699 11.9839 8.84973 12.9167 10.0003 12.9167Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default BulkHomeIcon;
