import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useHandelArticle } from "../../../hooks/article/useHandelArticle";
import Dropdown from "../../../components/shared/Dropdown";
import TextField from "../../../components/shared/TextField";
import { useHandelArticleCategory } from "../../../hooks/article/useHandelArticleCategory";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import DraftEditor from "../../../components/shared/Editor";
import { useAuth } from "../../../context/auth";
import SingleImageUploader from "../../../components/shared/SingleImageUploader";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { handelInputMap } from "../../../util/handelInputMap";
import { TRANSLATION } from "../../../static/translation";
const EditArticle = () => {
  // Define the columns and data for the table
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const { id } = useParams();
  const [content, setContent] = useState<any>();
  const [image, setImage] = useState("");
  const auth = useAuth();
  const intl = useIntl();
  const { register, errors, handleSubmit, onUpdate, ToGetArticle, setValue } =
    useHandelArticle();

  const { ToGetArticleCategory } = useHandelArticleCategory();
  const categories = ToGetArticleCategory();
  const article = ToGetArticle(Number(id));

  const onUpdateArticle = async (data: any) => {
    await onUpdate(
      {
        userId: auth?.user?.id,
        articleCategoryId: Number(selectedCategory?.id),
        image: image,
        content: content,
        ...data,
      },
      Number(article.data?.id)
    );
  };

  useEffect(() => {
    if (article.data) {
      setValue("title", article?.data.title);
      setValue("description", article?.data?.description);
      setImage(article?.data?.image || "");
      setContent(article?.data?.content || "");
      const findedArticle = categories?.data?.find((item) => item.id);
      if (findedArticle) {
        setSelectedCategory({
          id: findedArticle.id,
          title: findedArticle.name,
        });
      }
    }
  }, [article.data]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onUpdateArticle)}
        >
          <div className="space-y-8">
            <div className="w-full rounded-lg bg-white py-10 px-14 space-y-8">
              <div className="w-full flex gap-x-5">
                <InputField
                  name="title"
                  label={handelInputMap(intl, "title")}
                  placeholder={handelInputMap(intl, "article_title_placeholder")}
                  register={register}
                  errors={errors?.title}
                  required={true}
                />
              </div>
              <SingleImageUploader
                title={handelInputMap(intl, "article_image")}
                description={handelInputMap(intl, "article_image_placeholder")}
                image={image}
                onChange={setImage}
              />
              <div className="w-full flex gap-x-5">
                <TextField
                  name="description"
                  label={handelInputMap(intl, "description")}
                  placeholder={handelInputMap(intl, "article_description_placeholder")}
                  register={register}
                  errors={errors?.description}
                  required={true}
                />
              </div>
              <div className="w-full flex gap-x-5">
                <Dropdown
                  label={handelInputMap(intl, "article_category")}
                  placeholder={handelInputMap(
                    intl,
                    "article_category_placeholder"
                  )}
                  required={true}
                  defaultValue={selectedCategory}
                  onChange={setSelectedCategory}
                  options={
                    categories?.data?.map((category) => ({
                      id: category.id,
                      title: category.name,
                    })) || []
                  }
                />
              </div>
              <div className="w-full rounded-lg">
                <DraftEditor value={content} setValue={setContent} />
              </div>

              <div className="pt-6">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    <FormattedMessage
                      id={TRANSLATION.SAVE_CHANGES.id}
                      defaultMessage={TRANSLATION.SAVE_CHANGES.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditArticle;
