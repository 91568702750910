type Props = {
  className?: string;
};
const ElementEqualIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15.75H15C14.8661 15.75 14.75 15.6339 14.75 15.5C14.75 15.3661 14.8661 15.25 15 15.25H21C21.1339 15.25 21.25 15.3661 21.25 15.5C21.25 15.6339 21.1339 15.75 21 15.75Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M21 19.75H15C14.8661 19.75 14.75 19.6339 14.75 19.5C14.75 19.3661 14.8661 19.25 15 19.25H21C21.1339 19.25 21.25 19.3661 21.25 19.5C21.25 19.6339 21.1339 19.75 21 19.75Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M19.7731 9.99001V9.99H19.77H15.73C14.9723 9.99 14.5694 9.85211 14.3501 9.65808C14.1466 9.47796 14 9.15669 14 8.51V3.98C14 3.33935 14.1461 3.01693 14.3509 2.83503C14.5707 2.63973 14.974 2.5 15.73 2.5H19.77C20.526 2.5 20.9293 2.63973 21.1491 2.83503C21.3539 3.01693 21.5 3.33935 21.5 3.98V8.52C21.5 9.16102 21.3537 9.48268 21.1499 9.66308C20.9312 9.85664 20.5288 9.99476 19.7731 9.99001Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M8.27315 9.99001V9.99H8.27H4.23C3.47225 9.99 3.06936 9.85211 2.85012 9.65808C2.64661 9.47796 2.5 9.15669 2.5 8.51V3.98C2.5 3.33935 2.64613 3.01693 2.85085 2.83503C3.07065 2.63973 3.47403 2.5 4.23 2.5H8.27C9.02597 2.5 9.42935 2.63973 9.64915 2.83503C9.85387 3.01693 10 3.33935 10 3.98V8.52C10 9.16102 9.85371 9.48268 9.64988 9.66308C9.43116 9.85664 9.02884 9.99476 8.27315 9.99001Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M10 15.73V19.77C10 20.5149 9.84713 20.9083 9.6277 21.1277C9.40827 21.3471 9.01492 21.5 8.27 21.5H4.23C3.48508 21.5 3.09173 21.3471 2.8723 21.1277C2.65287 20.9083 2.5 20.5149 2.5 19.77V15.73C2.5 14.9851 2.65287 14.5917 2.8723 14.3723C3.09173 14.1529 3.48508 14 4.23 14H8.27C9.01492 14 9.40827 14.1529 9.6277 14.3723C9.84713 14.5917 10 14.9851 10 15.73Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default ElementEqualIcon;
