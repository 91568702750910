import ReactQuill from "react-quill";
import "./style.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css"; // added this
import QuillToolbar, { formats, modules } from "./QuillToolbar";

// import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/froala_editor.pkgd.min.css";
// import "froala-editor/js/plugins/lists.min.js";

// import FroalaEditor from "react-froala-wysiwyg";
type Props = {
  icon?: any;
  value?: string;
  setValue: (value: string) => void;
};

const DraftEditor: React.FC<Props> = (props) => {
  const { value, setValue } = props;
  // const modules = {
  //   toolbar: [
  //     [{ header: [1, 2, 3, 4, 5, 6, true] }],
  //     ["bold", "italic", "underline", "strike", "blockquote"],
  //     ["link", "image"],
  //     ["clean"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //     ],
  //     [{ direction: "rtl" }],
  //     [{ align: [] }],
  //   ],
  // };

  // const formats = [
  //   "header",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "strike",
  //   "blockquote",
  //   "list",
  //   "bullet",
  //   "indent",
  //   "link",
  //   "image",
  // ];
  return (
    <div className="text-editor relative h-full ">
      <QuillToolbar />
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        className="h-full"
        onChange={setValue}
      />
       {" "}
      {/* <FroalaEditor
        tag="textarea"
        model={model}
        plugins={["lists"]}
        config={{
          direction: "rtl",
          toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "formatUL",
            "formatOL",
          ],
        }}
      /> */}
    </div>
  );
};

export default DraftEditor;
