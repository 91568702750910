import { ChangeEvent, useState } from "react";
import { useHandelListing } from "../../../hooks/listing/useHandelListing";
import Pagination from "../../../components/shared/Pagination";
import Loader from "../../../components/shared/Loader";
import NoListingFound from "./noListingFound";
import Table from "../../../components/shared/Table";
import Button from "../../../components/shared/button";
import Edit2Icon from "../../../components/icons/Edit2Icon";
import React from "react";
import { Typo } from "../../../components/shared/typo";
import { mapPorpuseBadge } from "../../../util/mapTranslation";
import { FormattedMessage, useIntl } from "react-intl";
import Price from "../../../components/shared/Price";
import Area from "../../../components/shared/Area";
import EyeIcon from "../../../components/icons/EyeIcon";
import clsx from "clsx";
import Dropdown from "../../../components/shared/Dropdown";
import {
  DEBOUNCE,
  getListingStates,
  getGolas,
} from "../../../static/constants";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useHandelCategory } from "../../../hooks/category/useHandelCategory";
import CategoryDropdown from "../../../components/CategoryDropdown";
import InputField from "../../../components/shared/InputField";
import Home2Icon from "../../../components/icons/Home2Icon";
import debounce from "lodash.debounce";
import { ROUTES } from "../../../constants/routes";
import { handelListingState } from "../../../util/handelListingState";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
import { Listing } from "../../../model/Listing";
import UpdateListingModal from "../../../modal/UpdateListingModal";
import { useLang } from "../../../context/language";
import {
  getLocaleProvinces,
  getLocaleSubCategories,
} from "../../../util/appUtil";
import {
  handelProvinceLocale,
  handelSubCategoryLocale,
} from "../../../util/handelLocaleMap";
const DashboardListings = () => {
  const LIMIT = 10;
  const intl = useIntl();
  const lang = useLang();
  const goals = getGolas(intl);
  const { ToGetDashboardListings } = useHandelListing({ intl });
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [code, setCode] = useState(0);
  const [porpuse, setPorpuse] = useState<DropdownOptionModel>();
  const [selectedListing, setSelectedListing] = useState<Listing>();
  const [selectedCategory, setSelectedCategory] =
    useState<DropdownOptionModel>();
  const [selectedProvince, setSelectedProvince] =
    useState<DropdownOptionModel>();
  const [selectedState, setSelectedState] = useState<DropdownOptionModel>();
  const { ToGetCategories } = useHandelCategory({ intl: intl });
  const { ToGetProvinces } = useHandelProvince();
  const states = handelListingState(intl);

  const listings = ToGetDashboardListings({
    page: page,
    limit: LIMIT,
    listingState: selectedState?.id ? `${selectedState.id}` : "",
    subCategoryId: Number(selectedCategory?.id) || undefined,
    provinceId: Number(selectedProvince?.id) || undefined,
    address: address,
    title: title,
    code: code && code !== 0 ? code : undefined,
    purpose: porpuse?.id || undefined,
  });

  const provinceResponse = ToGetProvinces(1, -1);
  const provinces = getLocaleProvinces(
    lang.language,
    provinceResponse?.data?.rows
  );

  const categoryResponse = ToGetCategories();
  const categories = getLocaleSubCategories(
    lang.language,
    categoryResponse?.data
  );

  const handleEdit = (listing: Listing) => {
    setSelectedListing(listing);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: handelInputMap(intl, "listing_code"),
        accessor: "id",
      },
      {
        Header: handelInputMap(intl, "title"),
        accessor: "title",
        Cell: ({ cell }: any) => (
          <div className="flex justify-center">
            <Typo.body4 className="truncate text-ellipsis max-w-[200px]">
              {cell.row.original?.title}
            </Typo.body4>
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "listing_type"),
        accessor: handelSubCategoryLocale(lang.language),
      },
      {
        Header: handelInputMap(intl, "listing_state"),
        accessor: "listingState",
        Cell: ({ cell }: any) => (
          <div className="flex justify-center">
            <Typo.body4 className="truncate text-ellipsis max-w-[200px]">
              {states[cell.row.original?.listingState]}
            </Typo.body4>
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "province"),
        accessor: handelProvinceLocale(lang.language),
      },
      {
        Header: handelInputMap(intl, "area"),
        accessor: "area",
        Cell: ({ cell }: any) => (
          <div className="flex justify-center">
            <Area
              area={cell.row.original?.area || ""}
              unit={cell.row.original?.unit || ""}
              intl={intl}
            />
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "goal"),
        accessor: "purpose",
        Cell: ({ cell }: any) => (
          <div>{mapPorpuseBadge(intl, cell.row.values.purpose)}</div>
        ),
      },

      {
        Header: handelInputMap(intl, "price"),
        accessor: "price",
        Cell: ({ cell }: any) => (
          <div className="flex justify-center">
            <Price
              price={cell.row.original?.price || ""}
              currency={cell.row.original?.currency || ""}
              intl={intl}
            />
          </div>
        ),
      },
      {
        Header: handelInputMap(intl, "actions"),
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              <Button
                theme="stroke-primary"
                size="normal"
                // to={`${ROUTES.EDIT_LISTING_ROUTE}/${cell?.row?.original?.slug}`}
                icon={<Edit2Icon className="h-4 w-4" />}
                onClick={() => handleEdit(cell.row.original)}
              ></Button>
              <Button
                theme="secandory"
                size="normal"
                to={`${ROUTES.DASHBOARD_LISTINGS_ROUTE}/${cell?.row?.original?.slug}`}
                icon={<EyeIcon className="h-4 w-4" />}
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const debouncedSetTitle = debounce(setTitle, DEBOUNCE);
  const debouncedSetCde = debounce(setCode, DEBOUNCE);
  const debouncedSetAddress = debounce(setAddress, DEBOUNCE);
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5">
      <div className="py-5">
        <div className="w-full col-span-3 bg-white rounded-lg pb-5">
          <div>
            <div className={clsx("bg-white px-4 pb-8 rounded-lg")}>
              <div className="whitespace-nowrap flex items-center gap-x-3 pt-5">
                <Home2Icon className="h-5" />
                <Typo.body4 fontWeight="font-bold">
                  <FormattedMessage
                    id={TRANSLATION.ALL_LISTING_TITLE.id}
                    defaultMessage={
                      TRANSLATION.ALL_LISTING_TITLE.defaultMessage
                    }
                  />
                  <span className="text-gray-400">{` (${
                    listings?.data?.count || 0
                  }) `}</span>
                </Typo.body4>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 pt-6 items-end gap-4">
                <Dropdown
                  options={goals || []}
                  defaultValue={porpuse}
                  onChange={setPorpuse}
                  placeholder={handelInputMap(intl, "goal")}
                  label={handelInputMap(intl, "goal")}
                />
                <CategoryDropdown
                  options={categories}
                  defaultValue={selectedCategory}
                  onChange={setSelectedCategory}
                  placeholder={handelInputMap(intl, "listing_type")}
                  label={handelInputMap(intl, "listing_type")}
                />
                <Dropdown
                  options={provinces || []}
                  defaultValue={selectedProvince}
                  onChange={setSelectedProvince}
                  placeholder={handelInputMap(intl, "province")}
                  label={handelInputMap(intl, "province")}
                />
                <Dropdown
                  options={getListingStates(intl) || []}
                  defaultValue={selectedState}
                  onChange={setSelectedState}
                  placeholder={handelInputMap(intl, "listing_state")}
                  label={handelInputMap(intl, "listing_state")}
                />
                <InputField
                  label={handelInputMap(intl, "address")}
                  placeholder={handelInputMap(intl, "address_placeholder")}
                  register={() => ({})}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    debouncedSetAddress(event.target.value)
                  }
                  name="address"
                />
                <InputField
                  label={handelInputMap(intl, "listing_code")}
                  placeholder={handelInputMap(intl, "listing_code")}
                  register={() => ({})}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    debouncedSetCde(Number(event.target.value))
                  }
                  name="code"
                />
                <InputField
                  label={handelInputMap(intl, "title")}
                  placeholder={handelInputMap(intl, "title_placeholder")}
                  register={() => ({})}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    debouncedSetTitle(event.target.value)
                  }
                  name="title"
                />
              </div>
            </div>

            {listings?.isLoading && <Loader className="h-screen" />}
            {!listings?.isLoading && listings?.data?.count === 0 && (
              <NoListingFound />
            )}

            {!listings?.isLoading &&
            listings?.data?.count &&
            listings?.data?.count > 0 ? (
              <div className="px-4">
                <Table columns={columns} data={listings?.data?.rows || []} />
              </div>
            ) : (
              ""
            )}

            {listings?.data && listings?.data?.count > 0 ? (
              <div className="px-10 pb-5 pt-20">
                <Pagination
                  onPageChange={setPage}
                  total={listings.data.count}
                  perPage={10}
                  currentPage={page}
                  type="simple-card-footer"
                  direction={lang.direction}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {selectedListing && (
        <UpdateListingModal
          open={selectedListing ? true : false}
          onClose={(state) => setSelectedListing(undefined)}
          listing={selectedListing}
          intl={intl}
        />
      )}
    </div>
  );
};

export default DashboardListings;
