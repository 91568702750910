type Props = {
  className?: string;
};
const DisabilityIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.251 20.0489C16.0531 21.8495 14.0056 23.0364 11.6809 23.0364C7.98842 23.0364 4.99501 20.0429 4.99501 16.3505C4.99501 14.2228 5.98913 12.3275 7.53828 11.103L7.14029 15.1492L15.7452 17.723L17.251 20.0489ZM8.69199 6.0034L4.88673 7.54121L1.11719 12.1288L2.80637 13.5694L6.61134 9.47955L8.36799 9.29558L7.83847 14.6806L16.1602 17.1697L20.3385 23.624L22.8812 22.5219L18.6139 14.0757L12.8173 12.2085L13.8576 5.6828L11.6259 4.8477L8.69199 6.0034ZM13.1549 0.375732C14.3432 0.375732 15.3064 1.33867 15.3064 2.52696C15.3064 3.71525 14.3432 4.67848 13.1549 4.67848C11.9666 4.67848 11.0034 3.71525 11.0034 2.52696C11.0034 1.33867 11.9666 0.375732 13.1549 0.375732Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DisabilityIcon;
