import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import Dropdown from "../../../components/shared/Dropdown";
import { useAuth } from "../../../context/auth";
import { useHandelCountry } from "../../../hooks/location/useHandelCountry";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useHandelSettings } from "../../../hooks/profile/useHandelSettings";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import ProfileUploader from "../../../components/shared/ProfileUploader";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useIntl } from "react-intl";
import { handelInputMap } from "../../../util/handelInputMap";
import { getLocaleCountries, getLocaleProvinces } from "../../../util/appUtil";
import { useLang } from "../../../context/language";
const Settings = () => {
  const auth = useAuth();
  const intl = useIntl();
  const lang = useLang();
  const [province, setProvince] = useState<DropdownOptionModel>();
  const [country, setCountry] = useState<DropdownOptionModel>();
  const [countries, setCountries] = useState<DropdownOptionModel[]>([]);
  const [provinces, setProvinces] = useState<DropdownOptionModel[]>([]);
  const [image, setImage] = useState("");
  const { ToGetCounntries } = useHandelCountry();
  const { ToGetProvinces } = useHandelProvince();
  const { register, errors, handleSubmit, onUpdate, setValue, loading } =
    useHandelSettings(intl);

  const countryResponse = ToGetCounntries();
  const provinceResponse = ToGetProvinces(1, -1);
  const user = auth.user;

  const onLoging = async (data: any) => {
    await onUpdate(
      data,
      Number(auth?.user?.id),
      Number(province?.id),
      Number(country?.id),
      image,
      auth.user?.roleId
    );
  };
  useEffect(() => {
    if (user) {
      setValue("name", user?.name);
      setValue("email", user?.email);
      setValue("address", user?.address || "");
      setValue("phone", user?.phone || "");
      setValue("whatsapp", user?.whatsapp || "");
      setImage(user?.image || "");
    }
  }, [user]); // pass user as a dependency

  useEffect(() => {
    if (countryResponse?.data) {
      const countryData = getLocaleCountries(
        lang.language,
        countryResponse?.data
      );
      countryResponse?.data?.map((item: any) => ({
        id: item.id,
        title: item.country,
      }));
      setCountries(countryData);
      const countryItem = countryData?.find(
        (item) => item.id === user?.countryId
      );
      if (countryItem) setCountry(countryItem);
      else if (countryData) {
        setCountry(countryData[0]);
      }
    }
  }, [countryResponse?.data]);

  useEffect(() => {
    if (provinceResponse?.data) {
      const provinceData = getLocaleProvinces(
        lang.language,
        provinceResponse?.data?.rows
      );

      setProvinces(provinceData);
      const provinceItem = provinceData?.find(
        (item: any) => item.id === user?.provinceId
      );
      if (provinceItem) setProvince(provinceItem);
      else if (provinceData) {
        setProvince(provinceData[0]);
      }
    }
  }, [provinceResponse?.data]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5 py-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onLoging)}
        >
          <div className=" space-y-8">
            <div className="w-full flex flex-col gap-y-6 sm:flex-row gap-x-32 rounded-lg bg-white py-9 px-14">
              <div className=" flex items-center gap-x-8">
                <ProfileUploader
                  image={image}
                  onChange={(image) => setImage(image)}
                />
                <div className="space-y-2">
                  <Typo.body1
                    fontWeight="font-medium"
                    className="text-gray-900"
                  >
                    {user?.name}
                  </Typo.body1>
                  <Typo.body3 className="text-gray-600">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.PHONE.id}
                        defaultMessage={TRANSLATION.PHONE.defaultMessage}
                      />
                      :
                    </span>
                    <span dir="ltr">{user?.phone}</span>
                  </Typo.body3>
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  <Typo.body3 className="text-gray-600 flex gap-x-3">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.ADDRESS.id}
                        defaultMessage={TRANSLATION.ADDRESS.defaultMessage}
                      />
                      :
                    </span>
                    <span>{user?.address}</span>
                  </Typo.body3>
                  <Typo.body3 className="text-gray-600 flex gap-x-3">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.EMAIL.id}
                        defaultMessage={TRANSLATION.EMAIL.defaultMessage}
                      />
                      :
                    </span>
                    <span>{user?.email}</span>
                  </Typo.body3>
                </div>
              </div>
            </div>
            <div className="w-full rounded-lg bg-white py-10 px-14 space-y-8">
              <div className=" grid sm:grid-cols-2 gap-y-8 gap-x-5 md:gap-x-10">
                <InputField
                  name="name"
                  label={handelInputMap(intl, "name")}
                  placeholder={handelInputMap(intl, "name_placeholder")}
                  register={register}
                  errors={errors?.name}
                  required={true}
                />
                <InputField
                  name="email"
                  label={handelInputMap(intl, "email")}
                  placeholder={handelInputMap(intl, "email_placeholder")}
                  type="email"
                  disabled={true}
                  register={register}
                  errors={errors?.email}
                  required={true}
                />
                <InputField
                  name="phone"
                  label={handelInputMap(intl, "phone")}
                  placeholder="07xxxxxxxx"
                  register={register}
                  defaultValue={user?.phone || ""}
                  errors={errors?.phone}
                  required={true}
                />
                <InputField
                  name="whatsapp"
                  label={handelInputMap(intl, "whatsapp")}
                  placeholder="07xxxxxxxx"
                  register={register}
                  defaultValue={user?.whatsapp || ""}
                  errors={errors?.whatsapp}
                  required={true}
                />
                <Dropdown
                  name="counntry"
                  label={handelInputMap(intl, "country")}
                  placeholder={handelInputMap(intl, "country_placeholder")}
                  defaultValue={country}
                  onChange={setCountry}
                  options={countries || []}
                />
                <Dropdown
                  name="province"
                  label={handelInputMap(intl, "province")}
                  placeholder={handelInputMap(intl, "province_placeholder")}
                  defaultValue={province}
                  onChange={setProvince}
                  options={provinces || []}
                />
                <div className="w-full sm:col-span-2 flex">
                  <InputField
                    name="address"
                    label={handelInputMap(intl, "address")}
                    placeholder={handelInputMap(intl, "address_placeholder")}
                    register={register}
                    errors={errors?.address}
                    required={true}
                  />
                </div>
              </div>

              <div className="pt-6">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                  loading={loading}
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    <FormattedMessage
                      id={TRANSLATION.SAVE_CHANGES.id}
                      defaultMessage={TRANSLATION.SAVE_CHANGES.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
