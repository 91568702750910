import { createSlice } from "@reduxjs/toolkit";

const location = localStorage.getItem("locationData");
const usableLocation = location && JSON.parse(location);

const initialState = {
  page: 1,
  // toBeCleared: false,
  limit: 12,
  title: "",
  purpose: "",
  provinceId: undefined,
  address: "",
  subCategoryId: undefined,
  minArea: undefined,
  maxArea: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  bathrooms: undefined,
  bedrooms: undefined,
  documentType: "",
  unit: "",
  currency: "",
  listingState: undefined,
  userId: undefined,
  agencyId: undefined,
  code: undefined,
  featured: false,
  latLng: [],
};
const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addFilter: (state, action) => {
      state.page = action.payload.page;
      state.title = action.payload.title;
      state.purpose = action.payload.purpose;
      state.address = action.payload.address;
      state.provinceId = action.payload.provinceId;
      state.subCategoryId = action.payload.subCategoryId;
      state.minArea = action.payload.minArea;
      state.maxArea = action.payload.maxArea;
      state.minPrice = action.payload.minPrice;
      state.maxPrice = action.payload.maxPrice;
      state.bathrooms = action.payload.bathrooms;
      state.bedrooms = action.payload.bedrooms;
      state.unit = action.payload.unit;
      state.currency = action.payload.currency;
      state.documentType = action.payload.documentType;
      state.featured = action.payload.featured
    },
    addLatLng: (state, action) => {
      state.latLng = action?.payload?.latLng;
    },
    // addFilterModal: (state, action) => {
    //   state.filterModal = action.payload.filterModal;
    // },
  },
});

export const { addFilter, addLatLng } = filterSlice.actions;
export default filterSlice.reducer;
