import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IntlProvider } from "react-intl";
import English from "../../src/i18n/translations/en.json";
import Persion from "../../src/i18n/translations/fa.json";
import Pashto from "../../src/i18n/translations/ps.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LANGUAGES } from "../static/enum";
interface LangContextType {
  language: string;
  setLanguage: (language: string) => any;
  direction: "ltr" | "rtl";
}

const LangContext = createContext<LangContextType>(null!);

export const LangProvider = ({ children }: any) => {
  const [language, setLanguage] = useLocalStorage("lang", "fa");
  const direction:"ltr" | "rtl" = language === LANGUAGES.ENGLISH ? "ltr" : "rtl";
  const value = useMemo(
    () => ({
      language,
      setLanguage,
      direction
    }),
    [language]
  );

  const getLocale = (locale: string) => {
    if (locale === "fa") {
      return Persion;
    } else if (locale === "ps") {
      return Pashto;
    } else {
      return English;
    }
  };
  return (
    <>
      <LangContext.Provider value={value}>
        <IntlProvider
          locale={language || "fa"}
          messages={getLocale(language || "fa")}
        >
          <div dir={language === LANGUAGES.ENGLISH ? "ltr" : "rtl"}>
            {children}
          </div>
        </IntlProvider>
      </LangContext.Provider>
      <ToastContainer
        position={language === "en" ? "top-left" : "top-right"}
        bodyClassName="font-iransans"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={language === LANGUAGES.ENGLISH ? false : true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition="bounce"
      />
    </>
  );
};

export const useLang = () => {
  return useContext(LangContext);
};
