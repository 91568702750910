import clsx from "clsx";
import React, { useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { getDocuments, getGolas } from "../../../static/constants";
import CategoryDropdown from "../../../components/CategoryDropdown";
import Dropdown from "../../../components/shared/Dropdown";
import Button from "../../../components/shared/button";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { Typo } from "../../../components/shared/typo";
import { handelInputMap } from "../../../util/handelInputMap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { ListingProp } from "../../../model/Listing";
import { TRANSLATION } from "../../../static/translation";
import ListingFilterModal from "../../../modal/ListingFilterModal";
import ThreeDotsIcon from "../../../components/icons/ThreeDots";
import { getLocaleProvinces } from "../../../util/appUtil";
type Props = {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  className?: string;
  categories: any;
  filter: ListingProp;
  category?: DropdownOptionModel;
  intl: IntlShape;
  setCategory: (category: DropdownOptionModel) => void;
  onChange: (name: string, value: any) => void;
  onSearch: (listingProps: ListingProp) => void;
  onResetFilter: (listingProps: ListingProp) => void;
  locale: string;
};

const ListingFilter: React.FC<Props> = (props) => {
  const {
    className,
    categories = [],
    onSearch,
    onChange,
    filter,
    category,
    intl,
    setCategory,
    onResetFilter,
    locale
  } = props;
  const goals = getGolas(intl);
  const documents = getDocuments(intl);
  const { ToGetProvinces } = useHandelProvince();
  const provinceResponse = ToGetProvinces(1, -1);
  const [showMorefilter, setShowMoreFilter] = useState(false);
  const provinces = getLocaleProvinces(locale, provinceResponse?.data?.rows);
  const onReset = () => {
    const defaultFilter = {
      page: 1,
      title: "",
      purpose: "",
      address: "",
      provinceId: undefined,
      subCategoryId: undefined,
      minArea: undefined,
      maxArea: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      bathrooms: undefined,
      bedrooms: undefined,
      unit: "",
      currency: "",
      documentType: "",
      featured: false,
    };
    onResetFilter(defaultFilter);
  };
  return (
    <div className={clsx("w-full relative rounded-3xl", className)}>
      <div
        className={clsx(
          "bg-white shadow-shadow2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-6xl w-full mx-auto flex-col gap-y-6 items-start rounded-xl p-8 "
        )}
      >
        <Dropdown
          options={goals || []}
          defaultValue={goals.find((item) => item.id === filter.purpose)}
          onChange={(porpuse: DropdownOptionModel) =>
            onChange("purpose", porpuse.id)
          }
          placeholder={handelInputMap(intl, "goal_placeholder")}
          label={handelInputMap(intl, "goal")}
        />
        <CategoryDropdown
          options={categories || []}
          defaultValue={category}
          onChange={setCategory}
          placeholder={handelInputMap(intl, "listing_type_placeholder")}
          label={handelInputMap(intl, "listing_type")}
        />
        <Dropdown
          options={provinces || []}
          defaultValue={provinces?.find(
            (item: any) => item.id === filter.provinceId
          )}
          onChange={(province: DropdownOptionModel) =>
            onChange("provinceId", province.id)
          }
          placeholder={handelInputMap(intl, "province_placeholder")}
          label={handelInputMap(intl, "province")}
        />
        <div className="flex items-end h-full w-full gap-x-4">
          <div className="flex items-center gap-x-2">
            <Button
              theme="primary-link"
              onClick={() => setShowMoreFilter(true)}
              icon={<ThreeDotsIcon className="w-[4px] mt-2" />}
              className=" flex items-center"
            >
              <FormattedMessage
                id={TRANSLATION.MORE.id}
                defaultMessage={TRANSLATION.MORE.defaultMessage}
              />
            </Button>
            <Button
              size="medium"
              theme="primary"
              className="shadow-shadow w-full"
              onClick={onSearch}
            >
              <Typo.heading4 fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATION.SEARCH.id}
                  defaultMessage={TRANSLATION.SEARCH.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <Button
              size="medium"
              theme="secandory"
              className="shadow-shadow w-full"
              onClick={onReset}
            >
              <Typo.heading4
                fontWeight="font-medium"
                className="whitespace-nowrap"
              >
                <FormattedMessage
                  id={TRANSLATION.DELETE_FILTER.id}
                  defaultMessage={TRANSLATION.DELETE_FILTER.defaultMessage}
                />
              </Typo.heading4>
            </Button>
          </div>
        </div>
      </div>
      <ListingFilterModal
        open={showMorefilter}
        onClose={setShowMoreFilter}
        intl={intl}
        documents={documents}
        filter={filter}
        onChange={onChange}
      />
    </div>
  );
};

export default ListingFilter;
