import CheckboxField from "../../../components/shared/CheckboxField";
import { Typo } from "../../../components/shared/typo";
import UserIcon from "../../../components/icons/UserIcon";
import AgencyIcon from "../../../components/icons/AgencyIcon";
import NextPrevButtons from "./NextPrevButtons";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { CreateUserProp } from "../../../model/User";
type Props = {
  disabled?: boolean;
  currentStep: number;
  role: number;
  setRole: (role: number) => void;
  loading: boolean;
  onUpdateProfile: (user: CreateUserProp) => Promise<void>;
};

const UserAccountType: React.FC<Props> = (props) => {
  const { currentStep, role, setRole, onUpdateProfile, loading } =
    props;

  const onUpdate = async () => {
    const update = {
      roleId: role,
    };
    await onUpdateProfile(update);
  };
  return (
    <div className="w-full h-80">
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-10">
          <Typo.heading1 className="text-gray-400">
            <FormattedMessage
              id={TRANSLATION.CHOOSE_ACCOUNT_TYPE.id}
              defaultMessage={TRANSLATION.CHOOSE_ACCOUNT_TYPE.defaultMessage}
            />
          </Typo.heading1>
          <div className="space-y-6">
            <div className="w-full flex max-w-[250px] items-center justify-between">
              <div className=" flex items-center gap-x-3">
                <UserIcon className="h-6 w-6 text-gray-300" />
                <Typo.heading1
                  fontWeight="font-medium"
                  className="text-secondary-900"
                >
                  <FormattedMessage
                    id={TRANSLATION.I_AM_USER.id}
                    defaultMessage={TRANSLATION.I_AM_USER.defaultMessage}
                  />
                </Typo.heading1>
              </div>
              <CheckboxField
                name="isAgency"
                isSelected={role === 1}
                onChange={() => setRole(1)}
                register={() => ({})}
              />
            </div>
            <div className="w-full flex max-w-[250px] items-center justify-between">
              <div className=" flex items-center gap-x-3">
                <AgencyIcon className="h-6 w-6 text-gray-300" />
                <Typo.heading1
                  fontWeight="font-medium"
                  className="text-secondary-900"
                >
                  <FormattedMessage
                    id={TRANSLATION.I_AM_AGENCY.id}
                    defaultMessage={TRANSLATION.I_AM_AGENCY.defaultMessage}
                  />
                </Typo.heading1>
              </div>
              <CheckboxField
                name="isAgency"
                isSelected={role === 2}
                onChange={() => setRole(2)}
                register={() => ({})}
              />
            </div>
          </div>
        </div>
        <NextPrevButtons
          onNext={onUpdate}
          loading={loading}
          currentStep={currentStep}
          btnType="submit"
        />
      </div>
    </div>
  );
};

export default UserAccountType;
