type Props = {
  className?: string;
};
const HomeIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 5.89349C12.5 5.33516 12.225 4.81849 11.7583 4.51016L8.425 2.28516C7.86667 1.91016 7.13333 1.91016 6.575 2.28516L3.24167 4.51016C2.78333 4.81849 2.5 5.33516 2.5 5.89349V10.6268C2.5 10.8602 2.68333 11.0435 2.91667 11.0435H12.0833C12.3167 11.0435 12.5 10.8602 12.5 10.6268V5.89349ZM7.5 8.96016C6.7 8.96016 6.04167 8.30182 6.04167 7.50182C6.04167 6.70182 6.7 6.04349 7.5 6.04349C8.3 6.04349 8.95833 6.70182 8.95833 7.50182C8.95833 8.30182 8.3 8.96016 7.5 8.96016Z"
        fill="#B7C1CF"
      />
      <path
        d="M18.3337 17.7086H17.2753V15.2086C18.067 14.9503 18.642 14.2086 18.642 13.3336V11.6669C18.642 10.5753 17.7503 9.68359 16.6587 9.68359C15.567 9.68359 14.6753 10.5753 14.6753 11.6669V13.3336C14.6753 14.2003 15.242 14.9336 16.017 15.2003V17.7086H12.5003V12.7086C12.5003 12.4753 12.317 12.2919 12.0837 12.2919H2.91699C2.68366 12.2919 2.50033 12.4753 2.50033 12.7086V17.7086H1.66699C1.32533 17.7086 1.04199 17.9919 1.04199 18.3336C1.04199 18.6753 1.32533 18.9586 1.66699 18.9586H16.6087C16.6253 18.9586 16.6337 18.9669 16.6503 18.9669C16.667 18.9669 16.6753 18.9586 16.692 18.9586H18.3337C18.6753 18.9586 18.9587 18.6753 18.9587 18.3336C18.9587 17.9919 18.6753 17.7086 18.3337 17.7086ZM6.87533 15.2086C6.87533 14.8669 7.15866 14.5836 7.50033 14.5836C7.84199 14.5836 8.12533 14.8669 8.12533 15.2086V17.7086H6.87533V15.2086Z"
        fill="#1BC457"
      />
    </svg>
  );
};

export default HomeIcon;
