import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import CloseIcon from "../icons/CloseIcon";
import BarIcon from "../icons/BarIcon";
import BellIcon from "../icons/BellIcon";
import { getMenus, getLanguages } from "../../static/constants";
import NavLink from "../shared/NavLink";
import { useAuth } from "../../context/auth";
import { useLang } from "../../context/language";
import { useHandelLogin } from "../../hooks/login/useHandelLogin";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../lib/hooks";
import { DropdownOptionModel } from "../../model/DropdownOption";
import { addFilter } from "../../lib/reducers/filter.slice";
import { ROUTES } from "../../constants/routes";
import AfIcon from "../icons/AfIcon";
import Button from "../shared/button";
import Dropdown from "../shared/Dropdown";
import PlusIcon from "../icons/PlusIcon";
import { hideModal, showModal } from "../../lib/reducers/modal.slice";
import { Typo } from "../shared/typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import Login from "../../modal/Login";
import Signup from "../../modal/Signup";
// import logo from "../../assets/image/logo.svg";
import Image from "../shared/Image";
import userIcon from "../../assets/image/user.png";
import { useIntl } from "react-intl";
import ForgoatPasswordModal from "../../modal/ForgotPasswordModal";
import AHLogo from "../icons/AHLogo";
import AHNameColumn from "../icons/AHNameColumn";
export default function Header() {
  const auth = useAuth();
  const lang = useLang();
  const intl = useIntl();
  const { onTapLogin } = useHandelLogin();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.user;
  const logindModal = useAppSelector((state) => state.modal.login);
  const signupModal = useAppSelector((state) => state.modal.signup);
  const forgoatPassword = useAppSelector((state) => state.modal.forgotPassword);

  const currentPath = location.pathname;
  const menus = getMenus(intl);
  const languages = getLanguages(intl);
  const onChangeLanguage = (language: DropdownOptionModel) => {
    lang.setLanguage(`${language.id}`);
  };

  const onClick = (id: string | number) => {
    dispatch(
      addFilter({
        page: 1,
        purpose: id,
      })
    );
    navigate(`${ROUTES.LISTINGS_ROUTE}`);
  };
  return (
    <div className="min-h-full z-10 relative pt-2 md:pt-6">
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <Button
              size="normal"
              theme="secandory"
              className="hidden"
              onClick={() => onTapLogin}
            ></Button>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between w-full">
                <div className="flex flex-shrink-0 items-center ">
                  <div>
                    <a
                      href={ROUTES.HOME_ROUTE}
                      className="flex items-center gap-x-2"
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id={TRANSLATION.AFGHAN_HOUSE_TITLE.id}
                          defaultMessage={
                            TRANSLATION.AFGHAN_HOUSE_TITLE.defaultMessage
                          }
                        />
                      </span>
                      <div>
                        <AHLogo
                          type="primary"
                          className="text-primary-600 h-8"
                        />
                      </div>
                      <AHNameColumn className="h-8" />
                    </a>
                  </div>
                </div>

                <div className="hidden md:-my-px md:flex items-center sm:gap-x-4 md:gap-x-6 xl:gap-x-8">
                  {menus.map((item) => (
                    <NavLink
                      key={item.id}
                      title={item.name}
                      link={item.href}
                      currentPath={currentPath}
                      onClick={() => onClick(`${item?.id}`)}
                    ></NavLink>
                  ))}
                </div>
                <div className="hidden md:flex md:items-center">
                  <div className="flex gap-x-3  font">
                    <Dropdown
                      options={languages}
                      position="absolute"
                      iconType="icon"
                      onChange={onChangeLanguage}
                      suffex={<AfIcon className="rounded-sm " />}
                    />
                    {/* <Button
                      theme="stroke"
                      size="medium"
                      icon={
                        <ProfileIcon className="text-secondary-500 h-6 w-6" />
                      }
                    ></Button> */}

                    <Button
                      size="small"
                      theme="primary"
                      to={auth?.user ? ROUTES.NEW_LISTING_ROUTE : undefined}
                      className="shadow-green "
                      icon={<PlusIcon className="h-6 w-6" />}
                      onClick={() =>
                        !auth?.user ? dispatch(showModal("login")) : {}
                      }
                    >
                      <Typo.heading4
                        fontWeight="font-bold"
                        className="text-white whitespace-nowrap"
                      >
                        <FormattedMessage
                          id={TRANSLATION.REGISTER_NEW_LISTING.id}
                          defaultMessage={
                            TRANSLATION.REGISTER_NEW_LISTING.defaultMessage
                          }
                        />
                      </Typo.heading4>
                    </Button>
                  </div>
                </div>
                <div className="-mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <CloseIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <BarIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {menus.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={clsx(
                      item.href === currentPath
                        ? "border-primary-500 bg-indigo-50 text-primary-700"
                        : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                    )}
                    aria-current={
                      item.href === currentPath ? "page" : undefined
                    }
                  >
                    <Typo.heading2>{item.name}</Typo.heading2>
                  </Disclosure.Button>
                ))}
              </div>
              {auth?.user ? (
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <Image
                        className="h-10 w-10 rounded-full"
                        src={user?.image ? user?.image : userIcon}
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <div className="text-base font-medium text-gray-800">
                        <Typo.heading3 fontWeight="font-bold">
                          {user?.name}
                        </Typo.heading3>
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        <Typo.heading2>{user?.email}</Typo.heading2>
                      </div>
                    </div>
                    <Button
                      type="button"
                      rounded="rounded-full"
                      className="relative ms-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </Button>
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="a"
                      href={ROUTES.DASHBOARD_ROUTE}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      <Typo.heading2>
                        <FormattedMessage
                          id={TRANSLATION.PROFILE.id}
                          defaultMessage={TRANSLATION.PROFILE.defaultMessage}
                        />
                      </Typo.heading2>
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href={ROUTES.SETTINGS_ROUTE}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      <Typo.heading2>
                        <FormattedMessage
                          id={TRANSLATION.SETTINGS.id}
                          defaultMessage={TRANSLATION.SETTINGS.defaultMessage}
                        />
                      </Typo.heading2>
                    </Disclosure.Button>
                    <Disclosure.Button className="block px-4 py-2 w-full text-start text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
                      <Typo.heading2>
                        <FormattedMessage
                          id={TRANSLATION.LOGOUT.id}
                          defaultMessage={TRANSLATION.LOGOUT.defaultMessage}
                        />
                      </Typo.heading2>
                    </Disclosure.Button>
                  </div>
                </div>
              ) : (
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      onClick={() => dispatch(showModal("login"))}
                      className="block px-4 w-full py-2 text-start text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      <FormattedMessage
                        id={TRANSLATION.LOGIN.id}
                        defaultMessage={TRANSLATION.LOGIN.defaultMessage}
                      />
                    </Disclosure.Button>
                    <Disclosure.Button
                      onClick={() => dispatch(showModal("signup"))}
                      className="block px-4 py-2 w-full text-start text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      <FormattedMessage
                        id={TRANSLATION.REGISTER.id}
                        defaultMessage={TRANSLATION.REGISTER.defaultMessage}
                      />
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Login
        open={logindModal && !user}
        onClose={() => dispatch(hideModal("login"))}
      />
      <Signup
        open={signupModal}
        onClose={() => dispatch(hideModal("signup"))}
      />
      <ForgoatPasswordModal
        open={forgoatPassword}
        onClose={() => dispatch(hideModal("forgotPassword"))}
      />
    </div>
  );
}
