type Props = {
  className?: string;
};
const CloseCircleIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 15.1667C11.6663 15.1667 14.6663 12.1667 14.6663 8.50004C14.6663 4.83337 11.6663 1.83337 7.99967 1.83337C4.33301 1.83337 1.33301 4.83337 1.33301 8.50004C1.33301 12.1667 4.33301 15.1667 7.99967 15.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.11328 10.3866L9.88661 6.61328"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88661 10.3866L6.11328 6.61328"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseCircleIcon;
