import InputField from "../../../components/shared/InputField";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import Dropdown from "../../../components/shared/Dropdown";
import { useHandelUser } from "../../../hooks/user/useHandelUser";
import { useAuth } from "../../../context/auth";
import { useHandelCountry } from "../../../hooks/location/useHandelCountry";
import { useHandelProvince } from "../../../hooks/location/useHandelProvince";
import { useHandelSettings } from "../../../hooks/profile/useHandelSettings";
import { useEffect, useState } from "react";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getRoles } from "../../../static/constants";
import PropertyLabel from "../listings/propertyLabel";
import { TRANSLATION } from "../../../static/translation";
import BulkStarIcon from "../../../components/icons/BulkStarIcon";
import Toggle from "../../../components/shared/Toggle";
import ProfileUploader from "../../../components/shared/ProfileUploader";
import { handelInputMap } from "../../../util/handelInputMap";
const UserDetails = () => {
  // Define the columns and data for the table
  const { id } = useParams();
  const { ToGetCounntries } = useHandelCountry();
  const { ToGetProvinces } = useHandelProvince();
  const { ToGetUser } = useHandelUser();
  const auth = useAuth();
  const intl = useIntl();
  const roles=getRoles(intl);
  const [province, setProvince] = useState<DropdownOptionModel>();
  const [country, setCountry] = useState<DropdownOptionModel>();
  const [role, setRole] = useState<DropdownOptionModel>();
  const [confirmed, setConfirmed] = useState(false);
  const [image, setImage] = useState("");
  const { register, errors, handleSubmit, onUpdateUser, setValue, loading } =
    useHandelSettings(intl);

  const countryResponse = ToGetCounntries();
  const provinceResponse = ToGetProvinces(1, -1);
  const user = ToGetUser(Number(id));

  const onUpdate = async (data: any) => {
    await onUpdateUser(
      data,
      Number(auth?.user?.id),
      Number(province?.id),
      Number(country?.id),
      image,
      Number(role?.id),
      confirmed
    );
  };

  const provinceData =
    provinceResponse?.data?.rows?.map((item: any) => ({
      id: item.id,
      title: item.state,
    })) || [];

  const countryData =
    countryResponse?.data?.map((item: any) => ({
      id: item.id,
      title: item.country,
    })) || [];

  useEffect(() => {
    if (user?.data) {
      setValue("name", user?.data?.name);
      setValue("email", user?.data?.email);
      setValue("address", user?.data?.address || "");
      setValue("phone", user?.data?.phone || "");
      setValue("whatsapp", user?.data?.whatsapp || "");
      setImage(user?.data?.image || "");
      setConfirmed(user?.data?.emailVerified);

      const provinceItem = provinceData?.find(
        (item: any) => item.id === user?.data?.provinceId
      );
      if (provinceItem) setProvince(provinceItem);
      else if (provinceData) {
        setProvince(provinceData[0]);
      }

      const userRole = roles.find((item) => item.id === user?.data?.roleId);
      if (userRole) {
        setRole(userRole);
      }

      const countryItem = countryData?.find(
        (item) => item.id === user?.data?.countryId
      );
      if (countryItem) setCountry(countryItem);
      else if (countryData) {
        setCountry(countryData[0]);
      }
    }
  }, [user.data, provinceResponse?.data, countryResponse?.data]); // pass user as a dependency

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-5">
      <div className="w-full">
        <form
          id="login"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onUpdate)}
        >
          <div className=" space-y-8">
            <div className="w-full flex flex-col gap-y-6 sm:flex-row gap-x-32 rounded-lg bg-white py-9 px-14">
              <div className=" flex items-center gap-x-8">
                <ProfileUploader
                  image={image}
                  onChange={(image) => setImage(image)}
                />
                <div className="space-y-2">
                  <Typo.body1
                    fontWeight="font-medium"
                    className="text-gray-900"
                  >
                    {user?.data?.name}
                  </Typo.body1>
                  <Typo.body3 className="text-gray-600">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.PHONE.id}
                        defaultMessage={TRANSLATION.PHONE.defaultMessage}
                      />
                      :
                    </span>
                    <span>{user?.data?.phone}</span>
                  </Typo.body3>
                </div>
              </div>
              <div>
                <div className="space-y-2">
                  <Typo.body3 className="text-gray-600 flex gap-x-3">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.ADDRESS.id}
                        defaultMessage={TRANSLATION.ADDRESS.defaultMessage}
                      />
                      :
                    </span>
                    <span>{user?.data?.address}</span>
                  </Typo.body3>
                  <Typo.body3 className="text-gray-600 flex gap-x-3">
                    <span className="font-medium text-gray-900">
                      <FormattedMessage
                        id={TRANSLATION.EMAIL.id}
                        defaultMessage={TRANSLATION.EMAIL.defaultMessage}
                      />
                      :
                    </span>
                    <span>{user?.data?.email}</span>
                  </Typo.body3>
                </div>
              </div>
            </div>
            <div className="w-full rounded-lg bg-white py-10 px-14 space-y-8">
              <div className="w-full flex gap-x-5">
                <InputField
                  name="name"
                  label={handelInputMap(intl, "name")}
                  placeholder={handelInputMap(intl, "name_placeholder")}
                  register={register}
                  errors={errors?.name}
                  required={true}
                />
                <InputField
                  name="email"
                  label={handelInputMap(intl, "email")}
                  placeholder={handelInputMap(intl, "email_placeholder")}
                  type="email"
                  disabled={true}
                  register={register}
                  errors={errors?.email}
                  required={true}
                />
              </div>
              <div className="w-full flex gap-x-5">
                <InputField
                  name="phone"
                  label={handelInputMap(intl, "phone")}
                  placeholder={handelInputMap(intl, "phone_placeholder")}
                  register={register}
                  errors={errors?.phone}
                  required={true}
                />
                <InputField
                  name="whatsapp"
                  label={handelInputMap(intl, "whatsapp")}
                  placeholder="07xxxxxxxx"
                  register={register}
                  errors={errors?.whatsapp}
                  required={true}
                />
              </div>
              <div className="w-full flex gap-x-5">
                <Dropdown
                  label={handelInputMap(intl, "country")}
                  placeholder={handelInputMap(intl, "country_placeholder")}
                  onChange={setCountry}
                  defaultValue={country}
                  options={countryData}
                />
                <Dropdown
                  label={handelInputMap(intl, "province")}
                  placeholder={handelInputMap(intl, "province_placeholder")}
                  defaultValue={province}
                  onChange={setProvince}
                  options={provinceData}
                />
              </div>
              <div className="w-full flex gap-x-5">
                <Dropdown
                  label={handelInputMap(intl, "privilege")}
                  placeholder={handelInputMap(intl, "privilege_placeholder")}
                  defaultValue={role}
                  onChange={setRole}
                  disabled={auth.user?.roleId !== 4}
                  options={roles}
                />
                <div className="w-full mt-5 flex justify-between items-center ">
                  <PropertyLabel
                    title={TRANSLATION.JACUZZI}
                    icon={<BulkStarIcon className="h-5 w-5" />}
                  />
                  <Toggle
                    size="sm"
                    defaultValue={confirmed}
                    onChange={setConfirmed}
                  />
                </div>
              </div>
              <div className="w-full flex">
                <InputField
                  name="address"
                  label={handelInputMap(intl, "address")}
                  placeholder={handelInputMap(intl, "address_placeholder")}
                  register={register}
                  errors={errors?.address}
                  required={true}
                />
              </div>

              <div className="pt-6">
                <Button
                  size="medium"
                  type="submit"
                  theme="primary"
                  className="shadow-green"
                >
                  <Typo.heading4 fontWeight="font-bold" className="text-white">
                    <FormattedMessage
                      id={TRANSLATION.SAVE_CHANGES.id}
                      defaultMessage={TRANSLATION.SAVE_CHANGES.defaultMessage}
                    />
                  </Typo.heading4>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserDetails;
