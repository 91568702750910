import React from "react";
import { Typo } from "./typo";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import Button from "./button";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addFilter } from "../../lib/reducers/filter.slice";
type Props = {
  id: number;
  name: string;
  amount: number;
};
const CategoryCardItem: React.FC<Props> = (props) => {
  const { name, amount, id } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(
          addFilter({
            page: 1,
            subCategoryId: id,
          })
        );
        navigate(`${ROUTES.LISTINGS_ROUTE}`);
      }}
      className="rounded-lg border w-full h-full border-gray-200 hover:border-white hover:shadow-shadow2 focus:border-secondary-200 flex flex-col space-y-6  p-4"
    >
      <div className="flex flex-col space-y-2 w-full items-center ">
        <Typo.heading4 fontWeight="font-medium" className="text-gray-900">
          {name}
        </Typo.heading4>
        <Typo.heading4 fontWeight="font-normal" className="text-gray-500">
          <FormattedMessage
            id={TRANSLATION.ITEM.id}
            defaultMessage={TRANSLATION.ITEM.defaultMessage}
            values={{ name: amount }}
          />
        </Typo.heading4>
      </div>
    </Button>
  );
};

export default CategoryCardItem;
