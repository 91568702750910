type Props = {
  className?: string;
};
const HuobiTokenIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.516 13.4043L13.4949 14.6809C13.4739 15.4894 12.8213 16.149 12.0213 16.149H8.27389C7.47389 16.149 6.82126 15.5107 6.80021 14.7022L6.73705 13.4043C2.88442 13.8086 0.105469 14.8511 0.105469 16.0851C0.105469 17.6596 4.58968 18.9149 10.1055 18.9149C15.6213 18.9149 20.1055 17.6383 20.1055 16.0851C20.1055 14.8511 17.3476 13.8086 13.516 13.4043Z"
        fill="currentColor"
      />
      <path
        d="M4.8854 4.85123L4.67488 5.36186C4.52751 5.70229 4.69593 6.10655 5.03277 6.25548C5.11698 6.29803 5.20119 6.31931 5.30646 6.31931C5.58014 6.31931 5.81172 6.17038 5.93804 5.89378L6.14856 5.38314C6.4854 4.57463 6.35909 3.65974 5.81172 2.97889C5.55909 2.65974 5.51698 2.19165 5.74856 1.82995L6.00119 1.40442C6.19067 1.08527 6.10646 0.659737 5.76961 0.468248C5.45383 0.276758 5.03277 0.361865 4.8433 0.70229L4.59067 1.12782C4.06435 1.97889 4.12751 3.08527 4.75909 3.85123C4.96962 4.12782 5.03277 4.5108 4.8854 4.85123Z"
        fill="currentColor"
      />
      <path
        d="M1.85346 4.85119L1.64293 5.36183C1.49557 5.70225 1.66399 6.10651 2.00083 6.25544C2.08504 6.298 2.16925 6.31927 2.27451 6.31927C2.5482 6.31927 2.77978 6.17034 2.90609 5.89374L3.11662 5.3831C3.45346 4.57459 3.32714 3.6597 2.77978 2.97885C2.52714 2.6597 2.48504 2.19161 2.71662 1.82991L2.96925 1.40438C3.15872 1.08523 3.07451 0.659699 2.75872 0.46821C2.44293 0.276721 2.02188 0.361827 1.83241 0.680976L1.57978 1.10651C1.05346 1.95757 1.13767 3.06395 1.7482 3.82991C1.93767 4.12778 1.97978 4.51076 1.85346 4.85119Z"
        fill="currentColor"
      />
      <path
        d="M17.6002 4.85123L17.3897 5.36186C17.2424 5.70229 17.4108 6.10655 17.7476 6.25548C17.8318 6.29803 17.916 6.31931 18.0213 6.31931C18.295 6.31931 18.5266 6.17038 18.6529 5.89378L18.8634 5.38314C19.2002 4.57463 19.0739 3.65974 18.5266 2.97889C18.2739 2.65974 18.2318 2.19165 18.4634 1.82995L18.716 1.40442C18.9055 1.08527 18.8213 0.659737 18.4845 0.468248C18.1687 0.276758 17.7476 0.361865 17.5581 0.70229L17.3055 1.12782C16.7792 1.97889 16.8424 3.08527 17.4739 3.85123C17.6845 4.12782 17.7266 4.5108 17.6002 4.85123Z"
        fill="currentColor"
      />
      <path
        d="M14.5475 4.85123L14.337 5.36186C14.1896 5.70229 14.358 6.10655 14.6949 6.25548C14.7791 6.29803 14.8633 6.31931 14.9686 6.31931C15.2423 6.31931 15.4738 6.17038 15.6001 5.89378L15.8107 5.38314C16.1475 4.57463 16.0212 3.65974 15.4738 2.97889C15.2212 2.65974 15.1791 2.19165 15.4107 1.82995L15.6633 1.40442C15.8528 1.08527 15.7686 0.659737 15.4317 0.468248C15.1159 0.276758 14.6949 0.361865 14.5054 0.70229L14.2528 1.12782C13.7265 1.97889 13.7896 3.08527 14.4212 3.85123C14.6317 4.12782 14.6949 4.5108 14.5475 4.85123Z"
        fill="currentColor"
      />
      <path
        d="M17.0732 7.65969C17.0522 7.65969 15.4522 8.53203 13.9574 8.95756C12.568 9.36182 11.0943 9.36182 10.2943 9.36182H9.91532C9.11532 9.36182 7.64164 9.36182 6.25216 8.95756C4.73637 8.51075 3.13637 7.65969 3.13637 7.65969C2.65216 7.40437 2.06269 7.57458 1.81006 8.06394C1.55743 8.55331 1.72585 9.14905 2.21006 9.40437C2.27322 9.44692 3.99953 10.3618 5.7048 10.8725C6.42059 11.0852 7.13637 11.1916 7.81006 11.2554L7.93637 14.6597C7.93637 14.8512 8.1048 15.0001 8.27322 15.0001H11.9995C12.189 15.0001 12.3364 14.8512 12.3364 14.6597L12.3995 11.2554C13.0522 11.1916 13.768 11.0639 14.4837 10.8725C16.189 10.3831 17.9153 9.44692 17.9785 9.40437C18.4627 9.14905 18.6311 8.55331 18.3785 8.06394C18.1469 7.59586 17.5574 7.40437 17.0732 7.65969Z"
        fill="currentColor"
      />
      <path
        d="M10.1059 8.74479C11.3035 8.74479 12.2743 7.76363 12.2743 6.55331C12.2743 5.34298 11.3035 4.36182 10.1059 4.36182C8.90833 4.36182 7.9375 5.34298 7.9375 6.55331C7.9375 7.76363 8.90833 8.74479 10.1059 8.74479Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HuobiTokenIcon;
