/* eslint-disable react/prop-types */
import { FC } from "react";
import {
  TileLayer,
  Marker,
  MapContainer,
  ZoomControl,
  Popup,
  useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import clsx from "clsx";
import { handleIcon } from "../../util/MapUtils";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";

type Props = {
  coords?: number[];
  className?: string;
  hasPopup?: boolean;
  refetch?: () => void;
};
const zoom = 13;

const Map: FC<Props> = ({ coords, className }) => {
  const MyComponent = (props: any) => {
    const { mapCoord } = props
    const map = useMap()
    if (mapCoord) {
      map.setView([mapCoord[0], mapCoord[1]]);
    }
    return <div>
      <ZoomControl position="topright" />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        mapCoord && <Marker
          position={[mapCoord[0], mapCoord[1]]}
          icon={handleIcon()}
        >
          <Popup>
            <FormattedMessage id={TRANSLATION.LISTING_LOCATION.id} defaultMessage={TRANSLATION.LISTING_LOCATION.defaultMessage} />
          </Popup>
        </Marker>
      }
    </div>
  }

  return (
    <div>
      {coords && <MapContainer
        center={[coords[0], coords[1]]}
        className={clsx(`rounded-lg z-[2] w-full h-[200px] ${className}`)}
        zoom={zoom}
        scrollWheelZoom={true}
        fadeAnimation={true}
        zoomAnimation={true}
        zoomControl={false}
      >
        {coords?.length > 0 && <MyComponent mapCoord={coords} />}

      </MapContainer>}
    </div>
  );
};

export default Map;
