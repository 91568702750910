type Props = {
  className?: string;
};
const RankingIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0836 6.75837L12.1836 8.95837C12.3336 9.25837 12.7336 9.55837 13.067 9.60837L15.0586 9.9417C16.3336 10.1584 16.6336 11.075 15.717 11.9917L14.167 13.5417C13.9086 13.8 13.7586 14.3084 13.842 14.675L14.2836 16.6C14.6336 18.1167 13.8253 18.7084 12.4836 17.9167L10.617 16.8084C10.2753 16.6084 9.72531 16.6084 9.38364 16.8084L7.51695 17.9167C6.17529 18.7084 5.36695 18.1167 5.71695 16.6L6.15864 14.675C6.24198 14.3167 6.09196 13.8084 5.83363 13.5417L4.28364 11.9917C3.36698 11.075 3.66696 10.15 4.94196 9.9417L6.93364 9.60837C7.26697 9.55004 7.66697 9.25837 7.81697 8.95837L8.91697 6.75837C9.50864 5.56671 10.492 5.56671 11.0836 6.75837Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.4"
        d="M5 8.12496C4.65833 8.12496 4.375 7.84163 4.375 7.49996V1.66663C4.375 1.32496 4.65833 1.04163 5 1.04163C5.34167 1.04163 5.625 1.32496 5.625 1.66663V7.49996C5.625 7.84163 5.34167 8.12496 5 8.12496Z"
        fill="#BDBDBD"
      />
      <path
        opacity="0.4"
        d="M15 8.12496C14.6583 8.12496 14.375 7.84163 14.375 7.49996V1.66663C14.375 1.32496 14.6583 1.04163 15 1.04163C15.3417 1.04163 15.625 1.32496 15.625 1.66663V7.49996C15.625 7.84163 15.3417 8.12496 15 8.12496Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.4"
        d="M10 3.95829C9.65833 3.95829 9.375 3.67496 9.375 3.33329V1.66663C9.375 1.32496 9.65833 1.04163 10 1.04163C10.3417 1.04163 10.625 1.32496 10.625 1.66663V3.33329C10.625 3.67496 10.3417 3.95829 10 3.95829Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export default RankingIcon;
