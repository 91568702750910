import { TRANSLATION } from "../static/translation";

export const handelValidationMap = (intl: any) => {
  const validations: { [key: string]: string } = {
    name_required: intl.formatMessage({
      id: TRANSLATION.NAME_REQUIRED.id,
      defaultMessage: TRANSLATION.NAME_REQUIRED.defaultMessage,
    }),
    email_required: intl.formatMessage({
      id: TRANSLATION.EMAIL_REQUIRED.id,
      defaultMessage: TRANSLATION.EMAIL_REQUIRED.defaultMessage,
    }),
    invalid_email: intl.formatMessage({
      id: TRANSLATION.INVALID_EMAIL.id,
      defaultMessage: TRANSLATION.INVALID_EMAIL.defaultMessage,
    }),
    phone_required: intl.formatMessage({
      id: TRANSLATION.PHONE_REQUIRED.id,
      defaultMessage: TRANSLATION.PHONE_REQUIRED.defaultMessage,
    }),
    invalid_phone: intl.formatMessage({
      id: TRANSLATION.INVALID_PHONE.id,
      defaultMessage: TRANSLATION.INVALID_PHONE.defaultMessage,
    }),
    whatsapp_required: intl.formatMessage({
      id: TRANSLATION.WHATSAPP_REQUIRED.id,
      defaultMessage: TRANSLATION.WHATSAPP_REQUIRED.defaultMessage,
    }),
    invalid_whatsapp: intl.formatMessage({
      id: TRANSLATION.INVALID_WHATSAPP.id,
      defaultMessage: TRANSLATION.INVALID_WHATSAPP.defaultMessage,
    }),
    address_required: intl.formatMessage({
      id: TRANSLATION.REQUIRED_ADDRESS.id,
      defaultMessage: TRANSLATION.REQUIRED_ADDRESS.defaultMessage,
    }),
    landline_required: intl.formatMessage({
      id: TRANSLATION.LANDLINE_REQUIRED.id,
      defaultMessage: TRANSLATION.LANDLINE_REQUIRED.defaultMessage,
    }),
    invalid_landline: intl.formatMessage({
      id: TRANSLATION.INVALID_LANDLINE.id,
      defaultMessage: TRANSLATION.INVALID_LANDLINE.defaultMessage,
    }),
    agencyName_required: intl.formatMessage({
      id: TRANSLATION.AGENCY_NAME_REQUIRED.id,
      defaultMessage: TRANSLATION.AGENCY_NAME_REQUIRED.defaultMessage,
    }),
    enter_your_password: intl.formatMessage({
      id: TRANSLATION.ENTER_YOUR_PASSWORD.id,
      defaultMessage: TRANSLATION.ENTER_YOUR_PASSWORD.defaultMessage,
    }),
    must_be_six_characters: intl.formatMessage({
      id: TRANSLATION.MUST_BE_SIX_CHARACTERS.id,
      defaultMessage: TRANSLATION.MUST_BE_SIX_CHARACTERS.defaultMessage,
    }),
    password_validation: intl.formatMessage({
      id: TRANSLATION.PASSWORD_VALIDATION.id,
      defaultMessage: TRANSLATION.PASSWORD_VALIDATION.defaultMessage,
    }),
    title_required: intl.formatMessage({
      id: TRANSLATION.REQUIRED_TITLE.id,
      defaultMessage: TRANSLATION.REQUIRED_TITLE.defaultMessage,
    }),
    description_required: intl.formatMessage({
      id: TRANSLATION.REQUIRED_DESCRIPTION.id,
      defaultMessage: TRANSLATION.REQUIRED_DESCRIPTION.defaultMessage,
    }),
    required_price: intl.formatMessage({
      id: TRANSLATION.PRICE_REQUIRED.id,
      defaultMessage: TRANSLATION.PRICE_REQUIRED.defaultMessage,
    }),
    invalid_price: intl.formatMessage({
      id: TRANSLATION.INVALID_PRICE.id,
      defaultMessage: TRANSLATION.INVALID_PRICE.defaultMessage,
    }),
    required_area: intl.formatMessage({
      id: TRANSLATION.AREA_REQUIRED.id,
      defaultMessage: TRANSLATION.AREA_REQUIRED.defaultMessage,
    }),
    invalid_area: intl.formatMessage({
      id: TRANSLATION.INVALID_AREA.id,
      defaultMessage: TRANSLATION.INVALID_AREA.defaultMessage,
    }),
    invalid_bathroom: intl.formatMessage({
      id: TRANSLATION.INVALID_BATHROOM.id,
      defaultMessage: TRANSLATION.INVALID_BATHROOM.defaultMessage,
    }),
    invalid_bedroom: intl.formatMessage({
      id: TRANSLATION.INVALID_BEDROOM.id,
      defaultMessage: TRANSLATION.INVALID_BEDROOM.defaultMessage,
    }),
    invalid_link: intl.formatMessage({
      id: TRANSLATION.INVALID_LINK.id,
      defaultMessage: TRANSLATION.INVALID_LINK.defaultMessage,
    }),
    link_required: intl.formatMessage({
      id: TRANSLATION.LINK_REQUIRED.id,
      defaultMessage: TRANSLATION.LINK_REQUIRED.defaultMessage,
    }),
    otp_token_required: intl.formatMessage({
      id: TRANSLATION.OTP_TOKEN_REQUIRED.id,
      defaultMessage: TRANSLATION.OTP_TOKEN_REQUIRED.defaultMessage,
    }),
    enter_otp: intl.formatMessage({
      id: TRANSLATION.ENTER_OTP.id,
      defaultMessage: TRANSLATION.ENTER_OTP.defaultMessage,
    }),
    confirm_your_password: intl.formatMessage({
      id: TRANSLATION.CONFIRM_YOUR_PASSWORD.id,
      defaultMessage: TRANSLATION.CONFIRM_YOUR_PASSWORD.defaultMessage,
    }),
    password_donot_match: intl.formatMessage({
      id: TRANSLATION.PASSWORD_DONOT_MATCH.id,
      defaultMessage: TRANSLATION.PASSWORD_DONOT_MATCH.defaultMessage,
    }),
  };

  return validations;
};
