import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import { handelValidationMap } from "../../util/handelValidationMap";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { PHONE_NUMBER_REGULAR_EXPRESSION } from "../../static/constants";
export const useHandelSettings = (intl: any) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const queryClient = useQueryClient();
  const validations = handelValidationMap(intl);
  const navigate = useNavigate();
  const schema = object().shape({
    name: string().required(validations["name_required"]),
    email: string()
      .required(validations["name_email"])
      .email(validations["invali_email"]),
    phone: string()
      .required(validations["phone_required"])
      .matches(PHONE_NUMBER_REGULAR_EXPRESSION, validations["invalid_phone"]),
    whatsapp: string().test(
      "whatsapp",
      validations["invalid_whatsapp"],
      (value) => {
        if (value) {
          const match = value.match(PHONE_NUMBER_REGULAR_EXPRESSION);
          return match ? true : false;
        } else {
          return true;
        }
      }
    ),
    address: string().required(validations["address_required"]),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const updateMutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "PUT",
        endpoint: `/update-profile`,
        data: doUser.data,
        requiredAuth: true,
      });
    },
    onSuccess: (data) => {
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ["userProfile"] });
    },
    onError: (error) => {
      setLoading(false);
      console.error("eeeeeee", error);
    },
  });

  const userUpdateMutation = useMutation({
    mutationFn: async (doUser: any) => {
      return await Fetch({
        method: "PUT",
        endpoint: `/user/${doUser.id}`,
        data: doUser.data,
        requiredAuth: true,
      });
    },
    onSuccess: (data) => {
      setLoading(false);
      navigate(ROUTES.DASHBOARD_USERS_ROUTE);
    },
    onError: (error) => {
      setLoading(false);
      console.error("eeeeeee", error);
    },
  });

  const onUpdateUser = async (
    data: any,
    userId: number,
    provinceId: number,
    countryId: number,
    image?: string,
    roleId?: number,
    confirmed?: boolean
  ) => {
    setLoading(true);
    const userData = {
      data: {
        provinceId: provinceId,
        countryId: countryId,
        roleId: roleId,
        image: image,
        emailVerified: confirmed,
        ...data,
      },
      id: userId,
    };
    userUpdateMutation.mutate(userData);
  };

  const onUpdate = async (
    data: any,
    userId: number,
    provinceId: number,
    countryId: number,
    image?: string,
    roleId?: number
  ) => {
    setLoading(true);
    const userData = {
      data: {
        provinceId: provinceId,
        countryId: countryId,
        roleId: roleId,
        image: image,
        ...data,
      },
      id: userId,
    };
    updateMutation.mutate(userData);
  };

  const onRoleUpdate = async (roleId?: number) => {
    setLoading(true);
    const userData = {
      data: {
        roleId: roleId,
      },
    };
    updateMutation.mutate(userData);
  };

  return {
    register,
    errors,
    loading,
    handleSubmit,
    onUpdateUser,
    onRoleUpdate,
    onUpdate,
    reset,
    setValue,
    currentStep,
    setCurrentStep,
  };
};
