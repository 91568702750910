type Props = {
  className?: string;
};
const WeightIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9297 9C13.9297 7.56958 14.3932 6.79586 14.966 6.36059C15.5646 5.90563 16.3738 5.75 17.1797 5.75C17.9855 5.75 18.7947 5.90563 19.3934 6.36059C19.9662 6.79586 20.4297 7.56958 20.4297 9V15C20.4297 16.4304 19.9662 17.2041 19.3934 17.6394C18.7947 18.0944 17.9855 18.25 17.1797 18.25C16.3738 18.25 15.5646 18.0944 14.966 17.6394C14.3932 17.2041 13.9297 16.4304 13.9297 15V9Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M3.57031 9C3.57031 7.56958 4.03382 6.79586 4.60659 6.36059C5.20527 5.90563 6.01446 5.75 6.82031 5.75C7.62616 5.75 8.43535 5.90563 9.03404 6.36059C9.60681 6.79586 10.0703 7.56958 10.0703 9V15C10.0703 16.4304 9.60681 17.2041 9.03404 17.6394C8.43535 18.0944 7.62616 18.25 6.82031 18.25C6.01446 18.25 5.20527 18.0944 4.60659 17.6394C4.03382 17.2041 3.57031 16.4304 3.57031 15V9Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M11.0703 12.25V11.75H12.9303V12.25H11.0703Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M22.5 14.75C22.3661 14.75 22.25 14.6339 22.25 14.5V9.5C22.25 9.36614 22.3661 9.25 22.5 9.25C22.6339 9.25 22.75 9.36614 22.75 9.5V14.5C22.75 14.6339 22.6339 14.75 22.5 14.75Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M1.5 14.75C1.36614 14.75 1.25 14.6339 1.25 14.5V9.5C1.25 9.36614 1.36614 9.25 1.5 9.25C1.63386 9.25 1.75 9.36614 1.75 9.5V14.5C1.75 14.6339 1.63386 14.75 1.5 14.75Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default WeightIcon;
