type Props = {
  className?: string;
};
const SendIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93313 4.21331L10.5931 2.32665C13.1331 1.47998 14.5131 2.86665 13.6731 5.40665L11.7865 11.0666C10.5198 14.8733 8.43979 14.8733 7.17312 11.0666L6.61312 9.38665L4.93313 8.82665C1.12646 7.55998 1.12646 5.48665 4.93313 4.21331Z"
        stroke="white"
      />
      <path d="M6.74023 9.1L9.1269 6.70667" stroke="white" />
    </svg>
  );
};

export default SendIcon;
