// import logo from "../../../assets/image/logo.svg";
import { getNavigations } from "../../../static/constants";
import SidebarNav from "./SidebarNav";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import Button from "../../shared/button";
import { Typo } from "../../shared/typo";
import LogoutIcon from "../../icons/LogoutIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { useIntl } from "react-intl";
import { ROUTES } from "../../../constants/routes";
import AHLogo from "../../icons/AHLogo";
import AHNameColumn from "../../icons/AHNameColumn";

type Props = {
  direction: string;
};

const Sidebar: React.FC<Props> = (props) => {
  // export default function Sidebar() {
  const { direction } = props;
  const location = useLocation();
  const auth = useAuth();
  const user = auth.user;
  const intl = useIntl();
  const navigations = getNavigations(intl);
  return (
    <div className="hidden select-none lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-60 lg:flex-col shadow-shadow4">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
        <Button to={ROUTES.HOME_ROUTE}>
          <div className="flex items-center justify-start gap-x-3 ps-3 w-full pt-2">
            <div>
              <AHLogo className="h-10 text-primary-600" type="primary" />
            </div>
            <div>
              <AHNameColumn className=" h-10 text-secondary-600" />
            </div>
          </div>
        </Button>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1 cursor-pointer">
                {navigations
                  .filter((item) => item.roles?.includes(Number(user?.roleId)))
                  .map((item) => (
                    <SidebarNav
                      current={location.pathname}
                      key={item.name}
                      nav={item}
                      direction={direction}
                    />
                  ))}
                <li className="relative mt-auto ">
                  <div className="w-full px-2 relative ">
                    <Button
                      onClick={() => auth.logout()}
                      theme=""
                      className={`flex items-center py-2 ps-6 text-gray-900`}
                    >
                      <div className="me-3">
                        <LogoutIcon />
                      </div>
                      <Typo.body4>
                        <FormattedMessage
                          id={TRANSLATION.LOGOUT.id}
                          defaultMessage={TRANSLATION.LOGOUT.defaultMessage}
                        />
                      </Typo.body4>
                    </Button>
                  </div>
                </li>
              </ul>
            </li>
            {/* <li className="mt-auto">
              <a
                href="#"
                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
              >
                Settings
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
