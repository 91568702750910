/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { DOTS, usePagination } from "../../hooks/usePagination";
import clsx from "clsx";
import Button from "./button";
import { Typo } from "./typo";
import ChevronIcon from "../icons/ChevronIcon";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import { DIRECTIONS } from "../../static/enum";
type PaginationPropsType = {
  onPageChange: (page: number) => void;
  total: number;
  perPage: number;
  currentPage: number;
  type: "centered-page-number" | "simple-card-footer";
  direction?: string;
};

const Pagination: FC<PaginationPropsType> = (prop) => {
  const { onPageChange, total, perPage, currentPage, type, direction } = prop;

  const paginationRange =
    (usePagination({
      currentPage: currentPage,
      totalCount: total,
      siblingCount: 1,
      pageSize: perPage,
    }) as any[]) || [];

  const quotient = Math.floor(total / perPage); // Quotient
  const remainder = total % perPage; // Remainder
  const totalPage = remainder !== 0 ? quotient + 1 : quotient;
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage < totalPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <nav className={clsx("flex items-center justify-between py-3 ")}>
      <div className="-mt-px flex w-0 flex-1">
        <Button
          disabled={currentPage === 1}
          onClick={onPrevious}
          className="px-2 border disabled:border-gray-200 hover:border-secondary-500 disabled:text-gray-300 hover:text-secondary-500 h-10 w-10 "
          size="medium"
        >
          <div className="flex justify-center  gap-x-2 ">
            <div
              className={clsx({
                "block ": type === "simple-card-footer",
              })}
            >
              <ChevronIcon
                className={clsx(
                  "h-5 w-5 ",
                  direction === DIRECTIONS.LTR ? "rotate-90" : "-rotate-90"
                )}
              />
            </div>
          </div>
        </Button>
      </div>
      <div
        className={clsx({
          "hidden md:-mt-px md:flex md:gap-x-2":
            type === "centered-page-number",
          hidden: type === "simple-card-footer",
        })}
      >
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div
                key={index}
                className="h-8 w-[1.75rem] flex justify-center items-center"
              >
                <span className="text-[0.875rem] font-medium text-gray-400">
                  . . .
                </span>
              </div>
            );
          }
          return (
            <Button
              size="medium"
              key={index}
              onClick={() => onPageChange(pageNumber)}
              className={`inline-flex items-center justify-center ${
                pageNumber === currentPage
                  ? "bg-primary-600 text-white"
                  : "border-transparent bg-gray-50 text-gray-400 hover:text-gray-600 hover:bg-gray-100"
              } py-3 h-10 w-10`}
            >
              <span className="text-grray-500">{pageNumber}</span>
            </Button>
          );
        })}
      </div>
      <div
        className={clsx({ "block md:hidden": type === "centered-page-number" })}
      >
        <Typo.heading4
          fontWeight="font-medium"
          className="flex items-center justify-center gap-1 text-sm leading-tight font-poppins text-gray-700"
        >
          <Typo.heading3 className="text-gray-500">
            <FormattedMessage
              id={TRANSLATION.PAGE.id}
              defaultMessage={TRANSLATION.PAGE.defaultMessage}
            />
          </Typo.heading3>
          <Typo.heading2 fontWeight="font-medium" className="text-gray-800">
            {currentPage}
          </Typo.heading2>
          <Typo.heading3 className="text-gray-500">
            <FormattedMessage
              id={TRANSLATION.OF.id}
              defaultMessage={TRANSLATION.OF.defaultMessage}
            />
          </Typo.heading3>
          <Typo.heading2 fontWeight="font-medium" className="text-gray-800">
            {totalPage}
          </Typo.heading2>
        </Typo.heading4>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end ">
        <Button
          disabled={currentPage === totalPage}
          onClick={onNext}
          className="px-2 border disabled:border-gray-200 hover:border-secondary-500 disabled:text-gray-300 hover:text-secondary-500 h-10 w-10 "
          size="medium"
        >
          <div className="flex justify-center gap-x-2 ">
            <div
              className={clsx({
                block: type === "simple-card-footer",
              })}
            >
              <ChevronIcon
                className={clsx(
                  "h-5 w-5 ",
                  direction === DIRECTIONS.LTR ? "-rotate-90" : "rotate-90"
                )}
              />
            </div>
          </div>
        </Button>
      </div>
    </nav>
  );
};

export default Pagination;
