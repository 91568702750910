type Props = {
  className?: string;
};
const FreezingLineIcon = ({ className }: Props) => {
  return (
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      className={className}
    >
      <rect fill="currentColor" x="17" y="18" width="11" height="2" />
      <rect fill="currentColor" x="17" y="22" width="11" height="2" />
      <path fill="currentColor" d="M17,26v2h10c.6,0,1-.4,1-1v-1h-11Z" />
      <path fill="currentColor" d="M15,17c0-.6,.4-1,1-1h12v-2h-10c-.6,0-1-.4-1-1s.4-1,1-1h10V1c0-.6-.4-1-1-1H13V13c0,.6-.4,1-1,1H0v13c0,.6,.4,1,1,1h7v-9h-3c-.6,0-1-.4-1-1s.4-1,1-1h4c.6,0,1,.4,1,1v10h5v-11Z" />
      <path fill="currentColor" d="M11,12V0H1C.4,0,0,.4,0,1V12H11Z" />
    </svg>
  );
};

export default FreezingLineIcon;
