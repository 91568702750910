import { Switch } from "@headlessui/react";
import clsx from "clsx";
import React, { ReactElement } from "react";

type Props = {
  defaultValue?: boolean;
  onChange: (state: boolean) => void;
  size?: "sm" | "md";
  theme?: "light" | "dark";
  label?: string | ReactElement;
  disabled?: boolean;
  className?: string;
  [x: string]: any;
};

const Toggle: React.FC<Props> = (props) => {
  const {
    defaultValue = false,
    onChange,
    size = "md",
    theme = "dark",
    label,
    description,
    disabled = false,
    className = "",
  } = props;

  return (
    <Switch.Group as="div" className="flex items-center w-full">
      <div className="flex group w-full select-none items-start justify-between">
        <Switch.Label
          as="span"
          className="ms-2 flex flex-col w-full justify-center text-sm cursor-pointer"
        >
          {label && (
            <span className="font-medium text-gray-700 font-inter text-base leading-6">
              {label}
            </span>
          )}
        </Switch.Label>
        <Switch
          checked={defaultValue}
          onChange={onChange}
          disabled={disabled}
          className={clsx(
            defaultValue
              ? `${
                  theme === "dark"
                    ? "bg-primary-700 hover:bg-primary-800  focus:bg-primary-700 "
                    : " bg-primary-200 hover:bg-primary-300 focus:bg-primary-200"
                }`
              : "bg-gray-100 hover:bg-gray-200 focus:bg-gray-100 ",
            size === "sm" ? "h-5 w-9 " : " h-6 w-11 ",
            "relative focus:shadow-xs  inline-flex items-center flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-4 focus:ring-primary-300 disabled:bg-gray-100",
            className
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              defaultValue
                ? size === "sm"
                  ? "-translate-x-[18px]"
                  : "-translate-x-[2px]"
                : "translate-x-[2px]",
              size === "sm" ? " h-4 w-4 " : " h-5 w-5 ",
              "pointer-events-none inline-block  transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
