type Props = {
  className?: string;
};
const BillIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.94629 1.6665V9.6465C2.94629 10.2998 3.25296 10.9198 3.77962 11.3132L7.25295 13.9132C7.99295 14.4665 9.01295 14.4665 9.75295 13.9132L13.2263 11.3132C13.7529 10.9198 14.0596 10.2998 14.0596 9.6465V1.6665H2.94629Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1.83301 1.6665H15.1663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.83301 5.3335H11.1663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 8.6665H11.1663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BillIcon;
