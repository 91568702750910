type Props = {
  className?: string;
};
const Edit2Icon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34031 2.39994L3.86697 8.19327C3.66031 8.41327 3.46031 8.84661 3.42031 9.14661L3.17364 11.3066C3.08697 12.0866 3.64697 12.6199 4.42031 12.4866L6.56697 12.1199C6.86697 12.0666 7.28697 11.8466 7.49364 11.6199L12.967 5.82661C13.9136 4.82661 14.3403 3.68661 12.867 2.29327C11.4003 0.913274 10.287 1.39994 9.34031 2.39994Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42651 3.3667C8.71318 5.2067 10.2065 6.61337 12.0598 6.80003"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 14.6666H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit2Icon;
