type Props = {
  className?: string;
};
const NearbyStoreIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.30778 13.4767L2.30779 13.4766L2.30488 13.4729C1.58929 12.5528 1.24993 11.3591 1.36612 10.1115C1.36617 10.1109 1.36623 10.1104 1.36628 10.1098L1.72438 6.46258C2.00078 4.51639 2.5556 3.29801 3.38105 2.55117C4.2022 1.80823 5.41957 1.41669 7.2862 1.41669H10.4862H12.4595H16.5129H18.4862H21.6329C23.4924 1.41669 24.7111 1.80805 25.5329 2.54754C26.3582 3.29018 26.9123 4.49931 27.1816 6.42429L27.5663 10.0985C27.5664 10.0995 27.5665 10.1005 27.5666 10.1016C27.6823 11.3125 27.3667 12.4694 26.6862 13.3767L26.6861 13.3766L26.6779 13.388C25.8272 14.5675 24.4599 15.25 22.9129 15.25C21.5036 15.25 20.1307 14.5538 19.2382 13.4062L18.61 12.5985L18.0289 13.4407C17.2519 14.5667 15.9784 15.25 14.4995 15.25C13.0263 15.25 11.7141 14.5319 10.9483 13.3945L10.3602 12.5209L9.72691 13.3624C8.84865 14.5296 7.45308 15.25 6.01953 15.25C4.50704 15.25 3.17186 14.6022 2.30778 13.4767Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M13.7084 21.9598L13.7099 21.9596C15.1419 21.8102 16.0871 22.0615 16.6629 22.5259C17.2204 22.9754 17.5813 23.7473 17.5754 24.9968H17.5754V25.0003V28.0903H11.4087V24.5337C11.4087 23.2047 12.4046 22.0932 13.7084 21.9598Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M3.5443 17.6805L3.54425 17.6806L3.55731 17.6849C3.94617 17.8146 4.34801 17.9199 4.77287 17.9813C4.91732 18.0053 5.12214 18.0382 5.33114 18.0429C5.5608 18.0699 5.79085 18.0834 6.02115 18.0834C7.54653 18.0834 9.038 17.586 10.2788 16.7206C11.483 17.5891 12.9576 18.0834 14.5011 18.0834C16.0522 18.0834 17.5093 17.6121 18.7127 16.7451C19.9464 17.5965 21.4128 18.0834 22.9145 18.0834C23.177 18.0834 23.4417 18.0691 23.6958 18.0398C23.846 18.0273 24.0144 18.0122 24.1893 17.9815C24.6635 17.9182 25.086 17.7823 25.442 17.6679L25.464 17.6608L25.464 17.6609L25.4749 17.6572C25.8593 17.5271 26.2445 17.811 26.2445 18.2001V22.1734C26.2445 25.4069 23.6458 28.0376 20.4245 28.0893V25.0001C20.4245 23.1386 19.8526 21.5798 18.6438 20.4968C17.5778 19.5275 16.167 19.0901 14.5278 19.0901C14.1746 19.0901 13.814 19.1042 13.4219 19.1476C10.6642 19.4284 8.57781 21.753 8.57781 24.5334V28.0893C5.35654 28.0376 2.75781 25.4069 2.75781 22.1734V18.2268C2.75781 17.8254 3.15215 17.5417 3.5443 17.6805Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default NearbyStoreIcon;
