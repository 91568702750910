import React, { Fragment, ReactElement, ChangeEvent } from "react";
import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import ChevronIcon from "../icons/ChevronIcon";
import { Typo } from "./typo";
import InputField from "./InputField";
import { handelInputMap } from "../../util/handelInputMap";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import { DropdownOptionModel } from "../../model/DropdownOption";
type Props = {
  iconType?: "none" | "image" | "icon" | "onlineIndicatro";
  position?: "relative" | "absolute";
  prefix?: string | ReactElement;
  suffex?: string | ReactElement;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  hasSearch?: boolean;
  minValue?: number;
  maxValue?: number;
  setMinValue: (minValue: number) => void;
  setMaxValue: (maxValue: number) => void;
  searchStyle?: string;
  intl: IntlShape;
  options: DropdownOptionModel[];
  onOptionSelect: (value: string) => void;
  optionDefaultValue: string;
  [x: string]: any;
};

const RangeDropdown: React.FC<Props> = (props) => {
  const {
    label = "",
    placeholder = "",
    position = "absolute",
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    disabled = false,
    intl,
    options,
    onOptionSelect,
    optionDefaultValue,
  } = props;

  // console.log("uuuuuuu ", optionDefaultValue);
  return (
    <div className="top-16 flex flex-col gap-y-1.5 w-full">
      {label ? (
        <Typo.heading4
          fontWeight="font-normal"
          className="text-gray-700 text-start"
        >
          {label}
        </Typo.heading4>
      ) : (
        ""
      )}
      <Combobox value={minValue} disabled={disabled}>
        {({ open }) => (
          <div className="relative">
            <div
              className={clsx(
                "relative w-full cursor-default overflow-hidden bg-white rounded-lg text-left shadow-xs border border-gray-300 focus:ring-4 focus:ring-gray-100",
                { " ring-4 ring-gray-100": open }
              )}
            >
              <Combobox.Button className="flex w-full gap-x-2 py-[12px] justify-between whitespace-nowrap items-center px-3.5 group">
                <Typo.heading4
                  fontWeight="font-medium"
                  className=" flex gap-x-2 text-gray-500 hover:text-gray-700"
                >
                  <>
                    {minValue ? ` ${minValue} ` : ""}
                    {minValue && maxValue && ` ${handelInputMap(intl, "to")} `}
                    {maxValue ? ` ${maxValue} ` : ""}
                    {!minValue && !maxValue && placeholder}
                    {optionDefaultValue !== "" &&
                      options?.find((item) => item.id === optionDefaultValue)
                        ?.title}
                  </>
                </Typo.heading4>

                <ChevronIcon
                  className={clsx("h-4 w-4 text-gray-500", {
                    " rotate-180 ": open,
                  })}
                ></ChevronIcon>
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options
                className={`${position} end-0 z-30 mt-2 w-full overflow-y-auto overflow-x-hidden py-1 max-h-60 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                <div className="flex flex-col w-full gap-y-3 items-center py-2 px-5 focus:ring-0 ">
                  <select
                    // id="location"
                    // name="location"
                    className="block w-full border-0 py-1.5 text-gray-900 ring-gray-300 focus:ring-0  sm:text-sm sm:leading-6"
                    defaultValue={optionDefaultValue}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedOption = options.find(
                        (item) => item.title === event.target.value
                      );
                      if (selectedOption) {
                        onOptionSelect(String(selectedOption.id));
                      }
                    }}
                  >
                    {options?.map((option) => {
                      return <option key={option.title}>{option.title}</option>;
                    })}
                  </select>

                  <InputField
                    label=""
                    value={minValue}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setMinValue(Number(event.target.value));
                    }}
                    className="w-full border border-gray-200 bg-white py-3 rounded-xl placeholder:text-gray-400 font-iransans text-sm text-gray-900 focus:ring-2 focus:ring-gray-100 focus:border-none"
                    type="number"
                    placeholder={handelInputMap(intl, "minimum")}
                    register={() => ({})}
                    name="min"
                  />
                  <Typo.heading5 fontWeight="font-medium">
                    <FormattedMessage
                      id={TRANSLATION.TO.id}
                      defaultMessage={TRANSLATION.TO.defaultMessage}
                    />
                  </Typo.heading5>
                  <InputField
                    label=""
                    value={maxValue}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setMaxValue(Number(event.target.value));
                    }}
                    className="w-full border border-gray-200 bg-white py-3 rounded-xl placeholder:text-gray-400 font-iransans text-sm text-gray-900 focus:ring-2 focus:ring-gray-100 focus:border-none"
                    type="number"
                    placeholder={handelInputMap(intl, "maximum")}
                    register={() => ({})}
                    name="max"
                  />
                </div>
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default RangeDropdown;
