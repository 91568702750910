type Props = {
  className?: string;
};
const CommercialIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95827 3.86826L5.2666 2.04326C3.27494 1.06826 1.6416 2.05993 1.6416 4.24326V16.6099C1.6416 17.5599 2.43327 18.3349 3.39993 18.3349H9.58327C10.0416 18.3349 10.4166 17.9599 10.4166 17.5016V6.17659C10.4166 5.30159 9.75827 4.25993 8.95827 3.86826ZM7.47493 11.4599H4.58327C4.2416 11.4599 3.95827 11.1766 3.95827 10.8349C3.95827 10.4933 4.2416 10.2099 4.58327 10.2099H7.47493C7.8166 10.2099 8.09993 10.4933 8.09993 10.8349C8.09993 11.1766 7.82493 11.4599 7.47493 11.4599ZM7.47493 8.12659H4.58327C4.2416 8.12659 3.95827 7.84326 3.95827 7.50159C3.95827 7.15993 4.2416 6.87659 4.58327 6.87659H7.47493C7.8166 6.87659 8.09993 7.15993 8.09993 7.50159C8.09993 7.84326 7.82493 8.12659 7.47493 8.12659Z"
        fill="#B7C1CF"
      />
      <path
        d="M18.3337 15.0352V16.2518C18.3337 17.4018 17.4003 18.3352 16.2503 18.3352H12.4753C12.0253 18.3352 11.667 17.9768 11.667 17.5268V15.7268C12.5587 15.8352 13.5003 15.5768 14.1753 15.0352C14.742 15.4935 15.467 15.7685 16.2587 15.7685C17.0337 15.7685 17.7587 15.4935 18.3337 15.0352Z"
        fill="#1BC457"
      />
      <path
        d="M18.3337 12.5427V12.5511C18.267 13.6427 17.3753 14.5177 16.2587 14.5177C15.1003 14.5177 14.1753 13.5761 14.1753 12.4344C14.1753 13.7094 13.0003 14.7344 11.667 14.4761V10.0011C11.667 9.46774 12.1587 9.06774 12.6837 9.18441L14.1753 9.51774L14.5753 9.60941L16.2753 9.99274C16.6837 10.0761 17.0587 10.2177 17.3837 10.4261C17.3837 10.4344 17.392 10.4344 17.392 10.4344C17.4753 10.4927 17.5587 10.5594 17.6337 10.6344C18.017 11.0177 18.267 11.5761 18.3253 12.3927C18.3253 12.4427 18.3337 12.4927 18.3337 12.5427Z"
        fill="#1BC457"
      />
    </svg>
  );
};

export default CommercialIcon;
