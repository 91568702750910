import React from "react";
import Image from "../Image";
import { Typo } from "../typo";
import LeftArrowIcon from "../../icons/LeftArrowIcon";
import Button from "../button";
import { ArticleModel } from "../../../model/Article";
import { ROUTES } from "../../../constants/routes";
import { FormattedMessage } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import TrashIcon from "../../icons/TrashIcon";
import EyeIcon from "../../icons/EyeIcon";
import Edit2Icon from "../../icons/Edit2Icon";
import Divider from "../Divider";
import clsx from "clsx";
import { DIRECTIONS } from "../../../static/enum";

type ArticleProp = {
  article?: ArticleModel;
  page?: "dashboard" | "public";
  onDelete?: (id: number) => void;
  direction?: string;
  pageType?: 'mobile' | 'web'
};

const ArticleCard: React.FC<ArticleProp> = (props) => {
  const { article, page = "public", onDelete, direction, pageType = "web" } = props;

  return (
    <>
      <div className="relative rounded-2xl border border-gray-200 hover:border-white cursor-pointer hover:shadow-shadow2 h-full bg-white p-4">
        <div className="bg-white space-y-4 select-none">
          <Image
            className="w-full rounded-xl"
            src={article?.image}
            alt="Article card view "
          ></Image>
          <div className="flex flex-col space-y-2">
            <Typo.heading2 fontWeight="font-bold" className="text-gray-900">
              {article?.title}
            </Typo.heading2>
            <Typo.body4 className="text-gray-500 text-ellipsis overflow-hidden flex-wrap truncate">
              {article?.description}
            </Typo.body4>
          </div>
          <div>
            {page !== "public" ? (
              <div className=" flex gap-2">
                <Button
                  icon={<TrashIcon className="h-4" />}
                  theme="white"
                  rounded="rounded-lg"
                  size="small"
                  onClick={() => onDelete && onDelete(Number(article?.id))}
                ></Button>
                <div className="flex items-center">
                  <Divider
                    className="text-gray-100 w-px h-full"
                    type={"vertical"}
                  />
                </div>
                <Button
                  icon={<EyeIcon className="h-4" />}
                  theme="white"
                  rounded="rounded-lg"
                  size="small"
                  to={`${ROUTES.ARTICLES_ROUTE}/${article?.id}`}
                ></Button>
                <div className="flex items-center">
                  <Divider
                    className="text-gray-100 w-px h-full"
                    type={"vertical"}
                  />
                </div>
                <Button
                  icon={<Edit2Icon className="h-4" />}
                  theme="white"
                  rounded="rounded-lg"
                  size="small"
                  to={`${ROUTES.DASHBOARD_ARTICLES_ROUTE}/${article?.id}`}
                ></Button>
              </div>
            ) : (
              <div className="flex justify-end group ">
                <Button
                  icon={
                    <LeftArrowIcon
                      className={clsx("h-4 group-hover:text-primary-600", direction === DIRECTIONS.LTR ? "rotate-180" : "")}
                    />
                  }
                  iconPos="right"
                  className="text-gray-800 group-hover:text-primary-600"
                  theme="label"
                  size="small"
                  to={`${pageType === "web" ? ROUTES.ARTICLES_ROUTE : ROUTES.MOBILE_ARTICLES}/${article?.id}`}
                >
                  <Typo.heading5 fontWeight="font-medium">
                    <FormattedMessage
                      id={TRANSLATION.READ_MORE.id}
                      defaultMessage={TRANSLATION.READ_MORE.defaultMessage}
                    />
                  </Typo.heading5>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard;
