type Props = {
  className?: string;
};
const ParkingIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.875 19.723V21C3.875 21.2652 3.98036 21.5196 4.16789 21.7071C4.35543 21.8946 4.60978 22 4.875 22H5.875C6.14022 22 6.39457 21.8946 6.58211 21.7071C6.76964 21.5196 6.875 21.2652 6.875 21V20H18.875V21C18.875 21.2652 18.9804 21.5196 19.1679 21.7071C19.3554 21.8946 19.6098 22 19.875 22H20.875C21.1402 22 21.3946 21.8946 21.5821 21.7071C21.7696 21.5196 21.875 21.2652 21.875 21V19.723C22.1784 19.549 22.4306 19.2981 22.6061 18.9956C22.7817 18.6932 22.8744 18.3497 22.875 18V15C22.875 14.169 22.368 13.458 21.647 13.155L20.279 9.051C20.0805 8.45348 19.6988 7.9337 19.1881 7.56551C18.6774 7.19732 18.0636 6.99944 17.434 7H8.316C7.68639 6.99944 7.07261 7.19732 6.56188 7.56551C6.05115 7.9337 5.66945 8.45348 5.471 9.051L4.103 13.155C3.7393 13.3073 3.42871 13.5637 3.21023 13.892C2.99176 14.2202 2.87513 14.6057 2.875 15V18C2.875 18.738 3.279 19.376 3.875 19.723ZM6.375 18C6.17795 17.9999 5.98285 17.9611 5.80082 17.8856C5.6188 17.8101 5.45342 17.6995 5.31413 17.5602C5.03283 17.2787 4.87487 16.897 4.875 16.499C4.87513 16.101 5.03335 15.7194 5.31484 15.4381C5.59633 15.1568 5.97804 14.9989 6.376 14.999C6.77396 14.9991 7.15556 15.1573 7.43687 15.4388C7.71817 15.7203 7.87613 16.102 7.876 16.5C7.87587 16.898 7.71765 17.2796 7.43616 17.5609C7.15467 17.8422 6.77296 18.0001 6.375 18ZM19.375 18C18.977 17.9999 18.5954 17.8417 18.3141 17.5602C18.0328 17.2787 17.8749 16.897 17.875 16.499C17.8751 16.101 18.0333 15.7194 18.3148 15.4381C18.5963 15.1568 18.978 14.9989 19.376 14.999C19.774 14.9991 20.1556 15.1573 20.4369 15.4388C20.7182 15.7203 20.8761 16.102 20.876 16.5C20.8759 16.898 20.7177 17.2796 20.4362 17.5609C20.1547 17.8422 19.773 18.0001 19.375 18ZM8.316 9H17.433C17.643 8.99995 17.8476 9.06599 18.0179 9.18876C18.1883 9.31153 18.3156 9.4848 18.382 9.684L19.488 13H6.262L7.367 9.684C7.504 9.275 7.886 9 8.316 9Z"
        fill="currentColor"
      />
      <path
        d="M22.875 7.3885V5.27951L13.191 2.05151C12.977 1.97987 12.745 1.98304 12.533 2.06051L2.875 5.57251V7.7005L12.89 4.05851L22.875 7.3885Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ParkingIcon;
