import clsx from "clsx";
type DividerType = {
  className?: string;
  type: "vertical" | "horizontal";
};
const Divider = ({ className, type = "horizontal" }: DividerType) => {
  return (
    <div
      className={clsx(
        `bg-gray-300 ${
          type === "horizontal" ? "w-full h-[1px]" : " h-full w-[1px] "
        } ${className}`
      )}
    />
  );
};

export default Divider;
