import React from "react";
import clsx from "clsx";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import Button from "../../../components/shared/button";
import { Typo } from "../../../components/shared/typo";
import { useIntl } from "react-intl";
import { handelStateMap } from "../../../util/handelInputMap";
import Dropdown from "../../../components/shared/Dropdown";
type TabProps = {
  currentTab?: DropdownOptionModel;
  tabs?: DropdownOptionModel[];
  label?: string;
  onTabChange?: (tab: DropdownOptionModel) => void;
};
const ListingTab: React.FC<TabProps> = (props) => {
  const { currentTab, tabs = [], onTabChange } = props;
  const intl = useIntl();
  return (
    <div>
      <div className="sm:hidden flex relative justify-center mx-auto ">
        <Dropdown
          onChange={onTabChange}
          placeholder="Select a tab"
          options={tabs}
          defaultValue={currentTab}
        ></Dropdown>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex w-full gap-x-6" aria-label="Tabs">
          {tabs?.map((tab) => (
            <Button
              onClick={() => onTabChange && onTabChange(tab)}
              key={tab.title}
              size="small"
            >
              <div
                className={clsx(
                  `flex items-center gap-x-2 whitespace-nowrap ${
                    tab?.id === currentTab?.id
                      ? " text-primary-700"
                      : " text-gray-500"
                  } `
                )}
              >
                <Typo.heading4
                  fontWeight={
                    tab?.id === currentTab?.id ? "font-bold" : "font-normal"
                  }
                >
                  {handelStateMap(intl, tab.title)}
                  {` (${tab?.supportingTitle}) `}
                </Typo.heading4>
              </div>
            </Button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ListingTab;
