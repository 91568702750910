type Props = {
  className?: string;
};
const GooglePlayIcon = ({ className }: Props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 747.24 798.53"
    >
      <path
        fill="#ffd900"
        d="M551.28,522.26c94.77-52.04,166.62-91.69,172.81-94.77,19.81-10.54,40.27-38.42,0-60.08-13-6.81-83-45.23-172.81-94.77l-124.5,125.73,124.5,123.89Z"
      />
      <path
        fill="#f43249"
        d="M426.78,398.38L30.35,797.92c9.3,1.23,19.81-1.23,32.2-8.04,26-14.23,301.66-164.77,488.74-267.58l-124.5-123.92Z"
      />
      <path
        fill="#00ee76"
        d="M426.78,398.38l124.5-125.12S90.43,21.76,62.54,6.91C52.04,.68,40.27-1.17,29.73,.68L426.78,398.38Z"
      />
      <path
        fill="#00d3ff"
        d="M426.78,398.38L29.73,.68C13.62,4.41,0,18.65,0,47.76V750.84c0,26.65,10.54,45.84,30.35,47.69L426.78,398.38Z"
      />
    </svg>
  );
};

export default GooglePlayIcon;
