import { FC, ReactElement, useState } from "react";
import Modal from "../wrapper/modal";
import Button from "../components/shared/button";
import { Typo } from "../components/shared/typo";
import Divider from "../components/shared/Divider";
import Tab from "../components/shared/Tab";
import PlusIcon from "../components/icons/PlusIcon";
import FeatureTab from "../pages/dashboard/listings/tabs/FeatureTab";
import PossibilityTab from "../pages/dashboard/listings/tabs/PossibilityTab";
import NearbyTab from "../pages/dashboard/listings/tabs/NearbyTab";
import PointTab from "../pages/dashboard/listings/tabs/PointTab";
import RecreationTab from "../pages/dashboard/listings/tabs/RecreationTab";
import { DropdownOptionModel } from "../model/DropdownOption";
import { getFeaturesTab } from "../static/constants";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../static/translation";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  featureValues: any;
  valueChange: (
    option: DropdownOptionModel | boolean | string,
    name: string
  ) => void;
};

const FeatureModal: FC<Props> = ({
  open,
  onClose,
  featureValues,
  valueChange,
}) => {
  const intl = useIntl();
  const featureTabs = getFeaturesTab(intl);
  const [currentTab, setCurrenTab] = useState<DropdownOptionModel>(
    featureTabs[0]
  );

  const features: { [key: string]: ReactElement } = {
    1: (
      <FeatureTab
        intl={intl}
        featureValues={featureValues}
        valueChange={valueChange}
      />
    ),
    2: (
      <PossibilityTab
        intl={intl}
        featureValues={featureValues}
        valueChange={valueChange}
      />
    ),
    3: (
      <NearbyTab
        intl={intl}
        featureValues={featureValues}
        valueChange={valueChange}
      />
    ),
    4: <PointTab featureValues={featureValues} valueChange={valueChange} />,
    5: (
      <RecreationTab featureValues={featureValues} valueChange={valueChange} />
    ),
  };

  return (
    <Modal className="max-w-4xl" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center space-y-8">
          <Typo.headingD
            fontWeight="font-bold"
            className="text-gray-900 text-center"
          >
            <FormattedMessage
              id={TRANSLATION.FACILITY_MORE_FEATURES.id}
              defaultMessage={TRANSLATION.FACILITY_MORE_FEATURES.defaultMessage}
            />
          </Typo.headingD>
          <Divider type="horizontal" className="hidden sm:block" />
        </div>
        <div className="mt-8 sm:mt-0">
          <Tab
            type="underline"
            tabs={featureTabs}
            onTabChange={setCurrenTab}
            currentTab={currentTab}
          />
          <div className="py-10">{features[Number(currentTab?.id) || 1]}</div>
        </div>
        <Divider type="horizontal" className="mt-20" />
        <div className="flex gap-x-6 items-center mt-4">
          <Button
            type="submit"
            icon={<PlusIcon />}
            theme="primary"
            size="medium"
            onClick={() => onClose(true)}
          >
            <Typo.heading4>
              <FormattedMessage
                id={TRANSLATION.ADD_FACILITY_MORE_FEATURES.id}
                defaultMessage={
                  TRANSLATION.ADD_FACILITY_MORE_FEATURES.defaultMessage
                }
              />
            </Typo.heading4>
          </Button>
          <Button onClick={() => onClose(false)} size="medium" theme="gray">
            <Typo.heading4>
              <FormattedMessage
                id={TRANSLATION.CANCEL.id}
                defaultMessage={TRANSLATION.CANCEL.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeatureModal;
