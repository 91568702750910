type Props = {
  className?: string;
};
const LayerIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2131_35233)">
        <path
          d="M9.46753 0.347072C9.79315 0.142049 10.2075 0.142049 10.5332 0.347072L18.6391 5.45081C19.262 5.84302 19.262 6.75107 18.6391 7.14327L17.4635 7.88345C17.4609 7.885 17.4576 7.88541 17.455 7.88715C17.4523 7.88889 17.4518 7.89086 17.4491 7.89252L10.5332 12.247C10.2075 12.452 9.79315 12.452 9.46753 12.247L2.5514 7.89237C2.54893 7.89078 2.54823 7.88867 2.54552 7.887C2.54281 7.88534 2.53964 7.885 2.53699 7.8833L1.36162 7.14327C0.738696 6.75107 0.738691 5.84301 1.36161 5.4508L9.46753 0.347072ZM18.6363 12.8582C19.2592 13.2504 19.2592 14.1585 18.6363 14.5507L10.5332 19.6529C10.2075 19.858 9.79314 19.858 9.46751 19.6529L1.3644 14.5507C0.741505 14.1585 0.741513 13.2504 1.36441 12.8582L1.59692 12.7118C1.92256 12.5068 2.33695 12.5068 2.66258 12.7118L9.58446 17.0704C9.63906 17.1048 9.70389 17.1321 9.77525 17.1507C9.84661 17.1693 9.9231 17.1789 10.0003 17.1789C10.0776 17.1789 10.1541 17.1693 10.2254 17.1507C10.2968 17.1321 10.3616 17.1048 10.4162 17.0704L17.3381 12.7118C17.6637 12.5068 18.0781 12.5068 18.4038 12.7118L18.6363 12.8582ZM17.4554 11.5905L17.455 11.5908L10.5332 15.9492C10.2075 16.1543 9.79314 16.1543 9.46751 15.9492L2.54599 11.591L2.54558 11.5907L1.36443 10.847C0.741529 10.4548 0.741539 9.54673 1.36445 9.15453L1.59559 9.009C1.92121 8.80397 2.33559 8.80398 2.66122 9.009L9.58446 13.3681C9.69477 13.4375 9.84436 13.4765 10.0003 13.4765C10.1563 13.4765 10.3059 13.4375 10.4162 13.3681L17.3395 9.009C17.6651 8.80397 18.0795 8.80398 18.4051 9.009L18.6362 9.15453C19.2591 9.54673 19.2592 10.4548 18.6363 10.847L17.4554 11.5905Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2131_35233">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LayerIcon;
