import { IntlShape } from "react-intl";
import { TRANSLATION } from "../static/translation";
import { Category } from "../model/Category";
import { Province } from "../model/Province";
import { Country } from "../model/Country";
export const timeAgo = (datetime: string, intl: IntlShape) => {
  const currentDate = new Date();
  const targetDate = new Date(datetime);

  const timeDifference = currentDate.getTime() - targetDate.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return intl.formatMessage(
      {
        id: TRANSLATION.SECS_AGO.id,
        defaultMessage: TRANSLATION.SECS_AGO.defaultMessage,
      },
      { value: seconds }
    );
  } else if (minutes < 60) {
    return intl.formatMessage(
      {
        id: months === 1 ? TRANSLATION.MIN_AGO.id : TRANSLATION.MINS_AGO.id,
        defaultMessage:
          months === 1
            ? TRANSLATION.MIN_AGO.defaultMessage
            : TRANSLATION.MINS_AGO.defaultMessage,
      },
      { value: minutes }
    );
  } else if (hours < 24) {
    return intl.formatMessage(
      {
        id: hours === 1 ? TRANSLATION.HOUR_AGO.id : TRANSLATION.HOURS_AGO.id,
        defaultMessage:
          hours === 1
            ? TRANSLATION.HOUR_AGO.defaultMessage
            : TRANSLATION.HOURS_AGO.defaultMessage,
      },
      { value: hours }
    );
  } else if (days < 7) {
    return intl.formatMessage(
      {
        id: days === 1 ? TRANSLATION.DAY_AGO.id : TRANSLATION.DAYS_AGO.id,
        defaultMessage:
          days === 1
            ? TRANSLATION.DAY_AGO.defaultMessage
            : TRANSLATION.DAYS_AGO.defaultMessage,
      },
      { value: days }
    );
  } else if (weeks < 4) {
    return intl.formatMessage(
      {
        id: weeks === 1 ? TRANSLATION.WEEK_AGO.id : TRANSLATION.WEEKS_AGO.id,
        defaultMessage:
          weeks === 1
            ? TRANSLATION.WEEK_AGO.defaultMessage
            : TRANSLATION.WEEKS_AGO.defaultMessage,
      },
      { value: weeks }
    );
  } else if (months < 12) {
    return intl.formatMessage(
      {
        id: months === 1 ? TRANSLATION.MONTH_AGO.id : TRANSLATION.MONTHS_AGO.id,
        defaultMessage:
          months === 1
            ? TRANSLATION.MONTH_AGO.defaultMessage
            : TRANSLATION.MONTHS_AGO.defaultMessage,
      },
      { value: months }
    );
  } else {
    return intl.formatMessage(
      {
        id: years === 1 ? TRANSLATION.YEAR_AGO.id : TRANSLATION.YEARS_AGO.id,
        defaultMessage:
          years === 1
            ? TRANSLATION.YEAR_AGO.defaultMessage
            : TRANSLATION.YEARS_AGO.defaultMessage,
      },
      { value: years }
    );
  }
};

export const arabicToLatin = (num: string) => {
  const map: { [key: string]: string } = {
    "٠": "0",
    "۰": "0",
    "١": "1",
    "۱": "1",
    "٢": "2",
    "۲": "2",
    "٣": "3",
    "۳": "3",
    "٤": "4",
    "۴": "4",
    "٥": "5",
    "۵": "5",
    "٦": "6",
    "۶": "6",
    "٧": "7",
    "۷": "7",
    "٨": "8",
    "۸": "8",
    "٩": "9",
    "۹": "9",
  };
  const result = num.replace(
    /[٠١٢٣٤٥٦٧٨٩۰۱۲۳۴۵۶۷۸۹]/g,
    function (match: string) {
      return map[match];
    }
  );
  return Number(result);
};

export const getLocalTitle = (
  locale: string,
  fa?: string,
  ps?: string,
  en?: string
) => {
  let result = fa;
  if (locale === "fa") {
    result = fa;
  } else if (locale === "ps") {
    result = ps;
  } else {
    result = en;
  }
  return result || "";
};

export const getLocaleCategories = (locale: string, data?: Category[]) => {
  let result = [];
  if (locale === "fa") {
    result =
      data?.map((category) => ({
        id: category.id,
        title: category.title_fa,
      })) || [];
  } else if (locale === "ps") {
    result =
      data?.map((category) => ({
        id: category.id,
        title: category.title_ps,
      })) || [];
  } else {
    result =
      data?.map((category) => ({
        id: category.id,
        title: category.title_en,
      })) || [];
  }
  return result;
};

export const getLocaleSubCategories = (locale: string, data?: Category[]) => {
  let result = [];
  if (locale === "fa") {
    result = data?.map((item: any) => ({
      id: item?.id,
      label: item?.title_fa,
      options: item?.subCategories?.map((subItem: any) => ({
        title: subItem?.title_fa || "",
        id: subItem?.id || "",
        supportingTitle: subItem?.listingCount,
      })),
    })) || [];
  } else if (locale === "ps") {
    result = data?.map((item: any) => ({
      id: item?.id,
      label: item?.title_ps,
      options: item?.subCategories?.map((subItem: any) => ({
        title: subItem?.title_ps || "",
        id: subItem?.id || "",
        supportingTitle: subItem?.listingCount,
      })),
    })) || [];
  } else {
    result = data?.map((item: any) => ({
      id: item?.id,
      label: item?.title_en,
      options: item?.subCategories?.map((subItem: any) => ({
        title: subItem?.title_en || "",
        id: subItem?.id || "",
        supportingTitle: subItem?.listingCount,
      })),
    })) || [];
  }
  return result;
};

export const getLocaleProvinces = (locale: string, data?: Province[]) => {
  let result = [];
  if (locale === "fa") {
    result =
      data?.map((province) => ({
        id: province.id,
        title: province.state_fa,
      })) || [];
  } else if (locale === "ps") {
    result =
      data?.map((province) => ({
        id: province.id,
        title: province.state_ps,
      })) || [];
  } else {
    result =
      data?.map((province) => ({
        id: province.id,
        title: province.state_en,
      })) || [];
  }
  return result;
};

export const getLocaleCountries = (locale: string, data?: Country[]) => {
  let result = [];
  if (locale === "fa") {
    result =
      data?.map((country) => ({
        id: country.id,
        title: country.country_fa,
      })) || [];
  } else if (locale === "ps") {
    result =
      data?.map((country) => ({
        id: country.id,
        title: country.country_ps,
      })) || [];
  } else {
    result =
      data?.map((country) => ({
        id: country.id,
        title: country.country_en,
      })) || [];
  }
  return result;
};
