import React from "react";
import { NotificationModel } from "../../../model/Notification";
import Image from "../Image";
import { Typo } from "../typo";
import { timeAgo } from "../../../util/appUtil";
import { IntlShape } from "react-intl";
type Props = {
  notification: NotificationModel;
  intl: IntlShape;
};

const NotificationItem: React.FC<Props> = (props) => {
  const { notification, intl } = props;
  return (
    <div className="p-3 w-full bg-white">
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white  ring-black ring-opacity-5">
        <div className="flex items-start gap-x-3">
          <div className="flex-shrink-0">
            <Image
              className="h-11 w-11 rounded-full"
              src={notification.thumbnail}
            />
          </div>
          <div className="w-0 flex-1">
            <Typo.body4 className="text-gray-500">{notification.content}</Typo.body4>
            <div className="mt-3">
              <Typo.heading1 className=" text-xs text-primary-600">{timeAgo(notification.createdAt, intl)}</Typo.heading1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
