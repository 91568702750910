import React, { ChangeEvent } from "react";
import Dropdown from "../../../components/shared/Dropdown";
import { Typo } from "../../../components/shared/typo";
import InputField from "../../../components/shared/InputField";
import debounce from "lodash.debounce";
import { DEBOUNCE } from "../../../static/constants";
import { DropdownOptionModel } from "../../../model/DropdownOption";
import Button from "../../../components/shared/button";
import { FormattedMessage, IntlShape } from "react-intl";
import { TRANSLATION } from "../../../static/translation";
import { handelInputMap } from "../../../util/handelInputMap";
type Props = {
  className?: string;
  provinces: DropdownOptionModel[];
  province?: DropdownOptionModel;
  setProvince: (province: DropdownOptionModel) => void;
  setAddress: (addres: string) => void;
  setAgency: (agency: string) => void;
  onSearch: () => void;
  intl: IntlShape;
};

const AllAgenciesFilter: React.FC<Props> = (props) => {
  const {
    provinces,
    province,
    setProvince,
    setAddress,
    setAgency,
    onSearch,
    intl,
  } = props;
  const debouncedSetAddress = debounce(setAddress, DEBOUNCE);
  const debouncedSetAgency = debounce(setAgency, DEBOUNCE);
  return (
    <div className="relative bg-gray-100 md:h-72 rounded-3xl select-none">
      <div className="z-1 pt-10 text-center select-none">
        <Typo.headingC className="" fontWeight="font-bold">
          <FormattedMessage
            id={TRANSLATION.AGENCY_TITLE.id}
            defaultMessage={TRANSLATION.AGENCY_TITLE.defaultMessage}
          />
        </Typo.headingC>
        <Typo.body3
          className="text-gray-600 mt-6 px-4 text-justify md:text-center max-w-xl mx-auto"
          fontWeight="font-normal"
        >
          <FormattedMessage
            id={TRANSLATION.AGENCY_DESCRIPTION.id}
            defaultMessage={TRANSLATION.AGENCY_DESCRIPTION.defaultMessage}
          />
        </Typo.body3>
      </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:flex gap-4 items-end bg-white max-w-4xl mx-auto left-0 right-0 md:absolute md:rounded-xl p-6 mt-10 md:mt-0 md:-bottom-12  w-full shadow-shadow2">
        <Dropdown
          label={handelInputMap(intl, "city")}
          placeholder={handelInputMap(intl, "city_placeholder")}
          options={provinces}
          defaultValue={province}
          onChange={setProvince}
        />
        <InputField
          label={handelInputMap(intl, "address")}
          placeholder={handelInputMap(intl, "address_placeholder")}
          register={() => ({})}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            debouncedSetAgency(event.target.value)
          }
          name="agency"
        />
        <InputField
          label={handelInputMap(intl, "agency")}
          placeholder={handelInputMap(intl, "agencyName_placeholder")}
          register={() => ({})}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            debouncedSetAddress(event.target.value)
          }
          name="address"
        />
        <div>
          <Button onClick={onSearch} size="medium" theme="primary">
            <Typo.heading4 fontWeight="font-medium">
              <FormattedMessage
                id={TRANSLATION.SEARCH.id}
                defaultMessage={TRANSLATION.SEARCH.defaultMessage}
              />
            </Typo.heading4>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AllAgenciesFilter;
