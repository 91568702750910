import { Icon } from "leaflet";
import mapPin from "../assets/image/mapPin.svg";

// const markerIconMap: { [key: string]: string } = {
//   wanted: wantedMarkerIcon,
//   personal: personalMarkerIcon,
//   storefrontItem: personalMarkerIcon,
//   storefront: storefrontMarkerIcon
// };

export const handleIcon = (type?: string) => {
  const icon = new Icon({
    iconUrl: mapPin,
    iconSize: [50, 50],
    iconAnchor: [25, 0],
  });
  return icon;
};
