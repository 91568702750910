type Props = {
  className?: string;
};
const NearbySchoolIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 666.34 666.66"
    >
      <path
        fill="currentColor"
        d="M473,0H193.67C72.34,0,0,72.33,0,193.66V472.66c0,121.67,72.34,194,193.67,194H472.67c121.33,0,193.67-72.33,193.67-193.66V193.66C666.67,72.33,594.34,0,473,0Zm-156.5,508.33c0,12-12,20-23,15.33-40.33-17.33-93-33.33-129.66-38l-6.34-.66c-20.33-2.67-37-21.67-37-42.34V186c0-25.67,20.67-44.67,46-42.67,41.67,3.33,103.34,23.33,142,45.33,5.34,3,8,8.67,8,14.34V508.33Zm229.5-66c0,20.67-16.66,39.67-37,42.33l-7,.67c-36.33,5-88.66,20.67-129,37.67-11,4.66-23-3.34-23-15.34V202.66c0-6,3-11.66,8.34-14.66,38.66-21.67,99-41,140-44.67h1.33c25.67,0,46.33,20.67,46.33,46.33v252.67Z"
      />
    </svg>
  );
};

export default NearbySchoolIcon;
