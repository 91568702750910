import { FormattedMessage, useIntl } from "react-intl";
import { Typo } from "../../../components/shared/typo";
import { TRANSLATION } from "../../../static/translation";
import Alert from "../../../components/shared/Alert";
import InputField from "../../../components/shared/InputField";
import { useHandelEmailConfirmation } from "../../../hooks/signup/useHandelEmailConfirmation";
import Button from "../../../components/shared/button";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handelInputMap } from "../../../util/handelInputMap";

const EmailConfirmation = () => {

    const navigage = useNavigate()
    const intl = useIntl();
    const {
        register,
        errors,
        handleSubmit,
        onSubmit,
        loading,
        alert,
        setAlert,
    } = useHandelEmailConfirmation();

    useEffect(() => {
        const email = localStorage?.getItem('email')
        if (!email) {
            navigage(-1)
        }
    }, [])

    return <div className="w-full">
        <div className="w-full max-w-xs mx-auto py-10">
            <div className=" items-center">
                {/* <Image className="h-14 w-14 mx-auto" src={logo} alt="logo" /> */}
                <Typo.heading1
                    fontWeight="font-bold"
                    className=" text-secondary-900 text-center mt-6"
                >
                    <FormattedMessage id={TRANSLATION.EMAIL_CONFIRMATION_TITLE.id} defaultMessage={TRANSLATION.EMAIL_CONFIRMATION_TITLE.defaultMessage} />
                </Typo.heading1>
                <Typo.heading5 className="text-gray-400 mt-2 text-center">
                    <FormattedMessage id={TRANSLATION.EMAIL_CONFIRMATION_DESCRIPTION.id} defaultMessage={TRANSLATION.EMAIL_CONFIRMATION_DESCRIPTION.defaultMessage} />
                </Typo.heading5>
                <Alert
                    type={alert?.type}
                    title={alert?.title}
                    description={alert?.description}
                    open={alert?.open || false}
                    setOpen={() =>
                        setAlert((prevAlert: any) => ({
                            ...prevAlert,
                            open: false,
                        }))
                    }
                />
            </div>
            <form
                id="login"
                action="#"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="w-full my-7">
                    <InputField
                        name="token"
                        label={handelInputMap(intl, 'enter_otp')}
                        placeholder={handelInputMap(intl, 'otp_placeholder')}
                        register={register}
                        errors={errors?.token}
                        required={true}
                    />
                </div>
                <div className=" flex flex-col space-y-2 ">
                    <Button
                        loading={loading}
                        size="medium"
                        type="submit"
                        theme="primary"
                    >
                        <Typo.heading4 fontWeight="font-bold" className="text-white">
                            <FormattedMessage
                                id={TRANSLATION.CONFIRM.id}
                                defaultMessage={TRANSLATION.CONFIRM.defaultMessage}
                            />
                        </Typo.heading4>
                    </Button>
                </div>
            </form>
        </div>
    </div>;
};

export default EmailConfirmation;
