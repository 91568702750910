import ArticleCard from "../../components/shared/Card/ArticleCard";
import Loader from "../../components/shared/Loader";
import Pagination from "../../components/shared/Pagination";
import { ArticleModel } from "../../model/Article";
type Props = {
    articles: any;
    direction: any;
    page: number;
    setPage: (page: number) => void;
    limit: number;
    pageType?: 'mobile' | 'web'
};
const Articels: React.FC<Props> = (props) => {
    const { articles, page, setPage, direction, limit, pageType = 'web' } = props;
    return (
        <div className={`col-span-4 ${pageType === 'web' ? 'md:col-span-3' : ''}`}>
            {articles?.isLoading && <Loader />}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
                {!articles?.isLoading &&
                    articles?.data?.rows?.map((article: ArticleModel) => {
                        return (
                            <ArticleCard
                                key={article.title}
                                article={article}
                                direction={direction}
                                pageType={pageType}
                            />
                        );
                    })}
            </div>
            <div className="w-full mt-24 ">
                <Pagination
                    onPageChange={setPage}
                    total={articles?.data?.count || 0}
                    perPage={limit}
                    currentPage={page}
                    type="centered-page-number"
                    direction={direction}
                />
            </div>
        </div>
    );
};

export default Articels;
