type Props = {
  className?: string;
};
const LogoutIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className}`}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41699 6.79999C7.67533 3.79999 9.21699 2.57499 12.592 2.57499H12.7003C16.4253 2.57499 17.917 4.06666 17.917 7.79166V13.225C17.917 16.95 16.4253 18.4417 12.7003 18.4417H12.592C9.24199 18.4417 7.70033 17.2333 7.42533 14.2833"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 10.5H3.01666"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.87501 7.70834L2.08334 10.5L4.87501 13.2917"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
