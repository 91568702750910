import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SocketProvider from "./context/socket";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async"; // Import HelmetProvider
import { LangProvider } from "./context/language";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LangProvider>
      <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
        <SocketProvider>
          <HelmetProvider>
            {/* Wrap your entire app */}
            <App />
          </HelmetProvider>
        </SocketProvider>
      </ErrorBoundary>
    </LangProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
