import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fetch } from "../../lib/https";
import { Province, ProvinceResponse } from "../../model/Province";
type props = (state: boolean) => void;
export const useHandelProvince = (onClose?: props) => {
  const queryClient = useQueryClient();
  const schema = object().shape({
    state_fa: string().required(),
    state_ps: string().required(),
    state_en: string().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const mutation = useMutation({
    mutationFn: async (doCategory: any) => {
      const response = await Fetch({
        method: "POST",
        data: doCategory,
        endpoint: "/province",
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["province"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (province: any) => {
      const response = await Fetch({
        method: "PUT",
        data: province,
        endpoint: `/province/${province.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["province"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (province: any) => {
      const response = await Fetch({
        method: "DELETE",
        data: province,
        endpoint: `/province/${province.id}`,
        requiredAuth: true,
      });
      return response;
    },
    onSuccess: (data) => {
      onClose && onClose(false);
      queryClient.invalidateQueries({ queryKey: ["province"] });
    },
    onError: (error) => {
      console.error("eeeeeee", error);
    },
  });

  const onSubmit = async (countryId: number, province: Province) => {
    const data = {
      id: province.id,
      countryId: countryId,
      state_fa: province.state_fa,
      state_ps: province.state_ps,
      state_en: province.state_en,
      _csrf: "w0qNjfiB-jWSEj5bVx1XW7gV70obPfhbr7p8",
    };
    mutation.mutate(data);
  };

  const onUpdate = async (
    countryId: number,
    province: Province,
    id: number
  ) => {
    const data = {
      id: id,
      countryId: countryId,
      state_fa: province.state_fa,
      state_ps: province.state_ps,
      state_en: province.state_en,
    };
    updateMutation.mutate(data);
  };

  const onDelete = async (id: number) => {
    const data = {
      id: id,
    };
    deleteMutation.mutate(data);
  };

  function ToGetProvinces(page: number, limit: number) {
    const result = useQuery({
      queryKey: ["province", page],
      queryFn: async () => await getProvinces(page, limit),
    });
    return result;
  }

  const getProvinces = async (
    page: number,
    limit: number
  ): Promise<ProvinceResponse> => {
    const response = await Fetch({
      endpoint: `/province?page=${page}&limit=${limit}`,
    });
    return response?.data;
  };

  return {
    register,
    errors,
    handleSubmit,
    onSubmit,
    onUpdate,
    onDelete,
    reset,
    ToGetProvinces,
    setValue
  };
};
