import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { User } from "../model/User";
import { useHandelUser } from "../hooks/user/useHandelUser";
import Loader from "../components/shared/Loader";
import { SocketState } from "./socket";
interface AuthContextType {
  user: User | null;
  token: any;
  login: (data: any, redirect?: boolean) => any;
  logout: () => any;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: any) => {
  const { ToGetMe, ToGetUser } = useHandelUser();
  const [token, setToken] = useLocalStorage("token", null);
  const { socket, setSocket } = SocketState();
  const meResponse = ToGetMe();
  const userResponse = ToGetUser(meResponse?.data?.id);

  let csrfToken = "";
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  // Event listeners for WebSocket events

  const createSocketConnection = (token: string) => {
    if (token) {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?authToken=${token}`
      );
      setSocket(socket);
    }
  };

  // call this function when you want to authenticate the user
  const login = async (data: any, redirect?: boolean) => {
    setToken(data?.jwt);
    setUser(data.user);
    createSocketConnection(data.jwt);

    if (redirect !== false) {
      const redirectPath = localStorage.getItem("redirect-path");
      if (redirectPath) {
        navigate(redirectPath);
      }
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null);
    setUser(null);
    if (socket) {
      socket.close();
    }
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user: user,
    }),
    [token, user]
  );

  useEffect(() => {
    if (userResponse?.data) {
      setUser(userResponse.data);
      if (!socket) {
        createSocketConnection(token);
        console.log(socket, "doesno't have connection ");
      }
    }
  }, [userResponse?.data]);

  if (userResponse?.isLoading || meResponse?.isLoading) {
    return <Loader className="h-screen" />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
