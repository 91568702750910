import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import NotificationIcon from "../../icons/BellIcon";
import { Typo } from "../typo";
import Button from "../button";
import EyeIcon from "../../icons/EyeIcon";
import { NotificationModel } from "../../../model/Notification";
import NotificationItem from "./NotificationItem";
import { FormattedMessage, IntlShape } from "react-intl";
import { ROUTES } from "../../../constants/routes";
import { TRANSLATION } from "../../../static/translation";

type Props = {
  notifications?: NotificationModel[];
  count?: number;
  unread?: number;
  intl: IntlShape;
};

const NotificationDropdown: React.FC<Props> = (props) => {
  const { notifications, unread, count, intl } = props;
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <button
          type="button"
          className="-m-2.5 p-2.5 relative text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">View notifications</span>
          <NotificationIcon />
          {unread && unread > 0 ? <div className=" rounded-full absolute top-3 start-2 bg-error-300 text-white font-iransans px-[3px] text-[8px]">
            {unread}
          </div> : ""}
        </button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute end-0 sm:-end-10 lg:-end-40 z-10 mt-5 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 py-0 ring-gray-900/5 focus:outline-none">
          <div className="py-2 bg-gray-50 w-full rounded-t-lg">
            <Typo.heading2
              fontWeight="font-medium"
              className="text-center text-gray-900"
            >
              <FormattedMessage
                id={TRANSLATION.MANAGE_MESSAGES.id}
                defaultMessage={TRANSLATION.MANAGE_MESSAGES.defaultMessage}
              />
            </Typo.heading2>
          </div>
          <div className="divide-y-[1px] divide-gray-200">
            {unread && unread > 0 ? notifications?.map((item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <NotificationItem
                    notification={item}
                    intl={intl}
                  ></NotificationItem>
                )}
              </Menu.Item>
            )) : ""}
          </div>
          <div className="py-2 bg-gray-50 w-full rounded-b-lg">
            <Menu.Item>
              <Button
                to={ROUTES.DASHBOARD_MESSAGES_ROUTE}
                theme="primary-link"
                className="w-full"
                icon={<EyeIcon className="h-5 text-gray-900" />}
              >
                <Typo.heading2 fontWeight="font-medium" className="text-center text-gray-900">
                  <FormattedMessage
                    id={TRANSLATION.VIEW_ALL.id}
                    defaultMessage={TRANSLATION.VIEW_ALL.defaultMessage}
                  />
                </Typo.heading2>
              </Button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationDropdown;
