import { FC, createContext, useContext, useState } from "react";
type Props = {
  children: React.ReactElement;
};

interface Socket {
  socket?: WebSocket;
  setSocket: (socket: WebSocket) => void;
}

const SocketContext = createContext<Socket>(null!);
const SocketProvider: FC<Props> = ({ children }) => {
  const [webSocket, setWebSocket] = useState<WebSocket>();
  // Example client-side code (browser)
  // const webSocket = new WebSocket(
  //   `ws://localhost:1336/socket?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTA1LCJpYXQiOjE3MTE0MzUwNzYsImV4cCI6MTcxNDQ1OTA3Nn0.sLGK8z2fwSrjtEahpz9Trb4-pQN27juTa-6YoXh1_nQ`
  // );

  // const [socket, setSocket] = useState();
  return (
    <SocketContext.Provider
      value={{ socket: webSocket, setSocket: setWebSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const SocketState = () => {
  const socketInstance = useContext(SocketContext);
  return socketInstance;
};

export default SocketProvider;
