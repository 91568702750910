import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import ChevronDownIcon from "../icons/CheveronDownIcon";
import Image from "./Image";
import { User } from "../../model/User";
import userIcon from "../../assets/image/profileAvatar.png";
import { useIntl } from "react-intl";
import { TRANSLATION } from "../../static/translation";
import { Typo } from "./typo";
import Button from "./button";
import { ROUTES } from "../../constants/routes";
type Props = {
  user: User | null;
  onLogout: () => void
};

const ProfileDropdown: React.FC<Props> = (props) => {
  const { user, onLogout } = props;
  const intl = useIntl();
  const userNavigation = [
    {
      name: intl.formatMessage({
        id: TRANSLATION.PROFILE.id,
        defaultMessage: TRANSLATION.PROFILE.defaultMessage
      })
      , href: ROUTES.SETTINGS_ROUTE
    },
    {
      name: intl.formatMessage({
        id: TRANSLATION.LOGOUT.id,
        defaultMessage: TRANSLATION.LOGOUT.defaultMessage
      }), href: ""
    },
  ];
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        <div>
          <Image
            className="h-10 w-10 rounded-full"
            src={user?.image ? user?.image : userIcon}
            alt=""
          />
        </div>
        <span className="hidden lg:flex lg:items-center">
          <span
            className="mr-4 text-sm font-semibold leading-6 text-gray-900"
            aria-hidden="true"
          >
            {user?.name}
          </span>
          <ChevronDownIcon className="h-7 w-7 text-gray-400" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute end-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation?.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Button
                  to={item.href !== "" ? item.href : undefined}
                  rounded="rounded"
                  onClick={() => {
                    if (item.href === "") {
                      onLogout && onLogout()
                    }
                  }}
                  className={clsx(
                    active ? "bg-gray-50" : "",
                    "block w-full px-3 py-1 text-sm leading-6 text-gray-900"
                  )}
                >
                  <Typo.heading5 fontWeight="font-medium">
                    {item.name}
                  </Typo.heading5>
                </Button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
