type Props = {
  className?: string;
};
const SecurityUserIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2137_35228)">
        <path
          d="M10 0C7.67226 0 5.16018 0.771224 3.94737 2.14286L4.88487 3.33333H15.1151L16.0526 2.14286C14.8398 0.771224 12.3277 0 10 0ZM5.98684 4.28571C6.15705 4.56152 6.4284 4.83339 6.9079 5.0744C7.66575 5.45533 8.77097 5.71429 10 5.71429C11.229 5.71429 12.3343 5.45533 13.0921 5.0744C13.5716 4.83339 13.843 4.56152 14.0132 4.28571H5.98684ZM5.32895 5.14137C5.68335 9.24104 7.63493 12.381 10 12.381C12.3651 12.381 14.3167 9.24104 14.6711 5.14137C14.3869 5.43921 14.0205 5.69739 13.602 5.90774C12.6454 6.38854 11.3871 6.66667 10 6.66667C8.61295 6.66667 7.35458 6.38854 6.39803 5.90774C5.97954 5.69739 5.61309 5.43921 5.32895 5.14137ZM6.41447 11.369L2.63158 12.8571C1.3276 13.447 0 15.1095 0 16.4286V20H6.74342L8.46217 13.0134C7.66884 12.6748 6.98116 12.0911 6.41447 11.369ZM13.5855 11.369C13.0188 12.0911 12.3312 12.6748 11.5378 13.0134L13.2072 20H20V16.4286C20 15.1095 18.7054 13.3831 17.3684 12.8571L13.5855 11.369ZM9.46546 13.2961L7.8125 20H12.1217L10.5263 13.2961C10.3542 13.3198 10.1794 13.3333 10 13.3333C9.81779 13.3333 9.64013 13.3205 9.46546 13.2961Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_35228">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecurityUserIcon;
