import { FC } from "react";
import Modal from "../wrapper/modal";
import InputField from "../components/shared/InputField";
import Button from "../components/shared/button";
import { useHandelSignup } from "../hooks/signup/useHandelSignup";
import { useHandelLogin } from "../hooks/login/useHandelLogin";
import Image from "../components/shared/Image";
import { Typo } from "../components/shared/typo";
import afLogo from "../../src/assets/image/logo.svg";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../lib/reducers/modal.slice";
import { GoogleLogin } from "@react-oauth/google";
import FacebookCircleIcon from "../components/icons/FacebookCircleIcon";
import Divider from "../components/shared/Divider";
import { handelInputMap } from "../util/handelInputMap";
import FacebookLogin, {
  RenderProps,
} from "react-facebook-login/dist/facebook-login-render-props";
import { FormattedMessage, useIntl } from "react-intl";
import { TRANSLATION } from "../static/translation";
import Alert from "../components/shared/Alert";
type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
};

const SignUp: FC<Props> = ({ open, onClose }) => {
  const intl = useIntl();
  const { register, errors, handleSubmit, onSubmit, loading, alert, setAlert } =
    useHandelSignup();
  const { responseFacebook, onGoogleLogin } = useHandelLogin();
  const dispatch = useDispatch();
  const onSignUp = async (data: any) => {
    try {
      await onSubmit(data);
    } catch (error) { }
  };

  return (
    <Modal className=" max-w-md" open={open} onClose={() => onClose(false)}>
      <div className="w-full">
        <div className=" items-center">
          <Image className="h-14 w-14 mx-auto" src={afLogo} alt="logo" />
          <Typo.heading1
            fontWeight="font-bold"
            className=" text-secondary-900 text-center mt-6"
          >
            <FormattedMessage
              id={TRANSLATION.REGISTER_TITLE.id}
              defaultMessage={TRANSLATION.REGISTER_TITLE.defaultMessage}
            />
          </Typo.heading1>
          <Typo.heading5 className="text-gray-400 mt-2 text-center">
            <FormattedMessage
              id={TRANSLATION.REGISTER_DESCRIPTION.id}
              defaultMessage={TRANSLATION.REGISTER_DESCRIPTION.defaultMessage}
            />
          </Typo.heading5>
          <Alert
            type={alert?.type}
            title={alert?.title}
            description={alert?.description}
            open={alert?.open || false}
            setOpen={() =>
              setAlert((prevAlert: any) => ({
                ...prevAlert,
                open: false,
              }))
            }
          />
        </div>
        <form
          id="signup"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSignUp)}
        >
          <div>
            <div className="w-full mt-5">
              <InputField
                name="name"
                placeholder={handelInputMap(intl, "name_placeholder")}
                label={handelInputMap(intl, "name")}
                register={register}
                errors={errors?.name}
                required={true}
              />
            </div>
            <div className="w-full mt-5">
              <InputField
                name="email"
                placeholder={handelInputMap(intl, "email_placeholder")}
                type="email"
                label={handelInputMap(intl, "email")}
                register={register}
                errors={errors?.email}
                required={true}
              />
            </div>
            <div className="w-full mt-5">
              <InputField
                name="password"
                placeholder={handelInputMap(intl, "password_placeholder")}
                label={handelInputMap(intl, "password")}
                type="password"
                register={register}
                errors={errors?.password}
                required={true}
              />
            </div>
          </div>
          <div className=" flex flex-col space-y-2 pt-8">
            <Button
              loading={loading}
              size="medium"
              type="submit"
              theme="primary"
            >
              <Typo.heading4 fontWeight="font-bold" className="text-white">
                <FormattedMessage
                  id={TRANSLATION.REGISTER.id}
                  defaultMessage={TRANSLATION.REGISTER.defaultMessage}
                />
              </Typo.heading4>
            </Button>
            <div className="relative justify-center py-6">
              <Divider type="horizontal"></Divider>
              <div className=" text-gray-400 text-sm font-medium px-2 font-iransans bg-white absolute top-4 w-28 left-0 right-0 mx-auto ">
                <FormattedMessage
                  id={TRANSLATION.CONTINUE_WITH.id}
                  defaultMessage={TRANSLATION.CONTINUE_WITH.defaultMessage}
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <FacebookLogin
                appId={`${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`}
                autoLoad={false}
                fields="name,email,picture"
                render={(renderProps: RenderProps) => (
                  <Button
                    theme="gray"
                    size="medium"
                    onClick={renderProps.onClick}
                    icon={
                      <FacebookCircleIcon className="text-[#0966FF] h-5 rounded-full" />
                    }
                  >
                    <Typo.heading4 fontWeight="font-medium" className="w-full">
                      <FormattedMessage
                        id={TRANSLATION.REGISTER_WITH_FACEBOOK.id}
                        defaultMessage={
                          TRANSLATION.REGISTER_WITH_FACEBOOK.defaultMessage
                        }
                      />
                    </Typo.heading4>
                  </Button>
                )}
                callback={responseFacebook}
              />
              <GoogleLogin
                onSuccess={onGoogleLogin}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
            <div className="py-2 flex items-center justify-center">
              <Button className="" theme="label">
                <Typo.heading4 fontWeight="font-medium">
                  <FormattedMessage
                    id={TRANSLATION.DO_YOUT_HAVE_ACCOUNT.id}
                    defaultMessage={
                      TRANSLATION.DO_YOUT_HAVE_ACCOUNT.defaultMessage
                    }
                  />
                  <span
                    onClick={() => {
                      dispatch(hideModal("signup"));
                      dispatch(showModal("login"));
                    }}
                    className=" text-primary-700"
                  >
                    <FormattedMessage
                      id={TRANSLATION.LOGIN.id}
                      defaultMessage={TRANSLATION.LOGIN.defaultMessage}
                    />
                  </span>
                </Typo.heading4>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignUp;
