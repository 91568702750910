import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Fetch } from "../../lib/https";
import { useState } from "react";
import Compressor from "compressorjs";

// Define the type of the onClose parameter
type props = (state: boolean) => void;
export const useHandelFile = (onClose?: props) => {
  const [loading, setLoding] = useState(false);
  const schema = object().shape({
    title: string().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await Fetch({
      endpoint: "/file",
      method: "POST",
      hasFile: true,
      data: formData,
    });
    return response;
  };

  const onCompresed = async (file: File) => {
    const compressedFile = await new Promise<File>((resolve) => {
      new Compressor(file, {
        quality: 0.5,
        success: (result: any) => {
          resolve(result);
        },
        error: (error) => {
          console.error("Compression Error: ", error);
          resolve(file);
        },
      });
    });
    return compressedFile;
  };


  return {
    register,
    loading,
    setLoding,
    onCompresed,
    errors,
    handleSubmit,
    onUpload,
    reset,
    setValue,
  };
};
